import React, { useEffect, useState } from 'react';
import './Categories.scss';
import { spleisUrl } from '../../config';
import api from '../../api';
import { showError } from '../../store';
import Button from '../../Button/Button';
import CategoriesLineChart from './CategoriesLineChart';
import CategoriesBarChart from './CategoriesBarChart';
import moment from 'moment';
import { Helmet } from 'react-helmet';

const colors = [
    '#ebac23',
    '#b80058',
    '#008cf9',
    '#006e00',
    '#00bbad',
    '#d163e6',
    '#b24502',
    '#ff9287',
    '#5954d6',
    '#00c6f8',
    '#878500',
    '#00a76c',
    '#bdbdbd',
];

const capitalize = (s) => s[0].toUpperCase() + s.slice(1);
export const dateToMonthAndYear = (value) => capitalize(moment(value, 'YYYY-MM-DD').format("MMM 'YY"));

const receiverTypes = [
    { display: 'Alle', id: 'all' },
    { display: 'Organisasjon', id: 'organization' },
    { display: 'Privat til annen bruker', id: 'other_user' },
    { display: 'Privat egeninnsamling', id: 'user' },
    { display: 'Privat', id: 'any_user' },
];

const Categories = () => {
    const [fetching, setFetching] = useState(false);
    const [error, setError] = useState(false);
    const [categoriesPerMonth, setCategoriesPerMonth] = useState([]);
    const [categories, setCategories] = useState([]);
    const [startMonth, setStartMonth] = useState(0);
    const [endMonth, setEndMonth] = useState(1);
    const [receiverType, setReceiverType] = useState([]);

    const monthOptions = [...Array(categoriesPerMonth.length).keys()].map((month) => (
        <option key={month} value={month}>
            {dateToMonthAndYear(categoriesPerMonth[month]?.date)}
        </option>
    ));

    const checkAndSetStartMonth = (val) => {
        if (endMonth < val) {
            setEndMonth(val);
        }
        setStartMonth(val);
    };

    const checkAndSetEndMonth = (val) => {
        if (startMonth > val) {
            setStartMonth(val);
        }
        setEndMonth(val);
    };

    const toggleCategory = (categoryName) => {
        const updatedCategories = categories.map((category) => ({
            ...category,
            enabled: category.name === categoryName ? !category.enabled : category.enabled,
        }));
        setCategories(updatedCategories);
    };

    const enableOrDisableAllCategories = (enabled) => {
        const updatedCategories = categories.map((category) => ({
            ...category,
            enabled,
        }));
        setCategories(updatedCategories);
    };

    useEffect(() => {
        let aborted = false;
        setFetching(true);
        api.get(`${spleisUrl}/api/admin/project/stats/category-volume-per-month?receiverType=${receiverType}`)
            .then((res) => {
                if (!aborted) {
                    setCategoriesPerMonth(res);
                    const categoryNames = Object.keys(res?.[0] || [])
                        .filter((key) => key !== 'date')
                        .sort();
                    setCategories(
                        categoryNames.map((cat, index) => ({
                            name: cat,
                            color: colors[index % colors.length],
                            enabled: true,
                        }))
                    );
                    setStartMonth(0);
                    setEndMonth(res.length - 1);
                    setError(false);
                }
            })
            .catch(() => {
                if (!aborted) {
                    setError(true);
                    showError('Feil ved henting av stats');
                }
            })
            .finally(() => {
                if (!aborted) {
                    setFetching(false);
                }
            });
        return () => {
            aborted = true;
        };
    }, [receiverType]);

    return (
        <>
            <Helmet>
                <title>Kategorier</title>
            </Helmet>
            <header className="task-header">
                <h1 className="task-title">Kategorier</h1>
            </header>
            <div className="task-body">
                {fetching ? (
                    <div>Henter...</div>
                ) : error ? (
                    <div>Feil :(</div>
                ) : (
                    <>
                        <div className="category-section">
                            <center>
                                {categories.map((category) => (
                                    <div key={category.name} style={{ display: 'inline-block' }}>
                                        <label className="category-label">
                                            <input
                                                type="checkbox"
                                                checked={category.enabled}
                                                onChange={() => {
                                                    toggleCategory(category.name);
                                                }}
                                            />
                                            <span style={{ color: category.color }}>{category.name}</span>
                                        </label>
                                    </div>
                                ))}
                            </center>
                        </div>
                        <div className="category-section">
                            <center>
                                <Button
                                    className="btn category-toggle-button"
                                    onClick={() => enableOrDisableAllCategories(true)}
                                    disabled={categories.every((cat) => cat.enabled)}
                                >
                                    Velg alle
                                </Button>
                                <Button
                                    className="btn category-toggle-button"
                                    onClick={() => enableOrDisableAllCategories(false)}
                                    disabled={categories.every((cat) => !cat.enabled)}
                                >
                                    Velg ingen
                                </Button>
                            </center>
                        </div>
                        <div className={'category-section'}>
                            <center>
                                <h2>Type mottaker</h2>
                                <select onChange={(e) => setReceiverType(e.target.value)} value={receiverType}>
                                    {receiverTypes.map((st) => (
                                        <option key={st.id} value={st.id}>
                                            {st.display}
                                        </option>
                                    ))}
                                </select>
                            </center>
                        </div>
                        <div className="category-section">
                            <center>
                                <label className="category-label">
                                    Fra:&nbsp;
                                    <select
                                        onChange={(e) => checkAndSetStartMonth(parseInt(e.target.value))}
                                        value={startMonth}
                                    >
                                        {monthOptions}
                                    </select>
                                </label>
                                <label className="category-label">
                                    Til:&nbsp;
                                    <select
                                        onChange={(e) => checkAndSetEndMonth(parseInt(e.target.value))}
                                        value={endMonth}
                                    >
                                        {monthOptions}
                                    </select>
                                </label>
                            </center>
                        </div>
                        <CategoriesBarChart
                            categories={categories}
                            categoriesPerMonth={categoriesPerMonth}
                            startMonth={startMonth}
                            endMonth={endMonth}
                        />
                        <CategoriesLineChart
                            categories={categories}
                            categoriesPerMonth={categoriesPerMonth}
                            startMonth={startMonth}
                            endMonth={endMonth}
                        />
                    </>
                )}
            </div>
        </>
    );
};

export default Categories;
