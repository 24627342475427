import React, { useState } from 'react';
import Button from '../Button/Button';
import api from '../api';
import moment from 'moment/moment';
import { showError } from '../store';
import Modal from 'react-modal';
import styles from './SelectPrizesAndDownloadCsv.module.scss';

export const SelectPrizesAndDownloadCsv = ({ url, prizes, title }) => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [selectedPrizes, setSelectedPrizes] = useState(prizes || []);

    const fetchCSV = () => {
        const queryParams = selectedPrizes.length > 0 ? `?prizes=${selectedPrizes}` : '';
        api.get(`${url}.csv${queryParams}`)
            .then((blob) => {
                const a = document.createElement('a');
                a.style.display = 'none';
                a.setAttribute('href', 'data:text/csv; charset=utf-8,' + encodeURIComponent(blob));
                a.download = `${title}_order_${moment().format('YYYY-MM-DD_HH-mm')}.csv`;
                document.body.appendChild(a);
                a.click();
            })
            .catch((e) => {
                console.log(e);
                showError('Feil ved nedlasting av csv-fil');
            });
    };

    const handleClickCsv = () => {
        if (prizes?.length > 0) {
            setModalIsOpen(true);
        } else {
            fetchCSV();
        }
    };

    const handleCheckboxChange = (prize) => {
        if (selectedPrizes.includes(prize)) {
            setSelectedPrizes(selectedPrizes.filter((sp) => sp !== prize));
        } else {
            setSelectedPrizes([...selectedPrizes, prize]);
        }
    };

    return (
        <>
            <Modal isOpen={modalIsOpen} overlayClassName={styles.modalWrapper} className={styles.modalContainer}>
                <h2>Velg premier som skal sendes ut</h2>
                <div className={styles.prizes}>
                    {prizes?.map((prize) => {
                        const id = `prize-${prize}`;
                        return (
                            <div key={prize}>
                                <label>
                                    <input
                                        type="checkbox"
                                        checked={selectedPrizes.includes(prize)}
                                        onChange={(e) => handleCheckboxChange(prize)}
                                    />
                                    &nbsp;{prize}
                                </label>
                            </div>
                        );
                    })}
                </div>
                <div className={styles.buttons}>
                    <Button
                        className={[styles.button, 'btn-cta'].join(' ')}
                        disabled={selectedPrizes?.length < 1}
                        onClick={fetchCSV}
                    >
                        Last ned csv
                    </Button>
                    <Button className={styles.button} onClick={() => setModalIsOpen(false)}>
                        Lukk
                    </Button>
                </div>
            </Modal>
            <Button className={styles.csvButton} onClick={handleClickCsv}>
                Last ned csv-fil for sending
            </Button>
        </>
    );
};

export default SelectPrizesAndDownloadCsv;
