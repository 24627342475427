import React, { useState } from 'react';
import FormButton from '../../FormButton/FormButton';
import styles from './OverrideOwnerName.module.scss';
import api from '../../api';
import { spleisUrl } from '../../config';
import { showError, showInfo } from '../../store';

const OverrideOwnerName = ({ project }) => {
    const [ownerName, setOwnerName] = useState(project?.profile?.overrideOwnerName || '');

    const onSubmit = () => {
        api.post(`${spleisUrl}/api/admin/project/${project.id}/override-owner-name`, {
            ownerName,
        })
            .then(() => {
                showInfo('Endret navn som vises for innsamler');
                window.notify();
            })
            .catch((err) => {
                showError('Klarte ikke å endre navnet som vises');
            });
    };

    return (
        <FormButton onClick={onSubmit} title="Vis annet navn">
            <div className={styles.formElement}>
                <input
                    type="text"
                    className={styles.input}
                    value={ownerName}
                    onChange={(e) => setOwnerName(e.target.value)}
                />
            </div>
        </FormButton>
    );
};

export default OverrideOwnerName;
