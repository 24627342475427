import React, { useEffect, useState } from 'react';
import TaskContainer from '../TaskContainer/TaskContainer';
import api from '../api';
import { spleisUrl } from '../config';
import { showError } from '../store';
import SchoolEvent from './SchoolEvent';
import { Helmet } from 'react-helmet';

const SchoolEvents = () => {
    const [schoolEvents, setSchoolEvents] = useState([]);
    const [selectedSchoolEvent, setSelectedSchoolEvent] = useState([]);

    useEffect(() => {
        api.get(`${spleisUrl}/api/admin/school/event`)
            .then((res) => {
                setSchoolEvents(res);
                if (res?.length > 0) {
                    setSelectedSchoolEvent(res[0]);
                }
            })
            .catch(() => showError('Feil ved henting av skoleeventer'));
    }, []);

    return (
        <TaskContainer>
            <Helmet>
                <title>Skoleeventer</title>
            </Helmet>
            <header className="task-header">
                <h1 className="task-title">Skoleeventer</h1>
            </header>
            <div className="task-body">
                <select
                    className="schools-section"
                    onChange={(e) =>
                        setSelectedSchoolEvent(schoolEvents.find((se) => se.school_event_name === e.target.value))
                    }
                >
                    {schoolEvents.map((schoolEvent) => {
                        return (
                            <option key={schoolEvent.school_event_name} value={schoolEvent.school_event_name}>
                                {schoolEvent.event_name}
                            </option>
                        );
                    })}
                </select>
                {selectedSchoolEvent && <SchoolEvent eventName={selectedSchoolEvent.school_event_name} />}
            </div>
        </TaskContainer>
    );
};

export default SchoolEvents;
