import React from 'react';
import styles from './User.module.scss';

const PhoneNumberLink = ({ phoneNumber, isButton }) =>
    isButton ? (
        <button
            className={styles.phoneLinkButton}
            onClick={e => {
                e.preventDefault();
                console.log('Lets go');
                window.open('https://www.1881.no/?query=' + phoneNumber);
                console.log('Lets go');
            }}
        >
            {phoneNumber}
        </button>
    ) : (
        <a target="_blank" href={'https://www.1881.no/?query=' + phoneNumber} rel="noopener noreferrer">
            {phoneNumber}
        </a>
    );

export default PhoneNumberLink;
