import React, { useEffect, useState } from 'react';
import { showError } from '../store';
import api from '../api';
import { paymentUrl, spleisUrl } from '../config';
import TaskContainer from '../TaskContainer/TaskContainer';
import { useParams } from 'react-router';
import { formatMoneyKr } from '../formatters';
import UserLink from '../SmartLinks/UserLink';
import InvoiceList from '../Invoice/InvoiceList';
import ChangeInvoiceEmail from './ChangeInvoiceEmail';
import SendInvoice from './SendInvoice';
import ChangeSubscriptionState from './ChangeSubscriptionState';
import ChangeSubscriptionType from './ChangeSubscriptionType';
import './Subscription.scss';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const Subscription = () => {
    const [subscription, setSubscription] = useState(null);
    const [organization, setOrganization] = useState(null);
    const [invoices, setInvoices] = useState([]);
    const [user, setUser] = useState([]);
    const params = useParams();
    const subscriptionId = params.id;
    const organizationId = params.organizationId;

    const fetchData = () => {
        if (subscriptionId) {
            api.get(`${spleisUrl}/api/admin/subscription/${subscriptionId}`)
                .then((subscription) => {
                    setSubscription(subscription);
                    return subscription;
                })
                .then((subscription) => {
                    if (subscription) {
                        api.get(`${spleisUrl}/api/admin/organization/${subscription?.organization_id}`).then((res) => {
                            setOrganization(res);
                        });
                    }
                    return subscription;
                })
                .then((subscription) => {
                    if (subscription) {
                        api.get(`${spleisUrl}/api/admin/user/${subscription?.user_id}`).then((res) => {
                            setUser(res);
                        });
                    }
                })
                .catch(() => showError('Feil ved henting av abonnement'));
            api.get(`${paymentUrl}/api/invoice/subscription/${subscriptionId}`)
                .then((res) => setInvoices(res))
                .catch(() => showError('Feil ved hening av faktura'));
        } else if (organizationId) {
            api.get(`${spleisUrl}/api/admin/subscription/organization/${organizationId}`)
                .then((subscription) => {
                    setSubscription(subscription);
                    return subscription;
                })
                .then((subscription) => {
                    if (subscription && subscription?.user_id) {
                        api.get(`${spleisUrl}/api/admin/user/${subscription?.user_id}`).then((res) => {
                            setUser(res);
                        });
                    }
                    return subscription;
                })
                .then((subscription) => {
                    if (subscription) {
                        api.get(`${paymentUrl}/api/invoice/subscription/${subscription?.id}`).then((res) =>
                            setInvoices(res)
                        );
                    }
                })
                .catch(() => showError('Feil ved henting av abonnement'));
            api.get(`${spleisUrl}/api/admin/organization/${organizationId}`)
                .then((res) => {
                    setOrganization(res);
                    return subscription;
                })
                .catch(() => showError('Feil ved henting av organisasjon'));
        }
    };

    window.subscribe(fetchData);

    useEffect(() => {
        fetchData();
    }, [subscriptionId, organizationId]);

    if (!subscription && !organization) {
        return <p>Mangler abonnement eller organisasjon</p>;
    }
    const invoiceEmailAddress = organization?.invoice_information?.email || user?.email;
    const invoiceEmailSendMethod = organization?.invoice_information?.ehf ? 'via EHF' : 'via e-post';
    const active = subscription?.active;
    return (
        <TaskContainer>
            <Helmet>
                <title>{organization?.name || ''} - Abonnement</title>
            </Helmet>
            <header className="task-header">
                <h1 className="task-title">
                    {organization?.name} {!active && '(Inaktiv)'}
                </h1>
            </header>
            <div className={'subscription-info'}>
                <span className={'subscription-label'}>Organisasjon</span>
                <span className={'subscription-value'}>
                    <Link to={`/organization/${organization?.id}`}>{organization?.name}</Link>
                </span>
                <span className={'subscription-label'}>Type abonnement</span>
                <span className={'subscription-value'}>
                    {!subscription ? (
                        'Ingen abonnement'
                    ) : (
                        <>
                            <Link to={`/subscription-type/${subscription?.subscription_type?.name}`}>
                                {subscription?.subscription_type?.displayTitle}
                            </Link>{' '}
                            (<ChangeSubscriptionType organizationId={organization?.id} />)
                        </>
                    )}
                </span>
                <span className={'subscription-label'}>Pris</span>
                <span className={'subscription-value'}>
                    {subscription && formatMoneyKr(subscription?.subscription_type?.price)}
                </span>
                <span className={'subscription-label'}>Aktiv</span>
                <span className={'subscription-value'}>
                    {active ? 'Aktiv' : 'Ikke aktiv'} (
                    <ChangeSubscriptionState subscriptionState={active} subscriptionId={subscription?.id} />)
                </span>
                <span className={'subscription-label'}>Initativtaker</span>
                <span className={'subscription-value'}>
                    {user?.first} {user?.last} {user && <UserLink id={user?.id} />}
                </span>
                <span className={'subscription-label'}>Faktura-adresse</span>
                <span className={'subscription-value'}>
                    {`${invoiceEmailAddress} (${invoiceEmailSendMethod})`} (
                    <ChangeInvoiceEmail
                        currentInvoiceEmailAddress={invoiceEmailAddress}
                        currentEhf={organization?.invoice_information?.ehf}
                        organizationId={organization?.id}
                    />
                    )
                </span>
            </div>
            <SendInvoice subscriptionId={subscription?.id} />
            <InvoiceList invoices={invoices} />
        </TaskContainer>
    );
};

export default Subscription;
