import React, { useEffect, useState } from 'react';
import qs from 'qs';
import TaskContainer from '../TaskContainer/TaskContainer';
import api from '../api';
import { spleisUrl } from '../config';
import { showError } from '../store';
import { formatMoneyKr } from '../formatters';
import './ProjectStats.scss';
import { ProjectTagsInput } from '../ProjectTags/ProjectTags';
import { Categories } from '../ProjectCategories/ProjectCategories';
import Button from '../Button/Button';
import ProjectStatsEvents from './ProjectStatsEvents';
import { Link } from 'react-router-dom';
import ProjectStatsOrganizations from './ProjectStatsOrganizations';
import { Helmet } from 'react-helmet';

const minDate = '2016-01-01';
const maxDate = new Date().toISOString().slice(0, 10);
const firstDayOfYear = `${new Date().getFullYear()}-01-01`;

const statsTypes = [
    {
        id: 'volumeTotal',
        display: 'Volum',
        showResult: (stats) => (
            <div>
                <div>Volum: {formatMoneyKr(stats.result.volume)}</div>
                <div>Til privat: {formatMoneyKr(stats.result.volume_to_user)}</div>
                <div>Til org: {formatMoneyKr(stats.result.volume_to_org)}</div>
            </div>
        ),
    },
    {
        id: 'volumePerYear',
        display: 'Volum per år',
        showResult: (stats) => (
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th>År</th>
                        <th>Volum</th>
                        <th>Til privat</th>
                        <th>Til org</th>
                    </tr>
                </thead>
                <tbody>
                    {stats.result.map((r) => (
                        <tr key={r.year}>
                            <td>{r.year}</td>
                            <td>{formatMoneyKr(r.volume)}</td>
                            <td>{formatMoneyKr(r.volume_to_user)}</td>
                            <td>{formatMoneyKr(r.volume_to_org)}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        ),
        toCsvRows: (stats) =>
            'Year,Volume,Privat,Org\n' +
            stats.result.map((r) => [r.year, r.volume, r.volume_to_user, r.volume_to_org].join(',')).join('\n'),
    },
    {
        id: 'volumePerMonth',
        display: 'Volum per måned',
        showResult: (stats) => (
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th>Måned</th>
                        <th>Volum</th>
                        <th>Til privat</th>
                        <th>Til org</th>
                    </tr>
                </thead>
                <tbody>
                    {stats.result.map((r) => (
                        <tr key={r.mnd}>
                            <td>{r.mnd}</td>
                            <td>{formatMoneyKr(r.volume)}</td>
                            <td>{formatMoneyKr(r.volume_to_user)}</td>
                            <td>{formatMoneyKr(r.volume_to_org)}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        ),
        toCsvRows: (stats) =>
            'Month,Volume,Privat,Org\n' +
            stats.result.map((r) => [r.mnd, r.volume, r.volume_to_user, r.volume_to_org].join(',')).join('\n'),
    },
    {
        id: 'volumePerWeek',
        display: 'Volum per uke',
        showResult: (stats) => (
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th>Uke</th>
                        <th>Volum</th>
                        <th>Til privat</th>
                        <th>Til org</th>
                    </tr>
                </thead>
                <tbody>
                    {stats.result.map((r) => (
                        <tr key={r.week}>
                            <td>{r.week}</td>
                            <td>{formatMoneyKr(r.volume)}</td>
                            <td>{formatMoneyKr(r.volume_to_user)}</td>
                            <td>{formatMoneyKr(r.volume_to_org)}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        ),
        toCsvRows: (stats) =>
            'Week,Volume,Privat,Org\n' +
            stats.result.map((r) => [r.week, r.volume, r.volume_to_user, r.volume_to_org].join(',')).join('\n'),
    },
    {
        id: 'volumePerDay',
        display: 'Volum per dag',
        showResult: (stats) => (
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th>Dag</th>
                        <th>Volum</th>
                        <th>Til privat</th>
                        <th>Til org</th>
                    </tr>
                </thead>
                <tbody>
                    {stats.result.map((r) => (
                        <tr key={r.day}>
                            <td>{r.day}</td>
                            <td>{formatMoneyKr(r.volume)}</td>
                            <td>{formatMoneyKr(r.volume_to_user)}</td>
                            <td>{formatMoneyKr(r.volume_to_org)}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        ),
        toCsvRows: (stats) =>
            'Day,Volume,Privat,Org\n' +
            stats.result.map((r) => [r.day, r.volume, r.volume_to_user, r.volume_to_org].join(',')).join('\n'),
    },
    {
        id: 'volumePerPricingFunctionName',
        display: 'Volum per prisfunksjon',
        showResult: (stats) => (
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th>Prisfunksjon</th>
                        <th>Volum</th>
                    </tr>
                </thead>
                <tbody>
                    {stats.result.map((r) => (
                        <tr key={r.day}>
                            <td>{r.pricingfunctionname}</td>
                            <td>{formatMoneyKr(r.volume)}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        ),
        toCsvRows: (stats) =>
            'PricingFunction,Volume\n' +
            stats.result.map((r) => [r.pricingfunctionname, r.volume].join(',')).join('\n'),
    },
    {
        id: 'projectIds',
        display: 'Prosjekt-IDer som ble opprettet i tidsrommet',
        showResult: (stats) => (
            <div>
                <div className="stats-hits">Antall: {stats.result?.length || 0}</div>
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>Prosjekt ID</th>
                        </tr>
                    </thead>
                    <tbody>
                        {stats.result.map((r) => (
                            <tr key={r.project_id}>
                                <td>
                                    <Link to={`/project/${r.project_id}`}>{r.project_id}</Link>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        ),
        toCsvRows: (stats) => 'Project ID\n' + stats.result.map((r) => r.project_id).join('\n'),
    },
    {
        id: 'projectIdsWithTransactionsInPeriod',
        display: 'Prosjekt-IDer som fikk innbetalinger i tidsrommet',
        showResult: (stats) => (
            <div>
                <div className="stats-hits">Antall: {stats.result?.length || 0}</div>
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>Prosjekt ID</th>
                        </tr>
                    </thead>
                    <tbody>
                        {stats.result.map((r) => (
                            <tr key={r.project_id}>
                                <td>
                                    <Link to={`/project/${r.project_id}`}>{r.project_id}</Link>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        ),
        toCsvRows: (stats) => 'Project ID\n' + stats.result.map((r) => r.project_id).join('\n'),
    },
    {
        id: 'projectCountPerYear',
        display: 'Antall prosjekter opprettet per år',
        showResult: (stats) => (
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th>År</th>
                        <th>Antall</th>
                        <th>Til privat</th>
                        <th>Til org</th>
                    </tr>
                </thead>
                <tbody>
                    {stats.result.map((r) => (
                        <tr key={r.year}>
                            <td>{r.year}</td>
                            <td>{r.project_count}</td>
                            <td>{r.project_count_to_user}</td>
                            <td>{r.project_count_to_org}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        ),
        toCsvRows: (stats) =>
            'År,Antall,Privat,Org\n' +
            stats.result
                .map((r) => [r.year, r.project_count, r.project_count_to_user, r.project_count_to_org].join(','))
                .join('\n'),
    },
    {
        id: 'projectCountPerMonth',
        display: 'Antall prosjekter opprettet per måned',
        showResult: (stats) => (
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th>Måned</th>
                        <th>Antall</th>
                        <th>Til privat</th>
                        <th>Til org</th>
                    </tr>
                </thead>
                <tbody>
                    {stats.result.map((r) => (
                        <tr key={r.mnd}>
                            <td>{r.mnd}</td>
                            <td>{r.project_count}</td>
                            <td>{r.project_count_to_user}</td>
                            <td>{r.project_count_to_org}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        ),
        toCsvRows: (stats) =>
            'Mnd,Antall,Privat,Org\n' +
            stats.result
                .map((r) => [r.mnd, r.project_count, r.project_count_to_user, r.project_count_to_org].join(','))
                .join('\n'),
    },
    {
        id: 'projectCountPerWeek',
        display: 'Antall prosjekter opprettet per uke',
        showResult: (stats) => (
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th>Uke</th>
                        <th>Antall</th>
                        <th>Til privat</th>
                        <th>Til org</th>
                    </tr>
                </thead>
                <tbody>
                    {stats.result.map((r) => (
                        <tr key={r.week}>
                            <td>{r.week}</td>
                            <td>{r.project_count}</td>
                            <td>{r.project_count_to_user}</td>
                            <td>{r.project_count_to_org}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        ),
        toCsvRows: (stats) =>
            'Uke,Antall,Privat,Org\n' +
            stats.result
                .map((r) => [r.week, r.project_count, r.project_count_to_user, r.project_count_to_org].join(','))
                .join('\n'),
    },
    {
        id: 'projectCountPerDay',
        display: 'Antall prosjekter opprettet per dag',
        showResult: (stats) => (
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th>Dag</th>
                        <th>Antall</th>
                        <th>Til privat</th>
                        <th>Til org</th>
                    </tr>
                </thead>
                <tbody>
                    {stats.result.map((r) => (
                        <tr key={r.day}>
                            <td>{r.day}</td>
                            <td>{r.project_count}</td>
                            <td>{r.project_count_to_user}</td>
                            <td>{r.project_count_to_org}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        ),
        toCsvRows: (stats) =>
            'Dag,Antall,Privat,Org\n' +
            stats.result
                .map((r) => [r.day, r.project_count, r.project_count_to_user, r.project_count_to_org].join(','))
                .join('\n'),
    },
];

const projectTypes = [
    { display: 'Prosjekter', id: 'projects' },
    { display: 'Fond i gave', id: 'fig' },
];
const ProjectStats = () => {
    const [stats, setStats] = useState();
    const [fetching, setFetching] = useState(false);
    const [tags, setTags] = useState([]);
    const [allCategories, setAllCategories] = useState([]);
    const [categories, setCategories] = useState([]);
    const [events, setEvents] = useState([]);
    const [organizations, setOrganizations] = useState([]);
    const [id] = useState('1');
    const [fromDate, setFromDate] = useState(firstDayOfYear);
    const [toDate, setToDate] = useState(maxDate);
    const [statsType, setStatsType] = useState(statsTypes[0].id);
    const [projectType, setProjectType] = useState(projectTypes[0].id);
    const [product, setProduct] = useState(undefined);

    useEffect(() => {
        api.get(`${spleisUrl}/api/admin/project/all-categories`)
            .then((res) => setAllCategories(res.allCategories))
            .catch(() => showError('Feil ved henting av alle kategorier'));
    }, []);

    const doFetch = () => {
        resetStats();
        setFetching(true);
        const queryString = qs.stringify({
            statsType,
            projectType,
            fromDate,
            toDate,
            tags,
            categories,
            eventIds: events,
            organizationIds: organizations,
            hasProfileElements: [],
            product,
        });
        api.get(`${spleisUrl}/api/admin/project/stats?${queryString}`)
            .then((res) => setStats(res))
            .catch(() => showError('Feil ved henting av stats'))
            .finally(() => setFetching(false));
    };

    const resetStats = () => {
        if (stats) {
            setStats(undefined);
        }
        return true;
    };

    const addTag = (tag) => {
        resetStats();
        setTags([...new Set([...tags, tag])]);
    };

    const removeTag = (tag) => {
        resetStats();
        setTags(tags.filter((existingTag) => existingTag !== tag));
    };

    const addCategory = (category) => {
        resetStats();
        setCategories([...new Set([...categories, category])]);
    };

    const removeCategory = (category) => {
        resetStats();
        setCategories(categories.filter((existingCategory) => existingCategory !== category));
    };

    const addEvent = (event) => {
        resetStats();
        setEvents([...new Set([...events, event])]);
    };

    const removeEvent = (event) => {
        resetStats();
        setEvents(events.filter((existingEvent) => existingEvent !== event));
    };

    const addOrganization = (org) => {
        resetStats();
        setOrganizations([...new Set([...organizations, org])]);
    };

    const removeOrganization = (org) => {
        resetStats();
        setOrganizations(organizations.filter((existingOrg) => existingOrg !== org));
    };

    const statsTypeFromResult = stats?.type && statsTypes.find((st) => st.id === stats.type);
    const result = statsTypeFromResult?.showResult?.(stats);
    const toCsv = statsTypeFromResult?.toCsvRows;

    const generateCsv = () => {
        const csvContent = 'data:text/csv;charset=utf-8,' + toCsv(stats);
        const encodedUri = encodeURI(csvContent);
        window.open(encodedUri);
    };

    return (
        <TaskContainer>
            <Helmet>
                <title>Prosjekter</title>
            </Helmet>
            <header className="task-header">
                <h1 className="task-title">Prosjekter</h1>
            </header>
            <div className="task-body">
                <div className="stats-section">
                    <h2>Fra dato</h2>
                    <input
                        type="date"
                        min={minDate}
                        max={maxDate}
                        defaultValue={fromDate}
                        onChange={(e) => resetStats() && setFromDate(e.target.value)}
                    />
                </div>
                <div className="stats-section">
                    <h2>Til dato</h2>
                    <input
                        type="date"
                        min={minDate}
                        max={maxDate}
                        defaultValue={toDate}
                        onChange={(e) => resetStats() && setToDate(e.target.value)}
                    />
                </div>
                <div className="stats-section">
                    <h2>Tags</h2>
                    <ProjectTagsInput id={id} tags={tags} addTag={addTag} removeTag={removeTag} />
                </div>
                <div className="stats-section">
                    <h2>Eventer</h2>
                    <ProjectStatsEvents events={events} addEvent={addEvent} removeEvent={removeEvent} />
                </div>
                <div className="stats-section">
                    <h2>Organisasjoner (mottakere)</h2>
                    <ProjectStatsOrganizations
                        organizations={organizations}
                        addOrganization={addOrganization}
                        removeOrganization={removeOrganization}
                    />
                </div>
                <div className="stats-section">
                    <h2>Kategorier</h2>
                    <Categories
                        allCategories={allCategories}
                        categories={categories}
                        addCategory={addCategory}
                        removeCategory={removeCategory}
                    />
                </div>
                <div className={'stats-section'}>
                    <h2>Type prosjekter</h2>
                    <select onChange={(e) => resetStats() && setProjectType(e.target.value)} value={projectType}>
                        {projectTypes.map((st) => (
                            <option key={st.id} value={st.id}>
                                {st.display}
                            </option>
                        ))}
                    </select>
                </div>
                <div className={'stats-section'}>
                    <h2>Produkt</h2>
                    <div>
                        <label>
                            <input
                                name="product"
                                type="radio"
                                checked={!product}
                                onChange={(e) => setProduct(undefined)}
                            />
                            &nbsp;Alle
                        </label>
                    </div>
                    <div>
                        <label>
                            <input
                                name="product"
                                type="radio"
                                checked={product === 'dugnad'}
                                onChange={(e) => setProduct('dugnad')}
                            />
                            &nbsp;Bare dugnader
                        </label>
                    </div>
                </div>
                <div className="stats-section">
                    <h2>Type data du vil hente</h2>
                    <select onChange={(e) => resetStats() && setStatsType(e.target.value)} value={statsType}>
                        {statsTypes.map((st) => (
                            <option key={st.id} value={st.id}>
                                {st.display}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="stats-section">
                    <Button type="button" onClick={doFetch} disabled={fetching}>
                        {fetching ? 'Henter...' : 'Hent data'}
                    </Button>
                </div>
                {result && (
                    <div className="stats-section">
                        <h2>Resultat</h2>
                        {toCsv && (
                            <div>
                                <Button type="button" className="stats-csv-button" onClick={generateCsv}>
                                    Gi meg csv
                                </Button>
                            </div>
                        )}
                        <div>{result}</div>
                    </div>
                )}
            </div>
        </TaskContainer>
    );
};

export default ProjectStats;
