import React, { useContext } from 'react';
import { spleisUrl } from '../../config';
import api from '../../api';
import { showError, showInfo } from '../../store';
import ProjectContext from '../project-context';
import ConfirmButton from '../../ConfirmButton/ConfirmButton';

const ToggleSubprojects = () => {
    const project = useContext(ProjectContext);
    const onOffText = project?.subprojects_enabled ? 'av' : 'på'

    const toggleSubprojects = async () => {
        try {
            await api.post(`${spleisUrl}/api/admin/project/${project.id}/toggle-subprojects`);
            showInfo(`Deltakere skrudd ${onOffText}`);
        } catch (err) {
            showError(`Klarte ikke å skru ${onOffText} deltakere: ${err}`);
        } finally {
            window.notify();
        }
    };

    if (!project) {
        return null;
    }

    return (
        <ConfirmButton
            onClick={toggleSubprojects}
            title={`Skru ${onOffText} deltakere`}
            successText={`Skru ${onOffText}`}
            message={`Er du sikker på at du vil skru ${onOffText} deltakere?`}
        />
    );
};

export default ToggleSubprojects;
