import React, { useState } from 'react';
import { spleisUrl } from '../config';
import FormButton from '../FormButton/FormButton';
import { showError, showInfo } from '../store';
import api from '../api';

const ChangeInvoiceEmail = ({ organizationId, currentInvoiceEmailAddress, currentEhf }) => {
    const [invoiceEmail, setInvoiceEmail] = useState(currentInvoiceEmailAddress);
    const [ehf, setEhf] = useState(!!currentEhf);

    const changeInvoiceEmail = () => {
        api.post(`${spleisUrl}/api/admin/organization/${organizationId}/invoice-email`, {
            email: invoiceEmail,
            ehf,
        })
            .then(() => {
                showInfo('Endret faktura-adresse for organisasjonen!');
                window.notify();
            })
            .catch(() => {
                showError('Klarte ikke å endre faktura-adresse for organisasjonen');
            });
    };

    return (
        <FormButton
            buttonText={`Endre`}
            title={'Endre faktura-adresse'}
            onClick={changeInvoiceEmail}
            className={'button-link'}
        >
            <div className={'invoice-email-form'}>
                <input
                    type={'text'}
                    value={invoiceEmail}
                    onChange={e => {
                        setInvoiceEmail(e.target.value);
                    }}
                    autoFocus={true}
                />
                <label>
                    Ønsker EHF? <input type={'checkbox'} checked={ehf} onChange={e => setEhf(e.target.checked)} />
                </label>
            </div>
        </FormButton>
    );
};

export default ChangeInvoiceEmail;
