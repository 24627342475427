import React, { Component } from 'react';
import api from '../api';
import { regnskapUrl } from '../config';
import ReactTable from 'react-table';
import moment from 'moment';
import Filter from './Filter';
import VisibleIf from '../VisibleIf/VisibleIf';
import { withRouter } from 'react-router-dom';
import queryString from 'qs';
import { showInfo } from '../store';

class AccountingTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            pages: -1,
            loading: false,
            project: '',
            fromDate: moment().subtract(1, 'months').startOf('month'),
            toDate: moment().subtract(1, 'months').endOf('month'),
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.performSearch = this.performSearch.bind(this);
        this.pageSize = 20;
        this.page = 0;
        this.sorted = props.sorted || [{ id: 'id', desc: true }];
    }

    mapParamsToState() {
        const query = queryString.parse(this.props.location.search.slice(1));
        this.setState({
            project: query.project || '',
            fromDate: query.from ? moment(query.from) : moment().subtract(1, 'months').startOf('month'),
            toDate: query.to ? moment(query.to) : moment().subtract(1, 'months').endOf('month'),
        });
    }

    componentWillMount() {
        this.mapParamsToState();
    }

    getSortData() {
        const sort = this.sorted;
        let sortField = '',
            sortDirection = '';
        if (sort.length > 0) {
            sortField = sort[0].id;
            sortDirection = sort[0].desc ? 'desc' : 'asc';
        }
        return { sortField, sortDirection };
    }

    handleInputChange = async (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value,
        });
    };

    handleInputChangeAndSearch = async (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState(
            {
                [name]: value,
            },
            this.performSearch
        );
    };

    fetchData = async () => {
        const limit = this.pageSize;
        const offset = this.page * this.pageSize;
        const { sortField, sortDirection } = this.getSortData();
        const slug = this.props.url;
        if (!slug) {
            return Promise.resolve();
        }
        try {
            const body = {
                project: this.state.project,
                fromDate: moment(this.state.fromDate).format('YYYY-MM-DD'),
                toDate: moment(this.state.toDate).format('YYYY-MM-DD'),
                limit,
                offset,
                sortField,
                sortDirection,
            };

            return api.post(`${regnskapUrl}/api/${slug}`, body);
        } catch (e) {
            console.error(e);
        }
    };

    fetchSumData = async () => {
        const limit = this.pageSize;
        const offset = this.page * this.pageSize;
        const { sortField, sortDirection } = this.getSortData();
        try {
            const body = {
                project: this.state.project,
                fromDate: moment(this.state.fromDate).format('YYYY-MM-DD'),
                toDate: moment(this.state.toDate).format('YYYY-MM-DD'),
                limit,
                offset,
                sortField,
                sortDirection,
            };
            return api.post(`${regnskapUrl}/api/${this.props.sumUrl}`, body);
        } catch (e) {
            console.error(e);
        }
    };

    performSearch = async () => {
        this.page = 0;
        this.props.history.push({
            pathname: this.props.history.location.pathname,
            search: `?project=${this.state.project}&from=${this.state.fromDate.format(
                'YYYY-MM-DD'
            )}&to=${this.state.toDate.format('YYYY-MM-DD')}`,
        });
        this.setState({ loading: true });
        const data = await this.fetchData();
        this.setState({ data: data?.data, pages: Math.floor(data?.count / this.pageSize) + 1, loading: false });
        if (this.props.includeEventSum) {
            this.setState({ loadingSum: true });
            const sumData = await this.fetchSumData();
            this.setState({ dataSum: sumData.data, loadingSum: false });
        }
    };

    downloadCSV = async () => {
        const { sortField, sortDirection } = this.getSortData();
        const body = {
            project: this.state.project,
            fromDate: moment(this.state.fromDate).format('YYYY-MM-DD'),
            toDate: moment(this.state.toDate).format('YYYY-MM-DD'),
            sortField,
            sortDirection,
        };
        await api.post(`${regnskapUrl}/api/${this.props.url}.csv`, body);
        showInfo('CSV-filen havner i CSV-filer i menyen til venstre');
    };

    render() {
        return (
            <div className="accounting-table-wrapper">
                <Filter
                    project={this.state.project}
                    fromDate={this.state.fromDate}
                    toDate={this.state.toDate}
                    handleInputChange={this.handleInputChange}
                    handleInputChangeAndSearch={this.handleInputChangeAndSearch}
                    performSearch={this.performSearch}
                    downloadCSV={this.downloadCSV}
                    showProjectFilter={this.props.showProjectFilter}
                />

                <div>
                    <VisibleIf isVisible={this.props.includeEventSum}>
                        <br />
                        <div>
                            <h3>SUM for hele resultatet</h3>
                        </div>
                        <ReactTable
                            manual
                            data={this.state.dataSum}
                            columns={
                                this.props.sumColumns || [
                                    {
                                        Header: 'Giver',
                                        accessor: 'giver',
                                        Cell: (row) => <span>{Math.round(row.value).toLocaleString()}</span>,
                                    },
                                    {
                                        Header: 'Fordring Stripe',
                                        accessor: 'fordringstripe',
                                        minWidth: 80,
                                        Cell: (row) => <span>{Math.round(row.value).toLocaleString()}</span>,
                                    },
                                    {
                                        Header: 'Stripe fee',
                                        accessor: 'feestripe',
                                        Cell: (row) => <span>{Math.round(row.value).toLocaleString()}</span>,
                                    },
                                    {
                                        Header: 'Fordring Vipps',
                                        accessor: 'fordringvipps',
                                        minWidth: 150,
                                        Cell: (row) => <span>{Math.round(row.value).toLocaleString()}</span>,
                                    },
                                    {
                                        Header: 'Vipps fee',
                                        accessor: 'feevipps',
                                        Cell: (row) => <span>{Math.round(row.value).toLocaleString()}</span>,
                                    },
                                    {
                                        Header: 'Fordring Faktura',
                                        accessor: 'fordringfaktura',
                                        minWidth: 150,
                                        Cell: (row) => <span>{Math.round(row.value).toLocaleString()}</span>,
                                    },
                                    {
                                        Header: 'Klientkonto',
                                        accessor: 'klientkonto',
                                        Cell: (row) => <span>{Math.round(row.value).toLocaleString()}</span>,
                                    },
                                    {
                                        Header: 'Bufferkonto',
                                        accessor: 'bufferkonto',
                                        Cell: (row) => <span>{Math.round(row.value).toLocaleString()}</span>,
                                    },
                                    {
                                        Header: 'Klientkonto Bambusa',
                                        accessor: 'klientkontobambusa',
                                        minResizeWidth: 30,
                                        Cell: (row) => <span>{Math.round(row.value).toLocaleString()}</span>,
                                    },
                                    {
                                        Header: 'Bufferkonto Bambusa',
                                        accessor: 'bufferkontobambusa',
                                        minResizeWidth: 30,
                                        Cell: (row) => <span>{Math.round(row.value).toLocaleString()}</span>,
                                    },
                                    {
                                        Header: 'Gjeld Bambusa',
                                        accessor: 'gjeldbambusa',
                                        minResizeWidth: 30,
                                        Cell: (row) => <span>{Math.round(row.value).toLocaleString()}</span>,
                                    },
                                    {
                                        Header: 'Bambusa',
                                        accessor: 'bambusa',
                                        minResizeWidth: 30,
                                        Cell: (row) => <span>{Math.round(row.value).toLocaleString()}</span>,
                                    },
                                    {
                                        Header: 'Innsamler',
                                        accessor: 'innsamler',
                                        Cell: (row) => <span>{Math.round(row.value).toLocaleString()}</span>,
                                    },
                                    {
                                        Header: 'Inntekt',
                                        accessor: 'inntekt',
                                        Cell: (row) => <span>{Math.round(row.value).toLocaleString()}</span>,
                                    },
                                    {
                                        Header: 'MVA',
                                        accessor: 'mva',
                                        Cell: (row) => <span>{Math.round(row.value)}</span>,
                                    },
                                    {
                                        Header: 'Tap',
                                        accessor: 'tap',
                                        Cell: (row) => <span>{Math.round(row.value).toLocaleString()}</span>,
                                    },
                                    {
                                        Header: 'Avvik',
                                        accessor: 'avvik',
                                        Cell: (row) => <span>{Math.round(row.value).toLocaleString()}</span>,
                                    },
                                ]
                            }
                            loadingText={'Laster..'}
                            noDataText={'Ingen sum funnet'}
                            ofText={'av'}
                            loading={this.state.loadingSum}
                            showPagination={false}
                            defaultPageSize={-1}
                            onFetchData={async () => {
                                this.setState({
                                    loadingSum: true,
                                });
                                const data = await this.fetchSumData();
                                this.setState({
                                    dataSum: data.data,
                                    loadingSum: false,
                                });
                            }}
                        />
                        <br />
                    </VisibleIf>
                    <VisibleIf isVisible={this.props.columns}>
                        <div>
                            <h3>Detaljert resultat</h3>
                        </div>
                        <ReactTable
                            manual
                            defaultPageSize={this.pageSize}
                            data={this.state.data}
                            columns={this.props.columns}
                            page={this.page}
                            pages={this.state.pages}
                            previousText={'Forrige'}
                            nextText={'Neste'}
                            pageText={'Side'}
                            loadingText={'Laster..'}
                            noDataText={'Ingen rader funnet'}
                            ofText={'av'}
                            rowsText={'rader'}
                            defaultSorted={this.sorted}
                            onPageChange={(page) => (this.page = page)}
                            onPageSizeChange={(pageSize) => (this.pageSize = pageSize)}
                            onSortedChange={(sorted) => (this.sorted = sorted)}
                            loading={this.state.loading}
                            getTrProps={this.props.getTrProps}
                            onFetchData={async (state) => {
                                this.setState({
                                    loading: true,
                                });
                                const data = await this.fetchData();
                                this.setState({
                                    data: data.data,
                                    pages: Math.floor(data.count / state.pageSize) + 1,
                                    loading: false,
                                });
                            }}
                        />
                    </VisibleIf>
                </div>
            </div>
        );
    }
}

export default withRouter(AccountingTable);
