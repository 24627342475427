import React, { useState } from 'react';
import Modal from 'react-modal';
import styles from '../Menu/Menu.module.scss';
import { TODO_DEFINITIONS } from './Todo';
import Button from '../Button/Button';

export default ({ todoConfig, setTodoConfig }) => {
    const [isOpen, setIsOpen] = useState(!todoConfig.initialized);
    return (
        <>
            <Modal isOpen={isOpen} overlayClassName={styles.modalWrapper} className={styles.modalContainer}>
                <h2>Velg hvilke todos du vil vise</h2>
                <p className={styles.todoConfigInfo}>
                    Dette kan endres når som helst med knappen nederst på siden, og er ikke skummelt 😊
                </p>
                {TODO_DEFINITIONS.map((todoDefinition) => {
                    const checked = todoConfig.configs.includes(todoDefinition.id);
                    return (
                        <div key={todoConfig.id}>
                            <label className="category-label">
                                <input
                                    type="checkbox"
                                    checked={checked}
                                    onChange={() => {
                                        if (checked) {
                                            setTodoConfig({
                                                ...todoConfig,
                                                configs: todoConfig.configs.filter((tc) => tc !== todoDefinition.id),
                                            });
                                        } else {
                                            setTodoConfig({
                                                ...todoConfig,
                                                initialized: true,
                                                configs: [...new Set([...todoConfig.configs, todoDefinition.id])],
                                            });
                                        }
                                    }}
                                />
                                <span>{todoDefinition.label}</span>
                            </label>
                        </div>
                    );
                })}
                <center className={styles.todoConfigButtons}>
                    <Button
                        className="btn"
                        onClick={() => {
                            setIsOpen(false);
                            if (!todoConfig.initialized) {
                                setTodoConfig({
                                    ...todoConfig,
                                    initialized: true,
                                });
                            }
                        }}
                    >
                        Lukk
                    </Button>
                </center>
            </Modal>
            <div className="task-body">
                <Button className="btn" onClick={() => setIsOpen(true)}>
                    Velg todos <i className="fa fa-cog" />
                </Button>
            </div>
        </>
    );
};
