export const reasonPresets = [
    {
        optionText: '(fritekst)',
        message: ``,
    },
    {
        optionText: 'Aksje-/verdipapirhandel',
        message: `Vi tillater ikke innsamlinger til aksje-/verdipapirhandel.`,
    },
    {
        optionText: 'Alder',
        message: `Privatpersoner må være fylt 15 år for å kunne bruke Spleis til å samle inn penger til et bestemt formål, ved å stå som innsamler eller mottaker.`,
    },
    {
        optionText: 'Barnefordeling/tvist',
        message: `Vi tillater ikke innsamlinger til barnefordelingssaker eller andre typer tvistesaker som omfatter eksponering av barns personopplysninger og retten til privatliv.`,
    },
    {
        optionText: 'Bøter',
        message: `Vi tillater ikke innsamlinger til bøter.`,
    },
    {
        optionText: 'Diskriminerende og/eller hatefulle ytringer',
        message: `Vi tillater ikke innsamlinger knyttet til diskriminerende og/eller hatefulle ytringer.`,
    },
    {
        optionText: 'Eksponering av barn',
        message: `Vi tillater ikke eksponering av barn i en sammenheng der barnas rettigheter krenkes.`,
    },
    {
        optionText: 'Formålet må være reelt og informasjon korrekt',
        message: `Formålet må være reelt og informasjon korrekt: Vi tillater ikke innsamlinger som bryter, eller vi mistenker kan bryte med dette kravet.`,
    },
    {
        optionText: 'Investerings- eller låneformidlingsfinansiering',
        message: `Spleis er en tjeneste for folkefinansiering ved donasjon og er ikke tilgjengelig for bruk ved investerings- eller låneformidlingsfinansiering.`,
    },
    {
        optionText: 'Lotteri',
        message: `Vi tillater ikke å avholde ulike former for pengespill eller lotteri uten tillatelse fra Lotteritilsynet.

En spleis kan ikke omfatte lodd/trekning av gevinster til givere som bidrar til en innsamling, med mindre gevinsten er av ubetydelig økonomisk verdi (eksempelvis en t-skjorte, en plakat eller lignende). Vårt produkt «digitale skrapelodd» tilfredsstiller disse kravene og er derfor tillatt på Spleis.`,
    },
    {
        optionText: 'Negativ omtale av tredjepart',
        message: `Vi tillater ikke at Spleis brukes til å sette personer, som kan identifiseres, i en uønsket situasjon og/eller som kan være egnet til å være til skade for dem og/eller pårørende, fordi det er lagt ut sensitive opplysninger om dem på vår plattform.`,
    },
    {
        optionText: 'Rusmidler',
        message: `Vi tillater ikke innsamlinger til rusmidler.`,
    },
    {
        optionText: 'Sex, pornografisk innhold og/eller seksuelle tjenester',
        message: `Vi tillater ikke innsamlinger relatert til sex, pornografisk innhold og/eller seksuelle tjenester.`,
    },
    {
        optionText: 'Stridende part',
        message: `I krig/konflikter tillates ikke innsamlinger som støtter en stridende part direkte, uavhengig av konflikt. Spleis tillater imidlertid innsamlinger til humanitære formål, som med fredelige virkemidler hjelper mennesker som er rammet av krig/konflikter.`,
    },
    {
        optionText: 'Våpen',
        message: `Vi tillater ikke innsamlinger til våpen som har til hensikt å skade andre.`,
    },
];
