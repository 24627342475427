export const presets = [
    {
        optionText: 'Vi har prøvd å ringe',
        message: `Hei 👋

Vi har forsøkt å ringe deg vedr. spleisen din.

Vennligst ta kontakt med oss på chat eller hei@spleis.no

Hilsen Spleis-teamet`,
    },
    {
        optionText: '(blank)',
        message: `Hei 👋

`,
    },
];
