import React, { Component } from 'react';
import moment from 'moment';
import AccountingTable from './AccountingTable';
import { Helmet } from 'react-helmet';

export default class MappedData extends Component {
    render() {
        return (
            <>
                <Helmet>
                    <title>Mappede transaksjoner</title>
                </Helmet>
                <AccountingTable
                    url={'mapped'}
                    columns={[
                        { Header: 'ID', minResizeWidth: 30, accessor: 'id' },
                        { Header: 'Prosjekt', minResizeWidth: 30, accessor: 'to_project' },
                        {
                            Header: 'Beløp',
                            accessor: 'amount',
                            minResizeWidth: 30,
                            minWidth: 120,
                            Cell: (row) => <span>{Math.round(row.value).toLocaleString()} kr.</span>,
                        },
                        {
                            Header: 'Dato',
                            accessor: 'created_at',
                            minResizeWidth: 30,
                            minWidth: 200,
                            Cell: (row) => <span>{moment(row.value).format('lll')}</span>,
                        },
                        { Header: 'Bankinnskudd', minResizeWidth: 30, accessor: 'bank_deposit_id', minWidth: 120 },
                        { Header: 'Type', minResizeWidth: 30, accessor: 'type' },
                    ]}
                />
            </>
        );
    }
}
