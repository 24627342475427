import ConfirmButton from '../../ConfirmButton/ConfirmButton';
import api from '../../api';
import { showError, showInfo } from '../../store';
import { spleisUrl } from '../../config';

export const DeleteReward = ({ reward }) => {
    const handleSave = async () => {
        try {
            await api.remove(`${spleisUrl}/api/admin/project/${reward.project_id}/reward/${reward.id}`);
        } catch (err) {
            showError('Feil ved sletting av belønning: ' + err.text);
        }
        window.notify();
        showInfo('Belønning slettet!');
    };
    return (
        <ConfirmButton
            className="btn-warning"
            title={'Slett'}
            successText={'Slett belønning'}
            cancelText={'Avbryt'}
            onClick={() => handleSave()}
            onClickCancel={() => setIsOpen(false)}
        >
            Er du sikker på at du vil slette denne belønningen?{' '}
            {reward.purchased_count > 0 ? <b>Det har blitt solgt {reward.purchased_count} stk. !</b> : ''} Den kan
            gjenopprettes senere.
        </ConfirmButton>
    );
};
