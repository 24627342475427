import React, { useContext, useEffect, useState } from 'react';
import api from '../api';
import { spleisUrl } from '../config';
import { showError, showInfo } from '../store';
import ProjectLink from '../SmartLinks/ProjectLink';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { formatMoneyKr } from '../formatters';
import { VERIFICATION_STATUSES } from '../Org/Org';
import { formatDate } from '../dates';
import Button from '../Button/Button';
import RecipientOrgComments from './RecipientOrgComments';
import { SortableRecipientHeaderCell } from './SortableRecipientTableCell';
import useQueryString from 'use-query-string';
import { useLocation } from 'react-router';
import { FILTER_TYPES } from './filters';
import { FavoriteOrganizationsContext } from '../favorite-organizations';
import FavoriteOrganization from '../Project/FavoriteOrganization/FavoriteOrganization';
import moment from 'moment';

export const UnacceptedByOrg = () => {
    const location = useLocation();
    const [query, setQuery] = useQueryString(location, (path) => window.history.pushState(null, document.title, path));
    const [fetching, setFetching] = useState(false);
    const [orgs, setOrgs] = useState([]);
    const { organizationIds: favoriteOrganizationIds } = useContext(FavoriteOrganizationsContext);

    const {
        sortField = 'earliest_project_ends_at',
        sortDirection = 'asc',
        includeActiveProjects = false,
        includeOldProjects = false,
        filter = FILTER_TYPES[0].value,
    } = query;

    const fetchComments = (organizationId) => {
        api.get(`/api/comments?organization=${organizationId}`)
            .then((res) => {
                res.sort((a, b) => (moment(a.created_at).isBefore(b.created_at) ? 1 : -1));
                setOrgs(
                    orgs.map((org) => {
                        if (org.id === organizationId) {
                            return {
                                ...org,
                                comments: res,
                            };
                        }
                        return org;
                    })
                );
            })
            .catch(() => showError('Feil ved henting kommentarer'));
    };



    useEffect(() => {
        setFetching(true);
        api.get(
            `${spleisUrl}/api/admin/organization/has-unaccepted-projects?includeActiveProjects=${includeActiveProjects}&includeOldProjects=${includeOldProjects}`
        )
            .then((res) => {
                res.sort((a, b) => a.name.localeCompare(b.name));
                setOrgs(res);
            })
            .catch(() => {
                showError('Feil ved henting organisasjoner med ikke-godtatte spleiser');
            })
            .finally(() => {
                setFetching(false);
            });
    }, [includeOldProjects, includeActiveProjects]);

    const setVerificationStatus = (id, status) => {
        setOrgs(
            orgs.map((org) => {
                if (org.id === id) {
                    return {
                        ...org,
                        verification_status: status,
                    };
                }
                return org;
            })
        );
    };

    const filterType = FILTER_TYPES.find((filterType) => filterType.value === filter) || FILTER_TYPES[0];
    const filteredOrgs = orgs.filter((org) => filterType.fn(org, favoriteOrganizationIds));

    const before = sortDirection === 'asc' ? 1 : -1;
    const after = sortDirection === 'asc' ? -1 : 1;
    const sortedOrgs = filteredOrgs.sort((a, b) => {
        const aValue = a[sortField] || '';
        const bValue = b[sortField] || '';
        if (sortField === 'earliest_project_ends_at') {
            return new Date(aValue) > new Date(bValue) ? before : after;
        } else if (sortField === 'unaccepted_collected_amount') {
            return Number(aValue) > Number(bValue) ? before : after;
        } else if (sortField === 'verification_status') {
            const statusA = VERIFICATION_STATUSES[a.verification_status] || '(Ingen)';
            const statusB = VERIFICATION_STATUSES[b.verification_status] || '(Ingen)';
            return statusA > statusB ? before : after;
        } else {
            return aValue > bValue ? before : after;
        }
    });
    const projectCount = sortedOrgs.reduce((acc, cur) => acc + cur.unaccepted_project_ids?.length || 0, 0);

    return (
        <>
            <Helmet>
                <title>Ikke bekreftet - org</title>
            </Helmet>
            <header className="task-header">
                <h1 className="task-title">Ikke bekreftet - org</h1>
            </header>
            <div className="task-body">
                <div className="recipient-filter">
                    <label>
                        <input
                            id="includeActiveProjects"
                            type="checkbox"
                            checked={includeActiveProjects}
                            onChange={(e) => setQuery({ includeActiveProjects: e.target.checked })}
                        />
                        <span className="recipient-filter-label">Vis aktive spleiser</span>
                    </label>
                </div>
                <div className="recipient-filter">
                    <label>
                        <input
                            id="includeOldProjects"
                            type="checkbox"
                            checked={includeOldProjects}
                            onChange={(e) => setQuery({ includeOldProjects: e.target.checked })}
                        />
                        <span className="recipient-filter-label">Vis gamle spleiser (avsluttet for et år siden)</span>
                    </label>
                </div>
                <div className="recipient-filter">
                    <select value={filter} onChange={(e) => setQuery({ filter: e.target.value })}>
                        {FILTER_TYPES.map((filterType) => (
                            <option key={filterType.value} value={filterType.value} disabled={filterType.disabled}>
                                {filterType.display}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="org-count">
                    Viser {sortedOrgs.length} organisasjoner ({projectCount} spleiser)
                </div>
                <table className="table">
                    <thead>
                        <tr className="bg-light">
                            <th />
                            <SortableRecipientHeaderCell name="Navn" field="name" query={query} setQuery={setQuery} />
                            <SortableRecipientHeaderCell
                                name="Org. status"
                                field="verification_status"
                                query={query}
                                setQuery={setQuery}
                            />
                            <SortableRecipientHeaderCell
                                name="Innsamlet"
                                field="unaccepted_collected_amount"
                                query={query}
                                setQuery={setQuery}
                            />
                            <th>Prosjekter</th>
                            <SortableRecipientHeaderCell
                                name="Tidligst avsluttet"
                                field="earliest_project_ends_at"
                                query={query}
                                setQuery={setQuery}
                            />
                            <th>Siste kommentar</th>
                        </tr>
                    </thead>

                    <tbody>
                        {fetching && (
                            <tr>
                                <td colSpan={100}>Henter...</td>
                            </tr>
                        )}
                        {!fetching &&
                            sortedOrgs.map((org) => (
                                <Org
                                    key={org.id}
                                    org={org}
                                    setVerificationStatus={setVerificationStatus}
                                    fetchComments={fetchComments}
                                />
                            ))}
                    </tbody>
                </table>
            </div>
        </>
    );
};

const Org = ({ org, setVerificationStatus, fetchComments }) => {
    const [expanded, setExpanded] = useState(false);

    const sendVerificationStatus = (status) => {
        api.put(`${spleisUrl}/api/admin/organization/${org.id}/verification-status`, {
            verification_status: status || null,
        })
            .then(() => {
                setVerificationStatus(org.id, status);
                showInfo('Endret verifiseringsstatus');
            })
            .catch(() => showError('Kunne ikke endre verifiseringsstatus'));
    };

    const renderProjectLinks = () => {
        const projectCount = org.unaccepted_project_ids?.length || 0;
        if (projectCount > 10) {
            return <>({projectCount} stk.)</>;
        } else {
            return org.unaccepted_project_ids?.map((pid) => (
                <div key={pid} className="recipient-projectid-link">
                    <ProjectLink id={pid} />
                </div>
            ));
        }
    };

    const buttonText = org.comments?.length > 0 ? org.comments[0].user_email?.split('@spleis.no').join('') : 'Ingen';
    const commentCountText = org.comments?.length > 0 ? ` (${org.comments.length})` : '';

    return (
        <>
            <tr>
                <td>
                    <FavoriteOrganization organizationId={org.id} />
                </td>
                <td>
                    <Link to={`/organization/${org.id}`}>{org.name}</Link>
                </td>
                <td>
                    <select
                        value={org.verification_status || ''}
                        onChange={(e) => sendVerificationStatus(e.target.value)}
                    >
                        <option value="">(Ingen)</option>
                        {Object.entries(VERIFICATION_STATUSES).map(([statusKey, statusValue]) => (
                            <option key={statusKey} value={statusKey}>
                                {statusValue}
                            </option>
                        ))}
                    </select>
                </td>
                <td>{formatMoneyKr(org.unaccepted_collected_amount)}</td>
                <td>{renderProjectLinks()}</td>
                <td>{formatDate(org.earliest_project_ends_at)}</td>
                <td>
                    <Button onClick={() => setExpanded(!expanded)}>
                        {buttonText}
                        {commentCountText}
                    </Button>
                </td>
            </tr>
            {expanded && <RecipientOrgComments org={org} fetchComments={fetchComments} />}
        </>
    );
};
