import React, { useState } from 'react';
import ConfirmButton from '../../ConfirmButton/ConfirmButton';
import api from '../../api';
import { spleisUrl } from '../../config';
import { showError, showInfo } from '../../store';

function canSendPayoutReminderForProject(project) {
    return !(project.payout_at || project.deleted || project.refunded_at || project.end_amount);
}

const SendPayoutReminder = ({ project }) => {
    const [isProcessing, setIsProcessing] = useState(false);

    const sendPayoutReminder = () => {
        setIsProcessing(true);
        api.post(`${spleisUrl}/api/admin/project/send-reminder-for-specific-projects?ids=${project.id}`)
            .then(results => {
                setIsProcessing(false);
                if (results.success.includes('' + project.id)) {
                    showInfo('Purring ble sendt!');
                } else if (results.skipped.includes('' + project.id)) {
                    showError(
                        'Kunne ikke sende purring fordi prosjektet ikke besto alle testene som trengs. Sjekk loggene til API-et for å se hvorfor.'
                    );
                } else {
                    showError(
                        'Kunne ikke sende purring fordi prosjektet noe feilet. Sjekk loggene til API-et for å se hvorfor.'
                    );
                }
                window.notify();
            })
            .catch(() => {
                setIsProcessing(false);

                showError(`Kunne ikke sende purring`);
            });
    };

    if (!canSendPayoutReminderForProject(project)) {
        return null;
    }

    return (
        <ConfirmButton
            title="Send purring på utbetaling"
            disabled={isProcessing}
            message="Sikker på at du vil sende en purring for å få utbetalt denne spleisen?"
            onClick={sendPayoutReminder}
        />
    );
};

export default SendPayoutReminder;
