import api from '../api';
import { useEffect, useState } from 'react';
import { spleisUrl } from '../config';

export const useReceiverBankAccount = ({ receiver }) => {
    const [receiverBankAccount, setReceiverBankAccount] = useState(null);

    const receiverId = receiver?.id;
    const receiverType = receiver?.type;

    useEffect(() => {
        if (receiverType === 'org') {
            api.get(
                `${spleisUrl}/api/admin/bank-account/main-account-for-organization/${receiverId}`
            ).then((bankAccount) => setReceiverBankAccount(bankAccount));
        } else if (receiverType === 'user' && receiverId) {
            api.get(`${spleisUrl}/api/admin/bank-account/main-account-for-user/${receiverId}`).then((bankAccount) =>
                setReceiverBankAccount(bankAccount)
            );
        }
    }, [receiverId, receiverType]);

    return { bankAccount: receiverBankAccount };
};
