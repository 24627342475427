import React, { useState } from 'react';
import Chevron from '../Chevron/Chevron';
import styles from './Collapsable.module.scss';

const Collapsable = ({ collapsed = false, canCollapse = true, title, children, className = '', wrapperClass = '' }) => {
    const [isCollapsed, setIsCollapsed] = useState(collapsed);

    const toggle = (e) => {
        e.preventDefault();
        setIsCollapsed(!isCollapsed);
    };
    return (
        <div className={className}>
            <h2 className={[styles.title, isCollapsed ? styles.isCollapsed : ''].join(' ')}>
                {canCollapse ? (
                    <button onClick={toggle} className={styles.titleButton}>
                        <span>{title}</span>
                        <Chevron isOpen={!isCollapsed} />
                    </button>
                ) : (
                    <div className={styles.titleButton}>
                        <span>{title}</span>
                    </div>
                )}
            </h2>
            {!isCollapsed && <div className={[styles.children, wrapperClass].join(' ')}>{children}</div>}
        </div>
    );
};

export default Collapsable;
