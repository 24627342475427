import moment from 'moment';

export const ISO_DATE_FORMAT = 'YYYY-MM-DD[T]HH:mm:ss';
export const DATE_FORMAT = 'DD.MM.YYYY [kl.] LT';
export const DATE_FORMAT_SHORT = 'DD.MM.YYYY';
export const DATE_FORMAT_SHORT_TIME = 'DD. MMM YY [kl.] LT';

const dateOrNowIfDateIsNull = date => date || new Date();

export const sortObjectArrayByDateField = (objectArray = [], field = 'created_at', descending = false) => {
    if (descending) {
        return objectArray.sort((a, b) => moment(b[field]).diff(moment(a[field])));
    }
    return objectArray.sort((a, b) => moment(a[field]).diff(moment(b[field])));
};

export const sortObjectArrayByDateFieldOrId = (objectArray = [], field = 'created_at') => {
    return objectArray.sort(
        (a, b) => moment(dateOrNowIfDateIsNull(a[field])).diff(moment(dateOrNowIfDateIsNull(b[field]))) || b.id - a.id
    );
};

export const sortObjectArrayByCollectedAmount = (objectArray = [], field = 'collected_amount', descending = true) => {
    if (descending) {
        return objectArray.sort((a, b) => b[field] - a[field]);
    }
    return objectArray.sort((a, b) => a[field] - b[field]);
};

export const formatDate = date => moment(date).format(DATE_FORMAT);
export const formatDateOnly = date => moment(date).format(DATE_FORMAT_SHORT);
