import React from 'react';

export const SortableRecipientHeaderCell = ({ name, field, query, setQuery }) => {
    const { sortField = 'earliest_project_ends_at', sortDirection = 'asc' } = query;

    const setSortField = (field) => {
        if (sortField === field) {
            setQuery({ sortDirection: sortDirection === 'asc' ? 'desc' : 'asc' });
        } else {
            setQuery({
                sortField: field,
                sortDirection: 'asc',
            });
        }
    };

    return (
        <th className="recipient-table-header-cell">
            <span onClick={() => setSortField(field)}>
                {name}{' '}
                {sortField === field && sortDirection === 'asc'
                    ? '⬆️'
                    : sortField === field && sortDirection === 'desc'
                    ? '⬇️'
                    : ''}
            </span>
        </th>
    );
};
