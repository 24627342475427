import React, { useState } from 'react';
import './Categories.scss';
import { Bar, BarChart, CartesianGrid, Cell, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { formatMoney, formatMoneyKr } from '../../formatters';

const CategoriesBarChart = ({ categoriesPerMonth, categories, startMonth, endMonth }) => {
    const [scale, setScale] = useState('linear');
    const data = [];

    categoriesPerMonth.slice(startMonth, endMonth + 1).forEach((month) => {
        Object.entries(month)
            .filter(([k]) => k !== 'date')
            .forEach(([k, v]) => {
                let cat = data.find((c) => c.name === k);
                if (!cat) {
                    cat = { name: k };
                    data.push(cat);
                }
                cat.value = (cat.value || 0) + parseInt(v || '0');
            });
    });

    const filteredAndSorted = data
        .filter((d) => d.value > 0)
        .filter((d) => categories.find((c) => c.name === d.name).enabled)
        .sort((a, b) => b.value - a.value);

    const min = scale === 'log' ? 1 : 0;
    const max = Math.max(...filteredAndSorted.map((o) => o.value)) || 1;

    return (
        <>
            <h1>Volum</h1>
            <div className="category-section" style={{ width: '100%', height: 500 }}>
                <ResponsiveContainer width="99%" height="100%">
                    <BarChart
                        width={500}
                        height={300}
                        data={filteredAndSorted}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                            dataKey="name"
                            domain={[0, filteredAndSorted.length]}
                            tick={{ fontSize: 14 }}
                            tickFormatter={(val) =>
                                val
                                    ? val
                                          .split(' ')
                                          .map((n) => n[0].toUpperCase())
                                          .join('')
                                    : '(Ingen)'
                            }
                        />
                        <YAxis
                            domain={[min, max]}
                            tick={{ fontSize: 12 }}
                            width={70}
                            tickFormatter={formatMoney}
                            scale={scale}
                        />
                        <Tooltip
                            formatter={(value) => [formatMoneyKr(value), 'Volum']}
                            itemSorter={(item) => parseInt(item.value || 0) * -1}
                            labelStyle={{
                                marginBottom: 10,
                            }}
                            itemStyle={{
                                fontSize: 14,
                                margin: 0,
                            }}
                        />
                        <Bar dataKey="value">
                            {filteredAndSorted.map((d) => (
                                <Cell key={`cell-${d.name}`} fill={categories.find((c) => c.name === d.name).color} />
                            ))}
                        </Bar>
                    </BarChart>
                </ResponsiveContainer>
                <div className="category-section">
                    <center>
                        <label className="category-label">
                            Skala:&nbsp;
                            <select onChange={(e) => setScale(e.target.value)} value={scale}>
                                <option value="linear">Lineær</option>
                                <option value="sqrt">Kvadratrot</option>
                                <option value="log">Logaritmisk</option>
                            </select>
                        </label>
                    </center>
                </div>
            </div>
        </>
    );
};

export default CategoriesBarChart;
