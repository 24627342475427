import { formatMoneyKr } from '../../formatters';
import React, { useEffect, useState } from 'react';
import qs from 'qs';
import api from '../../api';
import { spleisUrl } from '../../config';
import { showError } from '../../store';
import ProjectLink from '../../SmartLinks/ProjectLink';
import { formatDate } from '../../dates';

const TagStats = ({ tagStats, fromDate, toDate }) => {
    const [fetching, setFetching] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const [projects, setProjects] = useState([]);

    const doFetch = () => {
        setProjects([]);
        setFetching(true);
        const queryString = qs.stringify({
            fromDate: fromDate,
            toDate: toDate,
            tag: tagStats.tag_id,
        });
        api.get(`${spleisUrl}/api/admin/project/tags/stats/projects?${queryString}`)
            .then((res) => setProjects(res))
            .catch(() => showError('Feil ved henting av stats'))
            .finally(() => setFetching(false));
    };

    useEffect(() => {
        if (expanded) {
            doFetch();
        }
    }, [expanded]);

    return (
        <>
            <tr style={{ cursor: 'pointer' }} onClick={() => setExpanded(!expanded)}>
                <td>{tagStats.tag_id}</td>
                <td>{tagStats.tag_count}</td>
                <td>{formatMoneyKr(tagStats.collected_amount)}</td>
            </tr>
            {expanded && fetching && (
                <tr>
                    <td colSpan={100}>Henter...</td>
                </tr>
            )}
            {expanded && !fetching && (
                <tr>
                    <td colSpan={100} style={{ padding: '2rem' }} className="bg-light">
                        <table className="table bg-white">
                            <thead>
                                <tr>
                                    <th>Prosjekt ID</th>
                                    <th>Tittel</th>
                                    <th>Publisert</th>
                                    <th>Innsamlet</th>
                                </tr>
                            </thead>
                            <tbody>
                                {projects.map((project) => {
                                    return (
                                        <tr key={project.id}>
                                            <td>
                                                <ProjectLink id={project.id} />
                                            </td>
                                            <td>{project.title}</td>
                                            <td>{formatDate(project.published_at)}</td>
                                            <td>{formatMoneyKr(project.collected_amount)}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </td>
                </tr>
            )}
        </>
    );
};

export default TagStats;
