import React, {useEffect, useState} from 'react';
import {paymentUrl, spleisUrl} from '../config';
import api from '../api';
import TaskContainer from '../TaskContainer/TaskContainer';
import Button from '../Button/Button';
import {showError, showInfo} from '../store';
import {formatBankAccount, formatMoneyKr} from '../formatters';
import ConfirmButton from '../ConfirmButton/ConfirmButton';
import {Helmet} from 'react-helmet';
import ProjectLink from '../SmartLinks/ProjectLink';
import PayoutFilesList from "./PayoutFileList";


const iconAutoCreated = <span title="Automatisk opprettet">🤖</span>;

const compareNumbers = (a, b, field) => parseInt(a[field], 0) - parseInt(b[field], 0);
const compareText = (a, b, field) => a[field].localeCompare(b[field]);
const compareBooleans = (a, b, field) => b[field] - a[field];

const getSortFunctionFromField = (field) => {
    if (['projectId', 'amount', 'bankaccount'].includes(field)) {
        return compareNumbers;
    } else if (field === 'createdAutomatically') {
        return compareBooleans;
    }
    return compareText;
};

const sortPayouts = (payouts, sortField, sortDirection, sortFunction) =>
    payouts.sort((a, b) => (sortDirection === 'asc' ? sortFunction(a, b, sortField) : sortFunction(b, a, sortField)));

const PayoutFiles = () => {
    const [payouts, setPayouts] = useState([]);
    const [sortField, setSortField] = useState('amount');
    const [sortDirection, setSortDirection] = useState('desc');

    const setSort = (newSortField) => {
        let newSortDirection = 'asc';
        if (sortField === newSortField) {
            newSortDirection = sortDirection === 'asc' ? 'desc' : 'asc';
        }
        setSortField(newSortField);
        setSortDirection(newSortDirection);
        sortPayouts(payouts, newSortField, newSortDirection, getSortFunctionFromField(newSortField));
    };

    const fetchData = () => {
        api.get(`${paymentUrl}/api/payout-files/preview`)
            .then((res) => {
                const newPayouts = ((res || {}).input || {}).payouts || [];
                sortPayouts(newPayouts, sortField, sortDirection, getSortFunctionFromField(sortField));
                setPayouts(newPayouts);
            })
            .catch((err) => showError(err.toString()));
    };
    const makeFile = () => {
        api.post(`${paymentUrl}/api/payout-files/`)
            .then((res) => showInfo('Laget betalingsfil med id ' + res))
            .then(fetchData)
            .catch((err) => showError(err.toString()));
    };
    const removePayout = (id) => {
        api.post(`${spleisUrl}/api/admin/payout/blocked`, {
            payoutIds: [id],
            blockedTypes: ['BLOCKED_AS_OVERWRITTEN'],
        })
            .then(() => {
                fetchData();
                showInfo('Fjernet utbetalingen fra listen');
            })
            .catch((e) => {
                showError(`Feil ved fjerning av utbetaling: ${e}`);
            });
    };

    useEffect(fetchData, []);

    return (
        <TaskContainer>
            <Helmet>
                <title>Betalingsfiler</title>
            </Helmet>

            <header className="task-header">
                <h1 className="task-title">Betalingsfiler til nettbank</h1>
            </header>

            <div className="task-actions">
                {payouts.length > 0 && (
                    <Button className="task-action-button" onClick={makeFile}>
                        Opprett fil for utestående betalinger
                    </Button>
                )}
            </div>

            <div className="task-body">
                {payouts.length > 0 && (
                    <div>
                        <h2>
                            Utestående betalinger ({payouts.length} spleiser,{' '}
                            {formatMoneyKr(payouts.map((p) => p.amount).reduce((a, v) => a + v))})
                        </h2>
                        <div>
                            <table className="table table-striped">
                                <thead>
                                <tr>
                                    <th>
                                        <button onClick={() => setSort('createdAutomatically')}>🤖</button>
                                    </th>
                                    <th>
                                        <button onClick={() => setSort('name')}>Mottaker</button>
                                    </th>
                                    <th>
                                        <button onClick={() => setSort('amount')}>Beløp</button>
                                    </th>
                                    <th>
                                        <button onClick={() => setSort('bankaccount')}>Konto</button>
                                    </th>
                                    <th>
                                        <button onClick={() => setSort('projectId')}>Prosjekt</button>
                                    </th>
                                    <th>
                                        <button onClick={() => setSort('text')}>Tekst</button>
                                    </th>
                                    <th/>
                                </tr>
                                </thead>
                                <tbody>
                                {payouts.map((t) => {
                                    return (
                                        <tr key={t.id}>
                                            <td>{t.createdAutomatically ? iconAutoCreated : ''}</td>
                                            <td>{t.name}</td>
                                            <td>{formatMoneyKr(t.amount)}</td>
                                            <td>{formatBankAccount(t.bankaccount)}</td>
                                            <td>{t.projectId && <ProjectLink id={t.projectId}/>}</td>
                                            <td>{t.text}</td>
                                            <td>
                                                <ConfirmButton
                                                    className="dangerous-button"
                                                    title="Fjern"
                                                    message="Er du sikker på at du vil fjerne den utestående utbetalingen fra listen?"
                                                    onClick={() => removePayout(t.payoutId)}
                                                />
                                            </td>
                                        </tr>
                                    );
                                })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                )}

                {payouts.length === 0 && <div>Ingen utestående betalinger</div>}

                <PayoutFilesList/>
            </div>
        </TaskContainer>
    );
};

export default PayoutFiles;
