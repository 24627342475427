import React, { useEffect, useState } from 'react';
import { spleisUrl } from '../../config';
import FormButton from '../../FormButton/FormButton';
import api from '../../api';
import { showError, showInfo } from '../../store';

const useActiveEvents = () => {
    const [events, setActiveEvents] = useState(null);
    useEffect(() => {
        api.get(`${spleisUrl}/api/admin/event/active`)
            .then((result) => {
                setActiveEvents(result);
            })
            .catch((err) => console.log('Kunne ikke hente aktive eventer', { err }));
    }, []);

    return { events };
};

const AddToEvent = ({ project }) => {
    const [eventId, setEventId] = useState(null);
    const [error, setError] = useState(null);
    const { events } = useActiveEvents();

    const addProjectToEvent = (e) => {
        if (eventId) {
            setError(null);
            return api
                .put(`${spleisUrl}/api/admin/project/${project.id}/event/${eventId}`)
                .then(() => {
                    setEventId(null);
                    showInfo('Prosjektet ble lagt til i eventet');
                    window.notify();
                })
                .catch((err) => {
                    setEventId(null);
                    showError('Kunne ikke legge til prosjektet i eventet nå');
                });
        } else {
            setError('Velg et event');
            return Promise.reject();
        }
    };

    const removeProjectFromEvent = () => {
        api.remove(`${spleisUrl}/api/admin/project/${project.id}/event`)
            .then(() => {
                setEventId(null);
                showInfo('Prosjektet ble lagt til i eventet');
                window.notify();
            })
            .catch((err) => {
                setEventId(null);
                showError('Kunne ikke legge til prosjektet i eventet nå');
            });
    };

    if (project.event_id) {
        return (
            <FormButton title={`Fjern fra event`} onClick={removeProjectFromEvent}>
                <p>Ta bort spleisen fra eventet</p>
            </FormButton>
        );
    }

    if (!events) {
        return null;
    }

    return (
        <FormButton title={`Legg til i event`} onClick={addProjectToEvent} keepOpenOnError={true}>
            <select
                onChange={(e) => {
                    setEventId(e.target.value);
                    setError(null);
                }}
            >
                <option>Velg et event</option>
                {events.map((event) => (
                    <option key={event.id} value={event.id}>
                        {event.name}
                    </option>
                ))}
            </select>
            {error && <p>{error}</p>}
        </FormButton>
    );
};

export default AddToEvent;
