import React from 'react';
import InvoiceTransactionTableRow from './InvoiceTransactionTableRow';

const InvoiceTransactionTable = ({ transactions }) => {
    return (
        <table className="table table-striped">
            <thead>
            <tr>
                <th>#</th>
                <th>Prosjekt</th>
                <th>BrukerId</th>
                <th>Tid</th>
                <th>Beløp</th>
                <th>Giver</th>
            </tr>
            </thead>
            <tbody>
            {transactions.map((t) => (
                <InvoiceTransactionTableRow transaction={t} />
            ))}
            </tbody>
        </table>
    )
}

export default InvoiceTransactionTable;