import React, { useState } from 'react';
import api from '../api';
import { spleisUrl } from '../config';
import { showError } from '../store';
import TaskContainer from '../TaskContainer/TaskContainer';
import styles from '../Menu/Menu.module.scss';
import Button from '../Button/Button';
import ShowMore from '../ShowMore/ShowMore';
import ProjectLink from '../SmartLinks/ProjectLink';
import * as queryString from 'qs';
import VisibleIf from '../VisibleIf/VisibleIf';
import { Helmet } from 'react-helmet';

const Search = () => {
    const [projects, setProjects] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [tags, setTags] = useState([]);

    const performSearch = (query) =>
        api.get(`${spleisUrl}/api/admin/search?${query}`)
            .then(res => setProjects(res))
            .catch(() => showError('Feil ved søking'));

    const handleSubmit = (e) => {
        const filter = {
            tags
        }

        const query = queryString.stringify({
            q: searchTerm,
            filter: JSON.stringify(filter),
        })

        e.preventDefault();
        performSearch(query)
    }

    const handleSearchTermChange = (e) => {
        setSearchTerm(e.target.value)
    }

    const handleAddTag = e => {
        if (e.key === 'Enter') {
            const tag = e.target.value.toLowerCase().trim();
            if (tag.length < 3) {
                return;
            }
            e.target.value = '';
            setTags(tags => [...tags, tag]);
        }
    };

    const handleRemoveTag = name => {
        setTags(tags.filter(tag => tag !== name))
    }

    return (
        <TaskContainer>
            <Helmet>
                <title>Søk</title>
            </Helmet>
            <header className="task-header">
                <h1 className="task-title">Søk</h1>
            </header>
            <div className="task-body">
                <form
                    onSubmit={handleSubmit}
                >
                    <input className={styles['menu-search-input']} placeholder="Søk på spleis" type="text" id="project-search-box" value={searchTerm} onChange={handleSearchTermChange} />
                    <Button>Søk</Button>
                </form>
                <VisibleIf isVisible={!!tags}>
                    <div>
                        <h5>Klikk på tag for å fjerne</h5>
                        <ul className="project-tags-list">
                            {tags?.map(tag => {
                                return (
                                    <li key={tag} title={tag} className="project-tag" onClick={() => handleRemoveTag(tag)}>
                                        {tag}
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                </VisibleIf>
                <div className="project-tags-add">
                    <h3>Legg til tag</h3>
                    <div className="project-tags-input">
                        <input
                            onKeyPress={handleAddTag}
                        />
                    </div>
                </div>
                <ShowMore
                    items={projects}
                    listCreator={showMoreItems => {
                        return (
                            <table className={'table table-striped'}>
                                <thead>
                                <tr>
                                    <th>Tittel</th>
                                    <th>Innsamler</th>
                                </tr>
                                </thead>
                                <tbody>
                                {showMoreItems.map(project => (
                                    <tr>
                                        <td><ProjectLink id={project.id} >{project.title}</ProjectLink></td>
                                        <td>{project.owner_first} {project.owner_last}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        );
                    }}
                />
            </div>
        </TaskContainer>
    );
};

export default Search;
