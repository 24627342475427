import React from 'react';
import styles from './Chevron.module.scss';

const Chevron = ({ isOpen = false, direction = 'up', className = '' }) => (
    <i
        className={[`fa fa-chevron-${direction}`, styles.arrowIcon, isOpen && styles.rotate, className].join(' ')}
        aria-hidden
    />
);
export default Chevron;
