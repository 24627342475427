import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import api from '../api';
import { spleisUrl } from '../config';
import { showError } from '../store';
import { Redirect } from 'react-router';
import TaskContainer from '../TaskContainer/TaskContainer';

const InvoiceNumberRedirect = () => {
    const params = useParams();
    const [invoice, setInvoice] = useState();
    const [loading, isLoading] = useState(true);

    const getInvoice = invoiceNumber => {
        isLoading(true);
        api.get(`${spleisUrl}/api/admin/invoice/invoiceNumber/${invoiceNumber}`)
            .then(res => {
                isLoading(false);
                setInvoice(res);
            })
            .catch(err => {
                isLoading(false);
                showError('Feil ved henting av faktura', err.text);
            });
    };

    useEffect(() => {
        getInvoice(params?.invoiceNumber)
    }, [params?.invoiceNumber]);

    if (invoice?.db.id) {
        return <Redirect to={`/invoice/${invoice.db.id}`} />;
    } else if (loading) {
        return (
            <p>Laster</p>
        );
    } else if(!loading && !invoice){
        return (
            <TaskContainer>
                <header className="task-header">
                    <h1 className="task-title">Fant ingen faktura med fakturanummer {params?.invoiceNumber}</h1>
                </header>
            </TaskContainer>
        )
    }
    return <p>Laster</p>
}

export default InvoiceNumberRedirect;