import React, { Component } from 'react';
import moment from 'moment';
import AccountingTable from './AccountingTable';
import { Helmet } from 'react-helmet';

export default class MappedData extends Component {
    render() {
        return (
            <>
                <Helmet>
                    <title>Pengeflyt</title>
                </Helmet>
                <AccountingTable
                    url={'events'}
                    columns={[
                        { Header: 'Event', accessor: 'event' },
                        {
                            Header: 'Date',
                            accessor: 'date',
                            minWidth: 180,
                            minResizeWidth: 30,
                            Cell: (row) => <span>{moment(row.value).format('lll')}</span>,
                        },
                        { Header: 'Giver', accessor: 'giver', Cell: (row) => <span>{Math.round(row.value)}</span> },
                        {
                            Header: 'Fordring Stripe',
                            accessor: 'fordringstripe',
                            minWidth: 80,
                            minResizeWidth: 30,
                            Cell: (row) => <span>{Math.round(row.value).toLocaleString()}</span>,
                        },
                        {
                            Header: 'Stripe fee',
                            accessor: 'feestripe',
                            Cell: (row) => <span>{Math.round(row.value)}</span>,
                        },
                        {
                            Header: 'Fordring Vipps',
                            accessor: 'fordringvipps',
                            minWidth: 150,
                            minResizeWidth: 30,
                            Cell: (row) => <span>{Math.round(row.value).toLocaleString()}</span>,
                        },
                        {
                            Header: 'Vipps fee',
                            accessor: 'feevipps',
                            Cell: (row) => <span>{Math.round(row.value)}</span>,
                        },
                        {
                            Header: 'Fordring Faktura',
                            accessor: 'fordringfaktura',
                            minWidth: 150,
                            Cell: (row) => <span>{Math.round(row.value).toLocaleString()}</span>,
                        },
                        {
                            Header: 'Klientkonto',
                            accessor: 'klientkonto',
                            minResizeWidth: 30,
                            Cell: (row) => <span>{Math.round(row.value).toLocaleString()}</span>,
                        },
                        {
                            Header: 'Bufferkonto',
                            accessor: 'bufferkonto',
                            minResizeWidth: 30,
                            Cell: (row) => <span>{Math.round(row.value).toLocaleString()}</span>,
                        },
                        {
                            Header: 'Innsamler',
                            accessor: 'innsamler',
                            minResizeWidth: 30,
                            Cell: (row) => <span>{Math.round(row.value)}</span>,
                        },
                        {
                            Header: 'Klientkonto Bambusa',
                            accessor: 'klientkontobambusa',
                            minResizeWidth: 30,
                            Cell: (row) => <span>{Math.round(row.value)}</span>,
                        },
                        {
                            Header: 'Bufferkonto Bambusa',
                            accessor: 'bufferkontobambusa',
                            minResizeWidth: 30,
                            Cell: (row) => <span>{Math.round(row.value)}</span>,
                        },
                        {
                            Header: 'Gjeld Bambusa',
                            accessor: 'gjeldbambusa',
                            minResizeWidth: 30,
                            Cell: (row) => <span>{Math.round(row.value)}</span>,
                        },
                        {
                            Header: 'Bambusa',
                            accessor: 'bambusa',
                            minResizeWidth: 30,
                            Cell: (row) => <span>{Math.round(row.value)}</span>,
                        },
                        {
                            Header: 'Inntekt',
                            accessor: 'inntekt',
                            minResizeWidth: 30,
                            Cell: (row) => <span>{Math.round(row.value)}</span>,
                        },
                        {
                            Header: 'MVA',
                            accessor: 'mva',
                            minResizeWidth: 30,
                            Cell: (row) => <span>{Math.round(row.value)}</span>,
                        },
                        {
                            Header: 'Tap',
                            accessor: 'tap',
                            Cell: (row) => <span>{Math.round(row.value).toLocaleString()}</span>,
                        },
                        {
                            Header: 'Avvik',
                            accessor: 'avvik',
                            minResizeWidth: 30,
                            Cell: (row) => <span>{Math.round(row.value)}</span>,
                        },
                        { Header: 'Meta_id', minResizeWidth: 30, accessor: 'meta_id' },
                        { Header: 'Meta_project', minResizeWidth: 30, accessor: 'meta_project' },
                        { Header: 'Meta_transaction_id', minResizeWidth: 30, accessor: 'meta_transaction_id' },
                    ]}
                    sorted={[{ id: 'date', desc: true }]}
                    includeEventSum={true}
                    sumUrl="events-sum"
                />
            </>
        );
    }
}
