import React, { useEffect, useRef, useState } from 'react';
import copy from 'clipboard-copy';
import api from '../api';
import { spleisFrontUrl, spleisUrl } from '../config';
import { showError, showInfo } from '../store';
import Button from '../Button/Button';
import styles from './ShowTemplate.module.scss';
import { JsonEditor as Editor } from 'jsoneditor-react';
import 'jsoneditor-react/es/editor.min.css';
import ace from 'brace';
import classNames from 'classnames';
import ConfirmModal from '../ConfirmModal/ConfirmModal';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import FormButton from '../FormButton/FormButton';
import { TemplateRowImage } from './TemplateRowImage';
import { Helmet } from 'react-helmet';

const PROFILE_HELP = {
    projectCreateHeadline: '',
    hidePayoutToOrganizationOption: false,
    wizardFields: ['title', 'moneygoal', 'payout_to', 'ends_at', 'description', 'purpose', 'cover_image'],
    wizardFieldsInfo: {
        title: '',
        ends_at: '',
        payout_to: '',
        rewards: '',
        collect_as: '',
        purpose: '',
        moneygoal: '',
        cover_image: '',
        description: '',
    },
    wizardFieldsTips: {
        title: '',
        ends_at: '',
        payout_to: '',
        rewards: '',
        collect_as: '',
        purpose: '',
        moneygoal: '',
        cover_image: '',
        description: '',
    },
    wizardFieldsTitle: {
        title: '',
        ends_at: '',
        payout_to: '',
        rewards: '',
        collect_as: '',
        purpose: '',
        moneygoal: '',
        cover_image: '',
        description: '',
    },
    wizardFieldsPlaceholders: {
        title: '',
        ends_at: '',
        payout_to: '',
        rewards: '',
        collect_as: '',
        purpose: '',
        moneygoal: '',
        cover_image: '',
        description: '',
    },
    rewardTemplate: {
        price: 250,
        title: '',
        description: '',
        quantity: '',
    },
};

const TemplateRow = ({ label, field, value, updateTemplate }) => {
    return (
        <div className={styles['template-input-container']}>
            <label className={styles['template-label']}>
                {label}
                <input
                    value={value || ''}
                    onChange={(e) => updateTemplate(e.target.value, field)}
                    className={styles['template-input']}
                />
            </label>
        </div>
    );
};

const TemplateCheckbox = ({ label, field, value, updateTemplate }) => {
    return (
        <div className={styles['template-input-container']}>
            <label className={styles['template-checkbox-label']}>
                <input
                    type="checkbox"
                    value={value}
                    defaultChecked={value}
                    onChange={(e) => updateTemplate(e.target.checked, field)}
                    className={styles['template-input']}
                />
                {label}
            </label>
        </div>
    );
};

const Reward = ({ reward, updateReward }) => {
    const [deleted, setDeleted] = useState(false);

    useEffect(() => {
        setDeleted(reward.deleted);
    }, [reward.id]);
    return (
        <li className={classNames(styles['reward-input'], deleted && styles['deleted-reward'])}>
            <h3>{reward.draft ? 'Legg til belønning' : 'Belønning'}</h3>
            <TemplateRow
                label={'Tittel'}
                field={'title'}
                value={reward.title}
                updateTemplate={(value, field) => updateReward(reward.id, value, field)}
            />
            <TemplateRowTextArea
                label={'Beskrivelse'}
                field={'description'}
                value={reward.description}
                updateTemplate={(value, field) => updateReward(reward.id, value, field)}
            />
            <TemplateRow
                label={'Pris'}
                field={'price'}
                value={reward.price}
                updateTemplate={(value, field) => updateReward(reward.id, value, field)}
            />
            <TemplateRow
                label={'Antall'}
                field={'quantity'}
                value={reward.quantity}
                updateTemplate={(value, field) => updateReward(reward.id, value, field)}
            />
            <TemplateRowSelect
                value={reward.requires_address}
                updateTemplate={(value, field) => updateReward(reward.id, value === 'true', field)}
                label="Krever adresse"
                field={'requires_address'}
                options={[
                    { label: 'Nei', value: false },
                    { label: 'Ja', value: true },
                ]}
            />

            <TemplateRow
                label={'Bilde'}
                field={'image_url'}
                value={reward.images?.image_url}
                updateTemplate={(value, field) => updateReward(reward.id, value, field)}
            />

            <Button
                className={styles['template-button']}
                onClick={() => {
                    updateReward(reward.id, !deleted, 'deleted');
                    setDeleted(!deleted);
                }}
            >
                {deleted ? 'Angre slett' : 'Slett'}
            </Button>
        </li>
    );
};

const TemplateRewardsRow = ({ label, field, value, updateTemplate }) => {
    const [rewards, setRewards] = useState(value?.filter((r) => !r.deleted) || []);

    const addReward = () => {
        setRewards([...rewards, { draft: true, requires_address: false, id: new Date().getTime() }]);
    };

    useEffect(() => {
        setRewards(value);
    }, [value?.length]);

    const updateReward = (id, value, rewardField) => {
        const rewardIndex = rewards.findIndex((r) => r.id === id);
        const updatedRewards = [
            ...rewards.slice(0, rewardIndex),
            { ...rewards[rewardIndex], [rewardField]: value },
            ...rewards.slice(rewardIndex + 1),
        ];

        const rewardsWithoutDeletedDrafts = updatedRewards.filter((r) => {
            return !(r.draft && r.deleted);
        });

        updateTemplate(rewardsWithoutDeletedDrafts, field);
        setRewards(updatedRewards);
    };

    const mappedRewards = rewards?.map((r) => <Reward key={r.id} reward={r} updateReward={updateReward} />);
    return (
        <div className={styles['template-input-container']}>
            <label className={styles['template-label']}>{label}</label>
            <ul className={styles.rewards}>{mappedRewards}</ul>

            <div>
                <Button className={styles['template-add-reward']} onClick={addReward}>
                    Legg til belønning
                </Button>
            </div>
        </div>
    );
};

const TemplateRowSelect = ({ label, value, field, options, updateTemplate }) => {
    return (
        <div className={styles['template-input-container']}>
            <label className={styles['template-label']}>
                {label}
                <select
                    value={value || options[0]}
                    onChange={(e) => updateTemplate(e.target.value, field)}
                    className={styles['template-input']}
                >
                    {options.map((o) => (
                        <option key={o.value} value={o.value}>
                            {o.label}
                        </option>
                    ))}
                </select>
            </label>
        </div>
    );
};

const TemplateRowTextArea = ({ label, value, field, updateTemplate }) => {
    return (
        <div className={styles['template-input-container']}>
            <label className={styles['template-label']}>
                {label}
                <textarea
                    value={value || ''}
                    onChange={(e) => updateTemplate(e.target.value, field)}
                    className={styles['template-input']}
                />
            </label>
        </div>
    );
};
const TemplateRowJson = ({ label, field, value, updateTemplate }) => {
    return (
        <div className={styles['template-input-container']}>
            <label className={[styles['template-label'], styles['template-label-editor']].join(' ')}>
                {label}
                <Editor
                    mode="code"
                    allowedModes={['code', 'tree']}
                    value={value}
                    onChange={(e) => {
                        updateTemplate(e, field);
                    }}
                    htmlElementProps={{
                        className: styles['json-input'],
                    }}
                    ace={ace}
                />
            </label>
        </div>
    );
};

const ShowTemplate = (props) => {
    const [template, setTemplate] = useState(null);
    const [showDelete, setShowDelete] = useState(false);
    const [profile, setProfile] = useState({});
    const history = useHistory();

    const templateId = props.match.params.id;

    function fetchData() {
        api.get(`${spleisUrl}/api/admin/template/${templateId}`)
            .then((res) => {
                if (res?.id) {
                    setTemplate({
                        ...res,
                        tags: Array.isArray(res.tags) ? res.tags.join(', ') : res.tags,
                        categories: Array.isArray(res.categories) ? res.categories.join(', ') : res.categories,
                    });
                    setProfile(res.profile);
                }
            })
            .catch(() => showError('Feil ved henting av mal fra baksystem', 3));
    }

    useEffect(() => {
        fetchData();
    }, [templateId]);

    const updateTemplate = (value, field) => {
        const newTemplate = { ...template };
        newTemplate[field] = value;
        setTemplate(newTemplate);
    };

    const deleteTemplate = async () => {
        try {
            await api.remove(`${spleisUrl}/api/admin/template/${templateId}`);
            history.push(`/cms/templates/`);
        } catch {
            showError('Feil ved sletting av mal', 3);
        }
    };

    const duplicateTemplate = () => {
        api.post(`${spleisUrl}/api/admin/template/${templateId}/duplicate`)
            .then((t) => {
                showInfo(<a href={`/cms/template/${t.templateId}`}>Ny mal opprettet med id {t.templateId}</a>);
            })
            .catch(() => showError('Feil ved duplisering av mal', 3));
    };

    const createProjectFromTemplate = () => {
        window.open(`${spleisFrontUrl}/project/create?templateId=${templateId}`, '_blank');
    };

    const mergeJsonIntoProjectProfiles = () => {
        if (confirm('Er du sikker? Verdiene blir overskrevet i profilen til alle prosjektene som har brukt malen.')) {
            api.post(`${spleisUrl}/api/admin/template/${templateId}/update-project-profiles`, {
                profile,
            })
                .then(() => showInfo('Prosjektprofilene ble oppdatert'))
                .catch(() => showError('Feil ved oppdatering av prosjektprofiler', 3));
        }
    };

    const saveTemplate = () => {
        api.put(`${spleisUrl}/api/admin/template`, {
            ...template,
        })
            .then((t) => {
                showInfo('Mal oppdatert', 3);
                setTemplate({
                    ...t,
                    tags: Array.isArray(t.tags) ? t.tags.join(', ') : t.tags,
                    categories: Array.isArray(t.categories) ? t.categories.join(', ') : t.categories,
                });
            })
            .catch((err) => {
                showError(`Feil ved oppdatering av mal: ${err}`, 3);
            });
    };

    if (!template) {
        return (
            <div className={styles.projectTemplate}>
                <h1>Malen finnes ikke</h1>
                <p>
                    <Link to={'/cms/templates/'}>Gå tilbake til oversikten over maler</Link>
                </p>
            </div>
        );
    }

    return (
        <div className={styles.projectTemplate}>
            <Helmet>
                <title>Prosjektmal {template.template_title ? `«${template.template_title}»` : ''}</title>
            </Helmet>
            <h1>Prosjektmal {template.template_title ? `«${template.template_title}»` : ''}</h1>
            <ConfirmModal
                isOpen={showDelete}
                message={`Vil du slette malen${template.template_title ? ` «${template.template_title}»` : ''}?`}
                cancelText={'Avbryt'}
                successText={'Slett malen'}
                onClickCancel={() => setShowDelete(false)}
                onClickSuccess={deleteTemplate}
            />
            <Button className={styles['duplicate-template-button']} onClick={duplicateTemplate}>
                Dupliser mal
            </Button>
            <Button className={styles['warning-button']} onClick={() => setShowDelete(true)}>
                Slett mal
            </Button>
            <Button className={styles['create-project-from-template-button']} onClick={createProjectFromTemplate}>
                Lag en spleis
            </Button>
            <TemplateRow
                value={template.template_title}
                updateTemplate={updateTemplate}
                label="Maltittel"
                field={'template_title'}
            />
            <TemplateRow value={template.title} updateTemplate={updateTemplate} label="Tittel" field={'title'} />
            <TemplateRow value={template.purpose} updateTemplate={updateTemplate} label="Formål" field={'purpose'} />
            <TemplateRow
                value={template.ends_at}
                updateTemplate={updateTemplate}
                label="Avslutningsdato"
                field={'ends_at'}
            />
            <TemplateRowTextArea
                value={template.description}
                updateTemplate={updateTemplate}
                label="Beskrivelse"
                field={'description'}
            />

            <TemplateRowImage
                value={template.img_url}
                label="Bilde"
                field={'img_url'}
                templateId={templateId}
                setTemplate={setTemplate}
                updateTemplate={updateTemplate}
            />

            <TemplateRow
                value={template.moneygoal ? parseInt(template.moneygoal, 10) : ''}
                updateTemplate={updateTemplate}
                label="Målbeløp"
                field={'moneygoal'}
            />
            <TemplateRowSelect
                value={template.goaltype}
                updateTemplate={updateTemplate}
                label="Målbeløptype"
                field={'goaltype'}
                options={[
                    { label: 'Fleksibel', value: 'FLEXI' },
                    { label: 'Fast', value: 'TARGET' },
                ]}
            />
            <TemplateRowSelect
                value={template.subprojects_enabled}
                updateTemplate={updateTemplate}
                label="Kan deltakere lage sider"
                field={'subprojects_enabled'}
                options={[
                    { label: 'Nei', value: false },
                    { label: 'Ja', value: true },
                ]}
            />
            <TemplateRow
                value={template.project_id}
                updateTemplate={updateTemplate}
                label="Prosjekt"
                field={'project_id'}
            />
            <TemplateRow
                value={template.organization_id}
                updateTemplate={updateTemplate}
                label="Organisasjonsid"
                field={'organization_id'}
            />
            <TemplateRow value={template.event_id} updateTemplate={updateTemplate} label="Eventid" field={'event_id'} />
            <TemplateRow value={template.tags} updateTemplate={updateTemplate} label="Tags" field={'tags'} />
            <TemplateRow
                value={template.categories}
                updateTemplate={updateTemplate}
                label="Kategorier (tech-navn)"
                field={'categories'}
            />
            <TemplateCheckbox
                value={template.block_payment}
                updateTemplate={updateTemplate}
                label="Blokkér for utbetaling"
                field={'block_payment'}
            />
            <TemplateRewardsRow
                value={template.rewards}
                updateTemplate={updateTemplate}
                label={'Belønninger'}
                field={'rewards'}
            />
            <TemplateRowJson
                value={template.profile}
                updateTemplate={updateTemplate}
                label="Hjelpetekster"
                field={'profile'}
            />
            <FormButton
                title="Legg til ting i profil til alle prosjektene som har brukt malen"
                onClick={mergeJsonIntoProjectProfiles}
            >
                <div className={styles.updateProjectProfilesInfo}>
                    Verdiene som settes her blir overskrevet, men resten av profilen blir værende
                </div>
                <Editor
                    mode="code"
                    allowedModes={['code', 'tree']}
                    value={profile}
                    onChange={setProfile}
                    htmlElementProps={{
                        className: styles.jsonInput,
                    }}
                    ace={ace}
                />
            </FormButton>
            <div>
                <Button className={'task-action-button'} onClick={() => copy(JSON.stringify(PROFILE_HELP))}>
                    Klikk her for å kopiere et startpunkt for hjelpetekst
                </Button>
            </div>
            <div>
                <Button className={styles['template-save-button']} onClick={saveTemplate}>
                    Oppdater mal
                </Button>
            </div>
        </div>
    );
};

export default ShowTemplate;
