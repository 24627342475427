import React, { Component } from 'react';
import { showError } from '../store';
import api from '../api';
import { spleisUrl } from '../config';
import { Link } from 'react-router-dom';
import './Invoice.scss';
import TaskContainer from '../TaskContainer/TaskContainer';
import InvoiceList from './InvoiceList';
import { Helmet } from 'react-helmet';

class Invoices extends Component {
    constructor(props) {
        super(props);
        this.state = { invoices: {} };
        api.get(`${spleisUrl}/api/admin/invoice/`)
            .then((res) => this.setState({ invoices: res }))
            .catch((err) => showError('Feil ved henting av fakturaer'));

        api.get(`${spleisUrl}/api/admin/invoice/old/`)
            .then((res) => this.setState({ oldInvoices: res }))
            .catch((err) => showError('Feil ved henting av fakturaer'));
    }

    render() {
        const invoices = this.state.invoices.db || [];
        const outdated = this.state.oldInvoices || [];
        const notSent = invoices.filter((i) => !i.sent_at && !i.creditnote_at);
        const notPaid = invoices.filter((i) => i.sent_at && !i.paid_at && !i.creditnote_at);
        const toBeRefunded = invoices.filter((i) => i.to_be_refunded);
        const rest = invoices.filter((i) => notSent.indexOf(i) === -1 && notPaid.indexOf(i) === -1);
        return (
            <TaskContainer>
                <Helmet>
                    <title>Fakturaoversikt</title>
                </Helmet>
                <header className="task-header">
                    <h1 className="task-title">Fakturaoversikt</h1>
                </header>
                <div className="task-actions">
                    <Link to="/invoiceCreate">Opprett ny</Link>
                </div>
                <div className="task-body">
                    <InvoiceList title="Nye som skal sendes" invoices={notSent} />
                    <InvoiceList title="Skal refunderes" invoices={toBeRefunded} />
                    <InvoiceList title="Venter på innbetaling" invoices={notPaid} />
                    <InvoiceList title="Betalte / kreditnota" invoices={rest} />
                    <InvoiceList
                        title="Utdaterte"
                        invoices={outdated}
                        description={'Fakturaer til prosjekter som ble avsluttet for over en måned siden.'}
                    />
                </div>
            </TaskContainer>
        );
    }
}

export default Invoices;
