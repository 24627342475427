import React, { useState } from 'react';
import api from '../../api';
import { spleisUrl } from '../../config';
import { showError, showInfo } from '../../store';
import ConfirmModal from '../../ConfirmModal/ConfirmModal';
import Button from '../../Button/Button';
import styles from './BlockProject.module.scss';

const projectWillBePausedMessageBody = (project) => `Hei 👋

Spleis nr. ${project.id} står i fare for å bli satt på pause: formålet og beskrivelsen i spleisen din er veldig generell, og vi ber derfor om at du oppdaterer den med mer konkret informasjon om hva du samler inn penger til og hvor/hvem pengene utbetales til. Mottaker bør være en navngitt person eller organisasjon.

Spleisen kan oppdateres her: https://www.spleis.no/project/${project.id}/edit/description

Dersom spleisen ikke oppdateres, vil den bli blokkert for innbetalinger første hverdag etter tre døgn. Ved fortsatt mangel på tilfredsstillende oppdatering innen 6 dager fra denne meldingen er sendt, vil spleisen bli slettet og pengene tilbakebetales til giverne.

Vi ber deg derfor svare på denne meldingen når spleisen er oppdatert.

Hilsen Spleis-teamet`;

export const PauseCountdownButton = ({ project, onClose }) => {
    const [pauseModalOpen, setPauseModalOpen] = useState(false);
    const [abortModalOpen, setAbortModalOpen] = useState(false);
    const isCountingDownToPause = project.will_be_paused_at;
    const isBlockedForTransactions = (project.blocked?.blocked_types || []).includes('BLOCKED_FOR_TRANSACTIONS');
    const [title, setTitle] = useState('Spleisen din trenger mer informasjon');
    const [message, setMessage] = useState(() => projectWillBePausedMessageBody(project));

    const startPauseCountdown = () => {
        api.post(`${spleisUrl}/api/admin/project/${project.id}/pause-countdown`)
            .then(() => {
                showInfo('Nedtelling startet');
                api.post('/api/messages', {
                    userId: project.owner_id,
                    subject: title,
                    message: message,
                })
                    .then((res) => {
                        if (res.inappSent || res.emailSent) {
                            api.post('/api/comments', {
                                comment: `Sendte intercom melding til eier med tittel: 'Spleisen din trenger mer informasjon'`,
                                kis: { project: project.id },
                            }).then(() => {
                                window.notify();
                                onClose();
                            });
                        } else {
                            window.notify();
                            onClose();
                        }
                    })
                    .catch((e) => showError(`Feil ved sending av melding til innsamler: ${e}`));
            })
            .catch((e) => showError(`Feil ved starting av nedtelling: ${e}`));
    };

    const abortPauseCountdown = () => {
        api.remove(`${spleisUrl}/api/admin/project/${project.id}/pause-countdown`)
            .then(() => {
                window.notify();
                onClose();
                showInfo('Pause-nedtelling avbrutt');
            })
            .catch(() => showError('Klarte ikke å avbryte pause-nedtellingen'));
    };

    const countdownToPauseButtonText = isCountingDownToPause
        ? 'Avbryt nedtelling'
        : isBlockedForTransactions
        ? 'Pauset'
        : 'Pause etter 72 timer';

    return (
        <>
            <Button
                disabled={isBlockedForTransactions}
                onClick={() => (isCountingDownToPause ? setAbortModalOpen(true) : setPauseModalOpen(true))}
            >
                {countdownToPauseButtonText}
            </Button>
            <ConfirmModal
                isOpen={pauseModalOpen}
                title={countdownToPauseButtonText}
                message="Er du sikker på at du vil starte nedtelling til pause? Dette legger til blå banner på spleisen så potensielle givere kan se det."
                successText="Pause etter 72 timer"
                cancelText="Avbryt"
                onClickSuccess={startPauseCountdown}
                onClickCancel={() => setPauseModalOpen(false)}
                className="dangerous-button"
            >
                <div className={styles.pauseInputWrapper}>Melding til innsamler</div>
                <div className={styles.pauseInputWrapper}>
                    <label>Tittel</label>
                    <input
                        className={styles.pauseTitle}
                        type="text"
                        onChange={(e) => setTitle(e.target.value)}
                        value={title}
                    />
                </div>
                <div className={styles.pauseInputWrapper}>
                    <label>Melding</label>
                    <textarea
                        className={styles.pauseMessage}
                        onChange={(e) => setMessage(e.target.value)}
                        value={message}
                    />
                </div>
            </ConfirmModal>
            <ConfirmModal
                isOpen={abortModalOpen}
                title="Avbryt nedtelling"
                message="Er du sikker på at du vil avbryte nedtellingen?"
                successText="Ja"
                cancelText="Nei"
                onClickSuccess={abortPauseCountdown}
                onClickCancel={() => setAbortModalOpen(false)}
                className="dangerous-button"
            />
        </>
    );
};
