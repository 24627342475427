import React, { useState, useEffect } from 'react';
import ConfirmModal from '../ConfirmModal/ConfirmModal';
import Button from '../Button/Button';

const ConfirmButton = ({
    title,
    message,
    successText,
    optionalText,
    cancelText,
    onClick = () => {},
    onClickButton = () => {},
    onClickCancel = () => {},
    onClickOptional = () => {},
    showOptionalButton = false,
    buttonText,
    keepOpenOnError,
    className,
    children,
    showButton,
    isOpen,
    doDoubleConfirm,
    disabled = false,
}) => {
    const [isModalOpen, setIsModalOpen] = useState(isOpen);

    useEffect(() => {
        setIsModalOpen(isOpen);
    }, [isOpen]);

    return (
        <>
            <Button
                className={className}
                onClick={(e) => {
                    onClickButton(e);
                    setIsModalOpen(true);
                }}
            >
                {buttonText || title}
            </Button>
            <ConfirmModal
                isOpen={isModalOpen}
                title={title}
                message={message}
                successText={successText || 'Lagre'}
                cancelText={cancelText || 'Avbryt'}
                optionalText={optionalText || ''}
                onClickOptional={() => {
                    setIsModalOpen(false);
                    onClickOptional();
                }}
                children={children}
                showButton={showButton}
                showOptionalButton={showOptionalButton}
                doDoubleConfirm={doDoubleConfirm}
                disabled={disabled}
                onClickSuccess={() => {
                    if (keepOpenOnError) {
                        onClick()
                            .then(() => setIsModalOpen(false))
                            .catch(() => {});
                    } else {
                        setIsModalOpen(false);
                        onClick();
                    }
                }}
                onClickCancel={() => {
                    setIsModalOpen(false);

                    onClickCancel();
                }}
            />
        </>
    );
};

export default ConfirmButton;
