import React from 'react';
import InfoDataList, { DataListItem } from '../InfoDataList/InfoDataList';
import { formatDate } from '../dates';
import './Org.scss';
import { VERIFICATION_STATUSES } from './Org';

const VerifiedInformation = ({ verificationData = {}, isVerified = false }) => {
    const { assignee } = verificationData;

    if (!(assignee || isVerified)) {
        return null;
    }

    let verifiedBy = '';

    if (assignee.aud) {
        verifiedBy = <span>{assignee.name}</span>;
    } else if (assignee.fnr) {
        if (assignee.role) {
            verifiedBy = (
                <>
                    <span>{assignee.role.name}</span>
                    <span> ({assignee.role.title})</span>
                </>
            );
        } else {
            verifiedBy = (
                <span>
                    {assignee.first} {assignee.last}
                </span>
            );
        }
    } else if (assignee.salt) {
        verifiedBy = (
            <a href={`/user/${assignee.id}`}>
                {assignee.first} {assignee.last}
            </a>
        );
    } else if (assignee.realName) {
        verifiedBy = <span>{assignee.realName}</span>;
    }

    if (!verifiedBy) {
        return null;
    }

    return <DataListItem label={'Organisasjonen ble verifisert av'}>{verifiedBy}</DataListItem>;
};

const BankAccount = ({ mainAccount = {}, organization = {} }) => {
    const { verification_data, verification_status } = organization;

    return (
        <section className="infoboks bankAccount">
            <InfoDataList key={mainAccount.id} title="Konto">
                <DataListItem label="ID">{mainAccount.id}</DataListItem>
                <DataListItem label="Kontonummer">{mainAccount.account_number}</DataListItem>
                <DataListItem label="Ble opprettet">
                    {mainAccount.created_at && formatDate(mainAccount.created_at)}
                </DataListItem>
                <DataListItem label="Verifiseringsdato">
                    {mainAccount.verified_at ? formatDate(mainAccount.verified_at) : 'Ikke verifisert'}
                </DataListItem>
                <DataListItem label="KAR-registeret">
                    {mainAccount.kar_account_verification
                        ? mainAccount.kar_account_verification.description
                        : 'Ikke sjekket'}
                </DataListItem>
                <DataListItem label="Hovedkonto">{mainAccount.is_main_account ? 'Ja' : 'Nei'}</DataListItem>
                {verification_data && (
                    <VerifiedInformation
                        verificationData={verification_data}
                        isVerified={verification_status === VERIFICATION_STATUSES.VERIFIED}
                    />
                )}
            </InfoDataList>
        </section>
    );
};

export default BankAccount;
