import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import AvstemmingTable from './AvstemmingTable';

export default class Avstemming extends Component {
    render() {
        return (
            <>
                <Helmet>
                    <title>Avstemming</title>
                </Helmet>
                <AvstemmingTable
                    url={'avstemming'}
                    sumUrl={'avstemming-sum'}
                    sorted={[{ id: 'date', desc: false }]}
                    showProjectFilter={false}
                    includeEventSum={true}
                />
            </>
        );
    }
}
