import PropTypes from 'prop-types';

const VisibleIf = ({ isVisible, children }) => {
    if (isVisible) {
        return children;
    }
    return null;
};

VisibleIf.defaultProps = {
    isVisible: false,
};

VisibleIf.propTypes = {
    isVisible: PropTypes.bool.isRequired,
    children: PropTypes.node.isRequired,
};

export default VisibleIf;
