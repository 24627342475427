import React, { Component } from 'react';
import InvoiceList from './InvoiceList';

class InvoiceTable extends Component {
    render() {
        const invoices = this.props.data;
        if (!!invoices && invoices.length > 0) {
            return (
                <div className={'invoice-table-data'}>
                    <InvoiceList title={this.props.title} invoices={invoices} />
                </div>
            );
        } else {
            return null;
        }
    }
}

export default InvoiceTable;
