import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import api from '../api';
import { showError, showInfo } from '../store';
import { paymentUrl, spleisUrl } from '../config';
import InfoDataList from '../InfoDataList/InfoDataList';
import TaskContainer from '../TaskContainer/TaskContainer';
import Button from '../Button/Button';
import Comments from '../Comments/Comments';
import { formatMoneyKr } from '../formatters';
import { formatDate } from '../dates';
import withData from '../with-data';
import history from '../history';
import ConfirmButton from '../ConfirmButton/ConfirmButton';
import InvoiceTransactionTable from './InvoiceTransactionTable';
import RefundTable from '../Refund/RefundTable';
import { Helmet } from 'react-helmet';
import ProjectLink from '../SmartLinks/ProjectLink';

class Transactions extends Component {
    createTransaction = () => {
        if (this.amount.value.length === 0 || this.to_project.value.length === 0) {
            showError('Beløp og prosjekt er påkrevd');
            return;
        }
        if (+this.amount.value > this.remainingAmount()) {
            showError('Beløp kan ikke være større enn gjenstående på fakturaen');
            return;
        }
        const projectId = this.to_project.value;
        const isGift = this.gift.value === 'true';
        const data = {
            amount: this.amount.value,
            to_project: projectId,
            from_user: this.props.invoice.from_user,
            created_at: new Date().toISOString(),
            from_user_meta_data:
                this.giverList.value || this.comment.value
                    ? { anonymous: false, name: this.giverList.value, comment: this.comment.value }
                    : { anonymous: true },
            invoice_number: this.props.invoice.invoice_number,
            pricing: !!this.pricing.value ? { functionName: this.pricing.value } : null,
        };
        api.post(`${spleisUrl}/api/admin/transaction`, data)
            .then((transaction) => {
                this.amount.value = this.to_project.value = this.giverList.value = this.gift.value = this.pricing.value =
                    '';
                showInfo('Transaksjon opprettet');
                window.notify();
                return transaction;
            })
            .then((transaction) => {
                if (isGift) {
                    const organizationIdentifier = this.props.invoice.fiken_metadata.customerInfo
                        .organizationIdentifier;

                    api.post(`${spleisUrl}/api/admin/project/gift`, {
                        projectId: projectId,
                        transactionId: transaction.id,
                        orgNumber: organizationIdentifier,
                    })
                        .then(() => {
                            showInfo('Transaksjon som vises i banner er opprettet');
                            window.notify();
                        })
                        .catch((e) => {
                            showError('Feil ved oppretting av gave: ' + e.toString());
                        });
                }
            })
            .catch((err) => showError('Feil ved oppretting av transaksjon ' + err.toString()));
    };

    remainingAmount = () =>
        +this.props.invoice.amount - (this.props.data || []).map((t) => +t.amount).reduce((a, v) => a + v, 0);

    render() {
        const transactions = this.props.data || [];
        return (
            <div>
                <header>
                    <h2>
                        {transactions.length} transaksjoner ({formatMoneyKr(this.remainingAmount())} igjen)
                    </h2>
                </header>
                {transactions.length > 0 && <InvoiceTransactionTable transactions={transactions} />}
                {!this.props.invoice.to_project && this.props.invoice.invoice_number && (
                    <div className="add-transaction-container">
                        <h2>Legg til transaksjon</h2>
                        <label className="invoice-transaction-input">
                            <span>Prosjekt-id: </span>
                            <input type="number" ref={(el) => (this.to_project = el)} />
                        </label>
                        <label className="invoice-transaction-input">
                            <span>Beløp: </span>
                            <input type="number" ref={(el) => (this.amount = el)} />
                        </label>
                        <label className="invoice-transaction-input">
                            <span>Prisfunksjon:</span>
                            <select ref={(el) => (this.pricing = el)}>
                                <option value={''}>Standard</option>
                                <option value={'free'}>Gratis</option>
                                <option value={'percentageSixPointFive'}>6.5%</option>
                                <option value={'sixPointFivePercentOver5k'}>6.5% over 5k</option>
                                <option value={'percentage5'}>5%</option>
                                <option value={'fivePercentOver10k'}>5% over 10k</option>
                            </select>
                        </label>
                        <label className="invoice-transaction-input">
                            <span>Giverlistenavn: </span>
                            <input type="text" ref={(el) => (this.giverList = el)} />
                        </label>
                        <label className="invoice-transaction-input">
                            <span>Kommentar: </span>
                            <input type="text" ref={(el) => (this.comment = el)} />
                        </label>
                        <label className="invoice-transaction-input" style={{ marginBottom: '20px' }}>
                            <span>Vis i banner som stor giver?</span>
                            <select ref={(el) => (this.gift = el)}>
                                <option value={false}>Nei</option>
                                <option value={true}>Ja</option>
                            </select>
                        </label>

                        <Button onClick={this.createTransaction}>Opprett transaksjon</Button>
                    </div>
                )}
            </div>
        );
    }
}

const TransactionsWithData = withData(Transactions, (id) => `${spleisUrl}/api/admin/transaction?invoiceNumbers=${id}`);
const RefundsWithData = withData(
    RefundTable,
    (invoiceNumber) => `${spleisUrl}/api/admin/transaction/refunds/invoice/${invoiceNumber}`
);

class Invoice extends Component {
    constructor(props) {
        super(props);
        this.state = { processing: false };
        this.fetchData();
    }

    fetchData = () => {
        api.get(`${spleisUrl}/api/admin/invoice/${this.props.match.params.id}`)
            .then((res) => this.setState({ db: res.db, fiken: res.fiken, matching_gift: res.matching_gift }))
            .catch((err) => showError('Feil ved henting av faktura', err.text));
    };

    sendInvoice = (method) => {
        this.setState({ processing: true });
        api.post(`${spleisUrl}/api/admin/invoice/${this.state.db.id}/send/${method}`)
            .then(() => {
                this.setState({ processing: false });
                this.fetchData();
                showInfo('Faktura sendt');
            })
            .catch((err) => {
                this.setState({ processing: false });
                showError(`Feil ved sending av faktura: ${err.text}`);
            });
    };

    addCreditnote = () => {
        this.setState({ processing: true });
        api.post(`${paymentUrl}/api/invoice/${this.state.db.id}/creditnote`, {})
            .then(() => {
                this.setState({ processing: false });
                this.fetchData();
                showInfo('Faktura merket med kreditnota.');
            })
            .catch((err) => {
                this.setState({ processing: false });
                showError(`Feil ved merking av faktura med kreditnota: ${err.text}`);
            });
    };

    createFikenInvoice = () => {
        this.setState({ processing: true });
        api.post(`${spleisUrl}/api/admin/invoice/${this.state.db.id}/createFiken`)
            .then((res) => {
                this.setState({ processing: false, db: res.db, fiken: res.fiken, matching_gift: undefined });
                showInfo('Faktura opprettet hos Fiken');
            })
            .catch((err) => {
                this.setState({ processing: false });
                showError(`Feil ved oppretting av faktura hos fiken: ${err.text}`);
            });
    };

    deleteInvoice = () => {
        this.setState({ processing: true });
        api.remove(`${paymentUrl}/api/invoice/${this.state.db.id}`)
            .then(() => {
                this.setState({ processing: false });
                showInfo('Faktura slettet');
                history.push(`/invoice`);
            })
            .catch((err) => {
                this.setState({ processing: false });
                showError(`Feil ved sletting av faktura: ${err}`);
            });
    };

    paidInvoice = () => {
        const body = {
            date: document.getElementById('dato-for-utbetaling').value || new Date(),
        };
        this.setState({ processing: true });
        api.post(`${spleisUrl}/api/admin/invoice/${this.state.db.id}/paid`, body)
            .then(() => {
                this.setState({ processing: false });
                this.fetchData();
                showInfo('Faktura merket som betalt');
            })
            .catch((err) => {
                this.setState({ processing: false });
                showError(`Feil ved betaling av faktura: ${err.text}`);
            });
    };

    convertToInvoiceWithoutProject = () => {
        this.setState({ processing: true });
        api.put(`${paymentUrl}/api/invoice/${this.state.db.id}/convert-to-invoice-without-project`, {
            invoiceId: this.state.db.id,
            projectId: this.state.db.to_project,
        })
            .then(() => {
                this.setState({ processing: false });
                this.fetchData();
                showInfo('Fakturaen er nå registrert som en samlefaktura');
            })
            .catch((err) => {
                this.setState({ processing: false });
                showError(`Feil ved konvertering til samlefaktura: ${err.text}`);
            });
    };

    getInvoicePdf = async ({ invoiceId, invoiceNumber }) => {
        const pdf = await api.get(`${spleisUrl}/api/admin/invoice/${invoiceId}/pdf`, { binary: true });
        const file = new Blob([pdf], { type: 'application/octet-binary' });
        const a = document.createElement('a');
        a.href = URL.createObjectURL(file);
        a.download = `${invoiceNumber}.pdf`;
        document.body.appendChild(a);
        a.click();
    };

    render() {
        const invoiceDb = this.state.db;
        const matchingGift = this.state.matching_gift;
        const invoiceFiken = this.state.fiken;
        if (!invoiceDb) {
            return null;
        }
        const fikenUrl = invoiceFiken ? invoiceFiken.friendlyFikenUrl : null;
        const sendt = invoiceDb.sent_at;
        const visOpprettKnapp = !invoiceDb.fiken_url;
        const visSendKnapp = invoiceDb.fiken_url && !invoiceDb.paid_at;
        const visPaidKnapp = invoiceDb.sent_at && !invoiceDb.paid_at;
        const visSamlefakturaKnapp = invoiceDb.sent_at && !invoiceDb.paid_at && invoiceDb.to_project;
        const visBrregKnapp = invoiceDb?.fiken_metadata?.customerInfo?.organizationIdentifier;
        // Sammenligning med "==" for å fange både null og undefined
        const sendSomEHF = invoiceDb.send_as_ehf == null ? 'Data mangler' : invoiceDb.send_as_ehf ? 'Ja' : 'Nei';
        const detaljer = {
            ID: invoiceDb.id,
            Fakturanummer: invoiceDb.invoice_number,
            KID: invoiceDb.kid || (invoiceFiken && invoiceFiken.kid) || '',
            Prosjekt: invoiceDb.to_project,
            Beløp: formatMoneyKr(invoiceDb.amount),
            'Send som EHF?': sendSomEHF,
            'Giverliste?': invoiceDb.transaction_list_info && invoiceDb.transaction_list_info.name,
            Opprettet: formatDate(invoiceDb.created_at),
            Sent: invoiceDb.sent_at && formatDate(invoiceDb.sent_at),
            Betalt: invoiceDb.paid_at && formatDate(invoiceDb.paid_at),
            Kreditnota: invoiceDb.creditnote_at && formatDate(invoiceDb.creditnote_at),
            Kunde: invoiceDb &&
                invoiceDb.fiken_metadata &&
                invoiceDb.fiken_metadata.customerInfo && {
                    Navn: invoiceDb.fiken_metadata.customerInfo.name,
                    Orgnummer: invoiceDb.fiken_metadata.customerInfo.organizationIdentifier,
                    Email: invoiceDb.fiken_metadata.customerInfo.email,
                },
            'Matching Gift': matchingGift && {
                Prosjekt: <ProjectLink id={matchingGift.project_id} />,
                Maksbeløp: matchingGift.max_amount,
                Epost: matchingGift.email,
            },
        };
        return (
            <TaskContainer detail={<Comments lookupKey="invoice" id={invoiceDb.id} postToSlack={false} />}>
                <Helmet>
                    <title>Faktura nr. {`${invoiceDb.invoice_number}`}</title>
                </Helmet>
                <header className="task-header">
                    <h1 className="task-title">Viser faktura {invoiceDb.invoice_number}</h1>
                    <Link className="back-link" to="/invoice/">
                        &larr; Tilbake
                    </Link>

                    <a href={fikenUrl} target="_blank" rel="noopener noreferrer">
                        {fikenUrl}
                    </a>
                </header>
                <div className={`task-actions ${visSendKnapp && !sendt ? 'task-actions--split' : ''}`}>
                    {visOpprettKnapp && (
                        <Fragment>
                            <Button
                                disabled={this.state.processing}
                                className="task-action-button"
                                onClick={() => this.createFikenInvoice()}
                            >
                                Opprett i Fiken
                            </Button>
                            <ConfirmButton
                                title="Slett fakturaen"
                                disabled={this.state.processing}
                                className="task-action-button"
                                message="Er du helt sikker på at du vil slette fakturaen?"
                                onClick={() => this.deleteInvoice()}
                            />
                        </Fragment>
                    )}
                    {visSendKnapp && (
                        <Fragment>
                            <ConfirmButton
                                title={`Send ${sendt ? 'på nytt ' : ''} som e-post`}
                                disabled={this.state.processing}
                                className="task-action-button"
                                message="Sikker på at du vil sende fakturaen?"
                                onClick={() => this.sendInvoice('email')}
                            />
                            <ConfirmButton
                                title={`Send ${sendt ? 'på nytt ' : ''} som EHF/e-post`}
                                disabled={this.state.processing}
                                className="task-action-button task-action-button--secondary"
                                message="Sikker på at du vil sende fakturaen?"
                                onClick={() => this.sendInvoice('auto')}
                            />
                        </Fragment>
                    )}
                    {visPaidKnapp && (
                        <Fragment>
                            <div className="task-action-button">
                                <input type="date" id="dato-for-utbetaling" />
                                <Button disabled={this.state.processing} onClick={() => this.paidInvoice()}>
                                    Registrer innbetaling
                                </Button>
                            </div>
                            <Button
                                disabled={this.state.processing}
                                className="task-action-button"
                                onClick={() => this.addCreditnote()}
                            >
                                Merk med kreditnota
                            </Button>
                        </Fragment>
                    )}
                    {visSamlefakturaKnapp && (
                        <>
                            <div className="task-action-button">
                                <Button
                                    disabled={this.state.processing}
                                    onClick={() => this.convertToInvoiceWithoutProject()}
                                >
                                    Endre til samlefaktura
                                </Button>
                            </div>
                        </>
                    )}
                    <div className="task-action-button">
                        <Button
                            onClick={() =>
                                this.getInvoicePdf({ invoiceId: invoiceDb.id, invoiceNumber: invoiceDb.invoice_number })
                            }
                        >
                            Last ned pdf
                        </Button>
                    </div>
                    {visBrregKnapp && (
                        <>
                            <div className="task-action-button">
                                <Button
                                    onClick={() =>
                                        window.open(
                                            `https://w2.brreg.no/enhet/sok/detalj.jsp?orgnr=${encodeURIComponent(
                                                invoiceDb?.fiken_metadata?.customerInfo?.organizationIdentifier
                                            )}`
                                        )
                                    }
                                >
                                    Sjekk org i Brreg
                                </Button>
                            </div>
                        </>
                    )}
                </div>
                <div className="task-body">
                    <InfoDataList object={detaljer} />

                    <div>
                        <TransactionsWithData id={invoiceDb.invoice_number} invoice={invoiceDb} />
                        <RefundsWithData id={invoiceDb.invoice_number} />
                    </div>
                </div>
            </TaskContainer>
        );
    }
}

export default Invoice;
