import React from 'react';
import moment from 'moment';
import ShowMore from '../ShowMore/ShowMore';
import UserLink from '../SmartLinks/UserLink';
import ProjectLink from '../SmartLinks/ProjectLink';
import Button from '../Button/Button';
import api from '../api';
import { spleisUrl } from '../config';
import { showError, showInfo } from '../store';

const printDate = (d) => (d ? moment(d).format('DD-MM-YYYY') : null);

const printAddress = (a) => {
    const address = JSON.parse(a);
    if (address) {
        return `${address.name}, ${address.address}, ${address.postalCode} ${address.postalName}`;
    } else {
        return '';
    }
};

const toggleSent = ({ id }) => {
    api.put(`${spleisUrl}/api/admin/transaction/lottery-ticket/toggle-sent/${id}`)
        .then(() => {
            window.notify();
            showInfo('Sendingsstatus er oppdatert på vinneren');
        })
        .catch((err) => showError('Noe feilet da vi skulle sendingsstatus på vinneren' + err));
};

const Winner = ({ id, created_at, project_id, user_id, address, revealed_at, reward_sent_at, downloaded_at }) => {
    return (
        <tr>
            <td>
                <a href={`/lottery/ticket/${id}`}>{id}</a>
            </td>
            <td>{printDate(created_at)}</td>
            <td>
                <ProjectLink id={project_id} />
            </td>
            <td>
                <UserLink id={user_id} />
            </td>
            <td>{printAddress(address)}</td>
            <td>{printDate(revealed_at)}</td>
            <td>{!!reward_sent_at ? printDate(reward_sent_at) : 'Ikke sendt'}</td>
            <td>{!!downloaded_at ? printDate(downloaded_at) : 'Ikke lastet ned'}</td>
            <td>
                <Button onClick={() => toggleSent({ id })}>
                    {!!reward_sent_at ? 'Fjern markering' : 'Marker sendt'}
                </Button>
            </td>
        </tr>
    );
};

const WinnerList = ({ shippingProducts, title, description }) => {
    const winners = shippingProducts.sort(
        (a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
    );

    return (
        <div className="lottery-ticket-table-container">
            <h2 title={description}>
                {title}
                {description ? ' (i)' : ''}
            </h2>

            <ShowMore
                items={winners}
                listCreator={(showMoreItems) => {
                    return (
                        <table className={'lottery-ticket-table table table-striped'}>
                            <thead>
                                <tr>
                                    <th>Id</th>
                                    <th>Kjøpt</th>
                                    <th>Prosjekt</th>
                                    <th>Bruker</th>
                                    <th>Adresse</th>
                                    <th>Skrapt</th>
                                    <th>Sendt</th>
                                    <th>Lastet ned</th>
                                    <th>Endre status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {showMoreItems.map((winner) => {
                                    return <Winner key={winner.id} {...winner} />;
                                })}
                            </tbody>
                        </table>
                    );
                }}
            />
        </div>
    );
};
export default WinnerList;
