import React, { createRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { paymentUrl, spleisUrl } from '../config';
import TaskContainer from '../TaskContainer/TaskContainer';
import withData from '../with-data';
import Button from '../Button/Button';
import api from '../api';
import { showError, showInfo } from '../store';
import { payoutStatus } from '../Project/Project';
import moment from 'moment';
import { formatBankAccount, formatMoneyKr } from '../formatters';
import Comments from '../Comments/Comments';
import ConfirmButton from '../ConfirmButton/ConfirmButton';
import { Helmet } from 'react-helmet';
import { useHistory, useLocation } from 'react-router';
import useQueryString from 'use-query-string';
import './PayoutFile.scss';

const PayoutStatus = ({ data }) => <span>{data && payoutStatus(data)}</span>;
const PayoutStatusWithData = withData(PayoutStatus, (id) => `${spleisUrl}/api/admin/payout/id/${id}`);

const PayoutMailList = ({ payouts, beskrivelse }) => (
    <div style={{ padding: '20px' }}>
        {payouts.length} {beskrivelse} betalinger:{' '}
        {formatMoneyKr(payouts.map((p) => +p.amount).reduce((a, b) => a + b, 0))}
        <br />
        <br />
        <ol style={{ marginTop: '20px' }}>
            {payouts.map((payout, i) => (
                <li key={payout.id}>
                    {i + 1}. {payout.name} (spleis nr #{payout.projectId} - {payout.projectTitle})
                </li>
            ))}
        </ol>
        <br />
        <br />
        <ul style={{ marginTop: '20px' }}>
            {payouts.map((payout, i) => (
                <li key={payout.id} style={{ marginTop: '20px' }}>
                    <div>{payout.text}</div>
                    {payout.orgNumber && (
                        <div>
                            <div>&nbsp;Organisasjonsnummer: {payout.orgNumber}</div>
                            <div>&nbsp;Organisasjonsnavn: {payout.name}</div>
                        </div>
                    )}
                    {!payout.orgNumber && <div>&nbsp;Innsamlers navn: {payout.name}</div>}
                    <div>&nbsp;Kontonummer: {formatBankAccount(payout.bankaccount)}</div>
                    <div>&nbsp;Har samlet inn totalt: {formatMoneyKr(payout.collectedAmount)}</div>
                    <div>&nbsp;Skal få utbetalt: {formatMoneyKr(payout.amount)}</div>
                    <br />
                </li>
            ))}
        </ul>
    </div>
);

const PayoutMail = ({ data }) => {
    const payoutsOK = data.input.payouts.filter((p) => data.result[p.id] && data.result[p.id].status === 'ACCP');
    const payoutsNotOK = data.input.payouts.filter((p) => !data.result[p.id] || data.result[p.id].status !== 'ACCP');
    return (
        <div style={{ marginTop: '50px' }}>
            <h2>Utkast til mail</h2>
            <div style={{ padding: '20px', border: '1px solid #ddd', boxShadow: '0 10px 6px -6px #777' }}>
                <PayoutMailList payouts={payoutsOK} beskrivelse="aksepterte" />
                <PayoutMailList payouts={payoutsNotOK} beskrivelse="avviste" />
            </div>
        </div>
    );
};

const SortablePayoutHeaderCell = ({ name, field, query, setQuery }) => {
    const { sortField = '', sortDirection = 'asc' } = query;

    const setSortField = (field) => {
        if (sortField === field) {
            setQuery({ sortDirection: sortDirection === 'asc' ? 'desc' : 'asc' });
        } else {
            setQuery({
                sortField: field,
                sortDirection: 'asc',
            });
        }
    };

    return (
        <th className="payout-table-header-cell">
            <span onClick={() => setSortField(field)}>
                {name}{' '}
                {sortField === field && sortDirection === 'asc'
                    ? '⬆️'
                    : sortField === field && sortDirection === 'desc'
                    ? '⬇️'
                    : ''}
            </span>
        </th>
    );
};

const PayoutFile = ({ data, match }) => {
    const history = useHistory();
    const location = useLocation();
    const isInAccounting = location.pathname.includes('accounting');
    const fileupload = createRef();
    const [payoutStatuses, setPayoutStatuses] = useState([]);
    const [query, setQuery] = useQueryString(location, (path) => window.history.pushState(null, document.title, path));
    const { sortField = '', sortDirection = 'asc' } = query;

    const before = sortDirection === 'asc' ? 1 : -1;
    const after = sortDirection === 'asc' ? -1 : 1;
    const sortedPayouts = (data?.input?.payouts || []).toSorted((a, b) => {
        const aValue = a[sortField] || '';
        const bValue = b[sortField] || '';
        if (sortField === 'amount' || sortField === 'projectId') {
            return Number(aValue) > Number(bValue) ? before : after;
        } else if (sortField === 'status') {
            const statusA = (data.result?.[a.id] || {}).status || 'MANGLER';
            const statusB = (data.result?.[b.id] || {}).status || 'MANGLER';
            return statusA > statusB ? before : after;
        } else if (sortField === 'bankaccount') {
            return formatBankAccount(aValue) > formatBankAccount(bValue) ? before : after;
        } else if (sortField === 'payoutstatus') {
            return payoutStatus(a) > payoutStatus(b) ? before : after;
        } else {
            return aValue > bValue ? before : after;
        }
    });

    const downloadFile = (filename, xml) => {
        const element = document.createElement('a');
        element.setAttribute('href', 'data:text/xml;charset=utf-8,' + encodeURIComponent(xml));
        element.setAttribute('download', filename);
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
    };

    const uploadFile = (e) => {
        e.preventDefault();
        const file = fileupload.current.files[0];
        if (!file) {
            return;
        }
        const reader = new FileReader();
        reader.onload = (cb) => {
            api.post(`${paymentUrl}/api/payout-files/${match.params.id}/response`, {
                file: cb.currentTarget.result,
            })
                .then((res) => window.notify())
                .catch((err) => showError('Feil ved filopplasting ' + err));
        };
        reader.readAsText(file);
    };

    const markPaidOut = () => {
        const textDecoder = new TextDecoder();
        api.post(`${paymentUrl}/api/payout-files/${match.params.id}/paidOut`, {}, {}, true)
            .then((response) => {
                const reader = response.body.getReader();
                return new ReadableStream({
                    start(controller) {
                        // The following function handles each data chunk
                        function push() {
                            // "done" is a Boolean and value a "Uint8Array"
                            reader.read().then(({ done, value }) => {
                                // If there is no more data to read
                                if (done) {
                                    controller.close();
                                    return;
                                }
                                // Get the data and send it to the browser via the controller

                                //console.log(new TextDecoder().decode(value));

                                setPayoutStatuses([textDecoder.decode(value), ...payoutStatuses]);
                                controller.enqueue(value);
                                // Check chunks by logging to the console
                                console.log(done, value);
                                push();
                            });
                        }

                        push();
                    },
                });
            })
            .then(() => {
                window.notify();
                showInfo('Alle prosjekter markert som utbetalt');
            });
    };

    const deletePayoutFile = () => {
        api.remove(`${spleisUrl}/api/admin/payout-files/${match.params.id}`, {}).then(() => {
            history.push('/payout-files/');
            window.notify();
            showInfo('Filen er slettet og utbetalingene lagt opp på nytt');
        });
    };

    const deleteResponseAndResult = () => {
        api.remove(`${spleisUrl}/api/admin/payout-files/${match.params.id}/response-and-result`)
            .then(() => {
                window.notify();
                showInfo('Slettet svarfil og status');
            })
            .catch((err) => showError(err));
    };

    if (!data) return null;
    const done = Object.values(data.result || {}).every((r) => !!r.payoutStatus);
    const maxlen = (str, max) => (str?.length > max ? str.slice(0, max) + '...' : str);

    const lastNedSvarfil = (
        <Button className="task-action-button" onClick={() => downloadFile(data.id + '-response.xml', data.response)}>
            Last ned svarfil
        </Button>
    );
    const lastNedForespoerselfil = (
        <Button
            className="task-action-button"
            onClick={() =>
                downloadFile(`${data.id}-request-${moment(data.created_at).format('YYYY-MM-DD')}.xml`, data.request)
            }
        >
            Last ned forespørselfil
        </Button>
    );
    const slettUtbetalingsfil = (
        <ConfirmButton
            className="task-action-button red"
            onClick={deletePayoutFile}
            title={'Slett filen og legg opp utbetalinger på nytt'}
            message={'Er du sikker på at du vil slette filen og legge alle utbetalingene opp på nytt?'}
        />
    );
    const slettSvarfilOgStatus = (
        <ConfirmButton
            className="task-action-button red"
            onClick={deleteResponseAndResult}
            title={'Slett svarfil og status'}
            message={'Er du sikker på at du vil slette svarfilen og statusen?'}
        />
    );

    const isRefunds = sortedPayouts.find((p) => !p.payoutId);

    return (
        <TaskContainer detail={<Comments lookupKey="payout-files" id={data.id} postToSlack={false} />}>
            <Helmet>
                <title>Utbetaling {data.id.substring(0, 10)}</title>
            </Helmet>
            <header className="task-header">
                <h1 className="task-title">Betalingsfil {moment(data.created_at).format('D. MMM')}</h1>
                ID: {data.id.substring(0, 10)}
                <br />
                Type: {isRefunds ? 'Refundering' : 'Utbetaling'}
            </header>
            <div className="task-actions">
                {!data.response && lastNedForespoerselfil}
                {data.result && Object.values(data.result || {}).some((r) => !r.payoutStatus) && (
                    <ConfirmButton
                        title="Sett utbetalt"
                        className="task-action-button"
                        message="Er du sikker på at du vil merke alle prosjektene som utbetalt? Dette tar litt tid..."
                        onClick={markPaidOut}
                    />
                )}
                {!isInAccounting && slettUtbetalingsfil}
                {!isInAccounting && !done && (data.response || data.result) && slettSvarfilOgStatus}
            </div>
            <div className="task-body">
                {!data.response && !isInAccounting && (
                    <form style={{ marginTop: '20px', marginBottom: '40px' }}>
                        <h2>Svarfil:</h2>
                        <input type="file" ref={fileupload} style={{ margin: '10px' }} />
                        <Button onClick={uploadFile}>Last opp svar-fil</Button>
                    </form>
                )}
                {payoutStatuses.length > 0 && (
                    <div style={{ margin: '20px', backgroundColor: '#eaeaea' }}>
                        <ul>
                            {payoutStatuses.map((s) => (
                                <li style={{ padding: '12px' }}>
                                    <pre>{s}</pre>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
                {sortedPayouts.length > 0 && (
                    <div key={data.messageId}>
                        <h2>
                            Utbetalinger ({sortedPayouts.length} {isRefunds ? 'refundering' : 'spleiser'},{' '}
                            {formatMoneyKr(sortedPayouts.map((p) => p.amount).reduce((a, v) => a + v))})
                        </h2>
                        <div>
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <SortablePayoutHeaderCell
                                            name="Spleis"
                                            field="projectId"
                                            query={query}
                                            setQuery={setQuery}
                                        />
                                        <SortablePayoutHeaderCell
                                            name="Beløp"
                                            field="amount"
                                            query={query}
                                            setQuery={setQuery}
                                        />
                                        <SortablePayoutHeaderCell
                                            name="Mottaker"
                                            field="name"
                                            query={query}
                                            setQuery={setQuery}
                                        />
                                        <SortablePayoutHeaderCell
                                            name="Konto"
                                            field="bankaccount"
                                            query={query}
                                            setQuery={setQuery}
                                        />
                                        <SortablePayoutHeaderCell
                                            name="Utbetalingstatus"
                                            field="payoutstatus"
                                            query={query}
                                            setQuery={setQuery}
                                        />
                                    </tr>
                                </thead>
                                <tbody>
                                    {sortedPayouts.map((t) => {
                                        let status = '';
                                        if (data.result) {
                                            const value = (data.result[t.id] || {}).status || 'MANGLER';
                                            status = (
                                                <span style={{ color: value === 'ACCP' ? 'green' : 'red' }}>
                                                    {value}
                                                </span>
                                            );
                                        }
                                        return (
                                            <tr
                                                key={t.id}
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => {
                                                    history.push(`/project/${t.projectId}`);
                                                }}
                                            >
                                                <td>
                                                    <Link
                                                        onClick={(e) => e.stopPropagation()}
                                                        to={`/project/${t.projectId}`}
                                                    >
                                                        {t.projectId}
                                                    </Link>{' '}
                                                    {maxlen(t.projectTitle, 30)}
                                                </td>
                                                <td>{formatMoneyKr(t.amount)}</td>
                                                <td>{maxlen(t.name, 25)}</td>
                                                <td>{formatBankAccount(t.bankaccount)}</td>
                                                <td>{status}</td>
                                                <td>
                                                    <PayoutStatusWithData id={t.payoutId} />
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                )}
                {data.result && <PayoutMail data={data} />}
                {data.response && (
                    <div style={{ marginTop: '20px' }}>
                        <h2>Filnedlasting</h2>
                        {lastNedForespoerselfil}
                        {lastNedSvarfil}
                    </div>
                )}
            </div>
        </TaskContainer>
    );
};

export default withData(
    PayoutFile,
    (id) => `${paymentUrl}/api/payout-files/${id}`,
    (props) => props.match.params.id
);
