import React, { useState, useRef } from 'react';
import _ from 'lodash';
import api from '../../api';
import styles from './RegisterAccountingError.module.scss';
import Button from '../../Button/Button';
import { regnskapUrl } from '../../config';
import moment from 'moment';
import { ISO_DATE_FORMAT } from '../../dates';
import ButtonLink from '../../ButtonLink/ButtonLink';
import TaskContainer from '../../TaskContainer/TaskContainer';
import { Helmet } from 'react-helmet';

function validateAmount(amount) {
    const validValues = Object.entries(amount).filter((amountEntry) => {
        const value = amountEntry[1];
        return value && !Number.isNaN(parseFloat(value));
    });

    return validValues.length > 0;
}

const INITIAL_KIS_STATE = {
    projectId: null,
    bankSettlementId: null,
    transactionId: null,
    errorId: null,
    payoutId: null,
};
const INITIAL_AMOUNT_STATE = {
    bankBalanse: null,
    bufferkonto: null,
    feeStripe: null,
    feeVipps: null,
    fordringFaktura: null,
    fordringStripe: null,
    fordringVipps: null,
    giver: null,
    klientkonto: null,
    innsamler: null,
    inntekt: null,
    tap: null,
    mva: null,
};

const RegisterAccountingError = () => {
    const formElement = useRef(null);

    const [kis, setKis] = useState(INITIAL_KIS_STATE);
    const [amount, setAmount] = useState(INITIAL_AMOUNT_STATE);
    const [date, setDate] = useState(moment().format(ISO_DATE_FORMAT));
    const [description, setDescription] = useState('');
    const [messages, setMessages] = useState({});
    const [responsible, setResponsible] = useState({});

    const setDateToNow = () => {
        setDate(moment().format(ISO_DATE_FORMAT));
    };

    const resetFormFields = () => {
        setDateToNow();
        setKis(INITIAL_KIS_STATE);
        setAmount(INITIAL_AMOUNT_STATE);
        setDescription('');
        setResponsible('');
    };

    const validateBody = ({ discrepancyBody }) => {
        if (
            _.isEmpty(discrepancyBody.kis) ||
            _.isEmpty(discrepancyBody.amount) ||
            !discrepancyBody.description ||
            !discrepancyBody.date
        ) {
            return false;
        }
        return true;
    };

    const onSubmit = async (e) => {
        e.preventDefault();

        const discrepancyBody = {
            kis: _.pickBy(kis, _.identity),
            date,
            description,
            amount: _.pickBy(amount, _.identity),
            responsible,
        };

        if (!validateBody({ discrepancyBody })) {
            setMessages({ generalErrors: 'Kunne ikke lagre avvik' });
            return;
        }

        if (!validateAmount(amount)) {
            return false;
        }

        await api.post(`${regnskapUrl}/api/accounting-error`, discrepancyBody);

        setMessages({ showRegistered: true });
        resetFormFields();

        formElement.current.reset();
    };

    const updateAndValidateNumberValue = ({ target }) => {
        const { value } = target;
        let numberValue = parseFloat(value);

        if (!value || Number.isNaN(numberValue)) {
            numberValue = null;
        }

        return numberValue;
    };

    const updateKis = ({ target }) => {
        setMessages({});
        const { name } = target;
        const numberValue = updateAndValidateNumberValue({ target });
        setKis({ ...kis, [name]: numberValue });
    };

    const updateAmount = ({ target }) => {
        setMessages({});
        const { name } = target;
        const numberValue = updateAndValidateNumberValue({ target });
        setAmount({ ...amount, [name]: numberValue });
    };

    return (
        <TaskContainer>
            <Helmet>
                <title>Registrer avvik</title>
            </Helmet>
            <section className={styles.registerAccountingError}>
                <form className={styles.registerForm} onSubmit={onSubmit} ref={formElement}>
                    <h1 className={styles.title}>Registrer avvik</h1>

                    <div className={styles.formSection}>
                        <label className={[styles.formLabel, styles.numberField].join(' ')}>
                            <span>Ansvarlig</span>
                            <div className={styles.numberInputWrapper}>
                                <input
                                    type="text"
                                    name="responsible"
                                    onChange={(e) => setResponsible(e.target.value)}
                                />
                            </div>
                        </label>
                        <label className={[styles.formLabel, styles.numberField].join(' ')}>
                            <span>Prosjekt-id</span>
                            <div className={styles.numberInputWrapper}>
                                <input type="number" name="projectId" onChange={updateKis} />
                            </div>
                        </label>
                        <label className={[styles.formLabel, styles.numberField].join(' ')}>
                            <span>Bankoppgjør-id</span>
                            <div className={styles.numberInputWrapper}>
                                <input type="number" name="bankSettlementId" onChange={updateKis} />
                            </div>
                        </label>
                        <label className={[styles.formLabel, styles.numberField].join(' ')}>
                            <span>Transaksjons-id</span>
                            <div className={styles.numberInputWrapper}>
                                <input type="number" name="transactionId" onChange={updateKis} />
                            </div>
                        </label>
                        <label className={[styles.formLabel, styles.numberField].join(' ')}>
                            <span>Avviks-id</span>
                            <div className={styles.numberInputWrapper}>
                                <input type="number" name="errorId" onChange={updateKis} />
                            </div>
                        </label>
                        <label className={[styles.formLabel, styles.numberField].join(' ')}>
                            <span>Utbetalings-id</span>
                            <div className={styles.numberInputWrapper}>
                                <input type="number" name="payoutId" onChange={updateKis} />
                            </div>
                        </label>
                    </div>

                    <div className={styles.formSection}>
                        <label className={[styles.formLabel, styles.numberField].join(' ')}>
                            <span>Dato</span>
                            <div className={styles.numberInputWrapper}>
                                <input
                                    type="datetime-local"
                                    name="date"
                                    onChange={(e) => setDate(e.target.value)}
                                    value={date}
                                />
                            </div>
                        </label>
                        <ButtonLink
                            className={styles.buttonLink}
                            onClick={(e) => {
                                e.preventDefault();
                                setMessages({});
                                setDateToNow();
                            }}
                        >
                            Nå
                        </ButtonLink>
                    </div>

                    <div className={styles.formSection}>
                        <h2 className={styles.subtitle}>Beløp</h2>

                        {Object.keys(amount).map((amountKey) => {
                            return (
                                <label className={[styles.formLabel, styles.numberField].join(' ')} key={amountKey}>
                                    <span>{amountKey}</span>
                                    <div className={styles.numberInputWrapper}>
                                        <input
                                            type="number"
                                            step="0.01"
                                            name={amountKey}
                                            placeholder={0}
                                            onChange={updateAmount}
                                        />
                                        <span> kr</span>
                                    </div>
                                </label>
                            );
                        })}
                    </div>

                    <div className={styles.formSection}>
                        <h2 className={styles.subtitle}>Beskrivelse</h2>
                        <label className={[styles.formLabel, styles.descriptionField].join(' ')}>
                            <textarea
                                name="description"
                                onChange={(e) => setDescription(e.target.value)}
                                className={styles.descriptionInput}
                            />
                        </label>
                    </div>

                    <Button>Registrer avvik</Button>
                    <div className={styles.messageBoxContainer}>
                        {messages.showRegistered && <div className={styles.infoMessage}>Avviket ble registrert</div>}
                        {messages.generalErrors && <div className={styles.errorMessage}>{messages.generalErrors}</div>}
                    </div>
                </form>
            </section>
        </TaskContainer>
    );
};

export default RegisterAccountingError;
