import React, { useEffect, useState } from 'react';
import api from '../api';
import { spleisUrl } from '../config';
import { showError, showInfo } from '../store';
import FormButton from '../FormButton/FormButton';
import Button from '../Button/Button';

const SchoolEvent = ({ eventName }) => {
    const [schoolSignups, setSchoolSignups] = useState([]);

    const fetchSignups = () => {
        api.get(`${spleisUrl}/api/admin/school/event/${eventName}`)
            .then((res) => setSchoolSignups(res))
            .catch(() => showError('Feil ved henting av påmeldte skoler'));
    };

    useEffect(() => {
        fetchSignups();
    }, [eventName]);

    useEffect(() => {
        window.subscribe(fetchSignups);
        return () => window.unsubscribe(fetchSignups);
    }, [eventName]);

    return (
        <div>
            <div className="schools-section">
                Ikke gi denne informasjonen til hvem som helst. Linken til admin siden inneholder informasjon om elever
                etc.
            </div>
            <div className="schools-section">
                Antall påmeldte: {schoolSignups.length}
            </div>
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th>Skole</th>
                        <th>Type</th>
                        <th>Kontakter</th>
                        <th>Admin-side</th>
                    </tr>
                </thead>
                <tbody>
                    {schoolSignups.map((schoolSignup) => (
                        <SchoolSignup
                            key={schoolSignup.school_organization_number}
                            schoolSignup={schoolSignup}
                            schoolEventName={eventName}
                        />
                    ))}
                </tbody>
            </table>
        </div>
    );
};

const SchoolSignup = ({ schoolSignup, schoolEventName }) => {
    const [first, setFirst] = useState(schoolSignup.contacts?.[0]?.first);
    const [last, setLast] = useState(schoolSignup.contacts?.[0]?.last);
    const [email, setEmail] = useState(schoolSignup.contacts?.[0]?.email);

    const updateContact = () => {
        api.put(`${spleisUrl}/api/admin/school/event/${schoolEventName}/school/${schoolSignup.school_organization_number}/contact`, {
            first,
            last,
            email,
        })
            .then(() => {
                showInfo('Endret kontakt');
                window.notify();
            })
            .catch(() => showError('Klarte ikke å endre kontakt'));
    };

    const sendEmail = () => {
        if (confirm(`Er du sikker på at du vil sende epost til ${schoolSignup.contacts.map(contact => contact.email).join(', ')}?`)) {
            api.post(`${spleisUrl}/api/admin/school/event/${schoolEventName}/school/${schoolSignup.school_organization_number}/send-email`)
                .then(() => showInfo('Epost sendt'))
                .catch(() => showError('Klarte ikke å sende epost'));
        }
    };

    return (
        <tr>
            <td>
                {schoolSignup.school_name} ({schoolSignup.school_organization_number})
            </td>
            <td>{schoolSignup.type}</td>
            <td>
                {schoolSignup.contacts?.map((contact, index) => {
                    return (
                        <div key={index} className="schools-table-contact">
                            <div>
                                {contact.first} {contact.last}
                            </div>
                            <div>
                                <a href={`mailto://${contact.email}`}>{contact.email}</a>
                            </div>
                            <div>
                                <FormButton title={`Endre kontakt`} onClick={updateContact}>
                                    <input
                                        className="school-contact-input"
                                        type="text"
                                        value={first}
                                        onChange={(e) => setFirst(e.target.value)}
                                        placeholder="Fornavn"
                                    />
                                    <input
                                        className="school-contact-input"
                                        type="text"
                                        value={last}
                                        onChange={(e) => setLast(e.target.value)}
                                        placeholder="Etternavn"
                                    />
                                    <input
                                        className="school-contact-input"
                                        type="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        placeholder="Epost"
                                    />
                                </FormButton>
                                {schoolSignup.contacts?.length > 0 && <Button onClick={sendEmail}>Send epost</Button>}
                            </div>
                        </div>
                    );
                })}
            </td>
            <td>
                <a target="_blank" href={`https://spleis.no/school-event/admin/${schoolSignup.secret_id}`}>
                    Link
                </a>
            </td>
        </tr>
    );
};

export default SchoolEvent;
