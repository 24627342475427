import React, { useRef, useState } from 'react';
import api from '../../api';
import { spleisUrl } from '../../config';
import { showError, showInfo } from '../../store';
import Button from '../../Button/Button';
import { getTranslateUrl } from '../../translate';

const ProjectPurpose = ({ project }) => {
    const [isEditing, setIsIsEditing] = useState(false);
    const purposeTextField = useRef(null);

    const lagre = () => {
        api.post(`${spleisUrl}/api/admin/project/${project.id}/updateProps`, {
            purpose: purposeTextField.current.value,
        })
            .then(() => {
                showInfo('Formålet oppdatert!');
                setIsIsEditing(false);
                window.notify();
            })
            .catch((err) => {
                showError(`Feil ved endring av formål: ${err}`);
            });
    };

    return (
        <div>
            <h2 className="project-purpose">Formål</h2>
            {isEditing && (
                <div>
                    <textarea
                        style={{ width: '100%', height: '4em' }}
                        ref={purposeTextField}
                        defaultValue={project.purpose}
                    />
                    <Button className="edit-button" onClick={lagre}>
                        Lagre
                    </Button>
                    <Button className="edit-button" onClick={() => setIsIsEditing(false)}>
                        Avbryt
                    </Button>
                </div>
            )}
            {!isEditing && (
                <div>
                    <div>
                        <p>{project.purpose}</p>
                    </div>
                    <Button className="edit-button" onClick={() => setIsIsEditing(true)}>
                        Endre formål
                    </Button>
                    <Button
                        className="edit-button"
                        onClick={() => {
                            window.open(
                                getTranslateUrl({ text: project.purpose }),
                                'translate',
                                'heigth=500,width=500'
                            );
                        }}
                    >
                        Oversett
                    </Button>
                </div>
            )}
        </div>
    );
};

export default ProjectPurpose;
