import React from 'react';
import { Helmet } from 'react-helmet';
import TotalAvstemmingTable from './TotalAvstemmingTable';

const TotalAvstemming = () => {
    return (
        <>
            <Helmet>
                <title>Totalavstemming</title>
            </Helmet>
            <TotalAvstemmingTable />;
        </>
    );
};

export default TotalAvstemming;
