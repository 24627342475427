import React, { useEffect, useState } from 'react';
import moment from 'moment/moment';
import ProfileInfo from '../ProfileInfo/ProfileInfo';
import { spleisUrl } from '../config';
import { showError } from '../store';
import api from '../api';
import { convertDateAsStringWithTwoDigitYearToFourDigitYear } from '../formatters';

const UserInfo = ({ id, projectId, isReceiver, size }) => {
    const [user, setUser] = useState({});
    const [bankAccount, setBankAccount] = useState({});
    const [projectSummary, setProjectSummary] = useState({});
    const [invitationName, setInvitationName] = useState('');
    const [invitationPhoneNumber, setInvitationPhoneNumber] = useState('');

    useEffect(() => {
        fetchUser(id);
        fetchAccount(id);
        fetchProjectSummaryForUser(id);
        fetchInvitationName({ projectId, isReceiver });
    }, [id, projectId, isReceiver]);

    const fetchUser = (id) => {
        if (id) {
            api.get(`${spleisUrl}/api/admin/user/${id}`)
                .then((res) => {
                    setUser(res);
                })
                .catch((err) => showError(err));
        }
    };

    const fetchProjectSummaryForUser = (id) => {
        if (id) {
            api.get(`${spleisUrl}/api/admin/project/summary-for-user/${id}`)
                .then((res) => {
                    setProjectSummary(res);
                })
                .catch((err) => showError(err));
        }
    };

    const fetchAccount = (userId) => {
        if (userId) {
            api.get(`${spleisUrl}/api/admin/bank-account/main-account-for-user/${userId}`)
                .then((res) => {
                    setBankAccount(res);
                })
                .catch((err) => showError(err));
        }
    };

    const fetchInvitationName = ({ projectId, isReceiver }) => {
        if (projectId && isReceiver) {
            api.get(`${spleisUrl}/api/admin/project/${projectId}/invitation`).then((invitation) => {
                setInvitationName(invitation.name);
                setInvitationPhoneNumber(invitation.phone_number);
            });
        }
    };

    const flipName = (reversedName) => reversedName.split(',').reverse().join(' ').trim();

    if (!user.id) {
        return null;
    }

    const { first, last, img_url, verification_data, otherUserProfiles } = user;
    const accountNumber = bankAccount;
    const hasVerificationData = !!(verification_data && verification_data.verifiedInformation);
    const accountOwnershipInfo =
        accountNumber && accountNumber.kar_account_verification
            ? accountNumber.kar_account_verification.statusText
            : 'Ikke sjekket';
    const birthDate = verification_data?.verifiedInformation?.fnr?.slice(0, 6);
    const birthDateWithFourDigitYear = convertDateAsStringWithTwoDigitYearToFourDigitYear(birthDate);
    const isUnder15 = birthDate && moment().isBefore(moment(birthDateWithFourDigitYear, 'DDMMYYYY').add(15, 'year'));

    return (
        <ProfileInfo
            title={`${first} ${last}`}
            verified={!!(hasVerificationData && verification_data.verifiedInformation.verified_at)}
            partiallyVerified={!!(hasVerificationData && verification_data.verifiedInformation.partially_verified_at)}
            identification={
                hasVerificationData ? `Verifisert navn: ${flipName(verification_data.verifiedInformation.name)}` : null
            }
            isUnder15={isUnder15}
            accountOwnership={`KAR: ${accountOwnershipInfo}`}
            imageSrc={img_url}
            size={size}
            projectSummary={projectSummary}
            otherUserProfiles={otherUserProfiles}
            invitationName={invitationName}
            invitationPhoneNumber={invitationPhoneNumber}
            redMark={user.redMark}
        />
    );
};
export default UserInfo;
