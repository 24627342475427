import React from 'react';
import Modal from 'react-modal';
import ConfirmButton from '../FormButton/FormButton';
import styles from './ConfirmModal.module.scss';

const ConfirmModalButton = (props) => {
    const { title, type } = props;
    return (
        <button {...props} className={'btn ' + (type === 'cancel' ? 'cancel' : 'btn-cta success')}>
            {title}
        </button>
    );
};

const DoubleConfirmModalButton = (props) => {
    const { type } = props;
    return (
        <ConfirmButton
            {...props}
            className={'btn ' + (type === 'cancel' ? 'cancel' : 'btn-cta success')}
            message="Er du sikker på at du vil gjøre dette?"
            successText="Ja"
        />
    );
};

const ConfirmModal = ({
    isOpen,
    doDoubleConfirm,
    title,
    message,
    successText,
    optionalText,
    cancelText,
    onClickSuccess,
    onClickOptional,
    onClickCancel,
    children,
    showButton = true,
    showOptionalButton = false,
    disabled = false,
}) => {
    return (
        <Modal
            isOpen={isOpen}
            overlayClassName={styles['confirm-modal-wrapper']}
            className={styles['confirm-modal-container']}
            ariaHideApp={false}
        >
            <div>
                <h3 className={styles['confirm-modal-heading']}>{title}</h3>
                <div dangerouslySetInnerHTML={{ __html: message }} />
                {children}
                <div className={styles['confirm-modal-buttons-container']}>
                    {cancelText ? (
                        <ConfirmModalButton title={cancelText} type="cancel" onClick={onClickCancel} />
                    ) : null}
                    {showOptionalButton &&
                        (doDoubleConfirm ? (
                            <DoubleConfirmModalButton title={optionalText} onClick={onClickOptional} />
                        ) : (
                            <ConfirmModalButton title={optionalText} type="success" onClick={onClickOptional} />
                        ))}
                    {showButton &&
                        (doDoubleConfirm ? (
                            <DoubleConfirmModalButton
                                title={successText}
                                onClick={onClickSuccess}
                                disabled={disabled}
                            />
                        ) : (
                            <ConfirmModalButton
                                title={successText}
                                type="success"
                                onClick={onClickSuccess}
                                disabled={disabled}
                            />
                        ))}
                </div>
            </div>
        </Modal>
    );
};

export default ConfirmModal;
