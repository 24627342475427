import moment from 'moment/moment';
import { DATE_FORMAT } from '../../dates';
import React, { useEffect, useState } from 'react';
import api from '../../api';
import { spleisUrl } from '../../config';
import { showError } from '../../store';
import Button from '../../Button/Button';
import './ProjectAlert.scss';

export default ({ projectId }) => {
    const [alerts, setAlerts] = useState([]);

    useEffect(() => {
        const getAlerts = () => {
            api.get(`${spleisUrl}/api/admin/project/${projectId}/unacknowledged-alerts`)
                .then((alerts) => setAlerts(alerts))
                .catch(() => setAlerts([]));
        };
        getAlerts();
        window.subscribe(getAlerts);

        return () => window.unsubscribe(getAlerts);
    }, [projectId]);

    const acknowledgeAlert = (id) => {
        api.post(`${spleisUrl}/api/admin/project/${projectId}/acknowledge-alert`, {
            id,
        })
            .then((alerts) => window.notify())
            .catch(() => showError('Klarte ikke å anerkjenne varsel'));
    };

    const acknowledgeAlertAndDisableAlertsUntil = (id) => {
        api.post(`${spleisUrl}/api/admin/project/${projectId}/acknowledge-alert-and-disable-alerts-until`, {
            id,
            until: moment().add(1, 'days').set('hour', 9).set('minute', 0).set('seconds', 0),
        })
            .then((alerts) => window.notify())
            .catch(() => showError('Klarte ikke å anerkjenne varsel'));
    };

    return alerts.map((alert) => (
        <div className="project-alert-container" key={alert.id}>
            <div className="project-alert-body">
                <div>{alert.description}</div>
                <div>varslet {moment(alert.alerted_at).format(DATE_FORMAT)}</div>
            </div>
            <div className="project-alert-actions">
                <div>Anerkjenn og...</div>
                <div>
                    <Button onClick={() => acknowledgeAlert(alert.id)}>Fjern varsel</Button>
                </div>
                <div>
                    <Button onClick={() => acknowledgeAlertAndDisableAlertsUntil(alert.id)}>
                        Stopp varsler til i morgen kl 9
                    </Button>
                </div>
            </div>
        </div>
    ));
};
