import Button from '../../Button/Button';
import React, { useState } from 'react';
import api from '../../api';
import { spleisUrl } from '../../config';
import { showError, showInfo } from '../../store';
import Modal from 'react-modal';
import styles from './BlockProject.module.scss';
import ConfirmButton from '../../ConfirmButton/ConfirmButton';
import { PauseCountdownButton } from './PauseCountdownButton';

export const BlockProject = ({ project, projectIsUnderPayment, className, updateProject }) => {
    const blockedForPayout = 'BLOCKED_FOR_PAYOUT';
    const blockedForTransaction = 'BLOCKED_FOR_TRANSACTIONS';
    const blockedForRefund = 'BLOCKED_FOR_REFUND';
    const blockedForRefundInProgress = 'BLOCKED_FOR_REFUND_IN_PROGRESS';

    const isBlockedForPayout = (project.blocked?.blocked_types || []).includes(blockedForPayout);
    const isBlockedForRefund = (project.blocked?.blocked_types || []).includes(blockedForRefund);
    const isBlockedForRefundInProgress = (project.blocked?.blocked_types || []).includes(blockedForRefundInProgress);
    const isBlockedForTransactions = (project.blocked?.blocked_types || []).includes(blockedForTransaction);

    const isBlocked =
        isBlockedForPayout || isBlockedForRefund || isBlockedForTransactions || isBlockedForRefundInProgress;

    const [processing, setProcessing] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    const block = (type) => {
        setProcessing(true);
        api.post(`${spleisUrl}/api/admin/project/${project.id}/block/${type}`)
            .then(() => {
                updateProject();
                setIsOpen(false);
                setProcessing(false);
                showInfo('Prosjekt ble blokkert');
            })
            .catch((e) => {
                setProcessing(false);
                showError(`Feil ved blokkering: ${e}`);
            });
    };

    const unblock = (type) => {
        setProcessing(true);
        return api
            .post(`${spleisUrl}/api/admin/project/${project.id}/unblock/${type}`)
            .then(() => {
                updateProject();
                setProcessing(false);
                setIsOpen(false);
                showInfo('Blokkering fjernet');
            })
            .catch((e) => {
                setProcessing(false);
                showError(`Feil ved fjerning av blokkering: ${e}`);
            });
    };

    const blockModal = () => {
        return (
            <Modal isOpen={isOpen} overlayClassName={styles.modalWrapper} className={styles.modalContainer}>
                <h2>Blokker prosjektet</h2>
                <div className={styles.form}>
                    <label className={styles.blockType}>
                        <span className={styles.blockLabel}>Nedtelling til pause</span>
                        <PauseCountdownButton project={project} onClose={() => setIsOpen(false)} />
                    </label>
                    <label className={styles.blockType}>
                        <span className={styles.blockLabel}>Innbetalinger</span>
                        <ConfirmButton
                            title={isBlockedForTransactions ? 'Fjern blokkering' : 'Blokker'}
                            message={`Er du sikker på at du vil ${
                                isBlockedForTransactions ? 'starte innsamlingen igjen' : 'sette innsamlingen på pause'
                            }?`}
                            onClick={() => {
                                if (isBlockedForTransactions) {
                                    unblock(blockedForTransaction);
                                } else {
                                    block(blockedForTransaction);
                                }
                            }}
                            className="dangerous-button"
                        />
                    </label>
                    <label className={styles.blockType}>
                        <span className={styles.blockLabel}>Refunderinger</span>
                        <Button
                            onClick={() => {
                                if (isBlockedForRefund) {
                                    unblock(blockedForRefund);
                                } else {
                                    block(blockedForRefund);
                                }
                            }}
                        >
                            {isBlockedForRefund ? 'Fjern blokkering' : 'Blokker'}
                        </Button>
                    </label>
                    <label className={styles.blockType}>
                        <span className={styles.blockLabel}>Utbetalinger</span>
                        <Button
                            onClick={() => {
                                if (isBlockedForPayout) {
                                    unblock(blockedForPayout);
                                } else {
                                    block(blockedForPayout);
                                }
                            }}
                        >
                            {isBlockedForPayout ? 'Fjern blokkering' : 'Blokker'}
                        </Button>
                    </label>
                </div>
                <div className={styles.buttons}>
                    <Button
                        className={styles.button}
                        onClick={() => {
                            setIsOpen(false);
                        }}
                    >
                        Lukk
                    </Button>
                </div>
            </Modal>
        );
    };

    let buttonText = isBlocked ? 'Blokkert' : project?.will_be_paused_at ? 'Vil bli blokkert' : 'Blokker';
    let blockedTypes = [];

    if (isBlockedForTransactions) {
        blockedTypes.push('innbetalinger');
    }
    if (isBlockedForRefund) {
        blockedTypes.push('refunderinger');
    }
    if (isBlockedForPayout) {
        blockedTypes.push('utbetalinger');
    }
    if (isBlockedForRefundInProgress) {
        blockedTypes.push('refusjon pågår');
    }

    const blockedTypesText = blockedTypes.length > 0 ? ` for ${blockedTypes.join(', ')}` : '';
    buttonText += blockedTypesText;

    return (
        <>
            <Button
                className={className}
                disabled={processing || projectIsUnderPayment}
                onClick={() => setIsOpen(true)}
            >
                {buttonText}
            </Button>
            {blockModal()}
        </>
    );
};
