import React from 'react';
import moment from 'moment';
import { formatDate } from '../dates';
import styles from './Timeline.module.scss';
import { USER_TYPE } from '../User/User';

export const buildTimeline = (project) => {
    const timeline = [];

    if (project.published_at) {
        timeline.push({ name: 'Startet', date: project.published_at });
    } else {
        timeline.push({ name: 'Opprettet', date: project.created_at });
        timeline.push({ name: 'Ikke startet' });
    }

    if (project?.blocked?.blocked_types?.length >= 1) {
        timeline.push({ name: 'Blokkert', date: project.blocked.block_status_changed_at, type: 'warning' });
    }

    if (moment(project.ends_at).isBefore(moment()) && project.published_at) {
        timeline.push({ name: 'Avsluttet', date: project.ends_at });
    } else {
        timeline.push({ name: 'Avsluttes', date: project.ends_at });
    }

    if (project.organization_accepted_payout_at) {
        timeline.push({ name: 'Org. har godkjent', date: project.organization_accepted_payout_at });
    } else if (project.receiver?.type === USER_TYPE.ORG) {
        timeline.push({ name: 'Godkjenning fra org gjenstår' });
    }

    if (project.refunded_at) {
        timeline.push({ name: 'Tilbakebetalt', date: project.refunded_at });
    } else if (project.payout_at) {
        timeline.push({ name: 'Utbetalt', date: project.payout_at });
    } else {
        timeline.push({ name: 'Utbetaling gjenstår' });
    }

    if (project.will_be_paused_at) {
        timeline.push({ name: 'Blokkeres for innbetalinger', date: project.will_be_paused_at, type: 'warning' });
    }

    return timeline.sort((a, b) => {
        if (!a.date && !b.date) {
            return 0;
        }
        if (a.date && !b.date) {
            return -1;
        }
        if (!a.date && b.date) {
            return 1;
        }
        return moment(a.date).diff(moment(b.date));
    });
};

const Timeline = ({ data }) => (
    <ol className={styles.timeline} id="timeline">
        {data.map((event) => (
            <li
                className={[
                    styles.timelineEvent,
                    event.date && !moment(event.date).isAfter(moment()) ? '' : styles.ongoing,
                    event.type && styles[event.type],
                ].join(' ')}
                key={event.name}
            >
                <span className={styles.circle} />
                <div className={styles.timestamp}>
                    <div className={styles.date} title={formatDate(event.date)}>
                        {event.date ? moment(event.date).format('DD.MM.YY') : '\u00A0'}
                    </div>
                    <div>
                        {event.date && `(${moment(event.date).fromNow()})`}
                    </div>
                </div>
                <div className={styles.status}>{event.name}</div>
            </li>
        ))}
    </ol>
);

export default Timeline;
