import React, { useState, useEffect } from 'react';
import { Search } from '../../Menu/Menu';
import { spleisUrl } from '../../config';
import Button from '../../Button/Button';
import FormButton from '../../FormButton/FormButton';
import api from '../../api';
import { showError, showInfo } from '../../store';
import styles from './ChangeReceiver.module.scss';

const ChangeReceiver = ({ project }) => {
    const [receivingOrgId, setReceivingOrgId] = useState(null);
    const [receivingUserId, setReceivingUserId] = useState(null);
    const [showChangeModal, setShowChangeModal] = useState(false);
    const [receiverName, setReceiverName] = useState('');
    const [receiverPhoneNumber, setReceiverPhoneNumber] = useState('');

    useEffect(() => {
        setReceivingOrgId(false);
        setReceivingUserId(false);
    }, [showChangeModal]);

    const onCancel = () => {
        setReceivingOrgId(false);
        setReceivingUserId(false);
        setReceiverName('');
        setReceiverPhoneNumber('');
    };

    const changeReceiverToOrg = e => {
        e.preventDefault();

        setShowChangeModal(false);
        api.put(`${spleisUrl}/api/admin/project/${project.id}/change-receiver-to-org`, {
            receiverOrgId: receivingOrgId,
        })
            .then(() => {
                setShowChangeModal(false);
                setReceivingOrgId(null);
                showInfo('Endret mottaker til organisasjon!');
                window.notify();
            })
            .catch(e => {
                setShowChangeModal(false);

                setReceivingOrgId(null);
                showError('Klarte ikke å endre mottaker');
            });
    };

    const changeReceiverToUser = e => {
        e.preventDefault();

        api.put(`${spleisUrl}/api/admin/project/${project.id}/change-receiver-to-user`, {
            receiverUserId: receivingUserId,
        })
            .then(() => {
                setShowChangeModal(false);
                setReceivingUserId(null);
                showInfo('Endret mottaker til brukeren!');
                window.notify();
            })
            .catch(e => {
                setShowChangeModal(false);

                setReceivingUserId(null);
                showError('Klarte ikke å endre mottaker');
            });
    };

    const changeReceiverToInvitation = e => {
        e.preventDefault();

        const updatedReceiverPhoneNumber = receiverPhoneNumber.startsWith('+47')
            ? receiverPhoneNumber
            : `+47${receiverPhoneNumber}`;

        api.put(`${spleisUrl}/api/admin/project/${project.id}/change-receiver-to-invitation`, {
            receiverName,
            receiverPhoneNumber: updatedReceiverPhoneNumber,
        })
            .then(() => {
                setShowChangeModal(false);
                setReceiverName('');
                setReceiverPhoneNumber('');
                showInfo('Endret mottaker til invitasjon!');
                window.notify();
            })
            .catch(e => {
                setShowChangeModal(false);
                showError(e);
            });
    };

    return (
        <FormButton
            showButton={false}
            title="Endre mottaker"
            className={styles.formButton}
            isOpen={showChangeModal}
            onClickCancel={() => {
                onCancel();
            }}
            onClickButton={e => {
                e.preventDefault();
                setShowChangeModal(true);
            }}
        >
            <p>OBS! Endring av mottaker setter ny prisfunksjon</p>
            <div className={styles.changeForms}>
                {!receivingUserId && !receiverName && !receiverPhoneNumber && (
                    <form onSubmit={changeReceiverToOrg} className={styles.changeForm}>
                        <h4 className={styles.recieverTitle}>Til organisasjon</h4>
                        <div className={styles.searchWrapper}>
                            {receivingOrgId ? (
                                <>
                                    <div className={styles.changeText}>
                                        Endre mottaker til org med id: {receivingOrgId}
                                    </div>
                                    <Button className={'btn-cta'} type={'submit'}>
                                        Endre til org.
                                    </Button>
                                </>
                            ) : (
                                <Search
                                    className={styles.searchField}
                                    id="org-search-box"
                                    searchUrl={`${spleisUrl}/api/admin/organization/search`}
                                    placeholder="Finn org (ctrl+o)"
                                    onClick={id => {
                                        setReceivingOrgId(id);
                                    }}
                                    listItem={option => (
                                        <div>
                                            <div>{option.object.name ? option.object.name : 'Ukjent'}</div>
                                            <div style={{ fontSize: '0.8em', color: '#676767' }}>
                                                {option.object.organization_number} (#
                                                {option.object.id})
                                            </div>
                                        </div>
                                    )}
                                />
                            )}
                        </div>
                    </form>
                )}
                {!receivingOrgId && !receiverName && !receiverPhoneNumber && (
                    <form onSubmit={changeReceiverToUser} className={styles.changeForm}>
                        <h4 className={styles.recieverTitle}>Til bruker</h4>
                        <div className={styles.searchWrapper}>
                            {receivingUserId ? (
                                <>
                                    <div className={styles.changeText}>
                                        Endre mottaker til bruker med id: {receivingUserId}
                                    </div>
                                    <Button className={'btn-cta'} type={'submit'}>
                                        Endre til bruker
                                    </Button>
                                </>
                            ) : (
                                <Search
                                    className={styles.searchField}
                                    id="org-search-box"
                                    searchUrl={`${spleisUrl}/api/admin/user/search`}
                                    placeholder="Finn bruker (ctrl+o)"
                                    onClick={id => {
                                        setReceivingUserId(id);
                                    }}
                                    listItem={({ object }) => (
                                        <div>
                                            <div>
                                                {object.first ? `${object.first} ${object.last}` : 'Ukjent'} (#
                                                {object.id})
                                            </div>
                                        </div>
                                    )}
                                />
                            )}
                        </div>
                    </form>
                )}
                {!receivingUserId && !receivingOrgId && (
                    <form onSubmit={changeReceiverToInvitation} className={styles.changeForm}>
                        <h4 className={styles.recieverTitle}>Til invitasjon</h4>
                        <input
                            value={receiverName}
                            onChange={e => setReceiverName(e.target.value)}
                            placeholder="Navn på mottaker"
                        />
                        <input
                            value={receiverPhoneNumber}
                            onChange={e => setReceiverPhoneNumber(e.target.value)}
                            placeholder="81549300"
                        />
                        {receiverName && receiverPhoneNumber && (
                            <Button className={'btn-cta'} type={'submit'}>
                                Endre til invitasjon
                            </Button>
                        )}
                    </form>
                )}
            </div>
        </FormButton>
    );
};

export default ChangeReceiver;
