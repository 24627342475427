import React from 'react';
import { Link, Route, Switch, useParams, withRouter } from 'react-router-dom';
import './Schools.scss';
import MainContainer from '../MainContainer/MainContainer';
import TaskContainer from '../TaskContainer/TaskContainer';
import SchoolEvents from './SchoolEvents';
import { Helmet } from 'react-helmet';
import HelmetForSubpages from '../HelmetForSubpages/HelmetForSubpages';

const Schools = () => {
    const params = useParams();

    const currentSubpage = params?.slug;
    const isSelected = (page) => {
        return currentSubpage === page;
    };

    return (
        <MainContainer>
            <HelmetForSubpages title="Skoler" />
            <div className={`task-list-container ${!currentSubpage ? 'block' : ''}`}>
                <div className="task-list-wrapper">
                    <header className="tasks-header">
                        <h2 className="schools-header">Skoler</h2>
                    </header>
                    <ul className="task-list schools-task-list">
                        <li
                            className={`schools-menu-item ${
                                isSelected('/schools/school-events/') ? 'schools-menu-item-selected' : ''
                            }`}
                        >
                            <Link className={'schools-menu-item'} to={`/schools/school-events`}>
                                Skoleeventer
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
            <TaskContainer>
                <Switch>
                    <Route path="/schools/school-events" component={SchoolEvents} />
                </Switch>
            </TaskContainer>
        </MainContainer>
    );
};

export default withRouter(Schools);
