import React, { useState } from 'react';
import styles from './ProjectSummary.module.scss';
import Button from '../../Button/Button';
import api from '../../api';
import { spleisUrl } from '../../config';
import { showError, showInfo } from '../../store';

const ProjectSummary = ({ project }) => {
    const [summary, setSummary] = useState(project.summary);

    const saveSummary = () => {
        api.post(`${spleisUrl}/api/admin/project/${project.id}/summary`, {
            summary,
        })
            .then(() => {
                showInfo('Sammendrag oppdatert');
                window.notify();
            })
            .catch((err) => {
                showError(`Feil endring av sammendrag: ${err}`);
            });
    };

    return (
        <>
            <h2>Sammendrag</h2>
            <div>
                <textarea className={styles.textArea} value={summary} onChange={(e) => setSummary(e.target.value)} />
            </div>
            <div>
                <Button onClick={saveSummary}>Lagre sammendrag</Button>
            </div>
        </>
    );
};

export default ProjectSummary;
