import React from 'react';
import { Helmet } from 'react-helmet';
import AccountingTable from './AccountingTable';
import { formatMoneyKr } from '../formatters';

const GavekortFIG = () => {
    return (
        <>
            <Helmet>
                <title>Gavekort som ikke er innløst</title>
            </Helmet>
            <AccountingTable
                includeEventSum={true}
                sumUrl="fig-gift-cards-sum"
                sumColumns={[
                    {
                        Header: 'Klientansvar',
                        accessor: 'client_liability',
                        minResizeWidth: 100,
                        width: 250,
                        Cell: (row) => <span>{formatMoneyKr(Math.abs(row.value))}</span>
                    },
                    {
                        Header: 'Antall',
                        accessor: 'count',
                        minResizeWidth: 100,
                        width: 250,
                        Cell: (row) => <span>{row.value}</span>
                    },
                ]}
            />
        </>
    );
};

export default GavekortFIG;
