import React, { useRef, useState } from 'react';
import api from '../../api';
import { spleisUrl } from '../../config';
import { showError, showInfo } from '../../store';
import Button from '../../Button/Button';
import { getTranslateUrl } from '../../translate';

const ProjectDescription = ({ project }) => {
    const [isEditing, setIsEditing] = useState(false);
    const descriptionTextarea = useRef(null);

    const lagre = () => {
        api.post(`${spleisUrl}/api/admin/project/${project.id}/updateProps`, {
            description: descriptionTextarea.current.value,
        })
            .then(() => {
                showInfo('Beskrivelse oppdatert!');
                setIsEditing(false);
                window.notify();
            })
            .catch((err) => {
                showError(`Feil endring av beskrivelse: ${err}`);
            });
    };

    return (
        <div>
            <h2>Beskrivelse</h2>
            {isEditing && (
                <div>
                    <textarea
                        style={{ width: '100%', height: '300px' }}
                        ref={descriptionTextarea}
                        defaultValue={project.description}
                    />
                    <Button className="edit-button" onClick={lagre}>
                        Lagre
                    </Button>
                    <Button className="edit-button" onClick={() => setIsEditing(false)}>
                        Avbryt
                    </Button>
                </div>
            )}
            {!isEditing && (
                <div>
                    <div className="project-description" dangerouslySetInnerHTML={{ __html: project.description }} />
                    <Button className="edit-button" onClick={() => setIsEditing(true)}>
                        Endre beskrivelse
                    </Button>
                    <Button
                        className="edit-button"
                        onClick={() => {
                            window.open(
                                getTranslateUrl({ text: project.description }),
                                'translate',
                                'heigth=500,width=500'
                            );
                        }}
                    >
                        Oversett
                    </Button>
                </div>
            )}
        </div>
    );
};
export default ProjectDescription;
