import React, { useEffect, useState } from 'react';
import './Categories.scss';
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { formatMoney, formatMoneyKr } from '../../formatters';
import { dateToMonthAndYear } from './Categories';

const CategoriesLineChart = ({ categoriesPerMonth, categories, startMonth, endMonth }) => {
    const [max, setMax] = useState(0);
    const [scale, setScale] = useState('linear');

    useEffect(() => {
        const enabledCategoryNames = categories.filter((cat) => cat.enabled).map((cat) => cat.name);
        const max = categoriesPerMonth.slice(startMonth, endMonth + 1).reduce((acc, cur) => {
            const maxThisMonth = enabledCategoryNames.reduce((a, b) => (Number(cur[a]) > Number(cur[b]) ? a : b), 0);
            return Math.max(acc, cur[maxThisMonth]);
        }, 0);
        setMax(max);
    }, [categoriesPerMonth, categories, startMonth, endMonth]);

    const min = scale === 'log' ? 1 : 0;

    return (
        <>
            <h1>Volum per måned</h1>
            <div className="category-section" style={{ width: '100%', height: 500 }}>
                <ResponsiveContainer width="99%" height="100%">
                    <LineChart
                        width={500}
                        height={300}
                        data={categoriesPerMonth.slice(startMonth, endMonth + 1)}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="date" tickFormatter={dateToMonthAndYear} />
                        <YAxis
                            domain={[min, max || 1]}
                            tick={{ fontSize: 12 }}
                            width={70}
                            tickFormatter={formatMoney}
                            scale={scale}
                        />
                        <Tooltip
                            formatter={formatMoneyKr}
                            labelFormatter={dateToMonthAndYear}
                            itemSorter={(item) => parseInt(item.value || 0) * -1}
                            labelStyle={{
                                marginBottom: 10,
                            }}
                            itemStyle={{
                                fontSize: 14,
                                margin: 0,
                            }}
                        />
                        {categories.map((category) => (
                            <Line
                                key={category.name}
                                strokeWidth={3}
                                type="monotone"
                                hide={!category.enabled}
                                dataKey={category.name}
                                stroke={category.color}
                                connectNulls={true}
                            />
                        ))}
                    </LineChart>
                </ResponsiveContainer>
                <div className="category-section">
                    <center>
                        <label className="category-label">
                            Skala:&nbsp;
                            <select onChange={(e) => setScale(e.target.value)} value={scale}>
                                <option value="linear">Lineær</option>
                                <option value="sqrt">Kvadratrot</option>
                                <option value="log">Logaritmisk</option>
                            </select>
                        </label>
                    </center>
                </div>
            </div>
        </>
    );
};

export default CategoriesLineChart;
