import { useContext, useState } from 'react';
import api from '../../api';
import { showError, showInfo } from '../../store';
import { canRefund } from '../Project';
import ProjectContext from '../project-context';
import ConfirmButton from '../../FormButton/FormButton';
import { spleisUrl } from '../../config';

export const ImmediateRefund = () => {
    const project = useContext(ProjectContext);
    const [isProcessing, setIsProcessing] = useState(false);

    const refund = () => {
        setIsProcessing(true);
        api.post(`${spleisUrl}/api/admin/project/${project.id}/refund?force=true`)
            .then((body) => {
                if (!body.failures || (body.failures.length === 0 && body.refunds.length >= 1)) {
                    showInfo('Prosjekt ble refundert');
                    window.notify();
                } else if (body.failures.length > 0 && body.refunds && body.refunds.length === 0) {
                    showError(`Alle transaksjoner som ble forsøkt refundert feilet`);
                } else {
                    showError(
                        `${body.refunds.length} transaksjoner ble refundert, men ${body.failures.length} feilet - så prøv igjen!`
                    );
                }
            })
            .catch((err) => {
                showError(`Feil ved refundering av prosjekt: ${err.text}`);
            })
            .finally(() => setIsProcessing(false));
    };
    return (
        <ConfirmButton
            className="dangerous-button"
            title="Refunder med en gang"
            disabled={!canRefund(project) || isProcessing}
            onClick={refund}
            message="Sikker på at du vil refundere spleisen med en gang?"
            successText="Refunder innsamlingen nå"
        />
    );
};
