import React, { useEffect, useState } from 'react';
import api from '../api';
import { spleisUrl } from '../config';
import { showError } from '../store';
import './ProjectStats.scss';

const ProjectStatsEvents = ({ events = [], addEvent, removeEvent }) => {
    const [allEvents, setAllEvents] = useState([]);

    const filteredEvents = allEvents.filter((event) => !events.includes(event.id));

    useEffect(() => {
        api.get(`${spleisUrl}/api/admin/event/all`)
            .then((res) => setAllEvents(res))
            .catch(() => showError('Feil ved henting av alle eventer'));
    }, []);

    return (
        <div>
            <div>
                <div>
                    {events.length > 0 && <p>Klikk på event for å fjerne</p>}
                    <ul className="stats-event-list">
                        {events.map((event) => {
                            return (
                                <li
                                    key={event}
                                    title={event}
                                    className="stats-event"
                                    onClick={() => removeEvent(event)}
                                >
                                    {allEvents.find(ae => ae.id === event).name}
                                </li>
                            );
                        })}
                    </ul>
                </div>

                <div>
                    <select
                        onChange={(e) => {
                            if (e.target.value) {
                                addEvent(parseInt(e.target.value, 10));
                            }
                        }}
                        disabled={filteredEvents.length === 0}
                    >
                        <option>Legg til event</option>
                        {filteredEvents.map((event) => (
                            <option key={event.id} value={event.id}>
                                {event.name}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
        </div>
    );
};

export default ProjectStatsEvents;
