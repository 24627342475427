import React, { Component } from 'react';
import moment from 'moment';
import 'moment/locale/nb';

import { Route } from 'react-router-dom';
import VisibleIf from '../VisibleIf/VisibleIf';
import ButtonLink from '../ButtonLink/ButtonLink';

import api from '../api';
import { spleisUrl } from '../config';

import './Refund.scss';
import Project from '../Project/Project';
import TaskList from '../TaskList/TaskList';
import TaskListSelect from '../TaskList/TaskListSelect';
import ListOverview from '../ListOverview/ListOverview';
import MainContainer from '../MainContainer/MainContainer';
import { Helmet } from 'react-helmet';
import HelmetForSubpages from '../HelmetForSubpages/HelmetForSubpages';

moment.locale('nb');

const TASK_LIST_OPTIONS = [
    { title: 'Nådde ikke målet', value: 'failed-not-refunded' },
    { title: 'Slettet', value: 'deleted-not-refunded' },
];

class Refund extends Component {
    constructor(props) {
        super(props);
        const locationSearch = new URLSearchParams(props.location.search);
        const filter = locationSearch.get('filter') || 'failed-not-refunded';
        this.state = {
            type: TASK_LIST_OPTIONS.find((t) => t.value === filter),
            'failed-not-refunded': [],
            'deleted-not-refunded': [],
        };
    }

    componentDidMount() {
        this.fetchData();
        window.subscribe(this.fetchData);
    }

    componentWillUnmount() {
        window.unsubscribe(this.fetchData);
    }

    fetchData() {
        TASK_LIST_OPTIONS.forEach((t) => this.fetchRefundsByFilter(t.value));
    }

    fetchRefundsByFilter = (type) => {
        api.get(`${spleisUrl}/api/admin/project/${type}`)
            .then((res) => {
                this.setState({
                    [type]: res,
                });
            })
            .catch((err) => {
                console.error(err);
                this.setState({ [type]: [] });
            });
    };

    onChange = (e) => {
        const filter = TASK_LIST_OPTIONS.find((t) => t.value === e.target.value);
        this.setNewFilter(filter);
    };

    setNewFilter = (filter) => {
        this.setState({ type: filter });
        this.props.history.push({
            pathname: this.props.location.pathname,
            search: `${filter.value ? `?filter=${filter.value}` : ''}`,
        });
    };

    render() {
        const projectId = this.props.match.params.projectId;
        return (
            <MainContainer>
                <HelmetForSubpages title="Refunderinger" />
                <VisibleIf isVisible={!!projectId}>
                    <ButtonLink className="back-link" onClick={this.props.history.goBack}>
                        &larr; Tilbake
                    </ButtonLink>
                </VisibleIf>
                <div className={`task-list-container ${!projectId ? 'block' : ''}`}>
                    <div className="task-list-wrapper">
                        <header className="tasks-header">
                            <TaskListSelect
                                options={TASK_LIST_OPTIONS}
                                onChange={this.onChange}
                                selected={this.state.type}
                            />
                        </header>
                        <ul className="task-list">
                            {this.state[this.state.type.value] &&
                                this.state[this.state.type.value].map((p) => (
                                    <TaskList project={p} link={`/refunds/${p.id}`} key={p.id} />
                                ))}
                        </ul>
                    </div>
                </div>
                <Route path="/refunds/:projectId" component={Project} />
                {!projectId && (
                    <ListOverview
                        type={'refunderinger'}
                        items={TASK_LIST_OPTIONS.map((t) => ({
                            title: t.title,
                            callback: () => this.setNewFilter(t),
                            items: this.state[t.value],
                        }))}
                    />
                )}
            </MainContainer>
        );
    }
}

export default Refund;
