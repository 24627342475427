import React, { Component } from 'react';
import './PromotedProjects.scss';
import api from '../api';
import { spleisUrl } from '../config';
import { showError, showInfo } from '../store';
import Button from '../Button/Button';
import { Helmet } from 'react-helmet';

class PromotedProjects extends Component {
    constructor(props) {
        super(props);
        this.state = {
            promoted: [],
        };
    }

    handleChange = (e) => {
        const promotedIndex = e.target.dataset.selection_index;
        const projectIndex = e.target.dataset.project_index;
        const value = e.target.value;

        const previousState = this.state.promoted;

        const changingPromotedSelection = previousState[promotedIndex];
        const changedPromotedSelectionProjects = {
            ...changingPromotedSelection,
            projects: Object.assign([...changingPromotedSelection.projects], { [projectIndex]: value }),
        };

        const newSelection = Object.assign([...this.state.promoted], {
            [promotedIndex]: changedPromotedSelectionProjects,
        });

        this.setState({
            promoted: newSelection,
        });
    };

    componentDidMount() {
        this.fetchData();
    }

    fetchData = () => {
        api.get(`${spleisUrl}/api/admin/project/promoted`)
            .then((res) => this.setState({ promoted: res }))
            .catch(() => showError('Feil ved henting av valgte prosjekter fra baksystem'));
    };

    cancel = () => {
        this.fetchData();
    };

    save = () => {
        api.post(`${spleisUrl}/api/admin/project/promoted`, this.state.promoted)
            .then(() => showInfo('Valgte prosjekter lagret'))
            .catch(() => showError('Feil ved lagring av prosjekter'));
    };

    render = () => {
        const inputFields = this.state.promoted.map((promoted, promotedIndex) => (
            <section className="promoted-section">
                <h2>{promoted.title}</h2>
                <ul>
                    {promoted.projects.map((project, projectIndex) => (
                        <li>
                            <label>
                                <span className="promoted-label">Prosjekt {projectIndex + 1}</span>
                                <input
                                    onChange={this.handleChange}
                                    className="promoted-input"
                                    type="number"
                                    value={project}
                                    data-selection_index={promotedIndex}
                                    data-project_index={projectIndex}
                                    placeholder={'Prosjekt-id'}
                                />
                            </label>
                        </li>
                    ))}
                </ul>
            </section>
        ));

        return (
            <div className="promoted-container">
                <Helmet>
                    <title>Utvalgte innsamlinger</title>
                </Helmet>
                <header className="task-header">
                    <h1 className="task-title">Utvalgte prosjekter</h1>
                </header>
                <div className="promoted-content">
                    {inputFields}
                    <Button className="task-action-button" onClick={this.save}>
                        Lagre
                    </Button>
                    <Button className="task-action-button" onClick={this.cancel}>
                        Avbryt
                    </Button>
                </div>
            </div>
        );
    };
}

export default PromotedProjects;
