import api from '../api';
import { spleisUrl } from '../config';
import { showError, showInfo } from '../store';
import ContactUser from './ContactUser';
import ConfirmButton from '../ConfirmButton/ConfirmButton';
import classNames from 'classnames';
import AddOrEditContactPersonButton from './AddOrEditContactPersonButton/AddOrEditContactPersonButton';
import React from 'react';

export const NifContacts = ({ contactUsers, spleisContacts, refreshData, organization }) => {
    if (!contactUsers || contactUsers.length === 0) {
        return null;
    }

    const notRegisteredContacts = contactUsers.filter((user) => !spleisContacts.find((s) => s.email === user.email));
    if (notRegisteredContacts.length === 0) {
        return null;
    }
    return (
        <div>
            <h3>Kontaktperson{contactUsers.length > 1 ? 'er' : ''} registrert hos NIF</h3>
            <div className={'orgContacts'}>
                {notRegisteredContacts.map((user) => {
                    return (
                        <ContactUser
                            key={user.id}
                            organizationUser={user}
                            refreshData={refreshData}
                            className={'nifData'}
                            organizationId={organization.id}
                        />
                    );
                })}
            </div>
        </div>
    );
};
