import React, { Component } from 'react';
import moment from 'moment';
import AccountingTable from './AccountingTable';
import { Helmet } from 'react-helmet';

export default class AccountingErrors extends Component {
    render() {
        return (
            <>
                <Helmet>
                    <title>Avvik</title>
                </Helmet>
                <AccountingTable
                    url={'accounting-errors'}
                    columns={[
                        {
                            Header: 'Id',
                            minWidth: 30,
                            minResizeWidth: 30,
                            accessor: 'id',
                            id: 'id',
                        },
                        {
                            Header: 'Beskrivelse',
                            minWidth: 250,
                            minResizeWidth: 30,
                            accessor: 'description',
                            id: 'description',
                        },
                        {
                            Header: 'Beløp',
                            accessor: 'amount',
                            minWidth: 250,
                            minResizeWidth: 30,
                            Cell: (row) => {
                                return (
                                    <span>
                                        {Object.keys(row.value)
                                            .map((key) => ` ${key}: ${row.value[key]}`)
                                            .join()}
                                    </span>
                                );
                            },
                            id: 'amount',
                        },
                        {
                            Header: 'Dato',
                            accessor: 'date',
                            id: 'date',
                            minWidth: 120,
                            minResizeWidth: 30,
                            Cell: (row) => <span>{moment(row.value).format('lll')}</span>,
                        },
                        {
                            Header: 'Opprettet',
                            id: 'created_at',
                            accessor: 'created_at',
                            minWidth: 120,
                            minResizeWidth: 30,
                            Cell: (row) => <span>{moment(row.value).format('lll')}</span>,
                        },
                        {
                            Header: 'Ansvarlig',
                            id: 'responsible',
                            accessor: 'responsible',
                            minWidth: 120,
                            minResizeWidth: 30,
                        },
                        {
                            Header: 'Fikset',
                            id: 'fixed',
                            accessor: 'fixed',
                            show: false,
                        },
                    ]}
                    getTrProps={(state, rowInfo, column) => {
                        console.log({ state, rowInfo, column });
                        if (rowInfo && rowInfo.row && !rowInfo.row.fixed) {
                            return {
                                style: {
                                    background: '#f99',
                                },
                            };
                        } else if (rowInfo && rowInfo.row && rowInfo.row.fixed) {
                            return {
                                style: {
                                    background: '#00ff9935',
                                },
                            };
                        } else {
                            return {};
                        }
                    }}
                />
            </>
        );
    }
}
