import React, { useState } from 'react';
import api from '../api';
import { spleisUrl } from '../config';
import { showError, showInfo } from '../store';
import FormButton from '../FormButton/FormButton';

export const ChangeAccountNumberButton = ({ organizationId, bankAccountNumber, refreshData, className }) => {
    const [number, setNumber] = useState(bankAccountNumber);

    function addBankAccount() {
        api.post(`${spleisUrl}/api/admin/bank-account/main-account-for-organization`, {
            organizationId: organizationId,
            accountNumber: number && number.replace(/\D/g, ''),
        })
            .then(() => {
                showInfo(`Bankkonto er endret til ${number}!`);
                refreshData();
            })
            .catch((error) => {
                showError(error);
            });
    }

    return (
        <FormButton title="Endre kontonummer" onClick={addBankAccount} className={className}>
            <form>
                <input
                    onPaste={(e) => {
                        e.preventDefault();
                        setNumber(e.clipboardData.getData('Text').replace(/[\s.]/g, ''));
                    }}
                    placeholder="12345678903"
                    maxLength="20"
                    value={number}
                    onChange={(e) => setNumber(e.target.value)}
                    type={'text'}
                />
            </form>
        </FormButton>
    );
};
