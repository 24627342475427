import Banner from '../../Banner/Banner';
import ButtonLink from '../../ButtonLink/ButtonLink';
import styles from './RedMark.module.scss';
import moment from 'moment/moment';
import { DATE_FORMAT_SHORT_TIME } from '../../dates';

const RedMark = ({ redMark, showRedMarkInfo, onRemove }) => {
    if (!redMark) {
        return null;
    }

    const markedByShort = redMark.marked_by.split('@spleis.no').join('');

    const bannerContent = (
        <>
            <div className={styles.redMarkText}>{redMark.reason}</div>
            {showRedMarkInfo && (
                <div className={styles.redMarkInfo}>
                    <div>Merket: {moment(redMark.marked_at).format(DATE_FORMAT_SHORT_TIME)}</div>
                    <div>Av: {markedByShort}</div>
                </div>
            )}
            {onRemove && (
                <>
                    (
                    <ButtonLink className={styles.removeRedMark} onClick={onRemove}>
                        fjern rødmerking
                    </ButtonLink>
                    )
                </>
            )}
        </>
    );

    return <Banner title={bannerContent} themeClass="error" />;
};

export default RedMark;
