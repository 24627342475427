import { useState } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import ConfirmButton from '../../ConfirmButton/ConfirmButton';
import api from '../../api';
import { showError, showInfo } from '../../store';
import { spleisUrl } from '../../config';

import styles from './EditReward.module.scss';

export const EditReward = ({ reward }) => {
    const handleSave = async () => {
        try {
            await api.put(`${spleisUrl}/api/admin/project/reward/${reward.id}`, { ...reward, ...formState });
        } catch (err) {
            showError('Feil ved oppdatering av belønning: ' + err.text);
        }
        window.notify();
        showInfo('Belønning oppdatert');
    };
    const [formState, setFormState] = useState({
        images: reward.images,
        title: reward.title,
        price: Number(reward.price),
        quantity: reward.quantity,
        description: reward.description,
        requires_address: reward.requires_address,
    });
    const updateFormState = (key, value) => {
        setFormState({ ...formState, [key]: value });
    };
    const deleteImage = (i) => {
        const images = [...formState.images];
        images.splice(i, 1);
        setFormState({ ...formState, images: images.length > 0 ? images : null });
    };
    return (
        <ConfirmButton
            title={'Rediger'}
            successText={'Lagre endringer'}
            cancelText={'Avbryt'}
            onClick={() => handleSave()}
            onClickCancel={() => setIsOpen(false)}
        >
            <div className={styles.form}>
                <Carousel responsive={{ all: { items: 1, breakpoint: { max: 4000, min: 0 } } }} autoPlay={false}>
                    {formState.images?.map((image, i) => (
                        <div>
                            <img className={styles.rewardImage} src={image.image_url} />
                            <button onClick={() => deleteImage(i)}>Slett bilde</button>
                        </div>
                    )) ?? <div>Ingen bilder</div>}
                </Carousel>
                <label>
                    Tittel:
                    <input
                        type="text"
                        value={formState.title}
                        onChange={(e) => updateFormState('title', e.target.value)}
                    />
                </label>
                <label>
                    Pris:
                    <input
                        type="number"
                        step="1"
                        value={formState.price}
                        onChange={(e) => updateFormState('price', Number(e.target.value))}
                    />
                </label>
                <label>
                    Antall tilgjenelige:
                    <input
                        type="number"
                        step="1"
                        value={formState.quantity}
                        onChange={(e) => updateFormState('quantity', Number(e.target.value))}
                    />
                </label>
                <label>
                    Beskrivelse:
                    <textarea
                        value={formState.description}
                        onChange={(e) => updateFormState('description', e.target.value)}
                    />
                </label>
                <label>
                    Belønningen krever adresse:
                    <input
                        type="checkbox"
                        checked={formState.requires_address}
                        onChange={(e) => updateFormState('requires_address', e.target.checked)}
                    />
                </label>
            </div>
        </ConfirmButton>
    );
};
