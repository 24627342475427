import React, { Component } from 'react';
import api from '../api';
import { regnskapUrl } from '../config';
import ReactTable from 'react-table';
import moment from 'moment';
import Filter from './Filter';
import VisibleIf from '../VisibleIf/VisibleIf';
import { withRouter } from 'react-router-dom';
import queryString from 'qs';
import { showInfo } from '../store';

const isTableValueEmpty = (value) => {
    if (!value) {
        return true;
    }
    if (value < 0.01 && value > -0.01) {
        return true;
    }
    return false;
};

class AvstemmingTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            pages: -1,
            loading: false,
            project: '',
            fromDate: moment().subtract(1, 'months').startOf('month'),
            toDate: moment().subtract(1, 'months').endOf('month'),
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.performSearch = this.performSearch.bind(this);
        this.pageSize = 50;
        this.page = 0;
        this.sorted = props.sorted || [{ id: 'id', desc: true }];
    }

    mapParamsToState() {
        const query = queryString.parse(this.props.location.search.slice(1));
        this.setState({
            project: query.project || '',
            fromDate: query.from ? moment(query.from) : moment().subtract(1, 'months').startOf('month'),
            toDate: query.to ? moment(query.to) : moment().subtract(1, 'months').endOf('month'),
        });
    }

    componentWillMount() {
        this.mapParamsToState();
    }

    getSortData() {
        const sort = this.sorted;
        let sortField = '',
            sortDirection = '';
        if (sort.length > 0) {
            sortField = sort[0].id;
            sortDirection = sort[0].desc ? 'desc' : 'asc';
        }
        return { sortField, sortDirection };
    }

    handleInputChange = async (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value,
        });
    };

    handleInputChangeAndSearch = async (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState(
            {
                [name]: value,
            },
            this.performSearch
        );
    };

    fetchData = async () => {
        const limit = this.pageSize;
        const offset = this.page * this.pageSize;
        const { sortField, sortDirection } = this.getSortData();
        try {
            const body = {
                project: this.state.project,
                fromDate: moment(this.state.fromDate).format('YYYY-MM-DD'),
                toDate: moment(this.state.toDate).format('YYYY-MM-DD'),
                limit,
                offset,
                sortField,
                sortDirection,
            };

            return api.post(`${regnskapUrl}/api/${this.props.url}`, body);
        } catch (e) {
            console.error(e);
        }
    };

    fetchSumData = async () => {
        const limit = this.pageSize;
        const offset = this.page * this.pageSize;
        const { sortField, sortDirection } = this.getSortData();
        try {
            const body = {
                project: this.state.project,
                fromDate: moment(this.state.fromDate).format('YYYY-MM-DD'),
                toDate: moment(this.state.toDate).format('YYYY-MM-DD'),
                limit,
                offset,
                sortField,
                sortDirection,
            };
            return api.post(`${regnskapUrl}/api/${this.props.sumUrl}`, body);
        } catch (e) {
            console.error(e);
        }
    };

    performSearch = async () => {
        this.page = 0;
        this.props.history.push({
            pathname: this.props.history.location.pathname,
            search: `?project=${this.state.project}&from=${this.state.fromDate.format(
                'YYYY-MM-DD'
            )}&to=${this.state.toDate.format('YYYY-MM-DD')}`,
        });
        this.setState({ loading: true });
        const data = await this.fetchData();
        this.setState({ data: data.data, pages: Math.floor(data.count / this.pageSize) + 1, loading: false });
        if (this.props.includeEventSum) {
            this.setState({ loadingSum: true });
            const sumData = await this.fetchSumData();
            this.setState({ dataSum: sumData.data, loadingSum: false });
        }
    };

    downloadCSV = async () => {
        const { sortField, sortDirection } = this.getSortData();
        const body = {
            project: this.state.project,
            fromDate: moment(this.state.fromDate).format('YYYY-MM-DD'),
            toDate: moment(this.state.toDate).format('YYYY-MM-DD'),
            sortField,
            sortDirection,
        };
        await api.post(`${regnskapUrl}/api/${this.props.url}.csv`, body);
        showInfo('CSV-filen havner i CSV-filer i menyen til venstre');
    };

    fetchSumOnClick = async () => {
        this.setState({
            loadingSum: true,
        });
        const data = await this.fetchSumData();
        this.setState({
            dataSum: data.data,
            loadingSum: false,
        });
    };

    render() {
        return (
            <div className="accounting-table-wrapper">
                <Filter
                    project={this.state.project}
                    fromDate={this.state.fromDate}
                    toDate={this.state.toDate}
                    handleInputChange={this.handleInputChange}
                    handleInputChangeAndSearch={this.handleInputChangeAndSearch}
                    performSearch={this.performSearch}
                    downloadCSV={this.downloadCSV}
                    showProjectFilter={this.props.showProjectFilter}
                />
                <button className={'fetch-sum-button'} onClick={this.fetchSumOnClick} disabled={this.state.loading}>
                    Hent sum
                </button>
                <div>
                    <VisibleIf
                        isVisible={this.props.includeEventSum && (this.state.loadingSum || !!this.state.dataSum)}
                    >
                        <br />
                        <div>
                            <h3>SUM for hele resultatet</h3>
                        </div>
                        <ReactTable
                            manual
                            data={this.state.dataSum}
                            columns={[
                                {
                                    Header: 'Dato',
                                    minResizeWidth: 30,
                                    accessor: 'date',
                                    Cell: (row) => <span></span>,
                                },
                                {
                                    Header: 'Bankkonto inn',
                                    accessor: 'bank_amount_in',
                                    minResizeWidth: 30,
                                    minWidth: 120,
                                    Cell: (row) =>
                                        isTableValueEmpty(row.value) ? (
                                            <span></span>
                                        ) : (
                                            <span>
                                                {row.value.toLocaleString('no', {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2,
                                                })}
                                            </span>
                                        ),
                                },
                                {
                                    Header: 'Spleis inn',
                                    accessor: 'spleis_amount_in',
                                    minResizeWidth: 30,
                                    minWidth: 200,
                                    Cell: (row) =>
                                        isTableValueEmpty(row.value) ? (
                                            <span></span>
                                        ) : (
                                            <span>
                                                {row.value.toLocaleString('no', {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2,
                                                })}
                                            </span>
                                        ),
                                },
                                {
                                    Header: 'Differanse inn',
                                    accessor: 'diff_in',
                                    minResizeWidth: 30,
                                    minWidth: 200,
                                    Cell: (row) =>
                                        isTableValueEmpty(row.value) ? (
                                            <span></span>
                                        ) : (
                                            <span>
                                                {row.value.toLocaleString('no', {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2,
                                                })}
                                            </span>
                                        ),
                                },
                                {
                                    Header: 'Bankkonto ut',
                                    accessor: 'bank_amount_out',
                                    minResizeWidth: 30,
                                    minWidth: 120,
                                    Cell: (row) =>
                                        isTableValueEmpty(row.value) ? (
                                            <span></span>
                                        ) : (
                                            <span>
                                                {row.value.toLocaleString('no', {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2,
                                                })}
                                            </span>
                                        ),
                                },
                                {
                                    Header: 'Spleis ut',
                                    accessor: 'spleis_amount_out',
                                    minResizeWidth: 30,
                                    minWidth: 200,
                                    Cell: (row) =>
                                        isTableValueEmpty(row.value) ? (
                                            <span></span>
                                        ) : (
                                            <span>
                                                {row.value.toLocaleString('no', {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2,
                                                })}
                                            </span>
                                        ),
                                },
                                {
                                    Header: 'Differanse ut',
                                    accessor: 'diff_out',
                                    minResizeWidth: 30,
                                    minWidth: 200,
                                    Cell: (row) =>
                                        isTableValueEmpty(row.value) ? (
                                            <span></span>
                                        ) : (
                                            <span>
                                                {row.value.toLocaleString('no', {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2,
                                                })}
                                            </span>
                                        ),
                                },
                                {
                                    Header: 'Korrigering eller forklaring',
                                    accessor: 'spleis_error_info',
                                    minResizeWidth: 30,
                                    minWidth: 200,
                                    Cell: (row) => <span></span>,
                                },
                            ]}
                            loadingText={'Laster..'}
                            noDataText={'Ingen sum funnet'}
                            ofText={'av'}
                            loading={this.state.loadingSum}
                            showPagination={false}
                            defaultPageSize={-1}
                        />
                        <br />
                    </VisibleIf>
                    <div>
                        <h3>Detaljert resultat</h3>
                    </div>
                    <ReactTable
                        manual
                        defaultPageSize={this.pageSize}
                        data={this.state.data}
                        columns={[
                            {
                                Header: 'Dato',
                                minResizeWidth: 30,
                                accessor: 'date',
                                Cell: (row) => <span>{moment(row.value).format('ll')}</span>,
                            },
                            {
                                Header: 'Bankkonto inn',
                                accessor: 'bank_amount_in',
                                minResizeWidth: 30,
                                minWidth: 120,
                                Cell: (row) =>
                                    isTableValueEmpty(row.value) ? (
                                        <span></span>
                                    ) : (
                                        <span>
                                            {row.value.toLocaleString('no', {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                            })}
                                        </span>
                                    ),
                            },
                            {
                                Header: 'Spleis inn',
                                accessor: 'spleis_amount_in',
                                minResizeWidth: 30,
                                minWidth: 200,
                                Cell: (row) =>
                                    isTableValueEmpty(row.value) ? (
                                        <span></span>
                                    ) : (
                                        <span>
                                            {row.value.toLocaleString('no', {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                            })}
                                        </span>
                                    ),
                            },
                            {
                                Header: 'Differanse inn',
                                accessor: 'diff_in',
                                minResizeWidth: 30,
                                minWidth: 200,
                                Cell: (row) =>
                                    isTableValueEmpty(row.value) ? (
                                        <span></span>
                                    ) : (
                                        <span>
                                            {row.value.toLocaleString('no', {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                            })}
                                        </span>
                                    ),
                            },
                            {
                                Header: 'Bankkonto ut',
                                accessor: 'bank_amount_out',
                                minResizeWidth: 30,
                                minWidth: 120,
                                Cell: (row) =>
                                    isTableValueEmpty(row.value) ? (
                                        <span></span>
                                    ) : (
                                        <span>
                                            {row.value.toLocaleString('no', {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                            })}
                                        </span>
                                    ),
                            },
                            {
                                Header: 'Spleis ut',
                                accessor: 'spleis_amount_out',
                                minResizeWidth: 30,
                                minWidth: 200,
                                Cell: (row) =>
                                    isTableValueEmpty(row.value) ? (
                                        <span></span>
                                    ) : (
                                        <span>
                                            {row.value.toLocaleString('no', {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                            })}
                                        </span>
                                    ),
                            },
                            {
                                Header: 'Differanse ut',
                                accessor: 'diff_out',
                                minResizeWidth: 30,
                                minWidth: 200,
                                Cell: (row) =>
                                    isTableValueEmpty(row.value) ? (
                                        <span></span>
                                    ) : (
                                        <span>
                                            {row.value.toLocaleString('no', {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                            })}
                                        </span>
                                    ),
                            },
                            {
                                Header: 'Korrigering eller forklaring',
                                accessor: 'spleis_error_info',
                                minResizeWidth: 30,
                                minWidth: 200,
                                Cell: (row) => <span>{row.value}</span>,
                            },
                        ]}
                        page={this.page}
                        pages={this.state.pages}
                        previousText={'Forrige'}
                        nextText={'Neste'}
                        pageText={'Side'}
                        loadingText={'Laster..'}
                        noDataText={'Ingen rader funnet'}
                        ofText={'av'}
                        rowsText={'rader'}
                        defaultSorted={this.sorted}
                        onPageChange={(page) => (this.page = page)}
                        onPageSizeChange={(pageSize) => (this.pageSize = pageSize)}
                        onSortedChange={(sorted) => (this.sorted = sorted)}
                        loading={this.state.loading}
                        getTrProps={this.props.getTrProps}
                        onFetchData={async (state, instance) => {
                            this.setState({
                                loading: true,
                            });
                            const data = await this.fetchData();
                            this.setState({
                                data: data.data,
                                pages: Math.floor(data.count / state.pageSize) + 1,
                                loading: false,
                            });
                        }}
                    />
                </div>
            </div>
        );
    }
}

export default withRouter(AvstemmingTable);
