import React from 'react';
import VisibleIf from '../VisibleIf/VisibleIf';
import styles from './InfoDataList.module.scss';

export const DataListItem = ({ label, children }) => (
    <li>
        <VisibleIf isVisible={!!label}>
            <span className={styles.infoLabel}>{label}:</span>
        </VisibleIf>
        <span className={styles.infoValue}>{children}</span>
    </li>
);

const ListObject = ({ className = '', object = {} }) => {
    return (
        <ul className={className}>
            {Object.keys(object).map(key => {
                const value = object[key];
                if (value === undefined || value === null) {
                    return null;
                }

                if (Array.isArray(value)) {
                    return value.map(val => <ListObject object={val} />);
                }

                if (value?.$$typeof) {
                    return (
                        <DataListItem key={key} label={key}>
                            {value}
                        </DataListItem>
                    )
                }

                if (typeof value === 'object' && !Array.isArray(value)) {
                    return (
                        <li className={styles.infoData} key={key}>
                            <h4 className={styles.infoDataTitle}>{key}:</h4>

                            <ListObject object={value} className={styles.dataSublist} />
                        </li>
                    );
                }

                return (
                    <DataListItem key={key} label={key}>
                        {String(value)}
                    </DataListItem>
                );
            })}
        </ul>
    );
};

const InfoDataList = ({ object, title = '', subtitle, children = null }) => {
    if (object) {
        return (
            <div className={styles.infoData}>
                <VisibleIf isVisible={!!title}>
                    <h3 className={styles.infoDataTitle}>{title}</h3>
                </VisibleIf>
                <ListObject object={object} />
            </div>
        );
    }

    return (
        <div className={styles.infoData}>
            <VisibleIf isVisible={!!title}>
                <h3 className={styles.infoDataTitle}>{title}</h3>
            </VisibleIf>
            <VisibleIf isVisible={!!subtitle}>
                <h4 className={styles.infoDataSubtitle}>{subtitle}</h4>
            </VisibleIf>
            <ul className={styles.infoDataList}>{children}</ul>
        </div>
    );
};

export default InfoDataList;
