import { sortObjectArrayByCollectedAmount, sortObjectArrayByDateField } from '../../dates';

export const SORT_OPTIONS = [
    {
        title: 'Publisert (nyeste)',
        value: 'published-at-desc',
        fn: (p) => sortObjectArrayByDateField(p, 'published_at', true),
    },
    {
        title: 'Publisert (eldste)',
        value: 'published-at-asc',
        fn: (p) => sortObjectArrayByDateField(p, 'published_at', false),
    },
    {
        title: 'Oppdatert (nyeste)',
        value: 'version-published-at-desc',
        fn: (p) => sortObjectArrayByDateField(p, 'version_published_at', true),
    },
    {
        title: 'Oppdatert (eldste)',
        value: 'version-published-at-asc',
        fn: (p) => sortObjectArrayByDateField(p, 'version_published_at', false),
    },
    {
        title: 'Avsluttes (tidligste)',
        value: 'ends-at-asc',
        fn: (p) => sortObjectArrayByDateField(p, 'ends_at', false),
    },
    {
        title: 'Avsluttes (seneste)',
        value: 'ends-at-desc',
        fn: (p) => sortObjectArrayByDateField(p, 'ends_at', true),
    },
    {
        title: 'Innsamlet (høy til lav)',
        value: 'collected-amount-desc',
        fn: (p) => sortObjectArrayByCollectedAmount(p, 'collected_amount', true),
    },
    {
        title: 'Innsamlet (lav til høy)',
        value: 'collected-amount-asc',
        fn: (p) => sortObjectArrayByCollectedAmount(p, 'collected_amount', false),
    },
    {
        title: 'Mål (høy til lav)',
        value: 'moneygoal-desc',
        fn: (p) => sortObjectArrayByCollectedAmount(p, 'moneygoal', true),
    },
    {
        title: 'Mål (lav til høy)',
        value: 'moneygoal-asc',
        fn: (p) => sortObjectArrayByCollectedAmount(p, 'moneygoal', false),
    },
    {
        title: 'Lagt opp til refusjon (tidligste)',
        value: 'marked-for-refund-asc',
        fn: (p) => sortObjectArrayByDateField(p, 'marked_for_refund_at', false),
    },
    {
        title: 'Lagt opp til refusjon (seneste)',
        value: 'marked-for-refund-desc',
        fn: (p) => sortObjectArrayByDateField(p, 'marked_for_refund_at', true),
    },
];

