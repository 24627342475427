import React, { useState } from 'react';
import moment from 'moment/moment';
import clipboard from 'clipboard-polyfill';
import { formatMoney } from '../formatters';
import { DATE_FORMAT } from '../dates';
import VisibleIf from '../VisibleIf/VisibleIf';
import UserLink from '../SmartLinks/UserLink';
import ProjectLink from '../SmartLinks/ProjectLink';
import ShowMore from '../ShowMore/ShowMore';
import ButtonLink from '../ButtonLink/ButtonLink';

const ASCENDING = 'ascending';
const DESCENDING = 'descending';
const SORT_NUMBER = 'number';
const SORT_DATE = 'date';
const SORT_TYPE = 'type';
const SORT_NAME = 'name';

const TransactionTable = ({ data, showProjectId = false, showUserId = false }) => {
    const [sort, setSort] = useState('amount');
    const [direction, setDirection] = useState(DESCENDING);
    const [type, setType] = useState(SORT_NUMBER);
    const [showComments, setShowComments] = useState(false);

    const updateSort = (field, sortType) => {
        let newDirection = ASCENDING;
        if (field === sort && direction === ASCENDING) {
            newDirection = DESCENDING;
        }

        setSort(field);
        setDirection(newDirection);
        setType(sortType || SORT_NUMBER);
    };

    const compareNumbers = (a, b, fieldFn) => parseInt(fieldFn(b), 0) - parseInt(fieldFn(a), 0);
    const compareDates = (a, b, fieldFn) => moment(fieldFn(b)).diff(moment(fieldFn(a)));

    const compareStrings = (a, b, fieldFn) => {
        const comparableA = fieldFn(a);
        const comparableB = fieldFn(b);
        if (!comparableA) {
            return 1;
        }
        if (!comparableB) {
            return -1;
        }
        return comparableA.localeCompare(comparableB);
    };

    const sortFn = (a, b) => {
        let sortFunc = compareNumbers;
        let fieldFunc = (p) => p[sort];

        if (type === SORT_DATE) {
            sortFunc = compareDates;
        }

        if (type === SORT_TYPE) {
            sortFunc = compareStrings;
            fieldFunc = getTypeFromTransaction;
        }

        if (type === SORT_NAME) {
            sortFunc = compareStrings;
            fieldFunc = getGiverNameFromTransaction;
        }

        if (direction === DESCENDING) {
            return sortFunc(a, b, fieldFunc);
        } else {
            return sortFunc(b, a, fieldFunc);
        }
    };

    const transactions = data || [];

    return (
        <div className="project-table-data">
            <header className="project-table-data-header">
                <h2>Innbetalinger</h2>
                <div>
                    <span>{transactions.length} innbetalinger </span>
                    {transactions.length > 0 && (
                        <ButtonLink
                            title="Kopier hele tabellen"
                            className="copy-transaction-table-button"
                            onClick={() => {
                                clipboard.writeText(
                                    copiedTableHeader.concat(
                                        transactions.map(getCopiableTableRowFromTransaction).join('\n')
                                    )
                                );
                            }}
                        >
                            Kopier tabellen
                        </ButtonLink>
                    )}
                    &nbsp;&nbsp;
                    <label className={''}>
                        <input
                            type="checkbox"
                            checked={showComments}
                            onChange={(e) => setShowComments(!showComments)}
                        />
                        &nbsp;Vis kommentarer
                    </label>
                </div>
            </header>

            <VisibleIf isVisible={transactions.length > 0}>
                <ShowMore
                    items={transactions.sort(sortFn)}
                    listCreator={(showMoreTransactions) => {
                        return (
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th>
                                            <ButtonLink onClick={() => updateSort('id')}>#</ButtonLink>
                                        </th>
                                        {showUserId && (
                                            <th>
                                                <ButtonLink onClick={() => updateSort('from_user')}>
                                                    BrukerId
                                                </ButtonLink>
                                            </th>
                                        )}
                                        {showProjectId && (
                                            <th>
                                                <ButtonLink onClick={() => updateSort('to_project')}>
                                                    ProsjektId
                                                </ButtonLink>
                                            </th>
                                        )}
                                        <th>
                                            <ButtonLink onClick={() => updateSort('created_at', SORT_DATE)}>
                                                Tid
                                            </ButtonLink>
                                        </th>
                                        <th>
                                            <ButtonLink onClick={() => updateSort('type', SORT_TYPE)}>Type</ButtonLink>
                                        </th>
                                        <th>
                                            <ButtonLink onClick={() => updateSort('amount')}>Beløp</ButtonLink>
                                        </th>
                                        <th>
                                            <ButtonLink onClick={() => updateSort('name', SORT_NAME)}>Navn</ButtonLink>
                                        </th>
                                        {showComments && <th>Kommentarer</th>}
                                    </tr>
                                </thead>
                                <tbody>
                                    {showMoreTransactions.map((t) => {
                                        const cls = t.refunded_at ? 'red' : '';
                                        return (
                                            <tr key={t.id} className={cls}>
                                                <td>
                                                    <a href={`/transaction/${t.id}`}>{t.id}</a>
                                                </td>
                                                {showUserId && (
                                                    <td>
                                                        <UserLink id={t.from_user} />
                                                    </td>
                                                )}
                                                {showProjectId && (
                                                    <td>
                                                        <ProjectLink id={t.to_project} />
                                                    </td>
                                                )}
                                                <td>{getFormattedDateFromTransaction(t)}</td>
                                                <td>{getTypeFromTransaction(t)}</td>
                                                <td>{formatMoney(t.amount)}</td>
                                                <td>{getGiverNameFromTransaction(t)}</td>
                                                {showComments && <td>{t.comment}</td>}
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        );
                    }}
                />
            </VisibleIf>
        </div>
    );
};

export default TransactionTable;

const copiedTableHeader = 'Transaksjons-ID\tBrukerId\tProsjektId\tTid\tType\tBeløp\tGiver\n';

function getGiverNameFromTransaction(t) {
    return !t.from_user_meta_data || t.from_user_meta_data.anonymous ? 'Anonym' : t.from_user_meta_data.name;
}

function getTypeFromTransaction(t) {
    return t.stripe_id
        ? 'Stripe'
        : t.mcash_tid
        ? 'mCASH'
        : t.invoice_number
        ? 'Faktura'
        : t.vipps_id
        ? 'Vipps'
        : 'Ukjent';
}

function getFormattedDateFromTransaction(t) {
    return moment(t.created_at).format(DATE_FORMAT);
}

function getCopiableTableRowFromTransaction(t) {
    return `${t.id}\t${t.from_user}\t${t.to_project}\t${getFormattedDateFromTransaction(t)}\t${getTypeFromTransaction(
        t
    )} \t${parseInt(t.amount, 0)}\t${getGiverNameFromTransaction(t)}\t`;
}
