import React, { useEffect, useState } from 'react';
import { showError } from '../store';
import api from '../api';
import { spleisUrl } from '../config';
import TaskContainer from '../TaskContainer/TaskContainer';
import SubscriptionList from './SubscriptionList';
import SubscriptionTypeList from './SubscriptionTypeList';
import './Subscription.scss';
import SubscriptionInvoicesModal from './SubscriptionInvoicesModal';
import { Helmet } from 'react-helmet';

const MIN_AMOUNT_TO_PAY_SUBSCRIPTION = 5000;
const CUSTOM_SUBSCRIPTION_NAME = 'skreddersøm';
const STANDARD_SUBSCRIPTION_NAME = 'standard';

const Subscriptions = () => {
    const [subscriptions, setSubscriptions] = useState([]);
    const [subscriptionTypes, setSubscriptionTypes] = useState([]);
    const [shouldPayThisMonth, setShouldPayThisMonth] = useState([]);
    const [shouldPayLastMonth, setShouldPayLastMonth] = useState([]);
    const [activeStandardSubscriptions, setActiveStandardSubscriptions] = useState([]);
    const [activeCustomSubscriptions, setActiveCustomSubscriptions] = useState([]);
    const [activeOtherSubscriptions, setActiveOtherSubscriptions] = useState([]);
    const [deactivatedSubscriptions, setDeactivatedSubscriptions] = useState([]);

    const [invoices, setInvoices] = useState([]);
    const [invoiceModalOpen, setInvoiceModalOpen] = useState(false);

    useEffect(() => {
        api.get(`${spleisUrl}/api/admin/subscription/subscriptions`)
            .then((res) => setSubscriptions(res))
            .catch(() => showError('Feil ved henting av abonnement'));
    }, []);

    useEffect(() => {
        api.get(`${spleisUrl}/api/admin/subscription/subscriptionTypes`)
            .then((subscriptionTypes) => setSubscriptionTypes(subscriptionTypes))
            .catch(() => showError('Feil ved henting av abonnementer'));
    }, []);

    useEffect(() => {
        setShouldPayThisMonth(
            subscriptions
                .filter(
                    (s) =>
                        s.collectedThisMonth >= MIN_AMOUNT_TO_PAY_SUBSCRIPTION &&
                        s?.subscription_type?.name === STANDARD_SUBSCRIPTION_NAME &&
                        s?.active
                )
                .map((s) => ({ ...s, collectedForGivenTime: s.collectedThisMonth }))
        );
        setShouldPayLastMonth(
            subscriptions
                .filter(
                    (s) =>
                        s.collectedLastMonth >= MIN_AMOUNT_TO_PAY_SUBSCRIPTION &&
                        s?.subscription_type?.name === STANDARD_SUBSCRIPTION_NAME &&
                        s?.active
                )
                .map((s) => ({ ...s, collectedForGivenTime: s.collectedLastMonth }))
        );
        setActiveStandardSubscriptions(
            subscriptions
                .filter((s) => s?.subscription_type?.name === STANDARD_SUBSCRIPTION_NAME && s?.active)
                .map((s) => ({ ...s, collectedForGivenTime: s?.collectedAmount }))
        );
        setActiveCustomSubscriptions(
            subscriptions
                .filter((s) => s?.subscription_type?.name === CUSTOM_SUBSCRIPTION_NAME && s?.active)
                .map((s) => ({ ...s, collectedForGivenTime: s?.collectedAmount }))
        );
        setActiveOtherSubscriptions(
            subscriptions
                .filter(
                    (s) =>
                        s?.subscription_type?.name !== STANDARD_SUBSCRIPTION_NAME &&
                        s?.subscription_type?.name !== CUSTOM_SUBSCRIPTION_NAME &&
                        s?.active
                )
                .map((s) => ({ ...s, collectedForGivenTime: s?.collectedAmount }))
        );
        setDeactivatedSubscriptions(
            subscriptions.filter((s) => !s?.active).map((s) => ({ ...s, collectedForGivenTime: s?.collectedAmount }))
        );
    }, [subscriptions?.length]);

    const setShowInvoices = ({ invoices }) => {
        setInvoices(invoices);
        setInvoiceModalOpen(true);
    };

    return (
        <TaskContainer>
            <Helmet>
                <title>Abonnementer</title>
            </Helmet>
            <header className="task-header">
                <h1 className="task-title">Abonnementer</h1>
            </header>
            <div className="task-body">
                <SubscriptionTypeList title="Tilgjengelige abonnementer" subscriptionTypeList={subscriptionTypes} />
                <SubscriptionList
                    title="Skal betale denne måneden"
                    subscriptions={shouldPayThisMonth}
                    period="denne måneden"
                    setShowInvoices={setShowInvoices}
                />
                <SubscriptionList
                    title="Skal betale forrige måneden"
                    subscriptions={shouldPayLastMonth}
                    period="forrige måned"
                    setShowInvoices={setShowInvoices}
                />
                <SubscriptionList
                    title="Aktive Skreddersøm"
                    subscriptions={activeCustomSubscriptions}
                    period="totalt"
                    setShowInvoices={setShowInvoices}
                />
                <SubscriptionList
                    title="Aktive Andre"
                    subscriptions={activeOtherSubscriptions}
                    period="totalt"
                    setShowInvoices={setShowInvoices}
                />
                <SubscriptionList
                    title="Aktive Spleis Pluss"
                    subscriptions={activeStandardSubscriptions}
                    period="totalt"
                    setShowInvoices={setShowInvoices}
                />
                <SubscriptionList title="Deaktiverte" subscriptions={deactivatedSubscriptions} period="totalt" />
                <SubscriptionInvoicesModal
                    invoices={invoices}
                    open={invoiceModalOpen}
                    setOpen={(open) => setInvoiceModalOpen(open)}
                />
            </div>
        </TaskContainer>
    );
};

export default Subscriptions;
