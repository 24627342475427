import React from 'react';
import moment from 'moment';
import ShowMore from '../ShowMore/ShowMore';
import UserLink from '../SmartLinks/UserLink';
import OrganizationLink from '../SmartLinks/OrganizationLink';
import { formatMoneyKr, formatNumber } from '../formatters';
import ButtonLink from '../ButtonLink/ButtonLink';

const SubscriptionList = ({ subscriptions, description, title, period, setShowInvoices }) => {
    const printDate = (d) => moment(d).format('DD.MM.YYYY');

    const ListContainer = ({ subscriptions, children }) => (
        <div className="subscription-table-container">
            <h2 title={description}>
                {title} ({subscriptions?.length})
            </h2>
            {children}
        </div>
    );

    if (subscriptions?.length === 0) {
        return (
            <ListContainer subscriptions={subscriptions}>
                <p>Ingen abonnementer</p>
            </ListContainer>
        );
    }

    const sortedSubscription = subscriptions
        ? subscriptions.sort((a, b) => {
              if (a.subscription_type.name !== b.subscription_type.name) {
                  return a.subscription_type.name.localeCompare(b.subscription_type.name);
              }
              if (a.collectedForGivenTime !== 0) {
                  return (b.collectedForGivenTime || 0) - (a.collectedForGivenTime || 0);
              }
              return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
          })
        : [];

    return (
        <ListContainer subscriptions={subscriptions}>
            <ShowMore
                items={sortedSubscription}
                initialShowCount={50}
                listCreator={(showMoreItems) => {
                    return (
                        <table className={'invoice-table table table-striped'}>
                            <thead>
                                <tr>
                                    <th>Type</th>
                                    <th>Navn</th>
                                    <th>Opprettet</th>
                                    <th>Organisasjon</th>
                                    <th>Initiativtager</th>
                                    <th>Aktiv</th>
                                    <th>Innsamlinger & kladder</th>
                                    <th>Samlet inn {period}</th>
                                    <th>Ubetalte fakturaer</th>
                                </tr>
                            </thead>
                            <tbody>
                                {showMoreItems.map((s) => {
                                    return (
                                        <tr key={s.id}>
                                            <td>
                                                <a href={`/subscription-type/${s.subscription_type.name}`}>
                                                    {s.subscription_type.name}
                                                </a>
                                            </td>
                                            <td>
                                                <a href={`/subscription/${s.id}`}>{s.subscription_type.displayTitle}</a>
                                            </td>
                                            <td>{printDate(s.created_at)}</td>
                                            <td>
                                                <OrganizationLink id={s.organization_id} showName={true} />
                                            </td>
                                            <td>
                                                <UserLink id={s.user_id} />
                                            </td>
                                            <td>{s.active ? 'Aktiv' : 'Deaktivert'}</td>
                                            <td className={'subscription-number'}>
                                                {formatNumber(s.projectCount || s.projectIds?.length || 0)}
                                            </td>
                                            <td className={'subscription-number'}>
                                                {formatMoneyKr(s.collectedForGivenTime || s.collectedAmount)}
                                            </td>
                                            <td className={'subscription-number'}>
                                                <ButtonLink
                                                    onClick={() => {
                                                        setShowInvoices({ invoices: s.invoices });
                                                    }}
                                                >
                                                    {s.invoices?.filter((i) => !i.paid_at).length || 0}&nbsp;stk
                                                </ButtonLink>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    );
                }}
            />
        </ListContainer>
    );
};
export default SubscriptionList;
