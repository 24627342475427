import { VERIFICATION_STATUSES } from '../Org/Org';

const orgIsSports = (org) =>
    org.industrial_code?.startsWith('93.') ||
    org.name.toLowerCase().includes('idrett') ||
    org.name.toLowerCase().includes(' i.f') ||
    org.name.toLowerCase().includes(' i.l') ||
    org.name.toLowerCase().endsWith(' if') ||
    org.name.toLowerCase().endsWith(' il');

const orgIsCharity = (org) => ['94.992', '88.995'].includes(org.industrial_code);

const SPACER = {
    display: '-',
    disabled: true,
};

export const FILTER_TYPES = [
    {
        display: 'Vis alle',
        value: 'ALL',
        fn: () => true,
    },
    {
        display: 'Favorittorganisasjoner',
        value: 'FAVORITES',
        fn: (org, favoriteIds) => favoriteIds.includes(org.id),
    },
    SPACER,
    {
        display: 'Idrett og klubber',
        value: 'SPORTS',
        fn: orgIsSports,
    },
    {
        display: 'Ikke idrett og klubber',
        value: 'NOT_SPORTS',
        fn: (org) => !orgIsSports(org),
    },
    SPACER,
    {
        display: 'Veldedige',
        value: 'CHARITY',
        fn: orgIsCharity,
    },
    {
        display: 'Ikke veldedige',
        value: 'NOT_CHARITY',
        fn: (org) => !orgIsCharity(org),
    },
    SPACER,
    {
        display: 'Status: (Ingen)',
        value: 'STATUS_NONE',
        fn: (org, favoriteIds) => !org.verification_status,
    },
    ...Object.entries(VERIFICATION_STATUSES).map(([statusKey, statusValue]) => ({
        display: `Status: ${statusValue}`,
        value: `STATUS_${statusKey}`,
        fn: (org) => org.verification_status === statusKey,
    })),
];
