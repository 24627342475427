import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './Button.scss';

const Button = ({ type = 'submit', onClick, disabled, className, title, children }) => (
    <button type={type} onClick={onClick} disabled={disabled} className={classNames('btn', className)} title={title}>
        {children}
    </button>
);

Button.propTypes = {
    type: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    children: PropTypes.node,
    className: PropTypes.string,
    title: PropTypes.string,
};

export default Button;
