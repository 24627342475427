import React, { useState } from 'react';
import { spleisUrl } from '../../config';
import api from '../../api';
import ConfirmButton from '../../ConfirmButton/ConfirmButton';
import { showError, showInfo } from '../../store';
import { useReceiverBankAccount } from '../receiver-bank-account-hook';
import { formatBankAccount } from '../../formatters';
import styles from '../Project.module.scss';
import FormButton from '../../FormButton/FormButton';
import { USER_TYPE } from '../../User/User';
import ButtonLink from '../../ButtonLink/ButtonLink';
import Button from '../../Button/Button';

const UpdateProjectAccountNumber = ({ project, projectBankAccount }) => {
    const { bankAccountId: receiverBankAccount } = useReceiverBankAccount({ receiver: project?.receiver });
    const [customBankAccount, setCustomBankAccount] = useState(projectBankAccount?.account_number || '');
    const setBankAccountFromReceiver = () => {
        api.post(`${spleisUrl}/api/admin/project/${project.id}/update-bankaccount-for-project`)
            .then(() => {
                showInfo('Kontonummer oppdatert');
                window.notify();
            })
            .catch((err) => {
                showError(err);
            });
    };

    const updateCustomBankAccount = () => {
        api.post(`${spleisUrl}/api/admin/project/${project.id}/update-bankaccount-for-project`, {
            bankAccountNumber: customBankAccount,
        })
            .then(() => {
                showInfo('Prosjektets kontonummer endret til ' + formatBankAccount(customBankAccount));
                window.notify();
            })
            .catch((err) => {
                showError(err);
            });
    };
    return (
        <div>
            <span className={styles.spacer}>
                {project.receiver?.type === USER_TYPE.ORG && (
                    <FormButton
                        className="dangerous-button"
                        title={'Endre kontonummer for prosjektet'}
                        onClick={updateCustomBankAccount}
                    >
                        <form>
                            <input
                                onPaste={(e) => {
                                    e.preventDefault();
                                    setCustomBankAccount(e.clipboardData.getData('Text').replace(/[\s.]/g, ''));
                                }}
                                placeholder={projectBankAccount?.account_number || '12345678901'}
                                maxLength="20"
                                value={customBankAccount}
                                onChange={(e) => setCustomBankAccount(e.target.value)}
                                type={'text'}
                            />
                        </form>
                    </FormButton>
                )}
            </span>

            {project?.payout_bank_account_id !== receiverBankAccount?.id && project.receiver?.type === USER_TYPE.ORG && (
                <span className={styles.spacer}>
                    <ConfirmButton
                        className="dangerous-button"
                        title="Sett kontonummer fra mottaker"
                        message="Er du sikker på du vil endre kontonummer for spleisen til det registrerte kontonummeret for mottakeren?"
                        onClick={setBankAccountFromReceiver}
                    />
                </span>
            )}
        </div>
    );
};

export default UpdateProjectAccountNumber;
