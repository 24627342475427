import React, { Component } from 'react';
import { spleisUrl } from '../config';
import withData from '../with-data';
import { Redirect } from "react-router";

class FundAsGift extends Component {
    render() {
        const project = this.props.data || {};

        if (project.id) {
            return <Redirect to={`/project/${project.id}`} />;
        }

        return (
            <div>
                <p>Fant ikke prosjektet</p>
            </div>
        );
    }
}

export default withData(
    FundAsGift,
    (code) => `${spleisUrl}/api/admin/project/fund-as-gift/${code}`,
    (props) => props.match.params.fundAsGiftCode
);
