import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Button from '../Button/Button';

const showMoreByClick = 10;
class ShowMore extends Component {
    constructor(props) {
        super(props);
        this.state = {
            initialShowCount: props.initialShowCount || 5,
            showCount: props.initialShowCount || 5,
        };
    }

    showMoreAction = () => {
        this.setState({
            showCount: this.state.showCount + showMoreByClick,
        });
    };

    showAllAction = () => {
        this.setState({
            showCount: this.props.items.length,
        });
    };

    resetAction = () => {
        this.setState({
            showCount: this.state.initialShowCount,
        });
    };

    render() {
        const { items, listCreator } = this.props;

        const slicedItems = items.slice(0, this.state.showCount);
        const isShowingAll = this.state.showCount >= items.length;
        const shouldShowButtonForDisplayingAll = this.state.showCount + showMoreByClick >= items.length;
        const canBeReset = this.state.showCount > this.state.initialShowCount

        return (
            <Fragment>
                {listCreator(slicedItems)}
                <div>
                    {isShowingAll || (
                        <Button onClick={this.showMoreAction} className="task-action-button">
                            Vis flere
                        </Button>
                    )}
                    {shouldShowButtonForDisplayingAll || (
                        <Button onClick={this.showAllAction} className="task-action-button">
                            Vis alle ({items.length})
                        </Button>
                    )}
                    {canBeReset && (
                        <Button onClick={this.resetAction} className="task-action-button">
                            Vis maks {this.state.initialShowCount}
                        </Button>
                    )}
                </div>
            </Fragment>
        );
    }
}

ShowMore.propTypes = {
    items: PropTypes.array.isRequired,
    listCreator: PropTypes.func.isRequired,
    initialShowCount: PropTypes.number,
};

export default ShowMore;
