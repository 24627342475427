import React, { useEffect, useState } from 'react';
import ProfileInfo from '../ProfileInfo/ProfileInfo';
import { spleisUrl } from '../config';
import api from '../api';
import { showError } from '../store';
import FavoriteOrganization from '../Project/FavoriteOrganization/FavoriteOrganization';

const OrganizationInfo = ({ organization_id, size, editable, editSaveCallback }) => {
    const [organization, setOrganization] = useState(null);
    const [mainContact, setMainContact] = useState(null);
    const [bankAccount, setBankAccount] = useState({});
    const [innsamlingskontrollStatus, setInnsamlingskontrollStatus] = useState({});

    useEffect(() => {
        const fetchOrganization = async (orgId) => {
            if (orgId) {
                try {
                    const organization = await api.get(`${spleisUrl}/api/admin/organization/${orgId}`);
                    setOrganization(organization);
                } catch (err) {
                    showError(err);
                }
            }
        };
        fetchOrganization(organization_id);
    }, [organization_id]);

    useEffect(() => {
        const fetchMainAccount = (orgId) => {
            if (orgId) {
                api.get(`${spleisUrl}/api/admin/bank-account/main-account-for-organization/${orgId}`)
                    .then(setBankAccount)
                    .catch((err) => showError(err));
            }
        };
        fetchMainAccount(organization_id);
    }, [organization_id]);

    useEffect(() => {
        const fetchInnsamlingskontrollStatus = (orgId) => {
            if (orgId) {
                api.get(`${spleisUrl}/api/admin/organization/${orgId}/innsamlingskontrollen`)
                    .then(setInnsamlingskontrollStatus)
                    .catch((err) => showError(err));
            }
        };
        fetchInnsamlingskontrollStatus(organization_id);
    }, [organization_id]);

    const fetchMainContactUser = async (userId) => {
        if (userId) {
            try {
                const mainContact = await api.get(`${spleisUrl}/api/admin/user/${userId}`);

                const contactName = `${mainContact.first || ''} ${mainContact.last || ''}`;
                setMainContact(`Kontaktperson: ${contactName}`);
            } catch (err) {
                showError(err);
            }
        }
    };

    if (!organization) {
        return null;
    }

    const accountOwnershipVerification = bankAccount.kar_account_verification
        ? `KAR: ${bankAccount.kar_account_verification.statusText}`
        : 'KAR: Ikke sjekket';

    return (
        <ProfileInfo
            title={organization.name}
            identification={`ORG-NR: ${organization.organization_number} ORG-ID: ${organization.id} `}
            accountOwnership={accountOwnershipVerification}
            account
            verified={!!organization.verified_at}
            innsamlingskontrollStatus={innsamlingskontrollStatus}
            imageSrc={organization.img_url}
            size={size}
            editable={editable}
            editSaveCallback={editSaveCallback}
            contact={mainContact}
            favoriteComponent={<FavoriteOrganization organizationId={organization.id} />}
        />
    );
};
export default OrganizationInfo;
