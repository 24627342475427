import React, { useRef, useState } from 'react';
import api from '../../api';
import { showError, showInfo } from '../../store';
import styles from './SendMessageToOwner.module.scss';
import { presets } from './SendMessageToOwnerPresets';
import FormButton from '../../FormButton/FormButton';

const replacePlaceholders = (message, project) => (message ? message.replaceAll('{projectId}', project.id) : '');

const SendMessageToOwner = ({ project }) => {
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [title, setTitle] = useState(replacePlaceholders(presets[0].title, project));
    const [message, setMessage] = useState(replacePlaceholders(presets[0].message, project));
    const messageRef = useRef();

    const addTextAtCursor = (textToInsert) => {
        if (!messageRef.current) {
            return;
        }
        const area = messageRef.current;
        area.setRangeText(textToInsert, area.selectionStart, area.selectionEnd, 'end');
        area.focus();
    };

    const AddTextButton = ({ text }) => (
        <button className="btn" onClick={() => addTextAtCursor(text)}>
            {text}
        </button>
    );

    const changePreset = (e) => {
        const preset = presets[e.target.value];
        setTitle(replacePlaceholders(preset.title, project));
        setMessage(replacePlaceholders(preset.message, project));
        setSelectedIndex(e.target.value);
    };

    const changeTitle = (e) => {
        setTitle(e.target.value);
    };

    const changeMessage = (e) => {
        setMessage(e.target.value);
    };

    const sendMessageToUserAboutChangesInProject = () => {
        api.post('/api/messages', {
            userId: project.owner_id,
            subject: title,
            message: message,
        })
            .then((res) => {
                if (!res.inappSent && !res.inappForbidden && !res.emailSent && !res.emailForbidden) {
                    showError('Klarte ikke sende melding eller epost til eier');
                    return;
                }

                const sentSomething = res.inappSent || res.emailSent;

                if (res.inappForbidden && res.emailForbidden) {
                    showError('Brukeren har reservert seg mot både epost og intercom meldinger');
                }
                if (sentSomething && !res.inappSent && !res.inappForbidden) {
                    showError('Sendte epost, men klarte ikke å sende itercom melding.');
                }
                if (sentSomething && !res.emailSent && !res.emailForbidden) {
                    showError('Sendte intercom melging, men klarte ikke å sende epost.');
                }
                if (sentSomething && res.emailForbidden) {
                    showInfo('Sendte intercom melding til eier av prosjektet, men de har reservert seg mot epost');
                }
                if (sentSomething && res.inappForbidden) {
                    showInfo('Sendte epost til eier av prosjektet, men de har reservert seg mot intercom meldinger');
                }
                if (res.inappSent && res.emailSent) {
                    showInfo('Sendte intercom melding og epost til eier av prosjektet');
                }

                if (res.inappSent) {
                    api.post('/api/comments', {
                        comment: `Sendte intercom melding til eier med tittel: ${title}`,
                        kis: { project: project.id },
                    })
                        .then(() => {
                            window.notify();
                        })
                        .catch(() => {
                            window.notify();
                            showError('Sendte intercom melding, men klarte ikke å legge til kommentar.');
                        });
                }

                if (res.emailSent) {
                    api.post('/api/comments', {
                        comment: `Sendte epost til eier med tittel: ${title}`,
                        kis: { project: project.id },
                    })
                        .then(() => {
                            window.notify();
                        })
                        .catch(() => {
                            window.notify();
                            showError('Sendte epost, men klarte ikke å legge til kommentar.');
                        });
                }
            })
            .catch(() => {
                showError('Klarte ikke sende melding eller epost til eier');
            });
    };

    return (
        <FormButton
            onClick={sendMessageToUserAboutChangesInProject}
            title="Send melding til eier"
            successText="Send som e-post"
        >
            <div className={styles.presetWrapper}>
                <label>Forhåndsdefinerte</label>
                <select className={styles.preset} onChange={changePreset} value={selectedIndex}>
                    {presets.map((preset, index) => (
                        <option key={index} value={index}>
                            {preset.optionText}
                        </option>
                    ))}
                </select>
            </div>
            <div className={styles.titleWrapper}>
                <label>Tittel</label>
                <input className={styles.title} type="text" onChange={changeTitle} value={title} />
            </div>
            <div className={styles.messageWrapper}>
                <label>Melding</label>
                <textarea className={styles.message} ref={messageRef} onChange={changeMessage} value={message} />
            </div>
            <div className={styles.addTextButtons}>
                <AddTextButton text="👋" />
                <AddTextButton text="😊" />
                <AddTextButton text="🙌" />
                <AddTextButton text="👆" />
                <AddTextButton text="👇" />
                <AddTextButton text="👉" />
                <AddTextButton text="🚀" />
            </div>
        </FormButton>
    );
};

export default SendMessageToOwner;
