import React, { useEffect, useState } from 'react';
import api from '../api';
import { showError, showInfo } from '../store';
import Button from '../Button/Button';
import './ShipmentList.scss';
import SelectPrizesAndDownloadCsv from './SelectPrizesAndDownloadCsv';

const ShippingListContainer = ({ url, listComponent, title, prizes, extraInfo }) => {
    const [shippingProducts, setShippingProducts] = useState([]);
    const [shippingProductsNotApproved, setShippingProductsNotApproved] = useState([]);

    const fetchData = () => {
        api.get(url)
            .then((res) => setShippingProducts(res))
            .catch(() => showError('Feil ved henting av produkter'));
        api.get(url + '/not-approved')
            .then((res) => setShippingProductsNotApproved(res))
            .catch(() => showError('Feil ved henting av produkter'));
    };

    const markAsSent = () => {
        api.put(`${url}/set-downloaded-sent`)
            .then((blob) => {
                window.notify();
                showInfo('Sendingsstatus er oppdatert');
            })
            .catch((err) => showError('Noe feilet da vi skulle sendingsstatus' + err));
    };

    useEffect(() => {
        fetchData();
        window.subscribe(fetchData);
    }, []);

    const ShippingList = listComponent;
    return (
        <div className="promoted-container">
            <header className="task-header">
                <h2 className="task-title">{title}</h2>
            </header>
            <div className="promoted-content">
                <SelectPrizesAndDownloadCsv url={url} prizes={prizes} title={title} />
                <Button onClick={markAsSent}>Marker nedlastede som sendt</Button>
            </div>
            {extraInfo && <div className="promoted-content">{extraInfo}</div>}
            <div className="promoted-content">
                <ShippingList
                    title="Ikke sendt"
                    shippingProducts={shippingProducts?.filter((p) => !(p.sent_at || p.reward_sent_at))}
                />
                <ShippingList
                    title="Ikke godkjent av mottaker enda"
                    shippingProducts={shippingProductsNotApproved}
                    disableActions
                />
                <ShippingList
                    title="Sendt"
                    shippingProducts={shippingProducts?.filter((p) => p.sent_at || p.reward_sent_at)}
                />
            </div>
        </div>
    );
};

export default ShippingListContainer;
