import Button from '../Button/Button';
import { Comment } from '../Comments/Comment';
import React, { useState } from 'react';
import api from '../api';

export default ({ org, fetchComments }) => {
    const [addCommentText, setAddCommentText] = useState('');

    const addComment = ({ orgId, comment }) => {
        api.post('/api/comments', {
            comment: comment,
            kis: { organization: orgId },
            postToSlack: false,
        }).then(() => {
            setAddCommentText('');
            fetchComments(org.id);
        });
    };

    const deleteComment = (id) => {
        api.remove(`/api/comments/${id}`).then(() => fetchComments(org.id));
    };

    return (
        <tr>
            <td colSpan={100} style={{ padding: '2rem' }} className="bg-light">
                <div className="recipients-add-comment-wrapper">
                    <textarea
                        placeholder={`Ny kommentar på ${org.name}`}
                        className="comments-input"
                        value={addCommentText}
                        onChange={(e) => setAddCommentText(e.target.value)}
                    />
                    <div>
                        <Button
                            disabled={addCommentText?.trim().length < 1}
                            onClick={() => addComment({ orgId: org.id, comment: addCommentText })}
                        >
                            Legg til
                        </Button>
                    </div>
                </div>
                {org.comments
                    ?.sort((a, b) => (a.created_at > b.created_at ? -1 : 1))
                    .map((comment) => (
                        <div key={comment.id} className="comment-wrapper">
                            <Comment comment={comment} deleteComment={() => deleteComment(comment.id)} />
                        </div>
                    ))}
            </td>
        </tr>
    );
};
