import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import api from '../api';
import { showError } from '../store';
import { spleisUrl } from '../config';
import './TooltipLink.scss';
import { formatMoneyKr } from '../formatters';

class ProjectLink extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    hent = () => {
        if (!this.state.data && !this.state.loading) {
            this.setState({ loading: true });
            api.get(`${spleisUrl}/api/admin/project/${this.props.id}`)
                .then(res => this.setState({ loading: false, data: res }))
                .catch(err => {
                    showError('Feil ved henting av data', err.text);
                    this.setState({ loading: false });
                });
        }
    };

    render() {
        return (
            <span onMouseOver={this.hent} className="tooltip-link-container">
                <Link
                    to={`/project/${this.props.id}`}
                    onClick={e => e.stopPropagation()}
                    onMouseOver={() => this.setState({ show: true })}
                    onMouseOut={() => this.setState({ show: false })}
                >
                    {this.props.children || this.props.id}
                </Link>

                <div className={`tooltip-container ${this.state.show ? 'show' : ''}`}>
                    {this.state.data && (
                        <div>
                            <div>#{this.state.data.id} {this.state.data.title}</div>
                            <div>({formatMoneyKr(this.state.data.collected_amount)})</div>
                        </div>
                    )}
                    {this.state.loading && <span style={{ fontStyle: 'italic' }}>Laster...</span>}
                </div>
            </span>
        );
    }
}

export default ProjectLink;
