import React, { useRef } from 'react';
import api from '../../api';
import { spleisUrl } from '../../config';
import { showError, showInfo } from '../../store';
import FormButton from '../../FormButton/FormButton';

const AddOrEditContactPersonButton = ({ orgId, user = {}, refreshData }) => {
    const firstNameInput = useRef(null);
    const lastNameInput = useRef(null);
    const phoneNumberInput = useRef(null);
    const emailInput = useRef(null);

    const changeContactUser = async () => {
        const contact = {
            userId: user.id,
            first: firstNameInput.current?.value,
            last: lastNameInput.current?.value,
            phoneNumber: phoneNumberInput.current?.value,
            email: emailInput.current?.value,
            organizationId: orgId,
        };

        return api
            .put(`${spleisUrl}/api/admin/organization/contact`, contact)
            .then(() => {
                refreshData();
                showInfo(`Kontaktpersonen ble ${user.id ? 'endret' : 'lagt til'}!`);
                return Promise.resolve();
            })
            .catch((error) => {
                showError(error);
                return Promise.reject(error);
            });
    };

    const title = user.id ? 'Endre' : 'Legg til kontaktperson';

    return (
        <FormButton title={title} onClick={changeContactUser} keepOpenOnError>
            <form>
                <input
                    placeholder="E-post"
                    defaultValue={user.email}
                    ref={emailInput}
                    readOnly={!!user.id}
                    title={!!user.id ? 'E-posten til en registrert bruker kan ikke endres' : ''}
                />
                <input placeholder="Telefonnummer" defaultValue={user.phone_number} ref={phoneNumberInput} />
                <input placeholder="Fornavn" defaultValue={user.first} ref={firstNameInput} />
                <input placeholder="Etternavn" defaultValue={user.last} ref={lastNameInput} />
            </form>
        </FormButton>
    );
};

export default AddOrEditContactPersonButton;
