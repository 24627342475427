import { combineReducers, applyMiddleware, createStore } from 'redux';

function user(state = {}, action) {
    if (action.type === 'LOGIN_SUCCESSFUL') {
        return action.payload;
    }
    return state;
}

function alert(state = null, action) {
    if (action.type === 'ALERT') {
        return action.payload;
    }
    if (action.type === 'ALERT_CLEAR') {
        return null;
    }
    return state;
}

const logMiddleware = store => next => action => next(action);

const store = createStore(combineReducers({ user, alert }), applyMiddleware(logMiddleware));

export function showError(text, timeout = 0) {
    store.dispatch({ type: 'ALERT', payload: { type: 'error', text } });
    if (timeout) {
        clearAlert(timeout);
    }
}

export function showInfo(text, timeout = 5) {
    store.dispatch({ type: 'ALERT', payload: { type: 'success', text } });
    if (timeout) {
        clearAlert(timeout);
    }
}

function clearAlert(timeout) {
    setTimeout(() => {
        store.dispatch({ type: 'ALERT_CLEAR' });
    }, timeout * 1000);
}

export default store;
