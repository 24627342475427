import React, { useEffect, useState } from 'react';
import api from '../api';
import { spleisUrl } from '../config';
import { showError } from '../store';
import TaskContainer from '../TaskContainer/TaskContainer';
import TaskListSelect from '../TaskList/TaskListSelect';
import ConfirmButton from '../ConfirmButton/ConfirmButton';
import { Helmet } from 'react-helmet';

const Giveaways = () => {
    const [giveaways, setGiveaways] = useState([]);
    const [selectedGiveaway, setSelectedGiveaway] = useState('');
    const [isDeleting, setIsDeleting] = useState(false);

    useEffect(() => {
        fetchGiveaways();
    }, []);

    const fetchGiveaways = () =>
        api
            .get(`${spleisUrl}/api/public/project/campaign/giveaways`)
            .then((res) => {
                const tmp = res.map((c) => c.campaign);
                setGiveaways(tmp);
                setSelectedGiveaway(tmp[0]);
            })
            .catch(() => showError('Feil ved henting av giveaways'));

    const deleteGiveaway = ({ campaign }) =>
        api
            .remove(`${spleisUrl}/api/admin/giveaway-campaign/giveaway`, { campaign })
            .then(() => {
                const updatedGiveaways = giveaways.filter((g) => g !== campaign);
                setGiveaways(updatedGiveaways);
                setSelectedGiveaway(updatedGiveaways[0]);
                setIsDeleting(false);
            })
            .catch(() => showError('Feil ved sletting av giveaway'));

    const deleteEntries = () => {
        setIsDeleting(true);
        deleteGiveaway({ campaign: selectedGiveaway });
    };

    return (
        <TaskContainer>
            <Helmet>
                <title>Giveaways</title>
            </Helmet>
            <header className="task-header">
                <h1 className="task-title">Giveaways</h1>
            </header>
            <div className="task-body">
                {giveaways?.length > 0 ? (
                    <>
                        <p>
                            Henter alle giveaways fra project_giveaway. Velg en giveaway og trykk "Slett alle
                            påmeldinger" for å slette alle påmeldinger til den valgte giveawayen.
                        </p>
                        <TaskListSelect
                            filter={giveaways}
                            onChange={(e) => setSelectedGiveaway(e.target.value)}
                            selected={selectedGiveaway}
                        />
                        <ConfirmButton
                            title="Slett alle påmeldinger"
                            disabled={isDeleting}
                            className="btn-warning"
                            message="Sikker på at du vil slette alle påmeldinger til den valge giveawayen?"
                            onClick={deleteEntries}
                        >
                            {selectedGiveaway}
                        </ConfirmButton>
                    </>
                ) : (
                    <p>Ingen giveaways å hente</p>
                )}
            </div>
        </TaskContainer>
    );
};

export default Giveaways;
