import React from 'react';
import './ShipmentList.scss';
import { spleisUrl } from '../config';
import DugnadList from './DugnadList';
import ShippingListContainer from './ShippingListContainer';
import { Helmet } from 'react-helmet';

const Dugnad = () => (
    <>
        <Helmet>
            <title>Dugnadsprodukter</title>
        </Helmet>
        <ShippingListContainer
            url={`${spleisUrl}/api/admin/transaction/shipping`}
            title="Dugnadsprodukter"
            listComponent={DugnadList}
        />
    </>
);

export default Dugnad;
