import React from 'react';
import './ShipmentList.scss';
import { spleisUrl } from '../config';
import WinnerList from './WinnerList';
import ShippingListContainer from './ShippingListContainer';
import { Helmet } from 'react-helmet';

const Winners = () => (
    <>
        <Helmet>
            <title>Lotterivinnere</title>
        </Helmet>
        <ShippingListContainer
            url={`${spleisUrl}/api/admin/transaction/lottery-ticket/winners`}
            title="Lotterivinnere"
            prizes={['Fleecebuff', 'Reflekssele', 'Termokopp']}
            listComponent={WinnerList}
        />
    </>
);

export default Winners;
