import React, { useEffect, useState } from 'react';
import { showError } from '../store';
import api from '../api';
import { spleisUrl } from '../config';
import TaskContainer from '../TaskContainer/TaskContainer';
import { useParams } from 'react-router';
import { formatMoneyKr } from '../formatters';
import SubscriptionList from './SubscriptionList';
import './Subscription.scss';
import { Helmet } from 'react-helmet';

const SubscriptionTypeVersion = ({ id, displayTitle, price, available }) => {
    const [subscriptions, setSubscriptions] = useState([]);

    const fetchData = () => {
        api.get(`${spleisUrl}/api/admin/subscription/subscription-type-id/${id}`)
            .then((subscriptions) => {
                setSubscriptions(subscriptions);
            })
            .catch(() => showError('Feil ved henting av abonnement type'));
    };

    useEffect(() => {
        window.subscribe(fetchData);
        return window.unsubscribe(fetchData);
    }, []);

    useEffect(() => {
        fetchData();
    }, [id]);

    return (
        <>
            <div className={'subscription-info'}>
                <span className={'subscription-label'}>Id</span>
                <span className={'subscription-value'}>{id}</span>
                <span className={'subscription-label'}>Navn</span>
                <span className={'subscription-value'}>{displayTitle}</span>
                <span className={'subscription-label'}>Pris</span>
                <span className={'subscription-value'}>{formatMoneyKr(price)}</span>
                <span className={'subscription-label'}>Tilgjengelig</span>
                <span className={'subscription-value'}>{available ? 'Gjeldende versjon' : 'Eldre versjon'}</span>
            </div>
            <SubscriptionList subscriptions={subscriptions} />
        </>
    );
};

const SubscriptionType = () => {
    const [subscriptionTypes, setSubscriptionTypes] = useState(null);
    const params = useParams();
    const subscriptionTypeName = params.name;

    const fetchData = () => {
        api.get(`${spleisUrl}/api/admin/subscription/subscriptionTypes/${subscriptionTypeName}`)
            .then((subscriptionTypes) => {
                setSubscriptionTypes(subscriptionTypes);
            })
            .catch(() => showError('Feil ved henting av abonnement type'));
    };

    useEffect(() => {
        window.subscribe(fetchData);
        return window.unsubscribe(fetchData);
    }, []);

    useEffect(() => {
        fetchData();
    }, [subscriptionTypeName]);

    if (!subscriptionTypes) {
        return null;
    }

    return (
        <TaskContainer>
            <Helmet>
                <title>Abonnementtype {subscriptionTypeName}</title>
            </Helmet>
            <header className="task-header">
                <h1 className="task-title">{subscriptionTypeName}</h1>
            </header>
            {subscriptionTypes.map((subscriptionType) => {
                return (
                    <SubscriptionTypeVersion
                        id={subscriptionType.id}
                        displayTitle={subscriptionType.display_title}
                        available={subscriptionType.available}
                        price={subscriptionType.price}
                    />
                );
            })}
        </TaskContainer>
    );
};

export default SubscriptionType;
