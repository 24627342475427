import React, { useEffect, useState } from 'react';
import api from '../api';
import { showError } from '../store';
import { spleisUrl } from '../config';
import './TooltipLink.scss';
import { Link } from 'react-router-dom';

const OrganizationLink = ({ id, children, showName }) => {
    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [data, setData] = useState(null);
    const [name, setName] = useState(null);
    const [fetch, setFetch] = useState(null);

    useEffect(() => {
        if (!data && !loading && (showName || fetch)) {
            setLoading(true);
            api.get(`${spleisUrl}/api/admin/organization/${id}`)
                .then(res => {
                    setLoading(false);
                    setData(res);
                    setName(res.name);
                })
                .catch(err => {
                    showError('Feil ved henting av data', err.text);
                    setLoading(false);
                });
        }
    }, [id, showName, fetch]);

    const output = () => {
        if (children) {
            return children;
        }

        if (showName && name) {
            return name;
        }

        return id;
    };

    return (
        <span
            onMouseOver={() => {
                if (!showName) {
                    setFetch(true);
                }
            }}
            className="tooltip-link-container"
        >
            <Link
                to={`/organizations/${id}`}
                onClick={e => e.stopPropagation()}
                onMouseOver={() => setShow(true)}
                onMouseOut={() => setShow(false)}
            >
                {output()}
            </Link>

            <div className={`tooltip-container ${show && !showName ? 'show' : ''}`}>
                {data && (
                    <div>
                        #{data.id} {data.name}
                    </div>
                )}
                {loading && <span style={{ fontStyle: 'italic' }}>Laster...</span>}
            </div>
        </span>
    );
};

export default OrganizationLink;
