import React from 'react';
import styles from './TaskListSelect.module.scss';

const TaskListSelect = ({ filter, options, onChange, selected }) => {
    if (filter && !options) {
        return (
            <select className={styles.taskSelect} onChange={onChange} value={selected}>
                {filter.map(value => (
                    <option key={value} value={value}>
                        {value}
                    </option>
                ))}
            </select>
        );
    }

    return (
        <select className={styles.taskSelect} onChange={onChange} value={selected && selected.value}>
            {options
                .filter(option => !filter || filter.includes(option.value))
                .map(option => (
                    <option key={option.value} value={option.value}>
                        {option.title}
                    </option>
                ))}
        </select>
    );
};

export default TaskListSelect;
