import { useParams } from 'react-router-dom';

import React, { useState, useEffect } from 'react';
import api from '../api';
import { spleisUrl } from '../config';
import { showError } from '../store';
import ShowMore from '../ShowMore/ShowMore';
import MatchingGift from './MatchingGift';
import TaskContainer from '../TaskContainer/TaskContainer';
import { Helmet } from 'react-helmet';

const MatchingGifts = () => {
    const [matchingGifts, setMatchingGifts] = useState([]);
    const params = useParams();
    useEffect(() => {
        api.get(`${spleisUrl}/api/admin/project/matching-gift/list`)
            .then((res) => setMatchingGifts(res))
            .catch(() => showError('Feil ved henting av matching gifts'));
    }, []);

    return (
        <TaskContainer>
            <Helmet>
                <title>Matching gifts</title>
            </Helmet>
            <header className="task-header">
                <h1 className="task-title">Matching gifts</h1>
            </header>
            <div className="task-body">
                <ShowMore
                    items={matchingGifts}
                    listCreator={(showMoreItems) => {
                        return (
                            <table className={'table table-striped'}>
                                <thead>
                                    <tr>
                                        <th>Prosjekt</th>
                                        <th>Organisasjon</th>
                                        <th>Matching-beløp</th>
                                        <th>Innsamlet</th>
                                        <th>Oppgjort</th>
                                        <th>Faktura</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {showMoreItems.map((mg) => {
                                        return (
                                            <MatchingGift
                                                shouldSendInvoice={
                                                    mg.collected_amount > 0 &&
                                                    (mg.collected_amount > mg.max_amount || mg.project_ended) &&
                                                    !(mg.transaction_id || mg.profile?.paidAmount)
                                                }
                                                selected={mg.id == params.id}
                                                {...mg}
                                                key={mg.id}
                                            />
                                        );
                                    })}
                                </tbody>
                            </table>
                        );
                    }}
                />
            </div>
        </TaskContainer>
    );
};

export default MatchingGifts;
