import React from 'react';
import { Link } from 'react-router-dom';
import { formatMoneyKr } from '../../formatters';

const MatchingGift = ({ project }) => {
    if (!project?.matchingGift?.matching) {
        return null;
    }
    return (
        <section className={'matching-gift-container'}>
            <header className="project-table-data-header">
                <h2>Matching gift</h2>
            </header>
            <div>
                <Link to={`/mg/${project.matchingGift.id}`}>
                    Matches med opptil {formatMoneyKr(project.matchingGift.max_amount)}.{' '}
                    {project.matchingGift?.transaction_id ? 'Beløpet er innbetalt ✅' : 'Beløpet er ikke innbetalt ❌'}
                </Link>
            </div>
        </section>
    );
};

export default MatchingGift;
