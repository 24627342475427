import React, { useState } from 'react';
import { spleisUrl } from '../config';
import api from '../api';
import { showError, showInfo } from '../store';
import FormButton from '../FormButton/FormButton';

const SendInvoice = ({ subscriptionId }) => {
    const [months, setMonths] = useState(1);

    const sendInvoice = () => {
        api.post(`${spleisUrl}/api/admin/subscription/${subscriptionId}/create-invoice`, { months })
            .then(() => {
                window.notify();
                showInfo('Sendt faktura til organisasjonen');
            })
            .catch(() => showError('Det oppsto en feil når vi skulle sende faktura'));
    };

    return (
        <div>
            <FormButton title="Send faktura" onClick={sendInvoice}>
                <div>Send faktura for {months} måneder</div>
                <input value={months} onChange={(e) => setMonths(e.target.value)}/>
            </FormButton>
        </div>
    );
};

export default SendInvoice;
