import React, { useEffect, useState } from 'react';
import ReactTable from 'react-table';
import api from '../api';
import { spleisFrontUrl, spleisUrl } from '../config';
import './Shortlinks.module.scss';
import ConfirmButton from '../ConfirmButton/ConfirmButton';
import { showError, showInfo } from '../store';
import { useLocation } from 'react-router';
import useQueryString from 'use-query-string';
import { Link } from 'react-router-dom';
import styles from './Shortlinks.module.scss';
import ShortlinkEdit from './ShortlinkEdit';
import { Helmet } from 'react-helmet';

const updateQuery = (path) => {
    window.history.pushState(null, document.title, path);
};

const Shortlinks = () => {
    const location = useLocation();
    const [query, setQuery] = useQueryString(location, updateQuery);
    const [shortlinks, setShortlinks] = useState([]);

    const fetchShortlinks = () => {
        api.get(`${spleisUrl}/api/admin/shortlinks`)
            .then((res) => setShortlinks(res))
            .catch(() => showError('Feil ved henting av shortlinks fra baksystem', 3));
    };

    useEffect(() => {
        fetchShortlinks();
    }, []);

    const createShortlink = (short, link, projectId, organizationId, templateCauseId) => {
        api.post(`${spleisUrl}/api/admin/shortlinks`, {
            short,
            link: link || null,
            projectId: projectId || null,
            organizationId: organizationId || null,
            templateCauseId: templateCauseId || null
        })
            .then(() => {
                showInfo(`Shortlink opprettet/endret`, 3);
                fetchShortlinks();
            })
            .catch(() => showError('Noe gikk feil ved oppretting av shortlink', 3));
    };

    const deleteShortlink = (short) => {
        api.remove(`${spleisUrl}/api/admin/shortlinks`, { short })
            .then(() => {
                showInfo(`Shortlink slettet`, 3);
                fetchShortlinks();
            })
            .catch(() => showError('Noe gikk feil ved sletting av shortlink', 3));
    };

    const filteredShortlinks = shortlinks.filter(
        (sl) =>
            !query?.q ||
            sl.short?.toLowerCase()?.includes(query.q?.toLowerCase()) ||
            sl.link?.toLowerCase()?.includes(query.q?.toLowerCase()) ||
            sl.project_id?.toString()?.includes(query.q?.toLowerCase()) ||
            sl.organization_id?.toString()?.includes(query.q?.toLowerCase())
    );

    return (
        <>
            <Helmet>
                <title>Shortlinks</title>
            </Helmet>
            <header className="task-header">
                <h2 className="task-title">Shortlinks</h2>
            </header>

            <div className={styles.shortlinkContent}>
                <input
                    value={query.q || ''}
                    onChange={(e) =>
                        setQuery({
                            q: e.target.value,
                        })
                    }
                    placeholder="Søk"
                />
            </div>

            <div className={styles.shortlinkContent}>
                <div className={styles.shortlinkMeny}>
                    <ShortlinkEdit
                        shortIsEditable={true}
                        onSubmit={createShortlink}
                    />
                </div>

                <ReactTable
                    data={filteredShortlinks}
                    className={`-striped ${styles.shortlinkTable}`}
                    defaultSorted={[{ id: 'short' }]}
                    getTdProps={() => ({
                        style: {
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            height: '50px',
                        },
                    })}
                    columns={[
                        {
                            Header: 'Shortlink',
                            accessor: 'short',
                            Cell: (row) => {
                                return (
                                    <ShortlinkEdit
                                        shortIsEditable={false}
                                        shortlink={row.row}
                                        buttonText={row.row.short}
                                        onSubmit={createShortlink}
                                    />
                                );
                            },
                        },
                        {
                            Header: 'Path',
                            accessor: 'link',
                            Cell: (row) => (
                                <div>
                                    <a href={`${spleisFrontUrl}${row.row.link}`}>{row.row.link}</a>
                                </div>
                            ),
                        },
                        {
                            Header: 'Project ID',
                            accessor: 'project_id',
                            Cell: (row) => (
                                <div>
                                    {row.row.project_id && (
                                        <Link to={`/project/${row.row.project_id}`}>{row.row.project_id}</Link>
                                    )}
                                </div>
                            ),
                        },
                        {
                            Header: 'Org ID',
                            accessor: 'organization_id',
                            Cell: (row) => (
                                <div>
                                    {row.row.organization_id && (
                                        <Link to={`/organization/${row.row.organization_id}`}>
                                            {row.row.organization_id}
                                        </Link>
                                    )}
                                </div>
                            ),
                        },
                        {
                            Header: 'Cause ID',
                            accessor: 'template_cause_id',
                            Cell: (row) => (
                                <div>
                                    {row.row.template_cause_id && (
                                        <a href={`https://spleis.no/cause/${row.row.template_cause_id}`} target="_blank">
                                            {row.row.template_cause_id}
                                        </a>
                                    )}
                                </div>
                            ),
                        },
                        {
                            Header: 'Actions',
                            id: 'actions',
                            accessor: (d) => d.short,
                            sortable: false,
                            width: 100,
                            Cell: (row) => (
                                <ConfirmButton
                                    title="Slett"
                                    message="Er du sikker på at du vil slette shortlinken?"
                                    className="btn-warning"
                                    onClick={() => deleteShortlink(row.value)}
                                />
                            ),
                        },
                    ]}
                />
            </div>
        </>
    );
};

export default Shortlinks;
