import React, { useContext, useState } from 'react';
import { spleisUrl } from '../../config';
import api from '../../api';
import { showError, showInfo } from '../../store';
import ProjectContext from '../project-context';
import Button from '../../Button/Button';
import ConfirmModal from '../../ConfirmModal/ConfirmModal';
import styles from './ToggleHideContent.module.scss';

const contentWillBeHiddenMessageBody = (project) => `Hei 👋

Spleisen din, «${project.title}» (spleis nr. ${project.id}), har fått deler av innholdet midlertidig skjult for besøkende. Vi har behov for å ta en ekstra vurdering av innsamlingen og dens innhold, og vil forsøke å gjøre dette innen neste virkedag. I mellomtiden oppfordrer vi deg til å se over spleisen på nytt, om den inneholder sensitive opplysninger eller brudd på våre vilkår for øvrig.

Det vil fortsatt være mulig å betale inn penger på spleisen og du vil få beskjed så snart saken er behandlet.

Hilsen Spleis-teamet`;

const contentIsOKMessageBody = (project) => `Hei 👋

Spleisen din, «${project.title}» (spleis nr. ${project.id}) er nå godkjent og innholdet synlig.

Hilsen Spleis-teamet`;

const ToggleHideContent = () => {
    const [hideModalOpen, setHideModalOpen] = useState(false);
    const [showModalOpen, setShowModalOpen] = useState(false);
    const [shouldSendEmail, setShouldSendEmail] = useState(true);
    const project = useContext(ProjectContext);
    const shouldHideContent = !project.content_hidden;
    const [title, setTitle] = useState('Spleisen din er under behandling');
    const [message, setMessage] = useState(() => contentWillBeHiddenMessageBody(project));
    const [contentOkTitle, setContentOkTitle] = useState('Spleisen din er godkjent');
    const [contentOkMessage, setContentOkMessage] = useState(() => contentIsOKMessageBody(project));

    const setContentHidden = () => {
        api.put(`${spleisUrl}/api/admin/project/${project.id}/hide-content`, {
            hide: shouldHideContent,
        })
            .then(async () => {
                if (shouldSendEmail) {
                    try {
                        const res = await api.post('/api/messages', {
                            userId: project.owner_id,
                            subject: title,
                            message: message,
                        });
                        if (res.inappSent || res.emailSent) {
                            await api.post('/api/comments', {
                                comment: `Sendte intercom melding til eier med tittel: '${title}'`,
                                kis: { project: project.id },
                            });
                        }
                    } catch (e) {
                        showError(`Feil ved sending av epost ved gjemming av innhold: ${e}`);
                    }
                }

                showInfo(`Bilder og beskrivelse ble skjult fra spleisen!`);
                window.notify();
                setHideModalOpen(false);
            })
            .catch((e) => showError(`Feil ved gjemming av innhold: ${e}`));
    };

    const removeSetContentHidden = () => {
        api.put(`${spleisUrl}/api/admin/project/${project.id}/hide-content`, {
            hide: shouldHideContent,
        })
            .then(async () => {
                if (shouldSendEmail) {
                    try {
                        const res = await api.post('/api/messages', {
                            userId: project.owner_id,
                            subject: contentOkTitle,
                            message: contentOkMessage,
                        });
                        if (res.inappSent || res.emailSent) {
                            await api.post('/api/comments', {
                                comment: `Sendte intercom melding til eier med tittel: '${contentOkTitle}'`,
                                kis: { project: project.id },
                            });
                        }
                    } catch (e) {
                        showError(`Feil ved sending av epost ved godkjenning av innhold: ${e}`);
                    }
                }

                showInfo(`Bilder og beskrivelse blir vist i spleisen!`);
                window.notify();
                setShowModalOpen(false);
            })
            .catch((e) => showError(`Feil ved vising av innhold: ${e}`));
    };

    const buttonText = `${shouldHideContent ? 'Gjem' : 'Vis'} bilder og beskrivelse`;

    return (
        <>
            <Button
                className="dangerous-button"
                onClick={() => (shouldHideContent ? setHideModalOpen(true) : setShowModalOpen(true))}
            >
                {buttonText}
            </Button>
            <ConfirmModal
                isOpen={hideModalOpen}
                title={buttonText}
                message="Dette skjuler bilder og beskrivelsen på spleisen, samt fjerner spleisen fra søk."
                successText={buttonText}
                cancelText="Avbryt"
                onClickSuccess={setContentHidden}
                onClickCancel={() => setHideModalOpen(false)}
                className="dangerous-button"
            >
                <br />
                <label className={styles.labelWithCheckbox}>
                    <input
                        type="checkbox"
                        checked={shouldSendEmail}
                        onChange={() => {
                            setShouldSendEmail(!shouldSendEmail);
                        }}
                    />
                    Send epost
                </label>
                {shouldSendEmail && (
                    <>
                        <div className={styles.hideContentInputWrapper}>Melding til innsamler</div>
                        <div className={styles.hideContentInputWrapper}>
                            <label>Tittel</label>
                            <input
                                className={styles.hideContentTitle}
                                type="text"
                                onChange={(e) => setTitle(e.target.value)}
                                value={title}
                            />
                        </div>
                        <div className={styles.hideContentInputWrapper}>
                            <label>Melding</label>
                            <textarea
                                className={styles.hideContentMessage}
                                onChange={(e) => setMessage(e.target.value)}
                                value={message}
                            />
                        </div>
                    </>
                )}
            </ConfirmModal>
            <ConfirmModal
                isOpen={showModalOpen}
                title={buttonText}
                message="Dette viser bilder og beskrivelsen på spleisen, samt legger til spleisen i søk."
                successText={buttonText}
                cancelText="Avbryt"
                onClickSuccess={removeSetContentHidden}
                onClickCancel={() => setShowModalOpen(false)}
                className="dangerous-button"
            >
                <br />
                <label className={styles.labelWithCheckbox}>
                    <input
                        type="checkbox"
                        checked={shouldSendEmail}
                        onChange={() => {
                            setShouldSendEmail(!shouldSendEmail);
                        }}
                    />
                    Send epost
                </label>
                {shouldSendEmail && (
                    <>
                        <div className={styles.hideContentInputWrapper}>Melding til innsamler</div>
                        <div className={styles.hideContentInputWrapper}>
                            <label>Tittel</label>
                            <input
                                className={styles.hideContentTitle}
                                type="text"
                                onChange={(e) => setContentOkTitle(e.target.value)}
                                value={contentOkTitle}
                            />
                        </div>
                        <div className={styles.hideContentInputWrapper}>
                            <label>Melding</label>
                            <textarea
                                className={styles.hideContentMessage}
                                onChange={(e) => setContentOkMessage(e.target.value)}
                                value={contentOkMessage}
                            />
                        </div>
                    </>
                )}
            </ConfirmModal>
        </>
    );
};

export default ToggleHideContent;
