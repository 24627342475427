import React, { useEffect, useState } from 'react';
import { Route } from 'react-router-dom';
import api from '../api';
import { spleisUrl } from '../config';
import VisibleIf from '../VisibleIf/VisibleIf';
import ButtonLink from '../ButtonLink/ButtonLink';
import TaskList from '../TaskList/TaskList';
import Project from '../Project/Project';
import MainContainer from '../MainContainer/MainContainer';
import TaskListSelect from '../TaskList/TaskListSelect';
import styles from './Tags.module.scss';
import { Helmet } from 'react-helmet';
import HelmetForSubpages from '../HelmetForSubpages/HelmetForSubpages';

const TagsTaskList = ({
    selectedProjectId,
    filterList = () => {},
    availableTags = [],
    selectedTag = '',
    visibleProjects = [],
}) => {
    const [showUnpublished, setShowUnpublished] = useState(false);
    const projectsFilteredByPublished = visibleProjects.filter((p) => (showUnpublished ? p : !!p.published_at));
    const numberOfNotPublishedProjects = visibleProjects.filter((p) => !p.published_at).length;
    return (
        <div className={`task-list-container ${!selectedProjectId ? 'block' : ''}`}>
            <div className="task-list-wrapper">
                <header className="tasks-header">
                    <TaskListSelect
                        onChange={(e) => filterList(e.target.value)}
                        filter={availableTags}
                        selected={selectedTag}
                    />
                    <div className="tasks-header-subtitle">
                        Viser {projectsFilteredByPublished.length} av {visibleProjects.length} innsamlinger
                    </div>
                    {numberOfNotPublishedProjects > 0 && (
                        <ButtonLink onClick={() => setShowUnpublished(!showUnpublished)} className={styles.button}>
                            {!showUnpublished
                                ? `Vis ${numberOfNotPublishedProjects} ikke-startede`
                                : `Skjul ${numberOfNotPublishedProjects} ikke-startede`}
                        </ButtonLink>
                    )}
                </header>
                <ul className="task-list">
                    {projectsFilteredByPublished.map((p) => (
                        <TaskList
                            project={p}
                            link={`/tags/${p.id}?filter=${selectedTag}`}
                            key={p.id}
                            selected={selectedProjectId === parseInt(p.id, 0)}
                        />
                    ))}
                </ul>
            </div>
        </div>
    );
};

const Tags = ({ history, location, match, ...props }) => {
    const { projectId } = match.params;
    const [availableTags, setAvailableTags] = useState([]);
    const [taggedProjects, setTaggedProjects] = useState([]);
    const locationSearch = new URLSearchParams(location.search);
    const [currentFilter, setCurrentFilter] = useState(locationSearch.get('filter') || '');

    const onFilter = (filter) => {
        history.push({
            pathname: location.pathname,
            search: `${filter ? `?filter=${filter}` : ''}`,
        });

        if (!filter) {
            setTaggedProjects([]);
        }

        setCurrentFilter(filter || '');
    };

    const fetchAllTags = async () =>
        api
            .get(`${spleisUrl}/api/admin/project/tag`)
            .then((result) => {
                setAvailableTags(['', ...result?.tags]);
            })
            .catch((err) => console.error(err));

    const fetchAllProjectsWithTag = async (tag) => {
        if (!tag) {
            return null;
        }
        return api
            .get(`${spleisUrl}/api/admin/project/projects/tag/${tag}`)
            .then((result) => {
                setTaggedProjects(result.projects);
            })
            .catch((err) => console.error(err));
    };

    useEffect(() => {
        fetchAllTags();
        if (currentFilter) {
            fetchAllProjectsWithTag(currentFilter);
        }

        window.subscribe(fetchAllTags);
    }, [currentFilter]);

    return (
        <MainContainer>
            <HelmetForSubpages title="Tags" />
            <VisibleIf isVisible={!!projectId}>
                <ButtonLink className="back-link" onClick={history.goBack}>
                    &larr; Tilbake
                </ButtonLink>
            </VisibleIf>
            <TagsTaskList
                visibleProjects={taggedProjects}
                availableTags={availableTags}
                filterList={onFilter}
                selectedProjectId={projectId}
                selectedTag={currentFilter}
                {...props}
            />
            <Route path="/tags/:projectId" component={Project} />
        </MainContainer>
    );
};

export default Tags;
