import React, { useContext, useState } from 'react';
import { spleisUrl } from '../../config';
import FormButton from '../../FormButton/FormButton';
import api from '../../api';
import { showError, showInfo } from '../../store';
import ProjectContext from '../project-context';
import styles from './ChangePricing.module.scss';

const pricingFunctions = [
    {
        value: '',
        description: '(Ingen valgt)',
    },
    {
        value: 'free',
        description: 'Gratis',
    },
    {
        value: 'percentage2',
        description: '2%',
    },
    {
        value: 'fivePercentOver10k',
        description: '5% over 10.000 kr',
    },
    {
        value: 'fivePercentOver25k',
        description: '5% over 25.000 kr',
    },
    {
        value: 'percentage5external',
        description: 'Transaksjoner betalt separat (5%)',
    },
    {
        value: 'percentage3point6',
        description: 'Transaksjoner med 3,6% gebyr (inkl. MVA)',
    },
    {
        value: 'percentage5',
        description: 'Transaksjoner med 5 % gebyr (inkl. MVA)',
    },
    {
        value: 'sixPointFivePercentOver5k',
        description: '6,5% over 5.000 kr (4.5% admin. gebyr og 2% transaksjonsgebyr)',
    },
    {
        value: 'percentage6Point5Over5000WithMax49500AdmFee',
        description: '6,5% over 5.000 kr (4.5% admin. gebyr og 2% transaksjonsgebyr). Max 49.500 admin. gebyr.',
    },
    {
        value: 'percentageSixPointFive',
        description: '6,5% (4.5% admin. gebyr og 2% transaksjonsgebyr)',
    },
    {
        value: 'percentage6Point5WithMax49500AdmFee',
        description: '6,5% (4.5% admin. gebyr og 2% transaksjonsgebyr). Max 49.500 admin. gebyr.',
    },
    {
        value: 'percentageSixPointFivePlus5ForEachLotteryTicket',
        description: '6,5% (4.5% admin. gebyr og 2% transaksjonsgebyr), med 5% for lodd',
    },
    {
        value: 'percentage6Point5Plus5ForEachLotteryTicketWithMax49500AdmFee',
        description:
            '6,5% (4.5% admin. gebyr og 2% transaksjonsgebyr) over 5.000 kr, med 5% for lodd. Max 49.500 kr admin. gebyr.',
    },
    {
        value: 'percentageTwoPointFiveOver5MThreePointSevenFiveOver2Point5MSixPointFive',
        description:
            '2% transaksjonsgebyr, 4,5% admingebyr inntil 2,5 mill totalt for org.en, deretter 1,75% inntill 5 mill kr, deretter 0,5%.',
    },
];

const ChangePricing = () => {
    const project = useContext(ProjectContext);
    const [pricingFunctionName, setPricingFunctionName] = useState(project?.pricing?.functionName);

    const changePricingHandler = () => {
        if (pricingFunctionName) {
            api.post(`${spleisUrl}/api/admin/project/${project.id}/pricing`, {
                pricingFunctionName,
            })
                .then(() => {
                    showInfo('Endret pris');
                })
                .catch(() => {
                    showError('Klarte ikke å endre pris');
                });
        }
    };

    if (!project.published_at || project.payout_at) {
        return null;
    }

    return (
        <FormButton title={`Endre pris`} onClick={changePricingHandler} disabled={!pricingFunctionName}>
            <div className={styles.explanation}>Endrer pris på alle transaksjoner som ikke har avvikende pris</div>
            <div>
                <select
                    defaultValue={pricingFunctionName}
                    onChange={(e) => setPricingFunctionName(e.target.value)}
                    autoFocus={true}
                >
                    {pricingFunctions.map((pricingFunction) => (
                        <option key={pricingFunction.value} value={pricingFunction.value}>
                            {pricingFunction.description}
                        </option>
                    ))}
                </select>
            </div>
        </FormButton>
    );
};

export default ChangePricing;
