import React from 'react';
import PropTypes from 'prop-types';
import './FunctionalBanner.scss';
import Button from '../Button/Button';

const FunctionalBanner = props => {
    const themeClass = `functional-banner-theme-${props.themeClass || 'info'}`;
    return (
        <div className={`functional-banner-container ${themeClass}`}>
            <div className="functional-banner-header">
                <h3 className="functional-banner-title">{props.title}</h3>
                {!!props.buttonCallback && !!props.buttonText && <Button onClick={props.buttonCallback}>{props.buttonText}</Button>}
            </div>
            {props.children}
        </div>
    );
};

FunctionalBanner.propTypes = {
    themeClass: PropTypes.string,
    title: PropTypes.string.isRequired,
};

export default FunctionalBanner;
