import React, { createContext, useCallback, useEffect, useState } from 'react';
import api from './api';
import { spleisUrl } from './config';
import { emailHasSpleisDomain } from './index';
import { showError } from './store';

export const FavoriteProjectsContext = createContext({});

const useFavoriteProjects = ({ user }) => {
    const [projectIds, setProjectIds] = useState([]);

    useEffect(() => {
        if (emailHasSpleisDomain(user)) {
            api.get(`${spleisUrl}/api/admin/favorite-project`)
                .then((data) => setProjectIds(data.projectIds))
                .catch((err) => showError('Klarte ikke å hente favoritter'));
        }
    }, [user]);

    const add = useCallback((projectId) => {
        api.post(`${spleisUrl}/api/admin/favorite-project/${projectId}`)
            .then((data) => {
                setProjectIds(data.projectIds);
                window.notify();
            })
            .catch((err) => showError('Klarte ikke å lagre favoritt'));
    }, []);

    const remove = useCallback((projectId) => {
        api.remove(`${spleisUrl}/api/admin/favorite-project/${projectId}`)
            .then((data) => {
                setProjectIds(data.projectIds);
                window.notify();
            })
            .catch((err) => showError('Klarte ikke å fjerne favoritt'));
    }, []);

    return {
        projectIds,
        add,
        remove,
    };
};

export default useFavoriteProjects;
