import styles from './Subscription.scss';
import Modal from 'react-modal';
import React from 'react';
import Button from '../Button/Button';
import ShowMore from '../ShowMore/ShowMore';
import { formatMoneyKr } from '../formatters';
import { formatDateOnly } from '../dates';
import ButtonLink from '../ButtonLink/ButtonLink';
import api from '../api';
import { spleisUrl } from '../config';

const SubscriptionInvoicesModal = ({ invoices, open, setOpen }) => {
    const name = invoices.length > 0 ? invoices[0].fiken_metadata?.customerInfo?.name : '';

    const openFiken = async ({ invoiceId }) => {
        const invoiceInfo = await api.get(`${spleisUrl}/api/admin/invoice/${invoiceId}`);
        window.open(invoiceInfo.invoiceFiken.friendlyFikenUrl, '_blank');
    };

    return (
        <Modal isOpen={open} overlayClassName={styles['modal-wrapper']} className={styles['modal-container']}>
            <div>
                <h3 className={styles['modal-heading']}>Fakturaer for {name}</h3>

                <ShowMore
                    items={invoices}
                    initialShowCount={50}
                    listCreator={(showMoreItems) => (
                        <table className={'invoice-table table table-striped'}>
                            <thead>
                                <tr>
                                    <th>Opprettet</th>
                                    <th>Sendt</th>
                                    <th>Betalt</th>
                                    <th>Kredittnota</th>
                                    <th>Beløp</th>
                                    <th>KID</th>
                                    <th>Link</th>
                                </tr>
                            </thead>
                            <tbody>
                                {showMoreItems.map((i) => {
                                    const paid = i.creditnote_at || i.paid_at;
                                    return (
                                        <tr className={paid ? '' : styles['invoice-unpaid']}>
                                            <td>{i.created_at && formatDateOnly(i.created_at)}</td>
                                            <td>{i.sent_at && formatDateOnly(i.sent_at)}</td>
                                            <td>{i.paid_at && formatDateOnly(i.paid_at)}</td>
                                            <td>{i.creditnote_at && formatDateOnly(i.creditnote_at)}</td>
                                            <td>{formatMoneyKr(i.amount)}</td>
                                            <td>{i.kid}</td>
                                            <td>
                                                <ButtonLink onClick={() => openFiken({ invoiceId: i.id })}>
                                                    {i.invoice_number}
                                                </ButtonLink>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    )}
                />
                <div className={styles['modal-buttons-container']}>
                    <Button onClick={() => setOpen(false)}>Lukk</Button>
                </div>
            </div>
        </Modal>
    );
};
export default SubscriptionInvoicesModal;
