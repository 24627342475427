import React from 'react';
import PropTypes from 'prop-types';

import './VerificationBadge.scss';

const VerificationBadge = (props) => {
    let text = 'Ikke verifisert';
    let icon = 'ikke-';
    if (props.verified) {
        text = 'Verifisert';
        icon = '';
    } else if (props.partiallyVerified) {
        text = 'Delvis verifisert';
        icon = 'delvis-';
    }

    return (
        <img
            className="verification-badge"
            alt=""
            title={text}
            src={`https://www.spleis.no/public/images/${icon}verifisert-ikon.svg`}
        />
    );
};

VerificationBadge.propTypes = {
    verified: PropTypes.bool.isRequired,
};

export default VerificationBadge;
