import React, { useState } from 'react';
import { spleisUrl } from '../config';
import FormButton from '../FormButton/FormButton';
import { showError, showInfo } from '../store';
import api from '../api';

const ChangeSubscriptionState = ({ subscriptionId, subscriptionState }) => {
    const [active, setActive] = useState(subscriptionState);

    const changeSubscriptionState = () => {
        api.post(`${spleisUrl}/api/admin/subscription/${subscriptionId}/active`, {
            active,
        })
            .then(() => {
                showInfo('Endret tilstand for abonnementet');
                window.notify();
            })
            .catch(() => {
                showError('Klarte ikke å endre tilstand for abonnementet');
            });
    };

    return (
        <FormButton
            buttonText={`Endre`}
            title={'Endre status'}
            onClick={changeSubscriptionState}
            className={'button-link'}
        >
            Aktiv: <input
                type="checkbox"
                checked={active}
                onChange={e => {
                    setActive(!active);
                }}
            />
        </FormButton>
    );
};

export default ChangeSubscriptionState;
