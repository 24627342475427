import React, { useEffect, useState } from 'react';
import FormButton from '../../FormButton/FormButton';
import api from '../../api';
import { spleisUrl } from '../../config';
import { showError, showInfo } from '../../store';
import styles from './ChangeProjectProfile.module.scss';

const booleanFields = [
    {
        key: 'projectCommunication',
        description: 'Vis "Snakk med innsamleren"',
    },
    {
        key: 'hideResponsibleUser',
        description: 'Gjem ansvarlig bruker (under innsamler)',
    },
    {
        key: 'projectCommunication',
        description: 'Prosjektkommunikasjon (meldinger etc.)',
    },
    {
        key: 'showTimeline',
        description: 'Vis aktivitet',
    },
    {
        key: 'showSharing',
        description: 'Vis delingsalternativer',
    },
    {
        key: 'hideSharingFacebook',
        description: 'Gjem deling på facebook',
    },
    {
        key: 'hideSharingInstagram',
        description: 'Gjem deling på instagram',
    },
    {
        key: 'hideProjectReporting',
        description: 'Gjem rapporteringsfunksjonen',
    },
    {
        key: 'hideEventSum',
        description: 'Gjem lenke til event-siden (nederst)',
    },
    {
        key: 'expandedNameAndComment',
        description: 'Ekspandér navn og kommentar på betalingssiden',
    },
    {
        key: 'showRewardsOnTop',
        description: 'Vis belønninger på toppen (valgfritt beløp nederst)',
    },
    {
        key: 'anonymousGiver',
        description: 'Bare anonyme givere',
    },
    {
        key: 'promotedGiverDisabled',
        description: 'Gjem promoterte givere',
    },
    {
        key: 'hideBanner',
        description: 'Gjem alle bannere',
    },
    {
        key: 'hideProjectForReceiver',
        description: 'Gavespleis (husk å gjøre innsamling hemmelig også)',
    },
    {
        key: 'hideSupportButton',
        description: 'Kan bare støtte med å kjøpe belønninger (ikke beløp)',
    },
    {
        key: 'disableAds',
        description: 'Skjul reklame',
    },
];

const ChangeProjectProfile = ({ project }) => {
    const [values, setValues] = useState({});

    useEffect(() => {
        const values = booleanFields.reduce((acc, curr) => {
            acc[curr.key] = project?.profile?.[curr.key] || false;
            return acc;
        }, {});
        setValues(values);
    }, [project]);

    const updateValue = (key, newValue) => {
        setValues({
            ...values,
            [key]: newValue,
        });
    };

    const onSubmit = () => {
        const changedValues = booleanFields.reduce((acc, curr) => {
            const valueFromProfile = project?.profile?.[curr.key] || false;
            const valueFromForm = values[curr.key];
            if (valueFromProfile !== valueFromForm) {
                acc[curr.key] = valueFromForm;
            }
            return acc;
        }, {});
        if (Object.keys(changedValues).length > 0) {
            api.post(`${spleisUrl}/api/admin/project/${project.id}/update-profile`, {
                profile: changedValues,
            })
                .then(() => {
                    showInfo('Endret profil');
                    window.notify();
                })
                .catch(() => {
                    showError('Klarte ikke å endre profil');
                });
        }
    };

    return (
        <FormButton title={`Profil`} onClick={onSubmit}>
            <div className={styles.container}>
                {Object.entries(values).map(([key, value]) => {
                    return (
                        <div key={key}>
                            <label className={styles.label}>
                                <input
                                    type="checkbox"
                                    checked={value}
                                    onChange={(e) => {
                                        updateValue(key, !value);
                                    }}
                                />
                                {booleanFields.find((booleanField) => booleanField.key === key).description}
                            </label>
                        </div>
                    );
                })}
            </div>
        </FormButton>
    );
};

export default ChangeProjectProfile;
