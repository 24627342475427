import auth0 from 'auth0-js';
import jwtDecode from 'jwt-decode';
import history from './history';
import store from './store';

const AUTH_CONFIG = {
    domain: 'app78143097.eu.auth0.com',
    clientId: '5xNC3FJ5e6x9c8jEm2ES-WKKeZSaZxLI',
    callbackUrl: window.location.protocol + '//' + window.location.host + '/callback',
    audience: 'https://app78143097.eu.auth0.com/userinfo',
};

class Auth {
    auth0 = new auth0.WebAuth({
        domain: AUTH_CONFIG.domain,
        clientID: AUTH_CONFIG.clientId,
        redirectUri: AUTH_CONFIG.callbackUrl,
        audience: AUTH_CONFIG.audience,
        responseType: 'token id_token',
        scope: 'openid profile email',
        leeway: 30,
    });

    userProfile;

    constructor() {
        this.login = this.login.bind(this);
        this.logout = this.logout.bind(this);
        this.handleAuthentication = this.handleAuthentication.bind(this);
        this.isAuthenticated = this.isAuthenticated.bind(this);
        this.getIdToken = this.getIdToken.bind(this);
        this.getProfile = this.getProfile.bind(this);
    }

    login() {
        localStorage.setItem('redirectPath', history.location.pathname);
        this.auth0.authorize();
    }

    handleAuthentication() {
        this.auth0.parseHash((err, authResult) => {
            if (authResult && authResult.idToken) {
                this.setSession(authResult);
            } else if (err) {
                window.location = '/';
                console.log(err);
            }
        });
    }

    setSession(authResult) {
        // Orginal er expires_at = JSON.stringify(authResult.expiresIn + new Date().getTime());
        // Så lenger vi kjører uten audience vil authResult.expiresIn være 7200s for id-token, bruk derfor jwt.exp
        // Se https://community.auth0.com/questions/5128/accesstoken-expiration
        localStorage.setItem('id_token', authResult.idToken);
        const decoded = jwtDecode(authResult.idToken);
        localStorage.setItem('expires_at', decoded.exp * 1000);

        const redirectTo = localStorage.getItem('redirectPath') || '/';
        localStorage.removeItem('redirectPath');
        history.replace(redirectTo);
        store.dispatch({ type: 'LOGIN_SUCCESSFUL', payload: decoded });
    }

    getIdToken() {
        return localStorage.getItem('id_token');
    }

    getProfile() {
        let idToken = this.getIdToken();
        if (idToken) {
            return jwtDecode(idToken);
        } else {
            return {};
        }
    }

    getProfileIfPresent() {
        const idToken = localStorage.getItem('id_token');
        return idToken ? jwtDecode(idToken) : null;
    }

    logout() {
        // Clear access token and ID token from local storage
        localStorage.removeItem('id_token');
        localStorage.removeItem('expires_at');
        this.userProfile = null;
        // navigate to the home route
        window.location = '/';
    }

    isAuthenticated() {
        // Check whether the current time is past the
        // access token's expiry time
        let expiresAt = JSON.parse(localStorage.getItem('expires_at'));
        return new Date().getTime() < expiresAt;
    }
}

export const auth = new Auth();
