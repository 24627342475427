import React, { Component } from 'react';
import moment from 'moment';
import AccountingTable from './AccountingTable';
import { Helmet } from 'react-helmet';

export default class UnmappedDate extends Component {
    render() {
        return (
            <>
                <Helmet>
                    <title>Ikke-mappede bankutskrifter</title>
                </Helmet>
                <AccountingTable
                    url={'unmapped-bank-settlements'}
                    columns={[
                        { Header: 'Id', accessor: 'id', minResizeWidth: 30 },
                        {
                            Header: 'Dato',
                            accessor: 'date',
                            minResizeWidth: 30,
                            minWidth: 180,
                            Cell: (row) => <span>{moment(row.value).format('lll')}</span>,
                        },
                        {
                            Header: 'Beskrivelse',
                            accessor: 'description',
                            minResizeWidth: 30,
                            minWidth: 500,
                        },
                        {
                            Header: 'Inn',
                            accessor: 'in',
                            minResizeWidth: 30,
                            Cell: (row) => <span>{Math.round(row.value).toLocaleString()}</span>,
                        },
                        {
                            Header: 'Ut',
                            accessor: 'out',
                            minResizeWidth: 30,
                            Cell: (row) => <span>{Math.round(row.value).toLocaleString()}</span>,
                        },
                        {
                            Header: 'Bank',
                            minResizeWidth: 30,
                            accessor: 'source_bank',
                        },
                    ]}
                    sorted={[{ id: 'date', desc: true }]}
                />
            </>
        );
    }
}
