import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import api from '../api';
import history from '../history';
import { showError } from '../store';
import { spleisUrl } from '../config';
import TaskContainer from '../TaskContainer/TaskContainer';
import queryString from 'qs';
import { Helmet } from 'react-helmet';
const Input = ({ type, label, value, state, onChange, onBlur, help, ...props }) => (
    <div className={'formgroup' + (state ? ` has-${state}` : '')}>
        <label className="col-sm-4 control-label">{label}</label>
        <div className="col-sm-8">
            <input
                type={type}
                className="form-control"
                value={value || ''}
                onChange={onChange}
                onBlur={onBlur}
                {...props}
            />
            <span className="help-block">{help}</span>
        </div>
    </div>
);

export const Submit = ({ label, onClick, disabled }) => (
    <div className="form-group">
        <div className="col-sm-8 col-sm-offset-4">
            <button className="btn btn-primary" disabled={disabled} onClick={onClick}>
                {label}
            </button>
        </div>
    </div>
);

const emptyInvoice = {
    name: {},
    email: {},
    organizationIdentifier: {},
    postalCode: {},
    postalPlace: {},
    address1: {},
    amount: {},
    projectId: {},
    comment: {},
    nameGiverliste: {},
    yourReference: {},
};

class InvoiceCreate extends Component {
    constructor(props) {
        super(props);
        const query = queryString.parse(this.props.location.search.slice(1));
        this.state = {
            ...emptyInvoice,
            organizationIdentifier: { value: query.orgNumber },
            comment: { value: query.comment },
            amount: { value: query.amount },
            email: { value: query.email },
            matchingGiftId: query.matchingGiftId,
            sending: false,
        };
        if (query.orgNumber) {
            this.updateWithOrganizationValues(query.orgNumber);
        }
    }

    updateWithOrganizationValues(organizationNumber) {
        api.get(`https://data.brreg.no/enhetsregisteret/api/enheter/${organizationNumber}`)
            .then((res) => {
                this.setState({
                    name: { value: res.navn, state: 'success' },
                    address1: { value: res.forretningsadresse.adresse, state: 'success' },
                    postalCode: { value: res.forretningsadresse.postnummer, state: 'success' },
                    postalPlace: { value: res.forretningsadresse.poststed, state: 'success' },
                });
            })
            .catch(() => {
                this.setState({
                    organizationIdentifier: {
                        ...this.state.organizationIdentifier,
                        state: 'error',
                        help: 'Fant ikke orgnummer i brreg',
                    },
                });
            });
    }

    onChange = (field, value) => {
        let state = 'success';
        let help;
        if (field === 'email' && !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
            state = 'error';
            help = 'Ugyldig e-post adresse';
        }
        if (field === 'organizationIdentifier' && value && !/^[0-9]{9}$/.test(value)) {
            state = 'error';
            help = 'Org.nummer må være 9 siffer';
        }
        this.setState({ [field]: { value, state, help } });
        if (field === 'projectId' && value.length > 0) {
            api.get(`${spleisUrl}/api/admin/project/${value}`)
                .then((res) => {
                    this.setState({
                        projectId: { value: this.state.projectId.value, state: 'success', help: res.title },
                    });
                })
                .catch(() => {
                    this.setState({
                        projectId: { ...this.state.projectId, state: 'error', help: 'Fant ikke prosjekt' },
                    });
                });
        }
        if (field === 'organizationIdentifier' && value.length === 9) {
            this.updateWithOrganizationValues(value);
        }
    };
    input = (field, label, type, props) => (
        <Input
            type={type}
            label={label}
            onChange={(e) => this.onChange(field, e.target.value)}
            onBlur={(e) => this.onChange(field, e.target.value.trim())}
            value={this.state[field].value}
            state={this.state[field].state}
            help={this.state[field].help}
            {...props}
        />
    );
    onSubmit = (e) => {
        e.preventDefault();
        this.setState({ sending: true });
        const invoice = this.state;
        const request = {
            customerInfo: {
                name: invoice.name.value,
                email: invoice.email.value,
                organizationIdentifier: invoice.organizationIdentifier.value,
                address: {
                    postalCode: invoice.postalCode.value,
                    postalPlace: invoice.postalPlace.value,
                    address1: invoice.address1.value,
                    country: 'Norge',
                },
                language: 'NORWEGIAN',
                customer: true,
            },
            amount: invoice.amount.value,
            projectId: invoice.projectId.value,
            comment: invoice.comment.value,
            nameGiverliste: invoice.nameGiverliste.value,
            yourReference: invoice.yourReference.value,
            ...(this.state.matchingGiftId
                ? { transactionMetadata: { matchingGiftId: this.state.matchingGiftId } }
                : {}),
        };
        api.post(`${spleisUrl}/api/admin/invoice/`, request)
            .then((res) => history.push(`/invoice/${res.id}`))
            .catch((err) => {
                this.setState({ sending: false });
                showError(`Feil ved oppretting av faktura. ${err}`);
            });
    };
    render() {
        const invoice = this.state;
        const ok = true; //Object.keys(invoice).every(field => invoice[field].state === 'success');
        return (
            <TaskContainer detail={<pre>{JSON.stringify(invoice, null, 2)}</pre>}>
                <Helmet>
                    <title>Opprett faktura</title>
                </Helmet>
                <header className="task-header">
                    <h1 className={'task-title'}>Opprett faktura</h1>
                </header>
                <Link className="back-link" to="/invoice/">
                    &larr; Tilbake
                </Link>
                <div className="task-body">
                    <form className="form-horizontal">
                        {this.input('projectId', 'Prosjekt ID (eller tom hvis samlefaktura)', 'number')}
                        {this.input('amount', 'Beløp', 'number')}
                        {this.input('email', 'E-post', 'text')}
                        {this.input('organizationIdentifier', 'Orgnummer', 'number', { max: '999999999' })}
                        {this.input('name', 'Navn', 'text')}
                        {this.input('address1', 'Adresse', 'text')}
                        {this.input('postalCode', 'Postnummer', 'number', { max: '9999' })}
                        {this.input('postalPlace', 'Poststed', 'text')}
                        {this.input('comment', 'Kommentar', 'text')}
                        {this.input('nameGiverliste', 'Navn på giverliste?', 'text')}
                        {this.input('yourReference', 'Din referanse', 'text')}
                        <Submit label="Opprett faktura" disabled={!ok || this.state.sending} onClick={this.onSubmit} />
                    </form>
                </div>
            </TaskContainer>
        );
    }
}

export default InvoiceCreate;
