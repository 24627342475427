import React from 'react';
import ShowMore from '../ShowMore/ShowMore';
import { formatMoneyKr } from '../formatters';

const SubscriptionTypeList = ({ subscriptionTypeList, description, title }) => {
    if (subscriptionTypeList?.length === 0) {
        return (
            <div className="subscription-table-container">
                <h2 title={description}>
                    {title}
                    {description ? ' (i)' : ''}
                </h2>
                <p>Ingen abonnementstyper</p>
            </div>
        );
    }

    return (
        <div className="subscription-table-container">
            <h2 title={description}>
                {title}
                {description ? ' (i)' : ''}
            </h2>

            <ShowMore
                items={subscriptionTypeList}
                initialShowCount={50}
                listCreator={showMoreItems => {
                    return (
                        <table className={'invoice-table table table-striped'}>
                            <thead>
                                <tr>
                                    <th>Type</th>
                                    <th>Visningsnavn</th>
                                    <th>Pris</th>
                                    <th>Tilgjengelig</th>
                                </tr>
                            </thead>
                            <tbody>
                                {showMoreItems.map(subscriptionType => {
                                    return (
                                        <tr key={subscriptionType.id}>
                                            <td>
                                                <a href={`/subscription-type/${subscriptionType.name}`}>{subscriptionType.name}</a>
                                            </td>
                                            <td>
                                                {subscriptionType.display_title}
                                            </td>
                                            <td className={'subscription-number'}>{formatMoneyKr(subscriptionType?.price)}</td>
                                            <td>{subscriptionType?.available ? 'Ja' : 'Nei'}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    );
                }}
            />
        </div>
    );
};
export default SubscriptionTypeList;
