import VisibleIf from '../../VisibleIf/VisibleIf';
import ShowMore from '../../ShowMore/ShowMore';
import moment from 'moment';
import { DATE_FORMAT } from '../../dates';
import styles from './ProjectUpdates.module.scss';

const ProjectUpdates = ({ data }) => {
    const sortedUpdates = (data || []).sort((a, b) => moment(b.created_at).diff(moment(a.created_at)));

    return (
        <div>
            <div className="project-table-data">
                <header className="project-table-data-header">
                    <h2>Oppdateringer</h2>
                    <div>{sortedUpdates.length} oppdateringer</div>
                </header>
                <VisibleIf isVisible={sortedUpdates.length > 0}>
                    <ShowMore
                        items={sortedUpdates}
                        initialShowCount={3}
                        listCreator={(showMore) => {
                            return (
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>Dato</th>
                                            <th>Tittel</th>
                                            <th>Tekst</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {showMore.map((update) => {
                                            return (
                                                <tr key={update.id}>
                                                    <td>{moment(update.created_at).format(DATE_FORMAT)}</td>
                                                    <td>{update.title}</td>
                                                    <td className={styles.description}>{update.description}</td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            );
                        }}
                    />
                </VisibleIf>
            </div>
        </div>
    );
};

export default ProjectUpdates;
