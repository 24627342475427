import React from 'react';
import { Link, Route, Switch, useParams, withRouter } from 'react-router-dom';
import './Stats.scss';
import ProjectStats from './ProjectStats';
import PotentialIncome from './PotentialIncome';
import MainContainer from '../MainContainer/MainContainer';
import TaskContainer from '../TaskContainer/TaskContainer';
import Categories from './Categories/Categories';
import HelmetForSubpages from '../HelmetForSubpages/HelmetForSubpages';
import TagsStats from './Tags/TagsStats';
import ProjectsPausedOrDeleted from './ProjectDeletedReasons/ProjectsPausedOrDeleted';

const Stats = () => {
    const params = useParams();

    const currentSubpage = params?.slug;
    const isSelected = (page) => {
        return currentSubpage === page;
    };

    return (
        <MainContainer>
            <HelmetForSubpages title="Statistikk" />
            <div className={`task-list-container ${!currentSubpage ? 'block' : ''}`}>
                <div className="task-list-wrapper">
                    <header className="tasks-header">
                        <h2 className="stats-header">Statistikk</h2>
                    </header>
                    <ul className="task-list stats-task-list">
                        <li
                            className={`stats-menu-item ${
                                isSelected('/stats/project-stats/') ? 'stats-menu-item-selected' : ''
                            }`}
                        >
                            <Link className={'stats-menu-item'} to={`/stats/project-stats`}>
                                Prosjekter
                            </Link>
                        </li>
                        <li
                            className={`stats-menu-item ${
                                isSelected('/stats/potential-income/') ? 'stats-menu-item-selected' : ''
                            }`}
                        >
                            <Link className={'stats-menu-item'} to={`/stats/categories`}>
                                Kategorier
                            </Link>
                        </li>
                        <li
                            className={`stats-menu-item ${
                                isSelected('/stats/tags/') ? 'stats-menu-item-selected' : ''
                            }`}
                        >
                            <Link className={'stats-menu-item'} to={`/stats/tags`}>
                                Tags
                            </Link>
                        </li>
                        <li
                            className={`stats-menu-item ${
                                isSelected('/stats/paused-or-deleted/') ? 'stats-menu-item-selected' : ''
                            }`}
                        >
                            <Link className={'stats-menu-item'} to={`/stats/paused-or-deleted`}>
                                Pauset eller slettet
                            </Link>
                        </li>
                        <li
                            className={`stats-menu-item ${
                                isSelected('/stats/potential-income/') ? 'stats-menu-item-selected' : ''
                            }`}
                        >
                            <Link className={'stats-menu-item'} to={`/stats/potential-income`}>
                                Ikke utbetalt inntekt
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
            <TaskContainer>
                <Switch>
                    <Route path="/stats/project-stats" component={ProjectStats} />
                    <Route path="/stats/potential-income" component={PotentialIncome} />
                    <Route path="/stats/categories" component={Categories} />
                    <Route path="/stats/tags" component={TagsStats} />
                    <Route path="/stats/paused-or-deleted" component={ProjectsPausedOrDeleted} />
                </Switch>
            </TaskContainer>
        </MainContainer>
    );
};

export default withRouter(Stats);
