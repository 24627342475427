import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import moment from 'moment';

const Filter = ({
    project,
    fromDate,
    toDate,
    handleInputChange,
    handleInputChangeAndSearch,
    performSearch,
    downloadCSV,
    showProjectFilter = true,
}) => {
    return (
        <div className="accounting-filter">
            {showProjectFilter && (
                <label>
                    Prosjekt
                    <input
                        name={'project'}
                        value={project}
                        onChange={handleInputChange}
                        onKeyPress={e => {
                            if (e.key === 'Enter') {
                                performSearch();
                            }
                        }}
                    />
                </label>
            )}
            <label>
                Fra og med dato
                <DatePicker
                    selected={fromDate}
                    onChange={date => {
                        handleInputChangeAndSearch({
                            target: {
                                name: 'fromDate',
                                value: date,
                            },
                        });
                    }}
                />
            </label>
            <label>
                Til og med dato
                <DatePicker
                    utcOffset={moment().utcOffset()}
                    selected={toDate}
                    onChange={date => {
                        handleInputChangeAndSearch({
                            target: {
                                name: 'toDate',
                                value: date,
                            },
                        });
                    }}
                />
            </label>
            <button onClick={performSearch}>Søk</button>
            <button onClick={downloadCSV}>CSV/Excel</button>
            (Du får tilsendt csv-filen på e-post)
        </div>
    );
};

Filter.propTypes = {
    project: PropTypes.string,
    fromDate: PropTypes.object,
    toDate: PropTypes.object,
    handleInputChange: PropTypes.func.isRequired,
    performSearch: PropTypes.func.isRequired,
};

export default Filter;
