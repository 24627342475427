import React, { useContext } from 'react';
import { spleisUrl } from '../../config';
import api from '../../api';
import { showError, showInfo } from '../../store';
import ProjectContext from '../project-context';
import ConfirmButton from '../../ConfirmButton/ConfirmButton';

const HideGiversAndComments = () => {
    const project = useContext(ProjectContext);

    const hideGiversAndComments = async () => {
        try {
            await api.post(`${spleisUrl}/api/admin/project/${project.id}/hide-givers-and-comments`);
            showInfo('Alle givere er gjort anonyme og kommentarene er skjult!');
        } catch (err) {
            showError(`Feil ved gjøring av givere anonyme og skjuling av kommentarer: ${err}`);
        }
        window.notify();
    };

    return (
        <ConfirmButton
            onClick={hideGiversAndComments}
            title={'Skjul givere og kommentarer'}
            successText={'Skjul alle'}
            message={
                'Dette vil gjøre alle givere anonyme og skjule alle kommentarer. Fremtidige givere vil også være anonyme.'
            }
        />
    );
};

export default HideGiversAndComments;
