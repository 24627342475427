import React from 'react';
import { DATE_FORMAT } from '../dates';
import Linkify from 'react-linkify';
import moment from 'moment/moment';
import { auth } from '../auth';
import ButtonLink from '../ButtonLink/ButtonLink';

export const Comment = ({ comment, deleteComment }) => {
    const currentUser = auth.getProfile();
    const userOwnsComment = currentUser.email === comment.user_email;
    const createdAt = moment(comment.created_at);
    return (
        <>
            <div className="comment-meta">
                <span title={comment.user_email}>{comment.user_email.split('@spleis.no').join('')}</span>{' '}
                <span className="comment-time" title={`${createdAt.fromNow()}, ${createdAt.format(DATE_FORMAT)}`}>
                    {createdAt.format(DATE_FORMAT)}
                </span>
            </div>
            <div className={`comment-container ${userOwnsComment ? 'comment-owner' : ''}`}>
                <Linkify properties={{ target: '_blank' }}>
                    <pre className="comment-body">{comment.comment}</pre>
                </Linkify>
            </div>
            {userOwnsComment && (
                <div className="comment-delete">
                    (<ButtonLink onClick={deleteComment}>Slett kommentar</ButtonLink>)
                </div>
            )}
        </>
    );
};
