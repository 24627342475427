import React from 'react';
import { spleisUrl } from '../config';
import './ProjectStats.scss';
import { Search } from '../Menu/Menu';

const ProjectStatsOrganizations = ({ organizations = [], addOrganization, removeOrganization }) => {
    return (
        <div>
            <div>
                <div className="stats-section-mini">
                    <Search
                        className="stats-search-field"
                        id="org-search-box"
                        searchUrl={`${spleisUrl}/api/admin/organization/search`}
                        placeholder="Finn org"
                        onClick={(id) => addOrganization(id)}
                        selectedItem={(option) => <div>{option.object.title ? option.object.title : ''}</div>}
                        listItem={(option) => (
                            <div>
                                <div>{option.object.name ? option.object.name : 'Ukjent'}</div>
                                <div style={{ fontSize: '0.8em', color: '#676767' }}>
                                    {option.object.organization_number} (#
                                    {option.object.id})
                                </div>
                            </div>
                        )}
                    />
                </div>
                <div>
                    {organizations.length > 0 && <p>Klikk på organisasjons-id for å fjerne</p>}
                    <ul className="stats-organizations-list">
                        {organizations.map((org) => (
                            <li
                                key={org}
                                title={org}
                                className="stats-organization"
                                onClick={() => removeOrganization(org)}
                            >
                                {org}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default ProjectStatsOrganizations;
