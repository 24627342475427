import { Link, Switch, Route, withRouter } from 'react-router-dom';
import React, { Component } from 'react';
import MappedData from './MappedData';
import './Accounting.scss';
import UnmappedData from './UnmappedData';
import UnmappedRefunds from './UnmappedRefunds';
import UnmappedBankSettlements from './UnmappedBankSettlements';
import CsvFiles from './CsvFiles';
import Events from './Events';
import MapInvoices from './MapInvoices';
import AccountingErrors from './AccountingErrors';
import RegisterAccountingError from './RegisterAccountingError/RegisterAccountingError';
import EventsProject from './EventsProject';
import Avstemming from './Avstemming';
import TotalAvstemming from './TotalAvstemming';
import HelmetForSubpages from '../HelmetForSubpages/HelmetForSubpages';
import DugnadsProdukter from './DugnadsProdukter';
import PayoutFileList from "../PayoutFiles/PayoutFileList";
import PayoutFile from "../PayoutFiles/PayoutFile";
import GavekortFIG from "./GavekortFIG";

class Accounting extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    isSelected(page) {
        return this.props.location.pathname.startsWith(page);
    }

    render() {
        return (
            <div className="accounting-main-container">
                <HelmetForSubpages title="Regnskap" />
                <div className="accounting-container">
                    <div className="accounting-list-wrapper">
                        <header className="accounting-header">Regnskap</header>
                        <ul className="accounting-list">
                            <li>
                                <Link
                                    className={`accounting-menu-item ${
                                        this.isSelected('/accounting/events/') ? 'accounting-menu-item-selected' : ''
                                    }`}
                                    to={`/accounting/events/${this.props.location.search}`}
                                >
                                    Pengeflyt
                                </Link>
                            </li>
                            <li>
                                <Link
                                    className={`accounting-menu-item ${
                                        this.isSelected('/accounting/total-avstemming/')
                                            ? 'accounting-menu-item-selected'
                                            : ''
                                    }`}
                                    to={`/accounting/total-avstemming/${this.props.location.search}`}
                                >
                                    Totalavstemming
                                </Link>
                            </li>
                            <li>
                                <Link
                                    className={`accounting-menu-item ${
                                        this.isSelected('/accounting/avstemming/')
                                            ? 'accounting-menu-item-selected'
                                            : ''
                                    }`}
                                    to={`/accounting/avstemming/${this.props.location.search}`}
                                >
                                    Avstemming
                                </Link>
                            </li>
                            <li>
                                <Link
                                    className={`accounting-menu-item ${
                                        this.isSelected('/accounting/mapped/') ? 'accounting-menu-item-selected' : ''
                                    }`}
                                    to={`/accounting/mapped/${this.props.location.search}`}
                                >
                                    Mappede transaksjoner
                                </Link>
                            </li>
                            <li>
                                <Link
                                    className={`accounting-menu-item ${
                                        this.isSelected('/accounting/unmapped/') ? 'accounting-menu-item-selected' : ''
                                    }`}
                                    to={`/accounting/unmapped/${this.props.location.search}`}
                                >
                                    Ikke-mappede transaksjoner
                                </Link>
                            </li>
                            <li>
                                <Link
                                    className={`accounting-menu-item ${
                                        this.isSelected('/accounting/unmapped-refunds/')
                                            ? 'accounting-menu-item-selected'
                                            : ''
                                    }`}
                                    to={`/accounting/unmapped-refunds/${this.props.location.search}`}
                                >
                                    Ikke-mappede refusjoner
                                </Link>
                            </li>
                            <li>
                                <Link
                                    className={`accounting-menu-item ${
                                        this.isSelected('/accounting/unmapped-bank-settlements/')
                                            ? 'accounting-menu-item-selected'
                                            : ''
                                    }`}
                                    to={`/accounting/unmapped-bank-settlements/${this.props.location.search}`}
                                >
                                    Ikke-mappede bankutskrifter
                                </Link>
                            </li>
                            <li>
                                <Link
                                    className={`accounting-menu-item ${
                                        this.isSelected('/accounting/map-invoices/')
                                            ? 'accounting-menu-item-selected'
                                            : ''
                                    }`}
                                    to={`/accounting/map-invoices/${this.props.location.search}`}
                                >
                                    Map faktura
                                </Link>
                            </li>
                            <li>
                                <Link
                                    className={`accounting-menu-item ${
                                        this.isSelected('/accounting/register-accounting-error/')
                                            ? 'accounting-menu-item-selected'
                                            : ''
                                    }`}
                                    to={`/accounting/register-accounting-error/${this.props.location.search}`}
                                >
                                    Registrer avvik
                                </Link>
                            </li>

                            <li>
                                <Link
                                    className={`accounting-menu-item ${
                                        this.isSelected('/accounting/accounting-errors/')
                                            ? 'accounting-menu-item-selected'
                                            : ''
                                    }`}
                                    to={`/accounting/accounting-errors/${this.props.location.search}`}
                                >
                                    Avvik
                                </Link>
                            </li>
                            <li>
                                <Link
                                    className={`accounting-menu-item ${
                                        this.isSelected('/accounting/events-project/')
                                            ? 'accounting-menu-item-selected'
                                            : ''
                                    }`}
                                    to={`/accounting/events-project`}
                                >
                                    Summer pr prosjekt
                                </Link>
                            </li>
                            <li>
                                <Link
                                    className={`accounting-menu-item ${
                                        this.isSelected('/accounting/fig-gift-cards/')
                                            ? 'accounting-menu-item-selected'
                                            : ''
                                    }`}
                                    to={`/accounting/fig-gift-cards/${this.props.location.search}`}
                                >
                                    Gavekort FIG
                                </Link>
                            </li>
                            <li>
                                <Link
                                    className={`accounting-menu-item ${
                                        this.isSelected('/accounting/dugnad-produkter/')
                                            ? 'accounting-menu-item-selected'
                                            : ''
                                    }`}
                                    to={`/accounting/dugnad-produkter/${this.props.location.search}`}
                                >
                                    Dugnadsprodukter
                                </Link>
                            </li>
                            <li>
                                <Link
                                    className={`accounting-menu-item ${
                                        this.isSelected('/accounting/csv-files') ? 'accounting-menu-item-selected' : ''
                                    }`}
                                    to={`/accounting/csv-files`}
                                >
                                    CSV-filer
                                </Link>
                            </li>
                            <li>
                                <Link
                                    className={`accounting-menu-item ${
                                        this.isSelected('/accounting/payout-files') ? 'accounting-menu-item-selected' : ''
                                    }`}
                                    to={`/accounting/payout-files/`}
                                >
                                    Utbetalingsfiler
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="accounting-table">
                    <Switch>
                        <Route path="/accounting/mapped" component={MappedData} />
                        <Route path="/accounting/unmapped" component={UnmappedData} />
                        <Route path="/accounting/unmapped-refunds" component={UnmappedRefunds} />
                        <Route path="/accounting/events" component={Events} />
                        <Route path="/accounting/events-project" component={EventsProject} />
                        <Route path="/accounting/unmapped-bank-settlements" component={UnmappedBankSettlements} />
                        <Route path="/accounting/map-invoices" component={MapInvoices} />
                        <Route path="/accounting/csv-files" component={CsvFiles} />
                        <Route path="/accounting/register-accounting-error" component={RegisterAccountingError} />
                        <Route path="/accounting/accounting-errors" component={AccountingErrors} />
                        <Route path="/accounting/avstemming" component={Avstemming} />
                        <Route path="/accounting/total-avstemming" component={TotalAvstemming} />
                        <Route path="/accounting/fig-gift-cards" component={GavekortFIG} />
                        <Route path="/accounting/dugnad-produkter" component={DugnadsProdukter} />
                        <Route path="/accounting/payout-files/:id" component={PayoutFile} />
                        <Route path="/accounting/payout-files/" component={PayoutFileList} />
                        <Route path="/accounting" component={Events} />
                    </Switch>
                </div>
            </div>
        );
    }
}

export default withRouter(Accounting);
