import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import moment from 'moment';
import 'moment/locale/nb';
import { Link } from 'react-router-dom';
import { formatMoney, formatMoneyKr } from '../formatters';
import { formatDate } from '../dates';
import './TaskList.scss';

moment.locale('nb');

const TaskList = ({ selected, link, project, displayDateFn }) => {
    const taskElement = useRef(null);

    useEffect(() => {
        if (selected) {
            const currentTask = ReactDOM.findDOMNode(taskElement.current);
            currentTask.parentElement.scrollTop = currentTask.offsetTop;
        }
    }, []);

    const { id, title, collected_amount, moneygoal, ends_at } = project;

    function displayDate() {
        if (displayDateFn) {
            return displayDateFn(project)
        }
        const endsAtMoment = moment(ends_at);
        const word = endsAtMoment.isBefore(moment()) ? 'Avsluttet' : 'Avsluttes';
        return `${word}: ${endsAtMoment.fromNow()}`;
    }

    const collectedClassName = collected_amount > 0 ? '-collected' : '-none-collected';

    return (
        <li className={`task-list-item-container${selected ? ' task-selected' : ''}`} ref={taskElement}>
            <Link className="task-list-item" to={link}>
                <div className="task-list-item-id">#{id}</div>
                <div>
                    <h4 className="task-list-item-title">{title}</h4>
                </div>
                <div className="task-list-item-money">
                    {moneygoal && parseInt(moneygoal, 0) ? (
                        <>
                            <span className={`task-list-item-money${collectedClassName}`}>
                                {formatMoney(collected_amount)}
                            </span>
                            <span className="task-list-item-money-kr"> av {formatMoneyKr(moneygoal)}</span>
                        </>
                    ) : (
                        <>
                            <span className={`task-list-item-money${collectedClassName}`}>
                                {formatMoney(collected_amount)}
                            </span>
                            <span className="task-list-item-money-kr"> kr</span>
                        </>
                    )}
                </div>
                <div className="task-list-item-time" title={formatDate(ends_at)}>
                    {displayDate()}
                </div>
            </Link>
        </li>
    );
};

export default TaskList;
