import React from 'react';
import ProjectLink from '../SmartLinks/ProjectLink';
import OrganizationLink from '../SmartLinks/OrganizationLink';
import { formatMoneyKr } from '../formatters';
import Button from '../Button/Button';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { formatDateOnly } from '../dates';

const MatchingGift = (mg) => {
    const history = useHistory();

    return (
        <tr className={mg.selected ? 'selected-matching-gift' : null}>
            <td>
                <ProjectLink id={mg.project_id}>{mg.title}</ProjectLink>
            </td>
            <td>
                <OrganizationLink id={mg.org_id}>{mg.organization.name}</OrganizationLink>
            </td>
            <td>{formatMoneyKr(mg.max_amount)}</td>
            <td>{formatMoneyKr(mg.collected_amount)}</td>
            <td>{mg.transaction || mg.profile?.paidAmount ? '✅' : '❌'}</td>
            <td>
                {mg.invoice ? (
                    (!mg.invoice.sent_at && <Link to={`/invoice/${mg.invoice.id}`}>Ikke sendt</Link>) ||
                    (!mg.invoice.paid_at && <Link to={`/invoice/${mg.invoice.id}`}>Ikke betalt</Link>) || (
                        <Link to={`/invoice/${mg.invoice.id}`}>Betalt {formatDateOnly(mg.invoice.paid_at)}</Link>
                    )
                ) : mg.shouldSendInvoice ? (
                    <Button
                        onClick={() =>
                            history.push(
                                `/invoiceCreate?matchingGiftId=${mg.id}&amount=${Math.min(
                                    mg.collected_amount,
                                    mg.max_amount
                                )}&orgNumber=${
                                    mg.organization.organization_number
                                }&comment=Faktura for Matching Gift på https://spleis.no/${mg.project_id}&email=${
                                    mg.email || ''
                                }`
                            )
                        }
                    >
                        Send faktura
                    </Button>
                ) : !!mg.transaction_id || mg.profile?.paidAmount ? (
                    'Betalt'
                ) : (
                    'Ikke klar for faktura'
                )}
            </td>
        </tr>
    );
};

export default MatchingGift;
