import { createContext, useCallback, useEffect, useState } from 'react';
import api from './api';
import { spleisUrl } from './config';
import { emailHasSpleisDomain } from './index';
import { showError } from './store';

export const FavoriteOrganizationsContext = createContext({});

const useFavoriteOrganizations = ({ user }) => {
    const [organizationIds, setOrganizationIds] = useState([]);

    useEffect(() => {
        if (emailHasSpleisDomain(user)) {
            api.get(`${spleisUrl}/api/admin/favorite-organization`)
                .then((data) => setOrganizationIds(data.organizationIds))
                .catch((err) => showError('Klarte ikke å hente favorittorganisasjoner'));
        }
    }, [user]);

    const add = useCallback((organizationId) => {
        api.post(`${spleisUrl}/api/admin/favorite-organization/${organizationId}`)
            .then((data) => {
                setOrganizationIds(data.organizationIds);
                window.notify();
            })
            .catch((err) => showError('Klarte ikke å lagre favorittorganisasjon'));
    }, []);

    const remove = useCallback((organizationId) => {
        api.remove(`${spleisUrl}/api/admin/favorite-organization/${organizationId}`)
            .then((data) => {
                setOrganizationIds(data.organizationIds);
                window.notify();
            })
            .catch((err) => showError('Klarte ikke å fjerne favorittorganisasjon'));
    }, []);

    return {
        organizationIds: organizationIds,
        add,
        remove,
    };
};

export default useFavoriteOrganizations;
