import React, { Component } from 'react';
import ShowMore from '../ShowMore/ShowMore';
import UserLink from '../SmartLinks/UserLink';
import ProjectLink from '../SmartLinks/ProjectLink';
import { formatMoney } from '../formatters';

class InvoiceList extends Component {
    constructor(props) {
        super(props);
        this.state = { visibleTable: this.props.visibleTable };
    }

    printDate(d) {
        return d ? new Date(d).toLocaleString() : null;
    }

    render() {
        const invoices = this.props.invoices.sort(
            (a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
        );

        return (
            <div className="invoice-table-container">
                <h2 title={this.props.description}>
                    {this.props.title}
                    {this.props.description ? ' (i)' : ''}
                </h2>

                <ShowMore
                    items={invoices}
                    listCreator={showMoreItems => {
                        return (
                            <table className={'invoice-table table table-striped'}>
                                <thead>
                                    <tr>
                                        <th>Faktura</th>
                                        <th>Tidspunkt</th>
                                        <th>Prosjekt</th>
                                        <th>Belønning</th>
                                        <th className="invoice-table-amount">Beløp</th>
                                        <th>Bruker</th>
                                        <th>Sendt</th>
                                        <th>Betalt</th>
                                        <th>Kreditert</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {showMoreItems.map(t => {
                                        return (
                                            <tr key={t.id}>
                                                <td>
                                                    <a href={`/invoice/${t.id}`}>{t.invoice_number || 'Til faktura'}</a>
                                                </td>
                                                <td>{this.printDate(t.created_at)}</td>
                                                <td>
                                                    <ProjectLink id={t.to_project} />
                                                </td>
                                                <td>{t.reward_id}</td>
                                                <td className="invoice-table-amount">{formatMoney(t.amount)}</td>
                                                <td>
                                                    <UserLink id={t.from_user} />
                                                </td>
                                                <td>{this.printDate(t.sent_at)}</td>
                                                <td>{this.printDate(t.paid_at)}</td>
                                                <td>{this.printDate(t.creditnote_at)}</td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        );
                    }}
                />
            </div>
        );
    }
}
export default InvoiceList;
