import React, { useState } from 'react';
import Button from '../../Button/Button';
import api from '../../api';
import { spleisUrl } from '../../config';
import { showError, showInfo } from '../../store';
import styles from './ApproveProject.module.scss';

const ApproveProject = ({ project, className = '' }) => {
    const [processing, setProcessing] = useState(false);

    const approveProject = () => {
        setProcessing(true);
        api.post(`${spleisUrl}/api/admin/project/${project.id}/approve`)
            .then(() => {
                setProcessing(false);
                showInfo('Prosjekt godkjent');
                window.notify();
            })
            .catch(() => {
                setProcessing(false);
                showError('Klarte ikke å godkjenne prosjekt');
            });
    };
    return project.approved ? (
        <div className={styles.approved}>Godkjent</div>
    ) : (
        <Button
            className={[className, styles.approveProjectButton].join(' ')}
            disabled={processing}
            onClick={approveProject}
        >
            Godkjenn
        </Button>
    );
};

export default ApproveProject;
