import React from 'react';
import { Link, Route, Switch } from 'react-router-dom';
import MainContainer from '../MainContainer/MainContainer';
import TaskContainer from '../TaskContainer/TaskContainer';
import './Recipients.scss';
import { MissingInfoOrg } from './MissingInfoOrg';
import HelmetForSubpages from '../HelmetForSubpages/HelmetForSubpages';
import { UnacceptedByOrg } from './UnacceptedByOrg';

export const Recipients = () => {
    return (
        <MainContainer>
            <HelmetForSubpages title="Mottakere" />
            <div className={`task-list-container`}>
                <div className="task-list-wrapper">
                    <header className="tasks-header">
                        <h2 className="stats-header">Mottakere</h2>
                    </header>
                    <ul className="task-list stats-task-list">
                        <li className={`stats-menu-item`}>
                            <Link className={'stats-menu-item'} to={`/recipients/missing-info-org`}>
                                Mangler info - org
                            </Link>
                        </li>
                        <li className={`stats-menu-item`}>
                            <Link className={'stats-menu-item'} to={`/recipients/unaccepted-by-org`}>
                                Ikke bekreftet - org
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
            <TaskContainer>
                <Switch>
                    <Route path="/recipients/missing-info-org" component={MissingInfoOrg} />
                    <Route path="/recipients/unaccepted-by-org" component={UnacceptedByOrg} />
                </Switch>
            </TaskContainer>
        </MainContainer>
    );
};
