import Button from '../Button/Button';

const GoogleButton = ({ whatToGoogle, className, children }) => {
    const googleTheThing = () => {
        window.open(`https://www.google.com/search?q=${encodeURIComponent(whatToGoogle)}`);
    };
    return (
        <Button className={className} onClick={googleTheThing}>
            {children}
        </Button>
    );
};

export default GoogleButton;
