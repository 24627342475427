import React, { useContext, useState } from 'react';
import { spleisUrl } from '../../config';
import FormButton from '../../FormButton/FormButton';
import api from '../../api';
import { showError, showInfo } from '../../store';
import ProjectContext from '../project-context';

const ChangeLocation = () => {
    const project = useContext(ProjectContext);
    const [postalCode, setPostalCode] = useState(null);

    const changeLocationForProject = () => {
        api.put(`${spleisUrl}/api/admin/project/${project.id}/change-postalcode`, {
            postalCode,
        })
            .then(() => {
                setPostalCode(null);
                showInfo('Endret postnummer for prosjektet!');
                window.notify();
            })
            .catch((e) => {
                setPostalCode(null);
                showError('Klarte ikke å endre postnummer');
            });
    };

    if (!!project.published_at) {
        return (
            <FormButton
                title={`Endre postnummer (${project?.location?.postal_code})`}
                onClick={changeLocationForProject}
            >
                <input
                    type={'text'}
                    value={postalCode}
                    onChange={(e) => setPostalCode(e.target.value)}
                    autoFocus={true}
                />
            </FormButton>
        );
    }
    return null;
};

export default ChangeLocation;
