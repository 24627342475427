import TaskContainer from '../TaskContainer/TaskContainer';
import { Helmet } from 'react-helmet';
import React, { useEffect, useState } from 'react';
import qs from 'qs';
import api from '../api';
import { spleisUrl } from '../config';
import { showError } from '../store';
import Button from '../Button/Button';
import ProjectLink from '../SmartLinks/ProjectLink';
import { formatDateOnly } from '../dates';
import Linkify from 'react-linkify';
import styles from './Controversial.module.scss';

const minDate = '2016-01-01';
const maxDate = new Date().toISOString().slice(0, 10);
const firstDayOfYear = `${new Date().getFullYear()}-01-01`;

export default () => {
    const [fromDate, setFromDate] = useState(firstDayOfYear);
    const [toDate, setToDate] = useState(maxDate);
    const [inputQuery, setInputQuery] = useState('');
    const [result, setResult] = useState([]);
    const [fetching, setFetching] = useState(false);

    const doFetch = () => {
        setResult([]);
        setFetching(true);
        const queryString = qs.stringify({
            query: inputQuery,
            publishedFromDate: fromDate,
            publishedToDate: toDate,
        });
        api.get(`${spleisUrl}/api/admin/project/with-summary?${queryString}`)
            .then((res) => setResult(res))
            .catch(() => showError('Feil ved henting kontroversielle spleiser'))
            .finally(() => setFetching(false));
    };

    useEffect(() => doFetch(), []);

    return (
        <TaskContainer>
            <Helmet>
                <title>Kontroversielle spleiser</title>
            </Helmet>
            <header className="task-header">
                <h1 className="task-title">Kontroversielle spleiser</h1>
            </header>
            <div className="task-body">
                <div className={styles.section}>
                    <h3>Publisert fra og med</h3>
                    <input
                        type="date"
                        min={minDate}
                        max={maxDate}
                        defaultValue={fromDate}
                        onChange={(e) => setFromDate(e.target.value)}
                    />
                </div>
                <div className={styles.section}>
                    <h3>Publisert til og med</h3>
                    <input
                        type="date"
                        min={minDate}
                        max={maxDate}
                        defaultValue={toDate}
                        onChange={(e) => setToDate(e.target.value)}
                    />
                </div>
                <div className={styles.section}>
                    <h3>Søk i tittel, beskrivelse og sammendrag</h3>
                    <input
                        className={styles.inputText}
                        type="text"
                        defaultValue={inputQuery}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter' && !fetching) {
                                doFetch();
                            }
                        }}
                        onChange={(e) => setInputQuery(e.target.value)}
                    />
                </div>
                <div className={styles.section}>
                    <Button type="button" onClick={doFetch} disabled={fetching}>
                        {fetching ? 'Søker...' : 'Søk'}
                    </Button>
                </div>
                {result.length > 0 && (
                    <div className={styles.section}>
                        <div className={styles.section}>{result.length} treff</div>
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th>Spleis</th>
                                    <th>Publisert</th>
                                    <th>Tittel</th>
                                    <th>Sammendrag</th>
                                </tr>
                            </thead>
                            <tbody>
                                {result.map((project) => (
                                    <tr key={project.id}>
                                        <td>
                                            <ProjectLink id={project.id} />
                                        </td>
                                        <td>{formatDateOnly(project.published_at)}</td>
                                        <td>{project.title}</td>
                                        <td className={styles.summary}>
                                            <Linkify properties={{ target: '_blank' }}>{project.summary}</Linkify>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
        </TaskContainer>
    );
};
