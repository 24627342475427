import React, { Component } from 'react';
import Button from '../Button/Button';
import withData from '../with-data';
import api from '../api';
import './Comments.scss';
import { sortObjectArrayByDateField } from '../dates';
import VisibleIf from '../VisibleIf/VisibleIf';
import ShowMore from '../ShowMore/ShowMore';
import Collapsable from '../Collapsable/Collapsable';
import { Comment } from './Comment';

class Comments extends Component {
    constructor(props) {
        super(props);
        this.state = { postToSlack: props.postToSlack, hidden: props.toggle };
    }

    comment = (e) => {
        e.preventDefault();
        if (this.textarea.value.trim().length > 0) {
            api.post('/api/comments', {
                comment: this.textarea.value,
                kis: { [this.props.lookupKey]: this.props.id },
                postToSlack: this.state.postToSlack,
                slackRef: this.props.slackRef,
                slackKey: this.props.slackKey,
            }).then(() => {
                this.props.fetchData();
                this.textarea.value = '';
            });
        }
    };

    deleteComment = (e, id) => {
        e.preventDefault();
        api.remove(`/api/comments/${id}`).then(() => this.props.fetchData());
    };

    render = () => {
        const comments = sortObjectArrayByDateField(this.props.data || [], 'created_at', true);

        return (
            <div className={[this.props.className, 'comments-container'].join(' ')}>
                {this.state.hidden && !comments.length ? (
                    <Button onClick={() => this.setState({ hidden: false })} className="btn-text">
                        Kommenter
                    </Button>
                ) : (
                    <Collapsable
                        title={'Kommentarer'}
                        className="comments-wrapper"
                        canCollapse={this.props.canCollapse || false}
                    >
                        <form className="comments-input-container">
                            <textarea
                                placeholder="Kommentar"
                                className="comments-input"
                                ref={(input) => {
                                    this.textarea = input;
                                }}
                                required
                            />

                            <div>
                                <Button onClick={this.comment} className="add-comment-button">
                                    Legg til
                                </Button>
                                <label className="post-to-slack">
                                    <input
                                        type="checkbox"
                                        checked={this.state.postToSlack}
                                        onChange={(e) => {
                                            this.setState({ postToSlack: e.target.checked });
                                        }}
                                    />
                                    Send til Slack
                                </label>
                            </div>
                        </form>
                        <VisibleIf isVisible={comments.length >= 1}>
                            <ShowMore
                                items={comments}
                                listCreator={(commentList) => (
                                    <ul className="comments-list">
                                        {commentList.map((comment) => {
                                            return (
                                                <li key={comment.id} className="comment-wrapper">
                                                    <Comment
                                                        comment={comment}
                                                        deleteComment={(e) => this.deleteComment(e, comment.id)}
                                                    />
                                                </li>
                                            );
                                        })}
                                    </ul>
                                )}
                            />
                        </VisibleIf>
                    </Collapsable>
                )}
            </div>
        );
    };
}

class CommentsContainer extends Component {
    constructor(props) {
        super(props);
        const { lookupKey } = this.props;
        this.component = withData(Comments, (id) => `/api/comments?${lookupKey}=${id}`);
    }

    render = () => React.createElement(this.component, this.props, null);
}

export default CommentsContainer;
