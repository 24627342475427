import React from 'react';

import './InnsamlingskontrollStatus.scss';

const InnsamlingskontrollStatus = ({ status }) => {
    let title;
    let image;
    if (status?.status === 'approved') {
        title = 'Godkjent av Innsamlingskontrollen';
        image = 'logo-emblem-white.svg';
    } else if (status?.status === 'obs') {
        title = 'OBS-listen hos Innsamlingskontrollen!';
        image = 'warning.svg';
    }

    if (!!title && !!image) {
        return (
            <a href={status.url}>
                <img className={'innsamlingskontrollen-badge'} alt="" title={title} src={`/images/${image}`} />
            </a>
        );
    }
    return null;
};

export default InnsamlingskontrollStatus;
