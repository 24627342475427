import React from 'react';
import './TaskContainer.scss';
import styles from './TaskContainer.module.scss';

const TaskContainer = ({ children, detail, detailClass }) => {
    return (
        <div className={styles.taskContainer}>
            <main className={styles.taskMain}>
                <div className={styles.taskWrapper}>{children}</div>
            </main>
            {detail && <aside className={[styles.taskDetail, detailClass].join(' ')}>{detail}</aside>}
        </div>
    );
};

export default TaskContainer;
