import React, { useState, useEffect } from 'react';
import { Search } from '../../Menu/Menu';
import { spleisUrl } from '../../config';
import Button from '../../Button/Button';
import FormButton from '../../FormButton/FormButton';
import api from '../../api';
import { showError, showInfo } from '../../store';
import styles from './ChangeOwner.module.scss';

const ChangeOwner = ({ project }) => {
    const [ownerOrgId, setOwnerOrgId] = useState(null);
    const [ownerUserId, setOwnerUserId] = useState(null);
    const [showChangeModal, setShowChangeModal] = useState(false);

    useEffect(() => {
        setOwnerOrgId(false);
        setOwnerUserId(false);
    }, [showChangeModal]);

    const onCancel = () => {
        setOwnerOrgId(false);
        setOwnerUserId(false);
    };

    const changeOwnerToOrg = (e) => {
        e.preventDefault();

        setShowChangeModal(false);
        api.put(`${spleisUrl}/api/admin/project/${project.id}/change-owner`, {
            orgId: ownerOrgId,
        })
            .then(() => {
                setOwnerOrgId(null);
                showInfo('Endret innsamler til organisasjon!');
                window.notify();
            })
            .catch((err) => {
                setOwnerOrgId(null);
                showError('Klarte ikke å endre innsamler');
            });
    };

    const changeOwnerToUser = (e) => {
        e.preventDefault();
        setShowChangeModal(false);
        api.put(`${spleisUrl}/api/admin/project/${project.id}/change-owner`, {
            userId: ownerUserId,
        })
            .then(() => {
                setOwnerUserId(null);
                showInfo('Endret innsamler til brukeren!');
                window.notify();
            })
            .catch((err) => {
                setOwnerUserId(null);
                showError('Klarte ikke å endre innsamler');
            });
    };

    return (
        <FormButton
            showButton={false}
            title="Endre innsamler"
            className={styles.formButton}
            isOpen={showChangeModal}
            onClickCancel={() => {
                onCancel();
            }}
            onClickButton={(e) => {
                e.preventDefault();
                setShowChangeModal(true);
            }}
        >
            <div className={styles.changeForms}>
                {!ownerUserId && (
                    <form onSubmit={changeOwnerToOrg} className={styles.changeForm}>
                        <h4 className={styles.recieverTitle}>Til organisasjon</h4>
                        <div className={styles.searchWrapper}>
                            {ownerOrgId ? (
                                <>
                                    <div className={styles.changeText}>
                                        Endre innsamler til org med id: {ownerOrgId}
                                    </div>
                                    <Button className={'btn-cta'} type={'submit'}>
                                        Endre til org.
                                    </Button>
                                </>
                            ) : (
                                <Search
                                    className={styles.searchField}
                                    id="org-search-box"
                                    searchUrl={`${spleisUrl}/api/admin/organization/search`}
                                    placeholder="Finn org"
                                    onClick={(id) => {
                                        setOwnerOrgId(id);
                                    }}
                                    listItem={(option) => (
                                        <div>
                                            <div>{option.object.name ? option.object.name : 'Ukjent'}</div>
                                            <div style={{ fontSize: '0.8em', color: '#676767' }}>
                                                {option.object.organization_number} (#
                                                {option.object.id})
                                            </div>
                                        </div>
                                    )}
                                />
                            )}
                        </div>
                    </form>
                )}
                {!ownerOrgId && (
                    <form onSubmit={changeOwnerToUser} className={styles.changeForm}>
                        <h4 className={styles.recieverTitle}>Til bruker</h4>
                        <div className={styles.searchWrapper}>
                            {ownerUserId ? (
                                <>
                                    <div className={styles.changeText}>
                                        Endre innsamler til bruker med id: {ownerUserId}
                                    </div>
                                    <Button className={'btn-cta'} type={'submit'}>
                                        Endre til bruker
                                    </Button>
                                </>
                            ) : (
                                <Search
                                    className={styles.searchField}
                                    id="org-search-box"
                                    searchUrl={`${spleisUrl}/api/admin/user/search`}
                                    placeholder="Finn bruker"
                                    onClick={(id) => {
                                        setOwnerUserId(id);
                                    }}
                                    listItem={({ object }) => (
                                        <div>
                                            <div>
                                                {object.first ? `${object.first} ${object.last}` : 'Ukjent'} (#
                                                {object.id})
                                            </div>
                                        </div>
                                    )}
                                />
                            )}
                        </div>
                    </form>
                )}
            </div>
        </FormButton>
    );
};

export default ChangeOwner;
