import api from '../api';
import { spleisUrl } from '../config';
import { showError, showInfo } from '../store';
import ContactUser from './ContactUser';
import ConfirmButton from '../ConfirmButton/ConfirmButton';
import classNames from 'classnames';
import AddOrEditContactPersonButton from './AddOrEditContactPersonButton/AddOrEditContactPersonButton';
import React from 'react';

export const MainContacts = ({ contactUsers, refreshData, organization }) => {
    const verifyContactUser = (mainContact) => {
        api.put(`${spleisUrl}/api/admin/organization/contact/${mainContact.id}/verify`)
            .then(() => {
                refreshData();
                showInfo('Kontaktperson er verifisert!', 10);
            })
            .catch((error) => {
                showError(error);
            });
    };

    const deleteContactUser = (mainContact) => {
        api.remove(`${spleisUrl}/api/admin/organization/contact/${mainContact.id}`)
            .then(() => {
                refreshData();
                showInfo('Kontaktperson er slettet!', 10);
            })
            .catch((error) => {
                showError(error);
            });
    };

    return (
        <div>
            <h3>{contactUsers.length > 1 ? 'Kontaktpersoner' : 'Kontaktperson'}</h3>
            <div className={'orgContacts'}>
                {contactUsers
                    .sort((a, b) => a.id > b.id)
                    .map((organizationUser) => {
                        return (
                            <ContactUser
                                key={organizationUser.id}
                                organizationUser={organizationUser}
                                refreshData={refreshData}
                                organizationId={organization.id}
                            >
                                {!organizationUser?.verified_at && (
                                    <ConfirmButton
                                        title="Verifiser kontaktperson"
                                        message="Er du sikker på at du vil verifisere kontaktpersonen?"
                                        onClick={() => verifyContactUser(organizationUser)}
                                    />
                                )}
                                <ConfirmButton
                                    title="Slett kontaktperson"
                                    message={
                                        contactUsers.length > 1
                                            ? `Er du sikker på at du vil slette kontaktpersonen?`
                                            : `Er du helt sikker på at du vil slette organisasjonens eneste kontaktperson?`
                                    }
                                    onClick={() => deleteContactUser(organizationUser)}
                                    className={'dangerous-button'}
                                />
                            </ContactUser>
                        );
                    })}
            </div>
        </div>
    );
};
