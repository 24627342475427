import React, { Component } from 'react';
import ReactTable from 'react-table';
import api from '../api';
import { spleisUrl } from '../config';
import './ObservationKeywords.scss';
import ConfirmButton from '../ConfirmButton/ConfirmButton';
import { showError } from '../store';
import FormButton from '../FormButton/FormButton';
import { Helmet } from 'react-helmet';

class ObservationKeywords extends Component {
    constructor(props) {
        super(props);
        this.state = {
            keywords: [],
        };
    }

    componentDidMount() {
        api.get(`${spleisUrl}/api/admin/project/observation-keywords`)
            .then((res) => {
                const keywords = res.sort((a, b) => a.keyword.localeCompare(b.keyword));
                this.setState({ keywords });
            })
            .catch(() => showError('Feil ved henting av observasjonsord fra baksystem', 3));
    }

    handleSubmit = () => {
        const keyword = this.keywordInput.value;
        const weight = this.weightInput.value;
        api.post(`${spleisUrl}/api/admin/project/observation-keyword`, { keyword, weight })
            .then((keywordObject) => this.setState({ keywords: [keywordObject, ...this.state.keywords] }))
            .catch(() => showError('Noe gikk feil ved oppretting av observasjonsord', 3));
    };

    handleDelete = (id) => {
        api.remove(`${spleisUrl}/api/admin/project/observation-keyword/${id}`)
            .then(() => this.setState({ keywords: this.state.keywords.filter((keyword) => keyword.id !== id) }))
            .catch(() => showError('Noe gikk feil ved sletting av observasjonsord', 3));
    };

    render() {
        return (
            <div className="keyword-container">
                <Helmet>
                    <title>Ord til observasjon</title>
                </Helmet>
                <header className="task-header">
                    <h1 className="task-title">Observasjonsord</h1>
                </header>
                <div className="keyword-content">
                    <div className="keyword-meny">
                        <FormButton title="Legg til nytt observasjonsord" onClick={() => this.handleSubmit()}>
                            <form>
                                <input
                                    ref={(ref) => {
                                        if (ref != null) this.keywordInput = ref;
                                    }}
                                    placeholder="observasjonsord"
                                />
                                <input
                                    ref={(ref) => {
                                        if (ref != null) this.weightInput = ref;
                                    }}
                                    placeholder="vekt (0.0-1.0)"
                                />
                            </form>
                        </FormButton>
                    </div>

                    <ReactTable
                        data={this.state.keywords}
                        className="-striped keyword-table"
                        defaultSorted={[{ id: 'id' }]}
                        getTdProps={() => ({
                            style: {
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                height: '50px',
                            },
                        })}
                        columns={[
                            {
                                Header: 'Observasjonsord',
                                accessor: 'keyword',
                            },
                            {
                                Header: 'Vekt',
                                accessor: 'weight',
                            },
                            {
                                Header: 'Actions',
                                id: 'actions',
                                accessor: (d) => d.id,
                                sortable: false,
                                width: 100,
                                Cell: (row) => (
                                    <ConfirmButton
                                        title="Slett"
                                        message="Er du sikker på at du vil slette ordet?"
                                        className="btn-warning"
                                        onClick={() => this.handleDelete(row.value)}
                                    />
                                ),
                            },
                        ]}
                    />
                </div>
            </div>
        );
    }
}

export default ObservationKeywords;
