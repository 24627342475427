import React from 'react';
import AccountingTable from './AccountingTable';

const TotalAvstemmingTable = () => {
    return (
        <AccountingTable
            url={'total-avstemming'}
            columns={[
                { Header: 'Type', minResizeWidth: 30, accessor: 'label' },
                {
                    Header: 'Beløp',
                    accessor: 'amount',
                    minResizeWidth: 30,
                    minWidth: 120,
                    Cell: (row) => <span>{Math.abs(row.value).toLocaleString()} kr.</span>,
                },
            ]}
        />
    );
};

export default TotalAvstemmingTable;
