import { Link, Route, withRouter } from 'react-router-dom';

import React from 'react';
import MainContainer from '../MainContainer/MainContainer';
import Dugnad from './Dugnad';
import VmDugnad from './VmDugnad';
import Winners from './Winners';
import './Shipping.scss';
import HelmetForSubpages from '../HelmetForSubpages/HelmetForSubpages';

const Shipping = ({ location }) => {
    const isSelected = (page) => {
        return location.pathname.startsWith(page);
    };

    return (
        <MainContainer>
            <HelmetForSubpages title="Utsendelse av produkter" />
            <div className="lottery-container">
                <div className="lottery-list-wrapper">
                    <header className="lottery-header">Lotteri</header>
                    <ul className="lottery-list">
                        <li
                            className={`lottery-menu-item ${
                                isSelected('/shipping/winners/') ? 'lottery-menu-item-selected' : ''
                            }`}
                        >
                            <Link className={'lottery-menu-item'} to={`/shipping/winners/`}>
                                Vinnere lotteri
                            </Link>
                        </li>
                        <li
                            className={`lottery-menu-item ${
                                isSelected('/shipping/dugnad/') ? 'lottery-menu-item-selected' : ''
                            }`}
                        >
                            <Link className={'lottery-menu-item'} to={`/shipping/dugnad/`}>
                                Dugnadsprodukter
                            </Link>
                        </li>
                        <li
                            className={`lottery-menu-item ${
                                isSelected('/shipping/vm-dugnad/') ? 'lottery-menu-item-selected' : ''
                            }`}
                        >
                            <Link className={'lottery-menu-item'} to={`/shipping/vm-dugnad/`}>
                                VM-Dugnad
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="lottery-content">
                <Route path="/shipping/winners" component={Winners} />
                <Route path="/shipping/dugnad" component={Dugnad} />
                <Route path="/shipping/vm-dugnad" component={VmDugnad} />
            </div>
        </MainContainer>
    );
};

export default withRouter(Shipping);
