import React, { useEffect, useState } from 'react';

import ConfirmButton from '../ConfirmButton/ConfirmButton';
import { showError, showInfo } from '../store';
import FormButton from '../FormButton/FormButton';
import api from '../api';
import { spleisUrl } from '../config';
import ConfirmModal from '../ConfirmModal/ConfirmModal';
import Button from '../Button/Button';
import styles from './ProjectInvitation.module.scss';

export const InvitationInfo = ({ projectId, hideProjectForReceiver = false }) => {
    const [invitation, setInvitation] = useState({});
    const [name, setName] = useState(invitation.name);
    const [phoneNumber, setPhoneNumber] = useState(invitation.phone_number);
    const [statusOpen, setStatusOpen] = useState(false);

    const sendSMS = () => {
        api.post(`${spleisUrl}/api/admin/project/${projectId}/invitation/${invitation.id}/send`)
            .then(() => showInfo(`SMS sendt til ${invitation.name}`))
            .catch(() => showError('Klarte ikke sende SMS til den inviterte mottakeren'));
    };

    const updateUserInfo = () => {
        api.put(`${spleisUrl}/api/admin/project/${projectId}/invitation/${invitation.id}`, {
            name,
            phone_number: phoneNumber,
        })
            .then(() => showInfo(`Oppdaterte navn og telefonnummer på invitert mottaker`))
            .then(() => fetchProjectInvitation())
            .catch(() => showError('Klarte oppdatere den inviterte mottakeren'));
    };

    const fetchProjectInvitation = () => {
        if (projectId != null) {
            api.get(`${spleisUrl}/api/admin/project/${projectId}/invitation`).then(newInvitation => {
                setInvitation(newInvitation);
                setName(newInvitation.name);
                setPhoneNumber(newInvitation.phone_number);
            });
        }
    };

    const resetForm = () => {
        setPhoneNumber(invitation.phone_number);
        setName(invitation.name);
    };

    useEffect(fetchProjectInvitation, [projectId]);

    if (!invitation.name) {
        return <div className="user-empty">Mottakeren har ikke registrert seg på spleis enda</div>;
    }
    const rows =
        !!invitation &&
        !!invitation.message_status &&
        invitation.message_status.map(status => (
            <tr className={styles.projectInvitationTableRow}>
                <td className={styles.projectInvitationTableCell}>{status.msisdn}</td>
                <td className={styles.projectInvitationTableCell}>{status.status}</td>
                <td className={styles.projectInvitationTableCell}>{status['err-code']}</td>
                <td className={styles.projectInvitationTableCell}>{status['message-timestamp']}</td>
            </tr>
        ));

    return (
        <div className="user-empty">
            <ConfirmModal
                title={'SMS-status'}
                successText="Ok"
                isOpen={statusOpen}
                onClickSuccess={() => setStatusOpen(false)}
            >
                <table>
                    <thead>
                        <tr className={styles.projectInvitationTableRow}>
                            <th className={styles.projectInvitationTableHeader}>Nummer</th>
                            <th className={styles.projectInvitationTableHeader}>Status</th>
                            <th className={styles.projectInvitationTableHeader}>Feilkode</th>
                            <th className={styles.projectInvitationTableHeader}>Tidspunkt</th>
                        </tr>
                    </thead>
                    <tbody>{rows}</tbody>
                </table>
            </ConfirmModal>
            <p className={styles.invitationName}>{invitation.name}</p>
            <p>Mobil: {invitation.phone_number}</p>
            <div className={styles.projectInvitationButtons}>
                {hideProjectForReceiver ? (
                    <div className={styles.receiverWarning}>
                        Dette er en gavespleis, og innsamlingen er skjult for mottaker
                    </div>
                ) : (
                    <ConfirmButton
                        title="Send SMS"
                        message={`Er du sikker på at du vil sende invitasjonslenken på SMS til ${invitation.name} på telefon ${invitation.phone_number}?`}
                        onClick={sendSMS}
                    />
                )}
                <FormButton
                    title="Endre"
                    className="task-action-button"
                    onClick={updateUserInfo}
                    onClickCancel={resetForm}
                >
                    <form>
                        <input
                            id="invitation-name"
                            placeholder="Navn"
                            value={name}
                            onChange={e => setName(e.currentTarget.value)}
                        />
                        <input
                            id="invitation-mobile"
                            placeholder="Mobilnummer"
                            value={phoneNumber}
                            onChange={e => setPhoneNumber(e.currentTarget.value)}
                        />
                    </form>
                </FormButton>
                <Button className="task-action-button" onClick={() => setStatusOpen(true)}>
                    Status
                </Button>
            </div>
        </div>
    );
};

export default InvitationInfo;
