import React from 'react';
import PropTypes from 'prop-types';
import './Org.scss';
import InfoDataList, { DataListItem } from '../InfoDataList/InfoDataList';
import ConfirmButton from '../ConfirmButton/ConfirmButton';
import PhoneNumberLink from '../User/PhoneNumberLink';

const ContactCandidates = props => {
    const { contacts, onAccept, onDeny } = props;
    return (
        <div className="org-contact-candidate">
            <h3>Registrerte kontaktperson-kandidater</h3>
            <div className="org-contact-candidate-body">
                {contacts.map(contact => (
                    <div key={`contact-candidate-${contact.user_id}`} className="infoboks">
                        <InfoDataList title={`${contact.first} ${contact.last}`}>
                            <DataListItem label="Telefonnummer">
                                <PhoneNumberLink phoneNumber={contact.phone_number} />
                            </DataListItem>
                            <DataListItem label="E-post">{contact.email}</DataListItem>
                            <div className="org-knapperad">
                                <ConfirmButton
                                    title="Avvis kontaktperson"
                                    message={`Er du sikker på at du vil avvise ${contact.first} ${contact.last} som kontaktperson?`}
                                    onClick={() => onDeny(contact)}
                                />{' '}
                                <ConfirmButton
                                    className="btn-cta"
                                    title="Godkjenne kontaktperson"
                                    message={`Er du sikker på at du vil godkjenne ${contact.first} ${contact.last} som kontaktperson?`}
                                    onClick={() => onAccept(contact)}
                                />
                            </div>
                        </InfoDataList>
                    </div>
                ))}
            </div>
        </div>
    );
};

ContactCandidates.defaultProps = {
    contacts: [],
};

ContactCandidates.propTypes = {
    mainAccount: PropTypes.array,
    onAccept: PropTypes.func.isRequired,
    onDeny: PropTypes.func.isRequired,
};

export default ContactCandidates;
