import React from 'react';
import { Helmet } from 'react-helmet';
import AccountingTable from './AccountingTable';
import { formatMoneyKr } from '../formatters';

const CustomCell = ({ children }) => {
    return <div className={'cell-align-right'}>{children}</div>;
};

const DugnadsProdukter = () => {
    return (
        <>
            <Helmet>
                <title>Dugnadsprodukter</title>
            </Helmet>
            <AccountingTable
                url="dugnad-products"
                includeEventSum={true}
                sumUrl="dugnad-products-sum"
                sumColumns={[
                    {
                        Header: '',
                        accessor: 'mva_prosent',
                        minResizeWidth: 30,
                        width: 300,
                        Cell: (row) => {
                            if (typeof row.value === 'number') {
                                return <span>{(Math.abs(row.value) * 100).toLocaleString('no')}% mva</span>;
                            } else if (row.value === null) {
                                return <span>Mangler verdi</span>;
                            }
                            return <span>{row.value}</span>;
                        },
                    },
                    {
                        Header: 'Inntekt (eks. mva)',
                        accessor: 'inntekt',
                        minResizeWidth: 30,
                        width: 250,
                        Cell: (row) => <CustomCell>{formatMoneyKr(Math.abs(row.value))}</CustomCell>,
                    },
                    {
                        Header: 'Mva',
                        accessor: 'mva',
                        minResizeWidth: 30,
                        width: 200,
                        Cell: (row) => <CustomCell>{formatMoneyKr(Math.abs(row.value))}</CustomCell>,
                    },
                ]}
                columns={[
                    { Header: 'Produkt', minResizeWidth: 30, accessor: 'produkt', width: 300 },
                    {
                        Header: 'Inntekt (eks. mva)',
                        accessor: 'inntekt',
                        minResizeWidth: 30,
                        width: 250,
                        Cell: (row) => <CustomCell>{formatMoneyKr(Math.abs(row.value))}</CustomCell>,
                    },
                    {
                        Header: 'Mva',
                        accessor: 'mva',
                        minResizeWidth: 30,
                        width: 200,
                        Cell: (row) => <CustomCell>{formatMoneyKr(Math.abs(row.value))}</CustomCell>,
                    },
                    {
                        Header: 'Mva %',
                        accessor: 'mva_prosent',
                        minResizeWidth: 30,
                        width: 150,
                        Cell: (row) => <CustomCell>{(Math.abs(row.value) * 100).toLocaleString('no')}%</CustomCell>,
                    },
                ]}
            />
        </>
    );
};

export default DugnadsProdukter;
