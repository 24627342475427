import React, { useState } from 'react';
import Button from '../../Button/Button';
import api from '../../api';
import { spleisUrl } from '../../config';
import { showError, showInfo } from '../../store';
import styles from './ConsiderProject.module.scss';

const ConsiderProject = ({ project, versions }) => {
    const [processing, setProcessing] = useState(false);

    const consider = () => {
        setProcessing(true);
        api.post(`${spleisUrl}/api/admin/project/${project.id}/considered`)
            .then(() => {
                showInfo('Versjon vurdert');
                window.notify();
            })
            .catch(() => showError('Klarte ikke å vurdere versjonen'))
            .finally(() => setProcessing(false));
    };

    const lastVersion = versions?.[0];

    if (lastVersion?.considered) {
        return <span className={styles.considered}>Vurdert</span>;
    }

    if (!versions || !lastVersion || lastVersion?.approved) {
        return null;
    }

    return (
        <Button className={styles.considerButton} disabled={processing} onClick={consider}>
            {processing ? 'Vurderer...' : 'Sett vurdert'}
        </Button>
    );
};

export default ConsiderProject;
