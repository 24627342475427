import React, { Component } from 'react';
import withData from '../with-data';
import { spleisUrl } from '../config';
import Button from '../Button/Button';
import api from '../api';
import VisibleIf from '../VisibleIf/VisibleIf';
import Collapsable from '../Collapsable/Collapsable';

export const ratingLevelText = (ratingLevel) => {
    switch (ratingLevel) {
        case 0:
            return 'Ikke nok ratings';
        case 1:
            return 'Negativ';
        case 2:
            return 'Kontroversiel';
        case 3:
            return 'Positiv';
        default:
            return 'Ukjent';
    }
};

class Ratings extends Component {
    addAdminRating = async (rating) => {
        api.put(`${spleisUrl}/api/admin/project/${this.props.id}/rating`, { rating: rating });
        window.notify();
    };

    render = () => {
        const ratings = this.props.data || {};
        const profile = this.props.project ? this.props.project.profile : null;
        return (
            <Collapsable title={'Rating'}>
                <div>Positive: {ratings.positive}</div>
                <div>Nøytrale: {ratings.neutral}</div>
                <div>Negative: {ratings.negative}</div>
                <div>Rating: {ratings.rating}</div>
                <div>Rating level: {ratingLevelText(ratings.ratingLevel)}</div>
                <div>Overstyrt rating: {profile ? profile.rating : 'Ingen rating satt'}</div>
                <div>
                    <Button onClick={() => this.addAdminRating('positive')}>{'Sett positiv'}</Button>
                    <Button onClick={() => this.addAdminRating('neutral')}>{'Sett nøytral'}</Button>
                    <Button onClick={() => this.addAdminRating('negative')}>{'Sett negativ'}</Button>
                    <VisibleIf isVisible={profile ? profile.rating : false}>
                        <Button onClick={() => this.addAdminRating(null)}>{'Ta bort overstyring'}</Button>
                    </VisibleIf>
                </div>
            </Collapsable>
        );
    };
}

class RatingsContainer extends Component {
    constructor(props) {
        super(props);
        this.component = withData(Ratings, (id) => `${spleisUrl}/api/admin/project/${id}/rating`);
    }
    render = () => React.createElement(this.component, this.props, null);
}

export default RatingsContainer;
