import React, { useRef, useState } from 'react';
import api from '../api';
import { showError, showInfo } from '../store';
import styles from './SendSmsToUser.module.scss';
import { presets } from './SendSmsToUserPresets';
import { spleisUrl } from '../config';
import ConfirmButton from '../ConfirmButton/ConfirmButton';

const SendSmsToUser = ({ user, buttonText = 'Send SMS' }) => {
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [message, setMessage] = useState(presets[0].message);
    const messageRef = useRef();

    const addTextAtCursor = (textToInsert) => {
        if (!messageRef.current) {
            return;
        }
        const area = messageRef.current;
        area.setRangeText(textToInsert, area.selectionStart, area.selectionEnd, 'end');
        area.focus();
    };

    const AddTextButton = ({ text }) => (
        <button className="btn" onClick={() => addTextAtCursor(text)}>
            {text}
        </button>
    );

    const changePreset = (e) => {
        const preset = presets[e.target.value];
        setMessage(preset.message);
        setSelectedIndex(e.target.value);
    };

    const changeMessage = (e) => {
        setMessage(e.target.value);
    };

    const sendSmsToUser = () => {
        api.post(`${spleisUrl}/api/admin/user/${user.id}/send-sms`, {
            message: message,
        })
            .then(() => {
                api.post('/api/comments', {
                    comment: `Sendte sms til brukeren med malen "${presets[selectedIndex].optionText}".`,
                    kis: { user: user.id },
                })
                    .then(() => {
                        window.notify();
                        showInfo('Sendte SMS til brukeren.');
                    })
                    .catch(() => {
                        window.notify();
                        showError('Sendte SMS, men klarte ikke å legge til kommentar.');
                    });
            })
            .catch(() => {
                showError('Klarte ikke å sende sms til eier');
            });
    };

    return (
        <ConfirmButton onClick={sendSmsToUser} title={buttonText} successText="Send SMS" className="task-action-button">
            <div className={styles.presetWrapper}>
                <label>Forhåndsdefinerte</label>
                <select className={styles.preset} onChange={changePreset} value={selectedIndex}>
                    {presets.map((preset, index) => (
                        <option value={index}>{preset.optionText}</option>
                    ))}
                </select>
            </div>
            <div className={styles.messageWrapper}>
                <label>Melding</label>
                <textarea className={styles.message} ref={messageRef} onChange={changeMessage} value={message} />
            </div>
            <div className={styles.addTextButtons}>
                <AddTextButton text="👋" />
                <AddTextButton text="😊" />
                <AddTextButton text="🙌" />
                <AddTextButton text="👆" />
                <AddTextButton text="👇" />
                <AddTextButton text="👉" />
                <AddTextButton text="🚀" />
            </div>
        </ConfirmButton>
    );
};

export default SendSmsToUser;
