import React, { useState } from 'react';
import { spleisUrl } from '../../config';
import api from '../../api';
import { showError, showInfo } from '../../store';
import ConfirmButton from '../../ConfirmButton/ConfirmButton';
import FormButton from '../../FormButton/FormButton';
import { reasonPresets } from './RemoveProjectReasonPresets';
import styles from './RemoveProject.module.scss';

const RemoveProject = ({ project }) => {
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [isProcessing, setIsProcessing] = useState(false);
    const [message, setMessage] = useState(reasonPresets[0].message);
    const [messageTitle, setMessageTitle] = useState(reasonPresets[0].optionText);

    const changePreset = (e) => {
        const preset = reasonPresets[e.target.value];
        setMessage(preset.message);
        setMessageTitle(preset.optionText);
        setSelectedIndex(e.target.value);
    };

    const remove = () => {
        setIsProcessing(true);

        api.remove(`${spleisUrl}/api/admin/project/${project.id}`)
            .then(() => {
                showInfo('Prosjekt fjernet');
                window.notify();
                setIsProcessing(false);
            })
            .catch((err) => {
                showError(`Feil ved fjerning av prosjekt: ${err.text}`);
                setIsProcessing(false);
            });
    };

    const removeAndNotify = () => {
        setIsProcessing(true);

        api.remove(`${spleisUrl}/api/admin/project/${project.id}?notify=true`, {
            reason: message,
            reasonTitle: messageTitle,
        })
            .then(() => {
                showInfo('Prosjekt fjernet');
                window.notify();
                setIsProcessing(false);
            })
            .catch((err) => {
                showError(`Feil ved fjerning av prosjekt: ${err.text}`);
                setIsProcessing(false);
            });
    };

    const restore = () => {
        setIsProcessing(true);
        api.post(`${spleisUrl}/api/admin/project/${project.id}/gjenopprett`)
            .then(() => {
                showInfo('Prosjekt gjenopprettet');
                window.notify();
                setIsProcessing(false);
            })
            .catch((err) => {
                showError(`Feil ved gjenoppretting av prosjekt: ${err.text}`);
                setIsProcessing(false);
            });
    };

    return project.deleted ? (
        <ConfirmButton
            title="Gjenopprett slettet spleis"
            disabled={isProcessing}
            message="Sikker på at du vil gjennopprette spleisen?"
            onClick={restore}
        />
    ) : (
        <>
            <ConfirmButton
                className="dangerous-button"
                title="Slett spleis"
                disabled={isProcessing}
                message="Sikker på at du vil fjerne spleisen uten å sende melding til innsamler?"
                onClick={remove}
            />
            <FormButton
                className="dangerous-button"
                onClick={removeAndNotify}
                title="Slett spleis og send melding"
                successText="Slett og send"
            >
                <div className={styles.removeProjectModal}>
                    <div className={styles.presetWrapper}>
                        <label>Forhåndsdefinerte begrunnelser</label>
                        <select className={styles.preset} onChange={changePreset} value={selectedIndex}>
                            {reasonPresets.map((preset, index) => (
                                <option key={index} value={index}>
                                    {preset.optionText}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className={styles.messageWrapper}>
                        <label>Begrunnelse</label>
                        <textarea
                            className={styles.message}
                            onChange={(e) => setMessage(e.target.value)}
                            value={message}
                        />
                    </div>
                </div>
            </FormButton>
        </>
    );
};

export default RemoveProject;
