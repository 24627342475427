import React, { useContext } from 'react';
import { spleisUrl } from '../../config';
import api from '../../api';
import { showError, showInfo } from '../../store';
import ProjectContext from '../project-context';
import ConfirmButton from '../../ConfirmButton/ConfirmButton';

const ToggleProjectSearchVisibility = () => {
    const project = useContext(ProjectContext);
    const newHiddenValue = !project.profile?.noIndex;

    const toggleProjectVisibility = async () => {
        try {
            await api.put(`${spleisUrl}/api/admin/project/${project.id}/hidden`, {
                hidden: newHiddenValue,
            });
            showInfo(`Spleisen blir ${newHiddenValue ? 'skjult fra' : 'vist i'} søk!`);
        } catch (err) {
            showError(`Feil ved endring av spleissynlighet: ${err}`);
        }
        window.notify();
    };

    const projectVisibilityText = `Gjør ${project.profile?.noIndex ? 'offentlig' : 'hemmelig'}`;

    return (
        <ConfirmButton
            onClick={toggleProjectVisibility}
            title={projectVisibilityText}
            successText={projectVisibilityText}
            message={`Det kan ta litt tid før spleisen ${
                newHiddenValue ? 'skjules' : 'vises'
            } i søket på spleis.no. Eksterne tjenester, som Google, tar lengre tid`}
        />
    );
};

export default ToggleProjectSearchVisibility;
