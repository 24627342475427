import ConfirmButton from '../../ConfirmButton/ConfirmButton';
import api from '../../api';
import { showError, showInfo } from '../../store';
import { spleisUrl } from '../../config';

export const UndeleteReward = ({ reward }) => {
    const handleSave = async () => {
        try {
            await api.put(`${spleisUrl}/api/admin/project/${reward.project_id}/reward/${reward.id}/undelete`);
        } catch (err) {
            showError('Feil ved gjenoppretting av belønning: ' + err.text);
        }
        window.notify();
        showInfo('Belønning gjenopprettet!');
    };
    return (
        <ConfirmButton
            className="btn-cta"
            title={'Gjenopprett'}
            successText={'Gjenopprett belønning'}
            cancelText={'Avbryt'}
            onClick={() => handleSave()}
            onClickCancel={() => setIsOpen(false)}
        >
            Er du sikker på at du vil gjenopprette denne belønningen? Den kan slettes igjen senere.
        </ConfirmButton>
    );
};
