import React, { useState } from 'react';
import api from '../../api';
import { spleisUrl } from '../../config';
import { showError, showInfo } from '../../store';
import Button from '../../Button/Button';
import { USER_TYPE } from '../../User/User';

const SetOrgWantsMoney = ({ project }) => {
    const [isProcessing, setIsProcessing] = useState(false);

    const orgAcceptPayment = () => {
        setIsProcessing(true);
        api.put(`${spleisUrl}/api/admin/project/${project.id}/org-accept-payout`, {})
            .then(() => {
                setIsProcessing(false);
                showInfo('Utbetaling av prosjekt ble akseptert');
                window.notify();
            })
            .catch(() => {
                setIsProcessing(false);
                showError('Feil i endring av aksepter utbetaling for prosjektet');
            });
    };

    if (project.receiver?.type === USER_TYPE.USER || project.organization_accepted_payout_at) {
        return null;
    }

    return (
        <Button
            disabled={isProcessing || project.organization_accepted_payout_at}
            title={project.organization_accepted_payout_at ? 'Organisajonen har godkjent spleisen' : ''}
            onClick={orgAcceptPayment}
        >
            Sett «godkjent av org»
        </Button>
    );
};

export default SetOrgWantsMoney;
