import React from 'react';
import InfoDataList, { DataListItem } from '../InfoDataList/InfoDataList';
import { formatDate } from '../dates';
import { intercomUrl, spleisUrl } from '../config';
import { Link } from 'react-router-dom';
import './Org.scss';
import api from '../api';
import AddOrEditContactPersonButton from './AddOrEditContactPersonButton/AddOrEditContactPersonButton';
import PhoneNumberLink from '../User/PhoneNumberLink';
import { showError, showInfo } from '../store';
import Button from '../Button/Button';

const ContactUser = ({ organizationUser, refreshData, organizationId, className, children }) => {
    const {
        id,
        user_id,
        first,
        last,
        email,
        phone_number,
        email_receiver,
        verified_name,
        verified_at,
        verification_data,
        role,
    } = organizationUser;

    const updateRole = () => {
        api.post(`${spleisUrl}/api/admin/organization/${organizationId}/update-brreg-role/${organizationUser.user_id}`)
            .then(() => {
                refreshData();
                showInfo('Kontaktpersonens rolle er oppdatert!', 10);
            })
            .catch((error) => {
                refreshData();
                showError(error);
            });
    };

    const updateEmailReceiver = (shouldReceiveEmails) => {
        api.put(
            `${spleisUrl}/api/admin/organization/${organizationId}/email-receiver/${organizationUser.user_id}?shouldReceiveEmails=${shouldReceiveEmails}`
        )
            .then(() => {
                refreshData();
                showInfo('Kontaktpersonens e-postpreferanser er oppdatert!', 10);
            })
            .catch((error) => {
                refreshData();
                showError(error);
            });
    };

    const displayName = verified_name ?? `${first} ${last}`;

    return (
        <div className={['infoboks', className].join(' ')}>
            <InfoDataList key={id} title={user_id ? 'Bruker' : 'Kandidat fra NIF'}>
                {user_id && (
                    <DataListItem label="Id">
                        <Link to={`/user/${user_id}`}>{user_id}</Link>
                    </DataListItem>
                )}
                <DataListItem label="Navn">
                    <Link to={`/user/${user_id}`}>{displayName}</Link>
                </DataListItem>
                {email && (
                    <DataListItem label="Mail">
                        <a href={`mailto:${email}`}>{email}</a>
                    </DataListItem>
                )}
                <DataListItem label="Telefonnummer">
                    <PhoneNumberLink phoneNumber={phone_number} />
                </DataListItem>
                {user_id && (
                    <>
                        <DataListItem label="Verifiseringsdato">
                            {verified_at ? formatDate(verified_at) : 'Ikke verifisert'}
                        </DataListItem>

                        <DataListItem label="Rolle fra brreg">
                            {verification_data ? verification_data.title : role ? role : 'Ukjent'}
                        </DataListItem>
                        <DataListItem label="Mottar e-post">{email_receiver ? 'Ja' : 'Nei'}</DataListItem>
                    </>
                )}
                {role && <DataListItem label="Rolle fra NIF">{role}</DataListItem>}
                {email && (
                    <DataListItem>
                        <a href={`${intercomUrl}/users/show?email=${email}`} target="_blank" rel="noopener noreferrer">
                            Send melding
                        </a>
                    </DataListItem>
                )}
            </InfoDataList>
            <div className={'contact-buttons'}>
                <AddOrEditContactPersonButton
                    user={organizationUser}
                    orgId={organizationId}
                    refreshData={refreshData}
                />
                {!!verified_name && (
                    <Button onClick={() => updateRole(organizationUser)}>Oppdater rolle fra brreg</Button>
                )}
                {user_id && (
                    <Button value={email_receiver} onClick={() => updateEmailReceiver(!email_receiver)}>
                        Toggle e-postmottaker
                    </Button>
                )}
                {children}
            </div>
        </div>
    );
};

export default ContactUser;
