import React, { useEffect, useState } from 'react';
import 'moment/locale/nb';
import './ListOverview.scss';
import { filterProjectsGlobal } from '../ProjectListFilter/ProjectListFilter';

const ListItem = ({ title, items = [], callback, fetchItems }) => {
    const [fetchedItems, setFetchedItems] = useState(items);
    useEffect(() => {
        if (fetchItems) {
            fetchItems().then((projects) => {
                setFetchedItems(filterProjectsGlobal(projects));
            });
        }
    }, []);

    return (
        <div className={`categoryContainer ${!!callback && 'clickable'}`} onClick={callback}>
            <h3 className={`categoryTitle`}>{title}</h3>
            <div className={`categoryItems`}>{fetchedItems?.length || items.length}</div>
        </div>
    );
};

const ListOverview = ({ type, items = [] }) => {
    return (
        <div className={`mainContainer`}>
            <h1>{`Oversikt ${type}`}</h1>
            <div className={`categoriesContainer`}>
                {items.map((props, idx) => {
                    return <ListItem key={idx} {...props} />;
                })}
            </div>
        </div>
    );
};

export default ListOverview;
