import React, {useContext, useState} from 'react';
import api from '../../api';
import {showError} from '../../store';
import ProjectContext from '../project-context';
import Button from '../../Button/Button';
import Modal from "react-modal";
import styles from "./CheckTerms.module.scss";

const CheckTerms = () => {
    const project = useContext(ProjectContext);

    const [response, setResponse] = useState();
    const [showModal, setShowModal] = useState();
    const [loading, setLoading] = useState();

    const checkTermsWithChatGPT = async () => {
        try {
            setShowModal(true);
            setLoading(true);
            const response = await api.post('/api/openai', {
                "projectText": project.purpose + ' ' + project.description,
            });
            setLoading(false)
            setResponse(response?.choices[0]?.message?.content)
        } catch (err) {
            showError(`Noe gikk galt: ${err}`);
        } finally {
            window.notify();
        }
    };

    if (!project) {
        return null;
    }

    return (
        <div>
            <Button onClick={checkTermsWithChatGPT}>Sjekk mot vilkårene (AI)</Button>
            <Modal
                isOpen={showModal}
                overlayClassName={styles.modalWrapper}
                className={styles.modalContainer}
                onRequestClose={() => setShowModal(false)}
            >
                <h2>AI sin vurdering om spleisen bryter vilkårene våre</h2>

                {loading ? <p>Venter på svar..</p> : <pre className={styles.modalContent}>{response}</pre>}

                <div className={styles.modalButtons}>
                    <Button
                        className={styles.modalButton}
                        onClick={() => {
                            setShowModal(false);
                        }}
                    >
                        Lukk
                    </Button>
                </div>
            </Modal>
        </div>
    );
};

export default CheckTerms;
