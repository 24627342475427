import React, { useEffect, useRef, useState } from 'react';
import { spleisUrl } from '../../config';
import api from '../../api';
import Button from '../../Button/Button';
import styles from './CustomMessage.module.scss';
import ConfirmButton from '../../ConfirmButton/ConfirmButton';
import Collapsable from '../../Collapsable/Collapsable';

const CustomMessage = ({ project }) => {
    const putSpleisMessage = async (customMessage) => {
        api.put(`${spleisUrl}/api/admin/project/${project.id}/spleisMessage`, { message: customMessage });
        setSpleisMessage(customMessage);
        window.notify();
    };

    useEffect(() => {
        setSpleisMessage(project?.profile?.spleisMessage);
    }, [project?.id]);

    const [spleisMessage, setSpleisMessage] = useState('');
    const textField = useRef(null);

    return (
        <Collapsable title={'Blått banner'} className={styles.container} collapsed={true}>
            <div className={styles.form}>
                <textarea
                    placeholder="Melding på spleis.no"
                    value={spleisMessage || ''}
                    onChange={(e) => setSpleisMessage(e.target.value)}
                    ref={textField}
                />
                <div className={styles.buttons}>
                    <ConfirmButton
                        className={['dangerous-button', styles.submitButton]}
                        title="Lagre"
                        message="Er du sikker på at du vil legge ut meldingen på spleisen?"
                        onClick={() => putSpleisMessage(textField.current?.value)}
                    />
                    <Button className={styles.submitButton} onClick={() => putSpleisMessage('')}>
                        Slett
                    </Button>
                </div>
            </div>
        </Collapsable>
    );
};
export default CustomMessage;
