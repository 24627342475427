import React, { useContext, useState } from 'react';
import { spleisUrl } from '../../config';
import FormButton from '../../FormButton/FormButton';
import api from '../../api';
import { showError, showInfo } from '../../store';
import ProjectContext from '../project-context';
import moment from 'moment';
import { ISO_DATE_FORMAT } from '../../dates';
import styles from './ChangeEndsAt.module.scss';

const ChangeEndsAt = () => {
    const project = useContext(ProjectContext);
    const [endsAt, setEndsAt] = useState();
    const duration = moment.duration(moment(endsAt).diff(moment(project.published_at)));
    const months = duration.asMonths();

    const changeEndsAtHandler = () => {
        if (endsAt) {
            api.post(`${spleisUrl}/api/admin/project/${project.id}/updateProps`, {
                ends_at: moment(endsAt).utc().format(),
            })
                .then(() => {
                    setEndsAt(null);
                    showInfo('Endret sluttdato');
                    window.notify();
                })
                .catch(() => {
                    setEndsAt(null);
                    showError('Klarte ikke å endre sluttdato');
                });
        }
    };

    const endNowHandler = () => {
        api.post(`${spleisUrl}/api/admin/project/${project.id}/end`)
            .then(() => {
                showInfo('Endret sluttdato');
                window.notify();
            })
            .catch(() => {
                showError('Klarte ikke å endre sluttdato');
            });
    };

    if (!!project.published_at) {
        return (
            <FormButton
                title={`Endre sluttdato`}
                onClick={changeEndsAtHandler}
                optionalText="Avslutt nå"
                showOptionalButton={project.published_at && moment(project.ends_at).isAfter(moment())}
                onClickOptional={endNowHandler}
            >
                <div>
                    <input
                        type="datetime-local"
                        defaultValue={moment(project.ends_at).format(ISO_DATE_FORMAT)}
                        onChange={(e) => setEndsAt(e.target.value)}
                        autoFocus={true}
                    />
                </div>
                {months > 8 && <div className={styles.error}>Prosjektet kan ikke vare lengre enn 8 måneder.</div>}
            </FormButton>
        );
    }
    return null;
};

export default ChangeEndsAt;
