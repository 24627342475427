import React, { Component } from 'react';
import moment from 'moment';
import queryString from 'qs';
import SelectableTable from './mapping/SelectableTable';
import VisibleIf from '../VisibleIf/VisibleIf';
import Button from '../Button/Button';
import api from '../api';
import { regnskapUrl } from '../config';
import Banner from '../Banner/Banner';
import MapInvoiceFilter from './mapping/MapInvoiceFilter';
import { Helmet } from 'react-helmet';

export default class MapInvoices extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            pages: -1,
            loading: false,
            project: '',
            fromDate: moment('2018-01-01'),
            toDate: moment(),
            invoices: [],
            bankDeposits: [],
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.performSearch = this.performSearch.bind(this);
    }

    mapParamsToState() {
        const query = queryString.parse(this.props.location.search.slice(1));
        this.setState({
            project: query.project || '',
            fromDate: query.from ? moment(query.from) : moment('2018-01-01'),
            toDate: query.to ? moment(query.to) : moment(),
            amount: query.amount,
        });
    }

    componentWillMount() {
        this.mapParamsToState();
    }

    handleInputChange = async (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value,
        });
    };

    handleInputChangeAndSearch = async (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState(
            {
                [name]: value,
            },
            this.performSearch
        );
    };

    performSearch = async () => {
        this.props.history.push({
            pathname: this.props.history.location.pathname,
            search: `?project=${this.state.project}&from=${this.state.fromDate.format(
                'YYYY-MM-DD'
            )}&to=${this.state.toDate.format('YYYY-MM-DD')}&amount=${this.state.amount}`,
        });
    };

    handleInvoiceSelection = (invoice) => {
        const invoices = this.state.invoices;
        const indexOfInvoice = invoices.indexOf(invoice);
        if (indexOfInvoice !== -1) {
            invoices.splice(indexOfInvoice, 1);
        } else {
            invoices.push(invoice);
        }
        this.setState({ invoices });
    };

    handleBankSelection = (bankDeposit) => {
        this.setState({ bankDeposits: [bankDeposit] });
    };

    mapSelectedElements = async (invoices, bankDeposit) => {
        const body = {
            invoices,
            bankDeposit,
        };
        api.post(`${regnskapUrl}/api/map-invoices`, body)
            .then((response) => {
                this.setState({ connectResponse: response, error: false, invoices: [], bankDeposits: [] });
            })
            .catch(() => {
                this.setState({ connectResponse: null, error: true });
            });
    };

    render() {
        const connectButtonVisible =
            !!this.state.invoices &&
            !!this.state.bankDeposits &&
            this.state.invoices.length >= 1 &&
            this.state.bankDeposits.length >= 1;
        return (
            <div className="map-invoices-wrapper">
                <Helmet>
                    <title>Map faktura</title>
                </Helmet>
                <VisibleIf isVisible={!!this.state.error}>
                    <Banner title={`Noe gikk galt i mappingen`} themeClass="warning" />
                </VisibleIf>
                <VisibleIf isVisible={this.state.connectResponse}>
                    <Banner title={`${this.state.connectResponse}`} themeClass="info" />
                </VisibleIf>
                <MapInvoiceFilter
                    fromDate={this.state.fromDate}
                    toDate={this.state.toDate}
                    amount={this.state.amount}
                    handleInputChangeAndSearch={this.handleInputChangeAndSearch}
                    performSearch={this.performSearch}
                />

                <VisibleIf isVisible={connectButtonVisible}>
                    <Button onClick={() => this.mapSelectedElements(this.state.invoices, this.state.bankDeposits[0])}>
                        Koble sammen valgte elementer
                    </Button>
                </VisibleIf>
                <VisibleIf isVisible={!connectButtonVisible}>
                    Velg en eller flere i hver kolonne, så får du muligheten til å koble de sammen i regnskapet
                </VisibleIf>

                <div className="map-invoices-container">
                    <div className="map-invoices-items">
                        Faktura
                        <SelectableTable
                            url={'unmapped-invoices'}
                            fromDate={this.state.fromDate}
                            toDate={this.state.toDate}
                            amount={this.state.amount}
                            columns={[
                                { Header: 'Id', minWidth: 50, minResizeWidth: 30, accessor: 'id' },
                                {
                                    Header: 'Fakturanummer',
                                    minWidth: 60,
                                    minResizeWidth: 30,
                                    accessor: 'invoice_number',
                                },
                                { Header: 'Prosjekt', minWidth: 60, minResizeWidth: 30, accessor: 'to_project' },
                                { Header: 'Brukerid', minWidth: 70, minResizeWidth: 30, accessor: 'from_user' },
                                {
                                    Header: 'Opprettet',
                                    minResizeWidth: 30,
                                    accessor: 'created_at',
                                    minWidth: 150,
                                    Cell: (row) => <span>{moment(row.value).format('lll')}</span>,
                                },
                                {
                                    Header: 'Sendt',
                                    minResizeWidth: 30,
                                    accessor: 'sent_at',
                                    minWidth: 150,
                                    Cell: (row) => <span>{moment(row.value).format('lll')}</span>,
                                },
                                {
                                    Header: 'Betalt',
                                    minResizeWidth: 30,
                                    accessor: 'paid_at',
                                    minWidth: 150,
                                    Cell: (row) => <span>{moment(row.value).format('lll')}</span>,
                                },
                                {
                                    Header: 'Mengde',
                                    minResizeWidth: 30,
                                    minWidth: 70,
                                    accessor: 'amount',
                                    Cell: (row) => <span>{Math.round(row.value).toLocaleString()}</span>,
                                },
                                {
                                    Header: 'Givernavn',
                                    minResizeWidth: 30,
                                    accessor: 'transaction_list_info',
                                    minWidth: 400,
                                    Cell: (row) => <span>{row.value && row.value.name}</span>,
                                },
                            ]}
                            sorted={[{ id: 'paid_at', desc: true }]}
                            select={this.handleInvoiceSelection}
                            selectedItems={this.state.invoices}
                        />
                    </div>
                    <div className="map-invoices-items">
                        Bank
                        <SelectableTable
                            fromDate={this.state.fromDate}
                            toDate={this.state.toDate}
                            amount={this.state.amount}
                            url={'unmapped-bank-settlements'}
                            columns={[
                                { Header: 'Id', minResizeWidth: 30, minWidth: 50, accessor: 'id' },
                                {
                                    Header: 'Dato',
                                    minResizeWidth: 30,
                                    accessor: 'date',
                                    minWidth: 180,
                                    Cell: (row) => <span>{moment(row.value).format('lll')}</span>,
                                },
                                {
                                    Header: 'Beskrivelse',
                                    minResizeWidth: 30,
                                    accessor: 'description',
                                    minWidth: 500,
                                },
                                {
                                    Header: 'Inn',
                                    minWidth: 70,
                                    minResizeWidth: 30,
                                    accessor: 'in',
                                    Cell: (row) => <span>{Math.round(row.value).toLocaleString()}</span>,
                                },
                                {
                                    Header: 'Ut',
                                    minWidth: 70,
                                    minResizeWidth: 30,
                                    accessor: 'out',
                                    Cell: (row) => <span>{Math.round(row.value).toLocaleString()}</span>,
                                },
                                {
                                    Header: 'Bank',
                                    minWidth: 70,
                                    minResizeWidth: 30,
                                    accessor: 'source_bank',
                                },
                            ]}
                            sorted={[{ id: 'date', desc: true }]}
                            select={this.handleBankSelection}
                            selectedItems={this.state.bankDeposits}
                        />
                    </div>
                </div>
            </div>
        );
    }
}
