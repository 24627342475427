import FormButton from '../../FormButton/FormButton';
import React, { useState } from 'react';
import styles from './RedMark.module.scss';

const AddRedMark = ({ addRedMark }) => {
    const [reason, setReason] = useState('');

    return (
        <FormButton
            className="task-action-button red"
            title={`Rødmerk bruker`}
            onClick={() => addRedMark(reason)}
            disabled={!reason}
        >
            <textarea
                className={styles.reasonField}
                placeholder="Grunn"
                value={reason}
                onChange={(e) => setReason(e.target.value)}
                autoFocus={true}
            />
        </FormButton>
    );
};

export default AddRedMark;
