import React, { useContext } from 'react';
import styles from './FavoriteProject.module.scss';
import { FavoriteProjectsContext } from '../../favorite-projects';

const FavoriteProject = ({ projectId }) => {
    const { projectIds, add, remove } = useContext(FavoriteProjectsContext);
    const isFavorite = projectIds.includes(parseInt(projectId));

    if (isFavorite) {
        return (
            <span onClick={() => remove(projectId)} className={[styles.favoriteStar, styles.favorite].join(' ')}>
                ★️
            </span>
        );
    } else {
        return (
            <span onClick={() => add(projectId)} className={styles.favoriteStar}>
                ☆️
            </span>
        );
    }
};

export default FavoriteProject;
