import React, { useContext, useState } from 'react';
import { spleisUrl } from '../../config';
import api from '../../api';
import { showError, showInfo } from '../../store';
import ProjectContext from '../project-context';
import ConfirmButton from '../../ConfirmButton/ConfirmButton';
import FormButton from '../../FormButton/FormButton';
import moment from 'moment';
import { ISO_DATE_FORMAT } from '../../dates';

import styles from './ToggleSelfServeRefunds.module.scss';

const ToggleSelfServeRefunds = () => {
    const project = useContext(ProjectContext);
    const [timestamp, setTimestamp] = useState(moment(project.ends_at));
    const [deadline, setDeadline] = useState(moment(project.ends_at));

    const selfServeRefundEnabled = project?.can_self_serve_refund;

    const allowSelfServeRefund = async () => {
        try {
            await api.post(`${spleisUrl}/api/admin/project/${project.id}/allow-self-serve-refund`, {
                beforeTimestamp: timestamp ? moment(timestamp).utc().format() : undefined,
                deadline: deadline ? moment(deadline).format('YYYY-MM-DD') : undefined,
            });
            showInfo('Givere kan nå refundere sitt bidrag, og informeres på e-post');
            window.notify();
        } catch (err) {
            showError(`Feil ved oppdatering av prosjekt: ${err.text}`);
        }
    };

    const disallowSelfServeRefund = async () => {
        try {
            await api.post(`${spleisUrl}/api/admin/project/${project.id}/disallow-self-serve-refund`);
            showInfo('Givere kan ikke lenger refundere sitt bidrag');
            window.notify();
        } catch (err) {
            showError(`Feil ved oppdatering av prosjekt: ${err.text}`);
        }
    };

    const selfServeRefundText = `${!selfServeRefundEnabled ? 'Aktiver' : 'Deaktiver'} selvbetjent refusjon`;

    if (!selfServeRefundEnabled) {
        return (
            <FormButton
                title={`Tillat selvbetjent refusjon`}
                buttonText="Aktiver selvbetjent refusjon"
                successText="Aktiver selvbetjent refusjon"
                onClick={allowSelfServeRefund}
                doDoubleConfirm
                className="dangerous-button"
            >
                <div className={styles.modalForm}>
                    <p>Alle transaksjoner opprettet fram til tidspunktet som velges vil kunne refunderes.</p>
                    <label for="refund-before">Åpne refusjon for transaksjoner opprettet før:</label>
                    <input
                        name="refund-before"
                        type="datetime-local"
                        value={timestamp.format(ISO_DATE_FORMAT)}
                        onInput={(e) => {
                            setTimestamp(moment(e.target.value));
                        }}
                        autoFocus={true}
                    />
                    <label for="refund-deadline">Refusjonsfrist (standard er spleisens avslutningsdato):</label>
                    <input
                        name="refund-deadline"
                        type="date"
                        value={deadline.format('YYYY-MM-DD')}
                        onInput={(e) => {
                            setDeadline(moment(e.target.value));
                        }}
                    />
                    <p className={styles.modalNb}>
                        NB: Aktivering vil sende e-post til alle giverne som kan refundere!
                    </p>
                </div>
            </FormButton>
        );
    }

    return (
        <ConfirmButton
            className="dangerous-button"
            onClick={disallowSelfServeRefund}
            title={selfServeRefundText}
            successText={selfServeRefundText}
            message={`Er du sikker på at du vil deaktivere selvbetjent refusjon?`}
        />
    );
};

export default ToggleSelfServeRefunds;
