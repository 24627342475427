import React, { useEffect, useState } from 'react';
import api from '../api';
import { spleisUrl } from '../config';
import { Link } from 'react-router-dom';
import { formatMoneyKr } from '../formatters';

export const Subscription = ({ organizationId }) => {
    const [subscription, setSubscription] = useState();

    useEffect(() => {
        api.get(`${spleisUrl}/api/admin/subscription/organization/${organizationId}`).then((result) => {
            setSubscription(result);
        });
    }, []);

    const administerButton = <Link to={`/organizations/${organizationId}/subscription`}>Administrer</Link>;

    if (!subscription) {
        return <div>Ingen abonnement valgt {administerButton}</div>;
    }

    if (!subscription?.active) {
        return <div>Deaktivert abonnement {administerButton}</div>;
    }

    return (
        <div>
            Abonnement: {subscription?.subscription_type?.displayTitle} til{' '}
            {formatMoneyKr(subscription?.subscription_type?.price)} {administerButton}
        </div>
    );
};
