import React from 'react';
import ProjectLink from '../SmartLinks/ProjectLink';
import UserLink from '../SmartLinks/UserLink';
import moment from 'moment';
import { DATE_FORMAT } from '../dates';
import { formatMoney } from '../formatters';

const InvoiceTransactionTableRow = ({ transaction }) => {
    return (
        <tr key={transaction.id}>
            <td>
                <a href={`/transaction/${transaction.id}`}>{transaction.id}</a>
            </td>
            <td>
                <ProjectLink id={transaction.to_project} />
            </td>
            <td>
                <UserLink id={transaction.from_user} />
            </td>
            <td>{moment(transaction.created_at).format(DATE_FORMAT)}</td>
            <td>{formatMoney(transaction.amount)}</td>
            <td>
                {transaction.from_user_meta_data
                    ? transaction.from_user_meta_data.anonymous
                        ? 'Anonym'
                        : transaction.from_user_meta_data.name
                    : 'Anonym'}
            </td>
        </tr>
    )
}

export default InvoiceTransactionTableRow;