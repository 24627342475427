import React, { Component } from 'react';
import ConfirmModal from '../ConfirmModal/ConfirmModal';
import Button from '../Button/Button';

export default class ConfirmButton extends Component {
    state = { open: false };

    render = () => {
        const { title, message, successText, optionalText, cancelText, onOptionalClick, onClick, children, ...props } = this.props;
        return (
            <>
                <Button {...props} onClick={() => this.setState({ open: true })}>{title}</Button>
                <ConfirmModal
                    isOpen={this.state.open}
                    title={title}
                    message={message}
                    successText={successText || 'OK'}
                    optionalText={optionalText}
                    cancelText={cancelText || 'Avbryt'}
                    onClickSuccess={() => this.setState({ open: false }, onClick)}
                    onClickOptional={() => this.setState({ open: false }, onOptionalClick)}
                    showOptionalButton={!!optionalText && !!onOptionalClick}
                    onClickCancel={() => this.setState({ open: false })}
                >
                  {children}
                </ConfirmModal>
            </>
        );
    };
}
