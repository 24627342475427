import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Button from '../../Button/Button';
import TaskContainer from '../../TaskContainer/TaskContainer';
import qs from 'qs';
import api from '../../api';
import { spleisUrl } from '../../config';
import { showError } from '../../store';
import TagStats from './TagStats';

const subtractMonths = (date, months) => {
    date.setMonth(date.getMonth() - months);
    return date;
};

const minDate = '2016-01-01';
const maxDate = new Date().toISOString().slice(0, 10);
const defaultFromDate = subtractMonths(new Date(), 1).toISOString().substring(0, 10);
const defaultToDate = new Date().toISOString().substring(0, 10);

const TagsStats = () => {
    const [stats, setStats] = useState([]);
    const [fetching, setFetching] = useState(false);
    const [fromDate, setFromDate] = useState(() => defaultFromDate);
    const [toDate, setToDate] = useState(() => defaultToDate);

    const doFetch = () => {
        setStats([]);
        setFetching(true);
        const queryString = qs.stringify({
            fromDate: fromDate,
            toDate: toDate,
        });
        api.get(`${spleisUrl}/api/admin/project/tags/stats?${queryString}`)
            .then((res) => setStats(res))
            .catch(() => showError('Feil ved henting av stats'))
            .finally(() => setFetching(false));
    };

    useEffect(() => doFetch(), []);

    return (
        <TaskContainer>
            <Helmet>
                <title>Tags</title>
            </Helmet>
            <header className="task-header">
                <h1 className="task-title">Tags</h1>
            </header>
            <div className="task-body">
                <div className="stats-section">
                    <h2>Fra dato</h2>
                    <input
                        type="date"
                        min={minDate}
                        max={maxDate}
                        value={fromDate}
                        onChange={(e) => setFromDate(e.target.value)}
                    />
                </div>
                <div className="stats-section">
                    <h2>Til dato</h2>
                    <input
                        type="date"
                        min={minDate}
                        max={maxDate}
                        value={toDate}
                        onChange={(e) => setToDate(e.target.value)}
                    />
                </div>
                <div className="stats-section">
                    <Button type="button" onClick={doFetch} disabled={fetching}>
                        {fetching ? 'Henter...' : 'Hent data'}
                    </Button>
                </div>
                {stats.length > 0 && (
                    <div className="stats-section">
                        <h2>Resultat</h2>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Tag</th>
                                    <th>Antall</th>
                                    <th>Innsamlet</th>
                                </tr>
                            </thead>
                            <tbody>
                                {stats.map((s) => (
                                    <TagStats key={s.tag_id} tagStats={s} fromDate={fromDate} toDate={toDate} />
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
        </TaskContainer>
    );
};

export default TagsStats;
