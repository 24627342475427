import { ChangeAccountNumberButton } from './ChangeAccountNumberButton';
import React from 'react';
import InfoDataList, { DataListItem } from '../InfoDataList/InfoDataList';

export const NifBankAccountNumber = ({ accountNumber, organization, refreshData }) => {
    return (
        <div className={'infoboks nifData nifBankAccount'}>
            <InfoDataList title={'Data fra NIF'}>
                <DataListItem label="Kontonummer registert hos NIF">{accountNumber}</DataListItem>
            </InfoDataList>

            <ChangeAccountNumberButton
                organizationId={organization.id}
                bankAccountNumber={accountNumber}
                className={'kimh-change-account'}
                refreshData={refreshData}
            />
        </div>
    );
};
