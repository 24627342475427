import { Component } from 'react';
import VisibleIf from '../VisibleIf/VisibleIf';
import ProjectLink from '../SmartLinks/ProjectLink';
import * as _ from 'lodash';
import React from 'react';
import './ProjectList.scss';
import moment from 'moment';

export class ProjectList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            active: true,
            ended: true,
            paidOut: false,
            refunded: false,
            deleted: false,
            drafts: false,
            receiver: false,
            userIsReceiverAndDeleted: false,
        };
    }

    toggleVisible(key) {
        this.setState({
            [key]: !this.state[key],
        });
    }

    renderList(key, title, projects) {
        if (projects.length === 0) {
            return null;
        }
        return (
            <div>
                <h3
                    className={`project-list-section-title ${this.state[key] ? 'expanded' : ''}`}
                    onClick={() => this.toggleVisible(key)}
                >
                    {title} {this.state[key] ? '▾' : '▸'}
                </h3>
                <VisibleIf isVisible={this.state[key]}>
                    {projects.map((project) => (
                        <div key={project.id} className={`project-element`}>
                            <span className={'project-id'}>
                                <ProjectLink id={project.id} />
                            </span>
                            <span>{project.title}</span>
                        </div>
                    ))}
                </VisibleIf>
            </div>
        );
    }

    render() {
        if (!this.props.data || !this.props.data.length) {
            return <div>Ingen prosjekter</div>;
        }
        const allProjects = _.sortBy(this.props.data, (p) => -1 * p.id);
        const userProjects = _.partition(allProjects, (p) => !!p.userIsReceiver);
        const userIsReceiver = userProjects[0].filter((p) => !p.deleted && !!p.published_at);
        const userIsReceiverAndDeleted = userProjects[0].filter((p) => !!p.deleted);
        const projects = userProjects[1];

        const deleted = _.partition(projects, (p) => !!p.deleted);
        const drafts = _.partition(deleted[1], (p) => !p.published_at);
        const paidOut = _.partition(drafts[1], (p) => !!p.payout_at);
        const refunded = _.partition(paidOut[1], (p) => !!p.refunded_at);
        const ended = _.partition(refunded[1], (p) => moment(p.ends_at).isBefore(moment()));
        const rest = ended[1];
        return (
            <div>
                {this.renderList('receiver', 'Mottaker', userIsReceiver)}
                {this.renderList('userIsReceiverAndDeleted', 'Mottaker (slettede)', userIsReceiverAndDeleted)}
                {this.renderList('active', 'Aktive', rest)}
                {this.renderList('ended', 'Avsluttet', ended[0])}
                {this.renderList('paidOut', 'Utbetalte', paidOut[0])}
                {this.renderList('refunded', 'Refunderte', refunded[0])}
                {this.renderList('deleted', 'Slettede', deleted[0])}
                {this.renderList('drafts', 'Kladder', drafts[0])}
            </div>
        );
    }
}
