import React, { Component, useState } from 'react';
import { Link } from 'react-router-dom';
import withData from '../with-data';
import moment from 'moment';
import { spleisUrl } from '../config';
import { DATE_FORMAT_SHORT_TIME, formatDate, sortObjectArrayByDateField } from '../dates';
import VisibleIf from '../VisibleIf/VisibleIf';
import ShowMore from '../ShowMore/ShowMore';
import UserLink from '../SmartLinks/UserLink';
import Button from '../Button/Button';
import api from '../api';
import { auth } from '../auth';
import Comments from '../Comments/Comments';
import Collapsable from '../Collapsable/Collapsable';
import './Reports.scss';
import styles from './Reports.module.scss';
import ButtonLink from '../ButtonLink/ButtonLink';
import { showError } from '../store';

const reportTypes = {
    scam: 'Svindel',
    privacy: 'Personvern',
    pornographic: 'Pornografisk innhold',
    exposing_children: 'Eksponering av barn',
    other: 'Annet',
};

const hasAdminAcknowledged = (report, email) =>
    report.acknowledged_by && report.acknowledged_by.map((ack) => ack.email).includes(email);

const Report = (props) => {
    const { report, isReporter, acknowledge, currentAdminUser, lookupKey, id } = props;
    const {
        comment,
        type,
        created_at,
        reporter_id,
        acknowledged_by,
        attachment_description,
        attachment_filename,
    } = report;
    const kis = Object.entries(report.kis)[0];
    const [downloadingFile, setDownloadingFile] = useState(false);

    const downloadFile = () => {
        setDownloadingFile(true);
        api.get(`${spleisUrl}/api/admin/report/file/${attachment_filename}`, { stream: true })
            .then((res) => {
                if (res.ok) {
                    return res.blob();
                } else {
                    throw Error('Klarte ikke å laste ned filen');
                }
            })
            .then((blob) => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = attachment_filename;
                document.body.appendChild(a);
                a.click();
                a.remove();
            })
            .catch((err) => {
                showError(err.message, 5000);
            })
            .finally(() => {
                setDownloadingFile(false);
            });
    };

    return (
        <li className={`report-wrapper${acknowledged_by ? ' report-wrapper__acknowledged' : ''}`}>
            <div className="report-meta">
                <span>{reportTypes[type] || type} </span>
                <span className="report-time" title={formatDate(created_at)}>
                    {moment(created_at).format(DATE_FORMAT_SHORT_TIME)}
                </span>
            </div>
            <div className={`report ${isReporter ? 'report--is-reporter' : ''}`}>
                <div className="report__comment">{comment}</div>
                <VisibleIf isVisible={!isReporter}>
                    <span className="report__reporter">
                        Rapportert av bruker: <UserLink id={reporter_id} />
                    </span>
                </VisibleIf>
                <VisibleIf isVisible={!!isReporter}>
                    <div className="report__reported">
                        <span className="">Rapportert: </span>
                        <Link to={`/${kis.join('/')}`}>{kis.join(' ')}</Link>
                    </div>
                </VisibleIf>

                {attachment_description && attachment_filename && (
                    <div className={styles.reportFile}>
                        <span>Vedlegg: </span>
                        {downloadingFile ? (
                            <span style={{ fontStyle: 'italic' }}>Laster ned...</span>
                        ) : (
                            <span title={attachment_filename}>
                                <ButtonLink onClick={downloadFile}>{attachment_description}</ButtonLink>
                                &nbsp;({attachment_filename.split('.').pop()})
                            </span>
                        )}
                    </div>
                )}

                <Comments
                    toggle={true}
                    lookupKey="report"
                    id={report.id}
                    slackRef={lookupKey}
                    slackKey={id}
                    postToSlack={false}
                    className={styles.reportComments}
                />

                <span className="report__acknowledged--status">
                    {acknowledged_by
                        ? `✓ av ${acknowledged_by.map((user) => user.given_name).join(', ')}`
                        : 'Ikke anerkjent'}{' '}
                    <VisibleIf isVisible={!hasAdminAcknowledged(report, currentAdminUser.email)}>
                        <Button className="btn-text" onClick={() => acknowledge(report.id)}>
                            Anerkjenn
                        </Button>
                    </VisibleIf>
                </span>
            </div>
        </li>
    );
};

class Reports extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    setReportAsAcknowledged = async (id) => {
        await api.put(`${spleisUrl}/api/admin/report/acknowledge/${id}`);
        this.props.fetchData();
        window.notify();
    };

    setAllReportsAsAcknowledged = async () => {
        const { lookupKey, id, user } = this.props;

        if (user) {
            await api.put(`${spleisUrl}/api/admin/report/acknowledge/user/${user.id}`);
        } else {
            await api.put(`${spleisUrl}/api/admin/report/acknowledge`, { kis: { [lookupKey]: id } });
        }
        window.notify();
    };

    render = () => {
        const { user, data, lookupKey, id } = this.props;
        const reports = sortObjectArrayByDateField(data || [], 'created_at', true);

        if (!reports.length) {
            return <span />;
        }

        const currentAdminUser = auth.getProfile();

        const hasUnacknowledgedReports = reports.filter((rep) => !rep.acknowledged_by);
        const currentAdminUserHasUnacknowledgedReports = reports.filter(
            (rep) => !hasAdminAcknowledged(rep, currentAdminUser.email)
        );

        const title = !user ? 'Rapporteringer' : `Innrapportert av ${user.first ? user.first : 'bruker'}`;
        return (
            <Collapsable title={title} className="reports-container">
                <VisibleIf isVisible={!!lookupKey}>
                    <div className="reports__information">
                        <VisibleIf isVisible={!!hasUnacknowledgedReports.length}>
                            <div className="reports__information--unacknowledged">
                                Det finnes ikke-anerkjente rapporteringer
                            </div>
                        </VisibleIf>
                        {currentAdminUserHasUnacknowledgedReports.length ? (
                            <Button onClick={this.setAllReportsAsAcknowledged}>
                                Anerkjenn {currentAdminUserHasUnacknowledgedReports.length} ✓
                            </Button>
                        ) : (
                            <div className="reports__information--acknowledged">Du har anerkjent alle ✓</div>
                        )}
                    </div>
                </VisibleIf>
                <VisibleIf isVisible={reports.length >= 1}>
                    <ShowMore
                        items={reports}
                        listCreator={(showMoreItems) => (
                            <ul className="reports-list">
                                {showMoreItems.map((report) => (
                                    <Report
                                        key={report.id}
                                        report={report}
                                        isReporter={!!user}
                                        currentAdminUser={currentAdminUser}
                                        acknowledge={this.setReportAsAcknowledged}
                                        lookupKey={lookupKey}
                                        id={id}
                                    />
                                ))}
                            </ul>
                        )}
                    />
                </VisibleIf>
            </Collapsable>
        );
    };
}

class ReportsContainer extends Component {
    constructor(props) {
        super(props);
        const { lookupKey } = props;

        if (!lookupKey) {
            this.component = withData(Reports, (id) => `${spleisUrl}/api/admin/report/reporter/${id}`);
        } else {
            this.component = withData(Reports, (id) => `${spleisUrl}/api/admin/report?${lookupKey}=${id}`);
        }
    }

    render = () => React.createElement(this.component, this.props, null);
}

export default ReportsContainer;
