import numeral from 'numeral';
try {
    numeral.register('locale', 'no', {
        delimiters: { thousands: '\xa0', decimal: ',' },
        ordinal: number => (number === 1 ? 'krone' : 'kroner'),
        currency: { symbol: 'kr' },
    });
} catch (e) {}
numeral.locale('no');

export function formatMoney(amount) {
    return Number(amount).toLocaleString('no', { minimumFractionDigits: 0, maximumFractionDigits: 2 })
}

export function formatNumber(amount) {
    return numeral(Math.round(amount)).format('0');
}

export function formatMoneyKr(amount) {
    return `${formatMoney(amount)}\xa0kr`;
}

export function formatBankAccount(bankaccount) {
    return bankaccount && `${bankaccount.slice(0, 4)}.${bankaccount.slice(4, 6)}.${bankaccount.slice(6, 11)}`;
}

export function convertDateAsStringWithTwoDigitYearToFourDigitYear(date) {
    if (!date) {
        return null;
    }
    const TWENTY_FIRST_CENTURY_TWO_DIGIT_YEAR_LIMIT = 20;
    const dateAndMonthAsString = date?.slice(0, 4) || '';
    const yearAsString = date?.slice(4, 6);
    const year = parseInt(yearAsString, 10);
    return year <= TWENTY_FIRST_CENTURY_TWO_DIGIT_YEAR_LIMIT ? dateAndMonthAsString.concat('20').concat(yearAsString) : dateAndMonthAsString.concat('19').concat(yearAsString);
}