import api from '../api';
import { regnskapUrl } from '../config';
import React, { useEffect, useState } from 'react';
import { formatDate } from '../dates';
import { Helmet } from 'react-helmet';

const CsvFiles = () => {
    const [csvFiles, setCsvFiles] = useState([]);
    const [trigger, setTrigger] = useState(0);
    useEffect(() => {
        fetchData();
    }, [trigger]);

    useEffect(() => {
        const timer = setInterval(() => setTrigger(Math.random), 10000);
        return () => clearInterval(timer);
    }, []);
    const fetchData = async () => {
        try {
            const csvFiles = await api.get(`${regnskapUrl}/api/csv-files?limit=30`);
            setCsvFiles(csvFiles);
        } catch (e) {
            console.error(e);
        }
    };

    const renderCsvFiles = csvFiles?.map((file) => {
        return (
            <tr>
                <td>{file.email}</td>
                <td>{formatDate(file.createdAt)}</td>
                <td>
                    <a href={file.url}>{file.key}</a>
                </td>
            </tr>
        );
    });

    return (
        <>
            <Helmet>
                <title>CSV-filer</title>
            </Helmet>
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th>E-post</th>
                        <th>Opprettet</th>
                        <th>Adresse</th>
                    </tr>
                </thead>
                <tbody>{renderCsvFiles}</tbody>
            </table>
        </>
    );
};

export default CsvFiles;
