import React from 'react';
import PropTypes from 'prop-types';
import './Banner.scss';

const Banner = props => {
    const themeClass = `banner-theme-${props.themeClass || 'info'}`;
    return (
        <div className={`banner-container ${themeClass}`}>
            <h3 className="banner-title">{props.title}</h3>
        </div>
    );
};

Banner.propTypes = {
    themeClass: PropTypes.string,
    title: PropTypes.any.isRequired,
};

export default Banner;
