import React, { useState } from 'react';
import withData from '../with-data';
import { spleisUrl } from '../config';
import styles from './ProjectCategories.module.scss';
import VisibleIf from '../VisibleIf/VisibleIf';
import api from '../api';
import Modal from 'react-modal';
import Button from '../Button/Button';
import Collapsable from '../Collapsable/Collapsable';
import ConfirmButton from '../ConfirmButton/ConfirmButton';

const ProjectCategories = ({ data, project, withExplanation }) => {
    const categories = project?.categories ?? [];
    const categorySuggestions = data?.categorySuggestions ?? [];
    const allCategories = data?.allCategories ?? [];

    const addCategory = async (category) => {
        await api.put(`${spleisUrl}/api/admin/project/category/${project.id}`, { category });
        window.notify();
    };

    const removeCategory = async (category) => {
        await api.remove(`${spleisUrl}/api/admin/project/category/${project.id}`, { category });
        window.notify();
    };

    const copyCategoriesToProjectsInTemplate = async () => {
        await api.post(`${spleisUrl}/api/admin/project/category/${project.id}/copy-to-all-projects-in-template`, {});
        window.notify();
    };

    return (
        <Collapsable title="Kategorier">
            <Categories
                categories={categories}
                categorySuggestions={categorySuggestions}
                allCategories={allCategories}
                addCategory={addCategory}
                removeCategory={removeCategory}
                withExplanation={withExplanation}
                hasTemplateId={!!project.template_id}
                copyCategoriesToProjectsInTemplate={copyCategoriesToProjectsInTemplate}
            />
        </Collapsable>
    );
};

export const Categories = ({
    categories,
    categorySuggestions = [],
    allCategories,
    addCategory,
    removeCategory,
    withExplanation,
    hasTemplateId,
    copyCategoriesToProjectsInTemplate,
}) => {
    const [explanationOpen, setExplanationOpen] = useState(false);

    const selectableCategorySuggestions = categorySuggestions.filter((cs) => !categories.includes(cs.categoryId));
    const selectableCategorySuggestionIds = selectableCategorySuggestions.map((cs) => cs.categoryId);
    const unusedCategories = allCategories
        .filter((category) => !categories.includes(category.categoryId))
        .filter((category) => !selectableCategorySuggestionIds.includes(category.categoryId));
    const findCategoryName = (categoryId) => allCategories.find((c) => c.categoryId === categoryId)?.name ?? categoryId;

    return (
        <>
            <VisibleIf isVisible={categories.length > 0}>
                <div className={styles.projectCategories}>
                    <p className={styles.projectCategoryListExplanation}>(klikk på kategori for å fjerne)</p>
                    <ul className={styles.projectCategoryList}>
                        {categories.map((categoryId) => {
                            return (
                                <li
                                    key={categoryId}
                                    title={categoryId}
                                    className={styles.projectCategorySuggestion}
                                    onClick={() => removeCategory(categoryId)}
                                >
                                    {findCategoryName(categoryId)}
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </VisibleIf>

            <VisibleIf isVisible={selectableCategorySuggestions.length > 0 || unusedCategories.length > 0}>
                <div className={styles.projectCategories}>
                    <h3>Legg til kategori</h3>
                    <ul className={styles.projectCategoryList}>
                        {selectableCategorySuggestions.map((categorySuggestion) => {
                            return (
                                <li
                                    key={categorySuggestion.categoryId}
                                    title={categorySuggestion}
                                    className={styles.projectCategorySuggestion}
                                    onClick={() => addCategory(categorySuggestion.categoryId)}
                                >
                                    {findCategoryName(categorySuggestion.categoryId)}
                                </li>
                            );
                        })}
                        {unusedCategories.map((category) => {
                            return (
                                <li
                                    key={category.categoryId}
                                    title={category.categoryId}
                                    className={styles.projectCategoryOther}
                                    onClick={() => addCategory(category.categoryId)}
                                >
                                    {findCategoryName(category.categoryId)}
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </VisibleIf>

            {withExplanation && (
                <div
                    className={[styles.suggestionsLink, styles.projectCategories].join(' ')}
                    onClick={() => setExplanationOpen(true)}
                >
                    (Klikk her for forklaring av forslag)
                </div>
            )}

            {withExplanation && (
                <Modal
                    isOpen={explanationOpen}
                    overlayClassName={styles.modalWrapper}
                    className={styles.modalContainer}
                    onRequestClose={() => setExplanationOpen(false)}
                >
                    <h2>Forklaring til kategoriforslag</h2>

                    <div>
                        {categorySuggestions.map((categorySuggestion) => {
                            return (
                                <div key={categorySuggestion.categoryId} className={styles.modalCategory}>
                                    <div className={styles.projectCategoryExplanationHeader}>
                                        {findCategoryName(categorySuggestion.categoryId)}
                                    </div>
                                    {categorySuggestion.matchedWords.length > 0 && (
                                        <div>Hele ord: {categorySuggestion.matchedWords.join(', ')}</div>
                                    )}
                                    {categorySuggestion.matchedPhrases.length > 0 && (
                                        <div>Fraser: {categorySuggestion.matchedPhrases.join(', ')}</div>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                    <div className={styles.modalButtons}>
                        <Button
                            className={styles.modalButton}
                            onClick={() => {
                                setExplanationOpen(false);
                            }}
                        >
                            Lukk
                        </Button>
                    </div>
                </Modal>
            )}

            {hasTemplateId && (
                <ConfirmButton
                    title="Kopier kategorier til prosjekter i malen"
                    message="Dette vil kopiere disse kategoriene til alle prosjekter i malen. Er du sikker på at du vil gjøre dette?"
                    onClick={copyCategoriesToProjectsInTemplate}
                />
            )}
        </>
    );
};

export default withData(ProjectCategories, (id) => `${spleisUrl}/api/admin/project/category-suggestion/${id}`);
