import React, { useState } from 'react';
import moment from 'moment/moment';
import { formatMoney } from '../formatters';
import { DATE_FORMAT } from '../dates';
import VisibleIf from '../VisibleIf/VisibleIf';
import ShowMore from '../ShowMore/ShowMore';
import { spleisFrontUrl } from '../config';
import api from '../api';

import HtmlDiff from 'htmldiff-js';
import ApproveProject from '../Project/ApproveProject/ApproveProject';
import styles from './ProjectVersionsTable.module.scss';
import ConsiderProject from '../Project/ConsiderProject/ConsiderProject';

const ASCENDING = 'ascending';
const DESCENDING = 'descending';
const SORT_NUMBER = 'number';
const SORT_DATE = 'date';

const ProjectVersionsTable = ({ data, project = {} }) => {
    const [sort, setSort] = useState('created_at');
    const [direction, setDirection] = useState(DESCENDING);
    const [type, setType] = useState(SORT_DATE);
    const [comparing, setComparing] = useState(false);

    const updateSort = (field, sortType) => {
        let newDirection = DESCENDING;
        if (field === sort && direction === DESCENDING) {
            newDirection = ASCENDING;
        }

        setSort(field);
        setDirection(newDirection);
        setType(sortType || SORT_NUMBER);
    };

    const fetchVersion = async ({ projectId, versionId }) => {
        return await api.get(`${spleisFrontUrl}/project/${projectId}/version/${versionId}`);
    };

    const compareProjectVersions = async (version1, version2, done) => {
        const html1 = await fetchVersion(version1);
        const html2 = await fetchVersion(version2);
        const strippedHtml1 = html1.replace(/<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi, '');
        const strippedHtml2 = html2.replace(/<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi, '');
        const result = HtmlDiff.execute(strippedHtml1, strippedHtml2);
        const win = window.open(
            '',
            'Title',
            'toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=auto,resizable=yes,width=800,height=1000'
        );
        const styles = `<link rel='stylesheet' href='${spleisFrontUrl}/public/app.css'><style> 
            ins, del {
                display: inline-block; 
            }
            ins img, del img {
                padding: 0.5rem;
            }
            ins { 
                background-color: #dfd; 
            } 
            del { 
                background-color: #fdd; 
            } 
        </style>`;
        win.document.head.innerHTML += styles;
        win.document.body.innerHTML = result;
        done();
    };

    const compareNumbers = (a, b) => parseInt(b, 0) - parseInt(a, 0);
    const compareDates = (a, b) => moment(b).diff(moment(a));

    const sortFn = (a, b) => {
        let sortFunc = compareNumbers;

        if (type === SORT_DATE) {
            sortFunc = compareDates;
        }

        if (direction === DESCENDING) {
            return sortFunc(a[sort], b[sort]);
        } else {
            return sortFunc(b[sort], a[sort]);
        }
    };

    const versions = data || [];
    const lastApprovedVersion = versions
        .sort((a, b) => compareDates(a.published_at, b.published_at))
        .find((v) => !!v.approved);
    const fistVersion = versions.sort((a, b) => compareDates(b.published_at, a.published_at))[0];

    return (
        <div>
            <div className="project-table-data">
                <header className="project-table-data-header">
                    <h2>
                        Versjoner
                        <div className={styles.approveButton}>
                            <ConsiderProject project={project} versions={data} />
                            <ApproveProject project={project} />
                        </div>
                    </h2>
                    <div>{versions.length} versjoner</div>
                </header>
                {comparing && (
                    <div className={styles.projectVersionDiffLoaderContainer}>
                        <div className={styles.projectVersionDiffLoader}>
                            <h2 className={styles.projectVersionDiffLoaderText}>Sammenligner versjoner...</h2>
                            <h3 className={styles.projectVersionDiffLoaderText}>
                                Innsamlinger med mange bilder og mye tekst kan ta opp til flere minutter å sammenligne
                            </h3>
                        </div>
                    </div>
                )}
                <VisibleIf isVisible={versions.length > 0}>
                    <ShowMore
                        items={versions.sort(sortFn)}
                        showCount={1}
                        listCreator={(showMore) => {
                            return (
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>Tittel</th>
                                            <th>Formål</th>
                                            <th>Mål type</th>
                                            <th>Pengemål</th>
                                            <th>
                                                <button onClick={() => updateSort('created_at', SORT_DATE)}>
                                                    Opprettet
                                                </button>
                                            </th>
                                            <th>
                                                <button onClick={() => updateSort('published_at', SORT_DATE)}>
                                                    Publisert
                                                </button>
                                            </th>
                                            <th>Godkjent</th>
                                            <th>Sammenlign med siste godkjent</th>
                                            <th>Sammenlign med første versjon</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {showMore.map((version) => {
                                            return (
                                                <tr key={version.id}>
                                                    <td>
                                                        <a
                                                            target={'_blank'}
                                                            href={`${spleisFrontUrl}/project/${project.id}/version/${version.id}`}
                                                            rel="noopener noreferrer"
                                                        >
                                                            {version.title}
                                                        </a>
                                                    </td>
                                                    <td>{version.purpose}</td>
                                                    <td>{version.goaltype}</td>
                                                    <td>{formatMoney(version.moneygoal)}</td>
                                                    <td>{moment(version.created_at).format(DATE_FORMAT)}</td>
                                                    <td>
                                                        {version.published_at
                                                            ? moment(version.published_at).format(DATE_FORMAT)
                                                            : ''}
                                                    </td>
                                                    <td>
                                                        {version.approved ? 'Ja' : 'Nei'}
                                                        {version.auto_approved ? '. Har automatisk godkjenning 🤖' : ''}
                                                    </td>
                                                    <td>
                                                        {lastApprovedVersion ? (
                                                            lastApprovedVersion.id !== version.id ? (
                                                                <button
                                                                    onClick={() => {
                                                                        setComparing(true);
                                                                        compareProjectVersions(
                                                                            {
                                                                                projectId: project.id,
                                                                                versionId: lastApprovedVersion.id,
                                                                            },
                                                                            {
                                                                                projectId: project.id,
                                                                                versionId: version.id,
                                                                            },
                                                                            () => setComparing(false)
                                                                        );
                                                                    }}
                                                                >
                                                                    Sammenlign med sist godkjente
                                                                </button>
                                                            ) : null
                                                        ) : (
                                                            'Ingen godkjent versjon'
                                                        )}
                                                    </td>
                                                    <td>
                                                        {fistVersion ? (
                                                            fistVersion.id !== version.id ? (
                                                                <button
                                                                    onClick={() => {
                                                                        setComparing(true);
                                                                        compareProjectVersions(
                                                                            {
                                                                                projectId: project.id,
                                                                                versionId: fistVersion.id,
                                                                            },
                                                                            {
                                                                                projectId: project.id,
                                                                                versionId: version.id,
                                                                            },
                                                                            () => setComparing(false)
                                                                        );
                                                                    }}
                                                                >
                                                                    Sammenlign med første versjon
                                                                </button>
                                                            ) : null
                                                        ) : (
                                                            'Ingen første versjon'
                                                        )}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            );
                        }}
                    />
                </VisibleIf>
            </div>
        </div>
    );
};

export default ProjectVersionsTable;
