import React from 'react';
import { formatMoneyKr } from '../formatters';
import './UserProjectStats.scss';

const UserProjectStats = ({ data }) => {
    if (!data) {
        return null;
    }

    return (
        <>
            <div className="user-project-stats-section">
                <div>Totalt innsamlet:</div>
                <div>{formatMoneyKr(data.total_amount)}</div>
            </div>
            <div className="user-project-stats-section">
                <div>Totalt refundert:</div>
                <div>{formatMoneyKr(data.total_refunded_amount)}</div>
            </div>
        </>
    );
};

export default UserProjectStats;
