import React, { useState } from 'react';
import './Shortlinks.module.scss';
import FormButton from '../FormButton/FormButton';
import styles from './Shortlinks.module.scss';

const ShortlinkEdit = ({ shortIsEditable, shortlink = {}, buttonText = 'Legg til shortlink', onSubmit }) => {
    const [short, setShort] = useState(shortlink.short || '');
    const [link, setLink] = useState(shortlink.link || '');
    const [projectId, setProjectId] = useState(shortlink.project_id || '');
    const [organizationId, setOrganizationId] = useState(shortlink.organization_id || '');
    const [templateCauseId, setTemplateCauseId] = useState(shortlink.template_cause_id || '');
    const lessThanOne = [link, projectId, organizationId, templateCauseId].filter((exists) => exists).length < 1;
    const moreThanOne = [link, projectId, organizationId, templateCauseId].filter((exists) => exists).length > 1;
    return (
        <FormButton
            title={buttonText}
            onClick={() => onSubmit(short, link, projectId, organizationId, templateCauseId)}
            disabled={lessThanOne || moreThanOne || !short}
        >
            {shortIsEditable && <div>
                <label className={styles.label}>
                    <span>Short</span>
                    <input value={short} type="text" onChange={(e) => setShort(e.target.value)} placeholder="Short" />
                </label>
            </div>}
            <div>
                <label className={styles.label}>
                    <span>Path (start med /)</span>
                    <input value={link} type="text" onChange={(e) => setLink(e.target.value)} placeholder="Path" />
                </label>
            </div>
            <div>
                <label className={styles.label}>
                    <span>Project ID</span>
                    <input
                        value={projectId}
                        type="number"
                        onChange={(e) => setProjectId(e.target.value)}
                        placeholder="Project ID"
                    />
                </label>
            </div>
            <div>
                <label className={styles.label}>
                    <span>Organization ID</span>
                    <input
                        value={organizationId}
                        type="number"
                        onChange={(e) => setOrganizationId(e.target.value)}
                        placeholder="Organization ID"
                    />
                </label>
            </div>
            <div>
                <label className={styles.label}>
                    <span>Template Cause ID</span>
                    <input
                        value={templateCauseId}
                        type="number"
                        onChange={(e) => setTemplateCauseId(e.target.value)}
                        placeholder="Template Cause ID"
                    />
                </label>
            </div>
            {lessThanOne && <div className={styles.moreThanOne}>Du må fylle inn minst et felt!</div>}
            {moreThanOne && <div className={styles.moreThanOne}>Du må bare fylle inn en av delene!</div>}
        </FormButton>
    );
};

export default ShortlinkEdit;
