import React, { useEffect, useState } from 'react';
import { paymentUrl } from '../config';
import api from '../api';
import Button from '../Button/Button';
import { showError } from '../store';
import history from '../history';
import { formatMoneyKr } from '../formatters';
import { useLocation } from 'react-router';

const printDate = (d) => (d ? new Date(d).toLocaleString() : null);

const PayoutFileList = () => {
    const location = useLocation();
    const isInAccounting = location.pathname.includes('accounting');
    const [list, setList] = useState([]);

    const fetchData = () => {
        api.get(`${paymentUrl}/api/payout-files/`)
            .then((res) => setList(res))
            .catch((err) => showError(err.toString()));
    };
    const fetchAllPaymentFiles = () => {
        api.get(`${paymentUrl}/api/payout-files/all`)
            .then((res) => setList(res))
            .catch((err) => showError(err.toString()));
    };

    useEffect(fetchData, []);

    return (
        <div style={{ marginTop: '50px' }}>
            <h2>Betalingsfiler</h2>
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Opprettet</th>
                        <th>Beløp</th>
                        <th>Prosjekter/refunderinger</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {list
                        .sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
                        .map((t) => {
                            const ferdig = Object.values(t.result || {}).every((r) => !!r.payoutStatus);
                            const status = !t.result ? 'Ny' : ferdig ? 'Ferdig' : 'Under arbeid';
                            const amount = t?.input?.payouts?.reduce((acc, payout) => acc + payout?.amount, 0);
                            return (
                                <tr
                                    key={t.id}
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        history.push(`${isInAccounting ? '/accounting' : ''}/payout-files/${t.id}`);
                                    }}
                                >
                                    <td>
                                        {t.id.substring(0, 10)}
                                        ...
                                    </td>
                                    <td>{printDate(t.created_at)}</td>
                                    <td>{formatMoneyKr(amount)}</td>
                                    <td>{t.input.payouts.length}</td>
                                    <td>{status}</td>
                                </tr>
                            );
                        })}
                </tbody>
            </table>
            <Button onClick={fetchAllPaymentFiles}>Hent siste 50 betalingsfiler</Button>
        </div>
    );
};

export default PayoutFileList;
