import React, { Component } from 'react';
import api from '../../api';
import { regnskapUrl } from '../../config';
import ReactTable from 'react-table';

export default class SelectableTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            loading: false,
        };
        this.sorted = props.sorted || [{ id: 'id', desc: true }];
    }

    getSortData() {
        const sort = this.sorted;
        let sortField = '',
            sortDirection = '';
        if (sort.length > 0) {
            sortField = sort[0].id;
            sortDirection = sort[0].desc ? 'desc' : 'asc';
        }
        return { sortField, sortDirection };
    }

    fetchData = async (props = this.props) => {
        const { sortField, sortDirection } = this.getSortData();
        try {
            const body = {
                fromDate: props.fromDate,
                toDate: props.toDate,
                amount: props.amount,
                sortField,
                sortDirection,
                onlyIn: true,
            };

            return api.post(`${regnskapUrl}/api/${this.props.url}`, body);
        } catch (e) {
            console.error(e);
        }
    };

    componentWillReceiveProps(newProps) {
        if (
            newProps.toDate !== this.props.toDate ||
            newProps.fromDate !== this.props.fromDate ||
            newProps.amount !== this.props.amount ||
            (newProps.selectedItems !== this.props.selectedItems &&
                (newProps.selectedItems === undefined || newProps.selectedItems.length === 0))
        ) {
            this.setState({
                loading: true,
            });
            this.fetchData(newProps).then(data => {
                this.setState({
                    data: data.data,
                    loading: false,
                });
            });
        }
    }

    isRowSelected = rowInfo => {
        return this.props.selectedItems.indexOf(rowInfo.original) !== -1 && this.props.selectedItems.length > 0;
    };

    onRowClick = (state, rowInfo) => {
        return {
            onClick: () => {
                this.props.select(this.state.data[rowInfo.index]);
            },
            style: {
                background: this.isRowSelected(rowInfo) ? '#00afec' : 'white',
                color: this.isRowSelected(rowInfo) ? 'white' : 'black',
            },
        };
    };

    render() {
        return (
            <div>
                <ReactTable
                    manual
                    data={this.state.data}
                    columns={this.props.columns}
                    loadingText={'Laster..'}
                    noDataText={'Ingen rader funnet'}
                    ofText={'av'}
                    rowsText={'rader'}
                    defaultSorted={this.sorted}
                    onSortedChange={sorted => (this.sorted = sorted)}
                    loading={this.state.loading}
                    showPagination={false}
                    defaultPageSize={-1}
                    onFetchData={async () => {
                        this.setState({
                            loading: true,
                        });
                        const data = await this.fetchData();
                        this.setState({
                            data: data.data,
                            loading: false,
                        });
                    }}
                    getTrProps={this.onRowClick}
                />
            </div>
        );
    }
}
