import React, { useContext } from 'react';
import styles from './FavoriteOrganization.module.scss';
import { FavoriteOrganizationsContext } from '../../favorite-organizations';

const FavoriteOrganization = ({ organizationId }) => {
    const { organizationIds, add, remove } = useContext(FavoriteOrganizationsContext);
    const isFavorite = organizationIds.includes(parseInt(organizationId));

    if (isFavorite) {
        return (
            <span onClick={() => remove(organizationId)} className={[styles.favoriteStar, styles.favorite].join(' ')}>
                ★️
            </span>
        );
    } else {
        return (
            <span onClick={() => add(organizationId)} className={styles.favoriteStar}>
                ☆️
            </span>
        );
    }
};

export default FavoriteOrganization;
