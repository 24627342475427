import React, { useState, useEffect } from 'react';
import { spleisUrl } from '../config';
import FormButton from '../FormButton/FormButton';
import { showError, showInfo } from '../store';
import api from '../api';
import './Subscription.scss';
import { formatMoneyKr } from '../formatters';

const ChangeSubscriptionType = ({ organizationId }) => {
    const [subscriptionTypes, setSubscriptionTypes] = useState([]);
    const [selectedSubscriptionType, setSelectedSubscriptionType] = useState();

    const changeSubscriptionType = () => {
        api.post(`${spleisUrl}/api/admin/subscription/organization/${organizationId}`, {
            subscriptionTypeName: selectedSubscriptionType?.name,
        })
            .then(() => {
                showInfo('Endret abonnementet');
                window.notify();
            })
            .catch(() => {
                showError('Klarte ikke å endre abonnementet');
            });
    };

    const getSubscriptionTypes = () => {
        api.get(`${spleisUrl}/api/admin/subscription/subscriptionTypes`)
            .then((subscriptionTypes) => {
                setSubscriptionTypes(subscriptionTypes);
            })
            .catch(() => {
                showError('Klarte ikke å hente abonnementer');
            });
    };

    useEffect(() => {
        getSubscriptionTypes();
    }, []);

    return (
        <FormButton
            buttonText={`Endre`}
            title={'Endre abonnement'}
            onClick={changeSubscriptionType}
            className={'button-link'}
        >
            <div className={'subscription-types-selection'}>
                {subscriptionTypes.map((subscriptionType) => (
                    <div
                        className={`subscription-type-item ${
                            selectedSubscriptionType?.name === subscriptionType.name &&
                            'subscription-type-item-selected'
                        }`}
                        onClick={() => {
                            setSelectedSubscriptionType(subscriptionType);
                        }}
                    >
                        {subscriptionType?.display_title} ({subscriptionType?.name}) –{' '}
                        {formatMoneyKr(subscriptionType?.price)}
                    </div>
                ))}
            </div>
        </FormButton>
    );
};

export default ChangeSubscriptionType;
