import React, { Component, useEffect, useState } from 'react';
import moment from 'moment';
import { showError, showInfo } from '../store';
import api from '../api';
import { paymentUrl, spleisFrontUrl, spleisUrl } from '../config';
import './Project.scss';
import { formatBankAccount, formatMoney, formatMoneyKr, formatNumber } from '../formatters';
import withData from '../with-data';
import Timeline, { buildTimeline } from '../Timeline/Timeline';
import VisibleIf from '../VisibleIf/VisibleIf';
import Comments from '../Comments/Comments';
import Ratings, { ratingLevelText } from '../Rating/Ratings';
import ConfirmButton from '../ConfirmButton/ConfirmButton';
import Banner from '../Banner/Banner';
import { DATE_FORMAT, DATE_FORMAT_SHORT_TIME, formatDate, formatDateOnly } from '../dates';
import TaskContainer from '../TaskContainer/TaskContainer';
import TransactionTable from '../TransactionTable/TransactionTable';
import ProjectTags from '../ProjectTags/ProjectTags';
import Audits from '../Audits/Audits';
import Reports from '../Reports/Reports';
import ProjectVersionsTable from '../ProjectVersion/ProjectVersionsTable';
import RefundTable from '../Refund/RefundTable';
import FunctionalBanner from '../FunctionalBanner/FunctionalBanner';
import InvoiceTable from '../Invoice/InvoiceTable';
import OwnerAndReceiver from './OwnerAndReceiver/OwnerAndReceiver';
import ApproveProject from './ApproveProject/ApproveProject';
import { BlockProject } from './BlockProject/BlockProject';
import { useReceiverBankAccount } from './receiver-bank-account-hook';
import { useProjectBankAccount } from './project-bank-account-hook';
import { USER_TYPE } from '../User/User';
import CustomMessage from './CustomMessage/CustomMessage';
import styles from './Project.module.scss';
import ProjectCategories from '../ProjectCategories/ProjectCategories';
import ProjectDescription from './ProjectDescription/ProjectDescription';
import ProjectTitle from './ProjectTitle/ProjectTitle';
import ProjectPurpose from './ProjectPurpose/ProjectPurpose';
import MatchingGift from './MatchingGift/MatchingGift';
import ProjectContext from './project-context';
import ProjectOptions from './ProjectOptions/ProjectOptions';
import { ProjectClaims } from './ProjectClaims/ProjectClaims';
import Button from '../Button/Button';
import FavoriteProject from './FavoriteProject/FavoriteProject';
import { Helmet } from 'react-helmet';
import ProjectSummary from './ProjectSummary/ProjectSummary';
import GoogleButton from './GoogleButton';
import ProjectUpdates from './ProjectUpdates/ProjectUpdates';
import ButtonLink from '../ButtonLink/ButtonLink';
import { RewardsWithData } from './ProjectRewards/ProjectRewards';
import UpdateProjectAccountNumber from './UpdateProjectAccountNumber/UpdateProjectAccountNumber';
import ProjectAlerts from './ProjectAlerts/ProjectAlerts';
import { Link } from 'react-router-dom';

const KIMH_END_DATE = moment('2020-12-01');

const TransactionsWithData = withData(TransactionTable, (id) => `${spleisUrl}/api/admin/transaction/project/${id}`);
const RefundsWithData = withData(RefundTable, (id) => `${spleisUrl}/api/admin/transaction/refunds/${id}`);
const ProjectVersionsWithData = withData(ProjectVersionsTable, (id) => `${spleisUrl}/api/admin/project/${id}/versions`);
const ProjectUpdatesWithData = withData(ProjectUpdates, (id) => `${spleisUrl}/api/admin/project/${id}/updates`);

const isPayedOutOrRefunded = (project) => project.payout_at || project.refunded_at;
export const canRefund = (project) => !isPayedOutOrRefunded(project);
const isMarkedForRefund = (project) => !!project.marked_for_refund_at;
const canPayout = (project) =>
    !project.refunded_at &&
    !project.deleted &&
    (project.organization_accepted_payout_at || project.receiver?.type === USER_TYPE.USER);

export function payoutStatus(payout) {
    if (!!payout.payout_at) {
        return `Utbetalt: ${moment(payout.payout_at).format(DATE_FORMAT_SHORT_TIME)}`;
    }
    if (!!payout.refunded_at) {
        return `Tilbakebetalt: ${moment(payout.payout_at).format(DATE_FORMAT_SHORT_TIME)}`;
    }
    if (!payout.payout_at && !payout.refunded_at && (!payout.blocked || !payout.blocked.blocked_types)) {
        return 'Venter på utbetaling';
    }
    const blockedTypes = (payout.blocked && payout.blocked.blocked_types) || [];
    if (blockedTypes.includes('BLOCKED_FOR_PAYOUT_IN_PAYOUTFILE')) {
        return 'Ligger i betalingsfil';
    }
    return null;
}

const FeeDetails = ({ settlement }) => (
    <div className={styles.settlementDetails}>
        {settlement.details.map((detail) => (
            <>
                <span>
                    {formatMoneyKr((detail.fee || detail.bambusaFee) / (1 + (detail.vatPercentage || 0.25)) || 0)}
                </span>
                <span>{detail.description}</span>
            </>
        ))}
    </div>
);

class UnacknowledgedProjectReports extends Component {
    render() {
        const hasUnacknowledgedReports =
            this.props.data && this.props.data.filter((rep) => !rep.acknowledged_by).length;
        return hasUnacknowledgedReports ? (
            <div className="project__payout--unacknowledged">Det finnes ikke-anerkjente rapporteringer</div>
        ) : null;
    }
}

const UnacknowledgedProjectReportsWithData = withData(
    UnacknowledgedProjectReports,
    (id) => `${spleisUrl}/api/admin/report?project=${id}`
);

const blockedTypesHasStatusUnderPayment = (blockedTypes) =>
    blockedTypes.length === 1 &&
    (blockedTypes.includes('BLOCKED_FOR_PAYOUT_IN_PROGRESS') ||
        blockedTypes.includes('BLOCKED_FOR_PAYOUT_IN_PAYOUTFILE'));

const MoneyLaunderingInfo = ({ project, projectId }) => {
    const GREEN = '#00ff99';
    const YELLOW = '#f8f50a';
    const RED = '#da3d00';

    const initialAdditionalData = {
        previousExperience: 'N/A',
        previousExperienceReceiver: 'N/A',
        openDisposition: 'N/A',
        receiverNegativeMedia: 'N/A',
        negativeMedia: 'N/A',
        riskCountry: 'N/A',
        projectsLastYearEqualPurpose: 'Nei',
        projectsLastYearUpdates: 'Nei',
        terrorismeConclusion: 'Ikke satt',
        volutenteerRegister: 'N/A',
        belongsInArea: 'N/A',
        receiverBelongsInArea: 'N/A',
        sharedCount: 0,
        mediaOppslag: 'Nei - finner ingen oppslag',
        otherInformation: '',
        launderingOrSanctionedCountries: 'N/A',
    };
    const [moneyLaunderingInfo, setMoneyLaunderingInfo] = useState({});
    const [storedMoneyLaunderingInfo, setStoredMoneyLaunderingInfo] = useState({});
    const [additionalData, setAdditionalData] = useState(initialAdditionalData);
    const [status, setStatus] = useState(false);

    useEffect(() => {
        if (project?.id) {
            api.get(`${spleisUrl}/api/admin/project/${projectId}/money-laundering-info`)
                .then((response) => {
                    setMoneyLaunderingInfo(response);
                })
                .catch((err) => {
                    showError(`Feil oppsto da vi skulle hente data om transaksjonene: ${err}`);
                    window.notify();
                });
            setAdditionalData(initialAdditionalData);
        }
    }, [projectId]);

    const fetchSharedCount = (projectId) => {
        if (projectId) {
            const projectUrl = `${spleisFrontUrl}/project/${projectId}`;
            api.get(
                `https://api.sharedcount.com/v1.0?url=${projectUrl}&apiKey=a8a32dca449201a3851078f8e74a57c29b464f03`
            )
                .then((response) => {
                    setAdditionalData({ ...additionalData, sharedCount: response.Facebook?.share_count });
                })
                .catch((err) => {
                    showError(`Feil oppsto da vi skulle hente data fra sharedcount ${err}`);
                    window.notify();
                });
        }
    };

    useEffect(() => {
        if (project?.id) {
            api.get(`${spleisUrl}/api/admin/project/${projectId}/money-laundering`)
                .then((response) => {
                    setStoredMoneyLaunderingInfo(response);
                })
                .catch((err) => {
                    showError(`Feil oppsto da vi skulle hente hvitvaskingsrapport for prosjektet: ${err}`);
                    window.notify();
                });
        }
    }, [projectId]);

    const getMoneyLaunderingInfo = (info) => {
        return [
            { label: 'URL', value: info.url },
            { label: 'Type spleis', value: info.type },
            { label: 'Konto verifisert', value: !!info.bankAccountIsVerified ? 'Ja' : 'Nei' },
            { label: 'Innsamlet beløp', value: formatMoneyKr(info.collectedAmount) },
            { label: 'Antall innbetalinger', value: formatNumber(info.transactionCount) },
            { label: 'Antall givere', value: formatNumber(info.giverCount) },
            {
                label: 'Antall spleiser for innsamler siste to år',
                value: formatNumber(info.projectCountForOwnerLastTwoYear),
                color: () => (info.projectCountForOwnerLastTwoYear <= 3 ? GREEN : RED),
            },
            {
                label: 'Antall spleiser for mottaker siste to år',
                value: formatNumber(info.projectCountForReceiverLastTwoYear),
                color: () => (info.projectCountForReceiverLastTwoYear <= 3 ? GREEN : RED),
            },
            { label: 'Har spleisene siste år hatt samme formål?', value: info.projectsLastYearEqualPurpose },
            {
                label: 'Har innsamler lagt ut oppdateringer på hvordan pengene har blitt brukt?',
                value: info.projectsLastYearUpdates,
            },
            { label: 'Andel beløp fra Stripe', value: `${formatNumber(info.stripeAmountPercent)} %` },
            { label: 'Høyeste beløp fra en og samme giver', value: formatMoneyKr(info.largestGiverSum) },
            { label: 'Antall innbetalinger fra samme giver', value: formatNumber(info.largestGiverCount) },
            {
                label: '%-andel av totalt innsamlet fra samme giver',
                value: Math.round(100 * info.largestGiverPercent) + ' %',
                color: () => (info.largestGiverPercent < 0.3 ? GREEN : info.largestGiverPercent < 0.51 ? YELLOW : RED),
            },
            { label: 'Antall store innbetalinger over 5 000 kroner', value: formatNumber(info.largePayments) },
            { label: 'Sum beløp over 5 000 kroner', value: formatMoneyKr(info.sumOfLargePayments) },
            {
                label: 'Hvor mye av spleisen som er betalt med like beløp over 500 kr.',
                value: `${formatNumber(info.percentOfLargeEqualAmounts)} %`,
            },
            { label: 'Antall varslinger/rapporteringer', value: info.reports },
            { label: 'Rating', value: ratingLevelText(info.rating) },
            {
                label: 'Delinger (antall) SharedCount',
                value: !!info.sharedCount ? formatNumber(info.sharedCount) : undefined,
            },
            {
                label: 'Mediaoppslag',
                value: info.mediaOppslag,
            },
            {
                label: 'Skal midlene fra spleisen videre til land knyttet til hvitvasking og/eller sanksjonerte land?',
                value: info.launderingOrSanctionedCountries,
            },
            {
                label: 'I såfall, hvilke land?',
                value: info.country,
            },
            {
                label: 'Annen informasjon',
                value: info.otherInformation,
            },
            {
                label: 'Skal innsamlingen finansiere et formål i et land på listen over risikoland?',
                value: info.riskCountry,
                color: (value) => (value === 'Ja' ? RED : value === 'Nei' ? GREEN : ''),
            },
            {
                label: 'Er innsamlerne omtalt negativt i media?',
                value: info.negativeMedia,
                color: (value) => (value === 'Ja' ? RED : value === 'Nei' ? GREEN : ''),
            },
            {
                label: 'Er mottakerne for de innsamlede midlene omtalt negativt i media?',
                value: info.receiverNegativeMedia,
                color: (value) => (value === 'Ja' ? RED : value === 'Nei' ? GREEN : ''),
            },
            {
                label: 'Er det åpenhet rundt disponeringen av innsamlede midler?',
                value: info.openDisposition,
                color: (value) => (value === 'Nei' ? RED : value === 'Ja' ? GREEN : ''),
            },
            {
                label: 'Har innsamler drevet innsamlinger eller veldedig arbeid på spleis før?',
                value: info.previousExperience,
                color: (value) => (value === 'Nei' ? RED : value === 'Ja' ? GREEN : ''),
            },
            {
                label: 'Har mottaker drevet innsamlinger eller veldedig arbeid på spleis før?',
                value: info.previousExperienceReceiver,
                color: (value) => (value === 'Nei' ? RED : value === 'Ja' ? GREEN : ''),
            },
            {
                label: 'Har innsamler tilhørighet til området hvor det samles inn penger til?',
                value: info.belongsInArea,
                color: (value) => (value === 'Nei' ? RED : value === 'Ja' ? GREEN : ''),
            },
            {
                label: 'Har mottaker tilhørighet til området hvor det samles inn penger til?',
                value: info.receiverBelongsInArea,
                color: (value) => (value === 'Nei' ? RED : value === 'Ja' ? GREEN : ''),
            },
            {
                label: 'Er innsamler eller mottaker registrert i frivillighetsregisteret?',
                value: info.volutenteerRegister,
                color: (value) => (value === 'Nei' ? RED : value === 'Ja' ? GREEN : ''),
            },
            { label: 'Samlet vurdering relatert til terrorfinansiering', value: info.terrorismeConclusion },
        ];
    };

    const storeMoneyLaundering = () => {
        api.post(`${spleisUrl}/api/admin/project/${projectId}/money-laundering`, {
            projectId,
            data: { ...moneyLaunderingInfo, ...additionalData },
            status,
        })
            .then((response) => {
                setStoredMoneyLaunderingInfo({ stored: true, ...response });
            })
            .catch((err) => {
                showError(`Feil oppsto da vi skulle lagre hvitvaskingsrapport for prosjektet: ${err}`);
                window.notify();
            });
    };

    if (!storedMoneyLaunderingInfo) {
        return null;
    }

    if (!project?.id || parseInt(project.collected_amount, 10) === 0) {
        return null;
    }

    const data = getMoneyLaunderingInfo(
        storedMoneyLaunderingInfo.stored ? storedMoneyLaunderingInfo.data : moneyLaunderingInfo
    );

    const tableData = data
        .filter((row) => row.label !== '' && !!row.value)
        .map((row) => (
            <tr key={row.label}>
                <td className={'money-laundering-table-label'}>{row.label}</td>
                <td style={row.color ? { backgroundColor: row.color(row.value) } : {}}>{row.value}</td>
            </tr>
        ));
    const table = (
        <table className="table table-striped">
            <tbody>{tableData}</tbody>
        </table>
    );

    const renderStatus = (status) => {
        if (status === 1) {
            return 'OK - settes til utbetaling';
        } else if (status === 2) {
            return 'Avvik fra rutinen - nærmere oppfølging kreves';
        } else if (status === 3) {
            return 'Ikke godkjent - utbetaling stoppet';
        }
    };

    const terrorismeSelect = (name) => {
        return (
            <select
                onChange={(e) => setAdditionalData({ ...additionalData, [name]: e.currentTarget.value })}
                defaultValue={'N/A'}
            >
                <option value={'Ja'}>Ja</option>
                <option value={'Nei'}>Nei</option>
                <option value={'N/A'}>N/A</option>
            </select>
        );
    };

    const countrySelect = (name) => {
        return (
            <select
                onChange={(e) => {
                    console.log(
                        Array.from(e.currentTarget.selectedOptions)
                            .map((o) => o.value)
                            .join(', ')
                    );
                    setAdditionalData({
                        ...additionalData,
                        [name]: Array.from(e.currentTarget.selectedOptions)
                            .map((o) => o.value)
                            .join(', '),
                    });
                }}
                multiple
            >
                <option value="AFGHANISTAN">AFGHANISTAN</option>
                <option value="ALBANIA">ALBANIA</option>
                <option value="AMERICAN SAMOA">AMERICAN SAMOA</option>
                <option value="ANGUILLA">ANGUILLA</option>
                <option value="ANTIGUA AND BARBUDA">ANTIGUA AND BARBUDA</option>
                <option value="ARMENIA">ARMENIA</option>
                <option value="AUSTRALIA">AUSTRALIA</option>
                <option value="BAHAMAS">BAHAMAS</option>
                <option value="BAHRAIN">BAHRAIN</option>
                <option value="BARBADOS">BARBADOS</option>
                <option value="BELARUS">BELARUS</option>
                <option value="BELIZE">BELIZE</option>
                <option value="BENIN">BENIN</option>
                <option value="BERMUDA">BERMUDA</option>
                <option value="BOSNIA AND HERZEGOWINA">BOSNIA AND HERZEGOWINA</option>
                <option value="BOTSWANA">BOTSWANA</option>
                <option value="BRUNEI DARUSSALAM">BRUNEI DARUSSALAM</option>
                <option value="BURKINA FASO">BURKINA FASO</option>
                <option value="BURUNDI">BURUNDI</option>
                <option value="CAMBODIA">CAMBODIA</option>
                <option value="CAMEROON">CAMEROON</option>
                <option value="CAPE VERDE">CAPE VERDE</option>
                <option value="CAYMAN ISLANDS">CAYMAN ISLANDS</option>
                <option value="CENTRAL AFRICAN REPUBLIC">CENTRAL AFRICAN REPUBLIC</option>
                <option value="CHAD">CHAD</option>
                <option value="COMOROS">COMOROS</option>
                <option value="CONGO, THE DEMOCRATIC REPUBLIC OF THE">CONGO, THE DEMOCRATIC REPUBLIC OF THE</option>
                <option value="COOK ISLANDS">COOK ISLANDS</option>
                <option value="COTE D IVOIRE">COTE D IVOIRE</option>
                <option value="CURAÇAO">CURAÇAO</option>
                <option value="CYPRUS">CYPRUS</option>
                <option value="DOMINICA">DOMINICA</option>
                <option value="EGYPT">EGYPT</option>
                <option value="ERITREA">ERITREA</option>
                <option value="ETHIOPIA">ETHIOPIA</option>
                <option value="FIJI">FIJI</option>
                <option value="GHANA">GHANA</option>
                <option value="GIBRALTAR">GIBRALTAR</option>
                <option value="GUAM">GUAM</option>
                <option value="GUATEMALA">GUATEMALA</option>
                <option value="GUERNSEY">GUERNSEY</option>
                <option value="GUINEA">GUINEA</option>
                <option value="GUINEA-BISSAU">GUINEA-BISSAU</option>
                <option value="GUYANA">GUYANA</option>
                <option value="HAITI">HAITI</option>
                <option value="HONG KONG">HONG KONG</option>
                <option value="IRAN (ISLAMIC REPUBLIC OF)">IRAN (ISLAMIC REPUBLIC OF)</option>
                <option value="IRAQ">IRAQ</option>
                <option value="IRELAND">IRELAND</option>
                <option value="ISLE OF MAN">ISLE OF MAN</option>
                <option value="JAMAICA">JAMAICA</option>
                <option value="JERSEY">JERSEY</option>
                <option value="JORDAN">JORDAN</option>
                <option value="KENYA">KENYA</option>
                <option value="KOREA, DEMOCRATIC PEOPLES REPUBLIC OF">KOREA, DEMOCRATIC PEOPLES REPUBLIC OF</option>
                <option value="KUWAIT">KUWAIT</option>
                <option value="LAO PEOPLES DEMOCRATIC REPUBLIC">LAO PEOPLES DEMOCRATIC REPUBLIC</option>
                <option value="LEBANON">LEBANON</option>
                <option value="LIBERIA">LIBERIA</option>
                <option value="LIBYAN ARAB JAMAHIRIYA">LIBYAN ARAB JAMAHIRIYA</option>
                <option value="LIECHTENSTEIN">LIECHTENSTEIN</option>
                <option value="LUXEMBOURG">LUXEMBOURG</option>
                <option value="MADAGASCAR">MADAGASCAR</option>
                <option value="MALDIVES">MALDIVES</option>
                <option value="MALI">MALI</option>
                <option value="MALTA">MALTA</option>
                <option value="MARSHALL ISLANDS">MARSHALL ISLANDS</option>
                <option value="MAURITIUS">MAURITIUS</option>
                <option value="MONACO">MONACO</option>
                <option value="MONTENEGRO">MONTENEGRO</option>
                <option value="MOROCCO">MOROCCO</option>
                <option value="MOZAMBIQUE">MOZAMBIQUE</option>
                <option value="MYANMAR">MYANMAR</option>
                <option value="NAMIBIA">NAMIBIA</option>
                <option value="NAURU">NAURU</option>
                <option value="NETHERLANDS">NETHERLANDS</option>
                <option value="NICARAGUA">NICARAGUA</option>
                <option value="NIGERIA">NIGERIA</option>
                <option value="NIUE">NIUE</option>
                <option value="OMAN">OMAN</option>
                <option value="PAKISTAN">PAKISTAN</option>
                <option value="PALAU">PALAU</option>
                <option value="PALESTINIAN TERRITORY, OCCUPIED">PALESTINIAN TERRITORY, OCCUPIED</option>
                <option value="PANAMA">PANAMA</option>
                <option value="PARAGUAY">PARAGUAY</option>
                <option value="PHILIPPINES">PHILIPPINES</option>
                <option value="PUERTO RICO">PUERTO RICO</option>
                <option value="QATAR">QATAR</option>
                <option value="RUSSIAN FEDERATION">RUSSIAN FEDERATION</option>
                <option value="SAINT KITTS AND NEVIS">SAINT KITTS AND NEVIS</option>
                <option value="SAINT LUCIA">SAINT LUCIA</option>
                <option value="SAINT VINCENT AND THE GRENADINES">SAINT VINCENT AND THE GRENADINES</option>
                <option value="SAMOA">SAMOA</option>
                <option value="SAUDI ARABIA">SAUDI ARABIA</option>
                <option value="SENEGAL">SENEGAL</option>
                <option value="SEYCHELLES">SEYCHELLES</option>
                <option value="SIERRA LEONE">SIERRA LEONE</option>
                <option value="SINGAPORE">SINGAPORE</option>
                <option value="SOMALIA">SOMALIA</option>
                <option value="SOUTH SUDAN">SOUTH SUDAN</option>
                <option value="SRI LANKA">SRI LANKA</option>
                <option value="SUDAN">SUDAN</option>
                <option value="SWAZILAND">SWAZILAND</option>
                <option value="SWITZERLAND">SWITZERLAND</option>
                <option value="SYRIAN ARAB REPUBLIC">SYRIAN ARAB REPUBLIC</option>
                <option value="TAIWAN, PROVINCE OF CHINA">TAIWAN, PROVINCE OF CHINA</option>
                <option value="TAJIKISTAN">TAJIKISTAN</option>
                <option value="TANZANIA, UNITED REPUBLIC OF">TANZANIA, UNITED REPUBLIC OF</option>
                <option value="THAILAND">THAILAND</option>
                <option value="TRINIDAD AND TOBAGO">TRINIDAD AND TOBAGO</option>
                <option value="TUNISIA">TUNISIA</option>
                <option value="TURKEY">TURKEY</option>
                <option value="TURKMENISTAN">TURKMENISTAN</option>
                <option value="TURKS AND CAICOS ISLANDS">TURKS AND CAICOS ISLANDS</option>
                <option value="UGANDA">UGANDA</option>
                <option value="UKRAINE">UKRAINE</option>
                <option value="UNITED ARAB EMIRATES">UNITED ARAB EMIRATES</option>
                <option value="UZBEKISTAN">UZBEKISTAN</option>
                <option value="VANUATU">VANUATU</option>
                <option value="VENEZUELA">VENEZUELA</option>
                <option value="VIET NAM">VIET NAM</option>
                <option value="VIRGIN ISLANDS (BRITISH)">VIRGIN ISLANDS (BRITISH)</option>
                <option value="VIRGIN ISLANDS (U.S.)">VIRGIN ISLANDS (U.S.)</option>
                <option value="YEMEN">YEMEN</option>
                <option value="ZIMBABWE">ZIMBABWE</option>
            </select>
        );
    };

    const getVerifiedOrProvidedName = (user) => {
        if (user.verified_name) {
            return user.verified_name;
        } else if (user.first || user.last) {
            return `${user.first || ''} ${user.last || ''}`;
        } else {
            return user.name;
        }
    };

    const ownerName =
        project?.owner?.type === 'user' ? getVerifiedOrProvidedName(project.owner) : project?.owner?.name || '';

    const receiverName =
        project?.receiver?.type === 'user'
            ? getVerifiedOrProvidedName(project.receiver)
            : project?.receiver?.name || '';

    return (
        <div className="project-table-data">
            <header className="project-table-data-header">
                <h2>Hvitvaskingsinfo</h2>
                {storedMoneyLaunderingInfo.stored && (
                    <div>
                        Rapporten ble lagret {formatDate(storedMoneyLaunderingInfo.date)} av{' '}
                        {storedMoneyLaunderingInfo.responsible.name}. Konklusjonen var:{' '}
                        <span className={'money-laundering-status'}>
                            {renderStatus(storedMoneyLaunderingInfo.status)}
                        </span>
                    </div>
                )}
            </header>
            {table}
            <VisibleIf isVisible={!storedMoneyLaunderingInfo.stored}>
                <div className={'additional-money-laundering-fields'}>
                    <label className={'money-laundering-field'}>
                        <span className={'money-laundering-label'}>Delinger (antall) SharedCount</span>
                        {additionalData.sharedCount ? (
                            <>{additionalData.sharedCount}</>
                        ) : (
                            <button onClick={() => fetchSharedCount(projectId)}>Hent data fra SharedCount</button>
                        )}
                    </label>
                    <label className={'money-laundering-field'}>
                        <span className={'money-laundering-label'}>Mediaoppslag</span>
                        <select
                            onChange={(e) =>
                                setAdditionalData({ ...additionalData, mediaOppslag: e.currentTarget.value })
                            }
                            defaultValue={'Nei - finner ingen oppslag'}
                        >
                            <option value={'Ja - landsdekkende medier'}>Ja - landsdekkende medier</option>
                            <option value={'Ja - lokale medier'}>Ja - lokale medier</option>
                            <option value={'Nei - finner ingen oppslag'}>Nei - finner ingen oppslag</option>
                        </select>
                    </label>
                    <div>
                        <span className={'money-laundering-label'}>Google</span>
                        <div>
                            <GoogleButton className="money-laundering-google-button" whatToGoogle={project.title}>
                                Prosjekttittel
                            </GoogleButton>
                            {ownerName && (
                                <GoogleButton className="money-laundering-google-button" whatToGoogle={ownerName}>
                                    {ownerName}
                                </GoogleButton>
                            )}
                            {receiverName && (
                                <GoogleButton className="money-laundering-google-button" whatToGoogle={receiverName}>
                                    {receiverName}
                                </GoogleButton>
                            )}
                        </div>
                    </div>
                    <label className={'money-laundering-field'}>
                        <span className={'money-laundering-label'}>Annen informasjon</span>

                        <textarea
                            onChange={(e) =>
                                setAdditionalData({ ...additionalData, otherInformation: e.currentTarget.value })
                            }
                        />
                    </label>
                </div>
                {(moneyLaunderingInfo.projectCountForOwnerLastTwoYear >= 3 ||
                    moneyLaunderingInfo.projectCountForReceiverLastTwoYear >= 3) && (
                    <>
                        <h3 className={'moneylaundering-header'}>Innsamlers spleiser siste år</h3>
                        <div className={'terrorisme-money-laundering-fields'}>
                            <label className={'money-laundering-field'}>
                                <span className={'money-laundering-label'}>Har spleisene like formål?</span>
                                <select
                                    onChange={(e) =>
                                        setAdditionalData({
                                            ...additionalData,
                                            projectsLastYearEqualPurpose: e.currentTarget.value,
                                        })
                                    }
                                    defaultValue={'Nei'}
                                >
                                    <option value={'Ja'}>Ja</option>
                                    <option value={'Nei'}>Nei</option>
                                </select>
                            </label>
                            <label className={'money-laundering-field'}>
                                <span className={'money-laundering-label'}>
                                    Har innsamler lagt ut oppdateringer på hvordan pengene har blitt brukt?
                                </span>
                                <select
                                    onChange={(e) =>
                                        setAdditionalData({
                                            ...additionalData,
                                            projectsLastYearUpdates: e.currentTarget.value,
                                        })
                                    }
                                    defaultValue={'Nei'}
                                >
                                    <option value={'Ja'}>Ja</option>
                                    <option value={'Nei'}>Nei</option>
                                </select>
                            </label>
                        </div>
                    </>
                )}

                <h3 className={'moneylaundering-header'}>Terrorfinansiering</h3>
                <div className={'terrorisme-money-laundering-fields'}>
                    <label className={'money-laundering-field'}>
                        <span className={'money-laundering-label'}>
                            Skal midlene fra spleisen videre til land knyttet til hvitvasking og/eller sanksjonerte
                            land?
                        </span>
                        {terrorismeSelect('launderingOrSanctionedCountries')}
                    </label>
                    {additionalData.launderingOrSanctionedCountries === 'Ja' && (
                        <label className={'money-laundering-field'}>
                            <span className={'money-laundering-label'}>Hvilket land skal midlene videre til?</span>
                            {countrySelect('country')}
                        </label>
                    )}
                    <label className={'money-laundering-field'}>
                        <span className={'money-laundering-label'}>
                            Skal innsamlingen finansiere et formål i et land på listen over risikoland?
                        </span>
                        {terrorismeSelect('riskCountry')}
                    </label>
                    <label className={'money-laundering-field'}>
                        <span className={'money-laundering-label'}>Er innsamlerne omtalt negativt i media?</span>
                        {terrorismeSelect('negativeMedia')}
                    </label>
                    <label className={'money-laundering-field'}>
                        <span className={'money-laundering-label'}>
                            Er mottakerne for de innsamlede midlene omtalt negativt i media?
                        </span>
                        {terrorismeSelect('receiverNegativeMedia')}
                    </label>
                    <label className={'money-laundering-field'}>
                        <span className={'money-laundering-label'}>
                            Er det åpenhet rundt disponeringen av innsamlede midler?
                        </span>
                        {terrorismeSelect('openDisposition')}
                    </label>
                    <label className={'money-laundering-field'}>
                        <span className={'money-laundering-label'}>
                            Har innsamler drevet innsamlinger eller veldedig arbeid før?
                        </span>
                        {terrorismeSelect('previousExperience')}
                    </label>
                    <label className={'money-laundering-field'}>
                        <span className={'money-laundering-label'}>
                            Har mottaker drevet innsamlinger eller veldedig arbeid før?
                        </span>
                        {terrorismeSelect('previousExperienceReceiver')}
                    </label>
                    <label className={'money-laundering-field'}>
                        <span className={'money-laundering-label'}>
                            Har innsamler tilhørighet til området hvor det samles inn penger til?
                        </span>
                        {terrorismeSelect('belongsInArea')}
                    </label>
                    <label className={'money-laundering-field'}>
                        <span className={'money-laundering-label'}>
                            Har mottaker tilhørighet til området hvor det samles inn penger til?
                        </span>
                        {terrorismeSelect('receiverBelongsInArea')}
                    </label>
                    <label className={'money-laundering-field'}>
                        <span className={'money-laundering-label'}>
                            Er innsamlerne eller mottaker registrert i frivillighetsregisteret?
                        </span>
                        {terrorismeSelect('volutenteerRegister')}
                    </label>
                    <label className={'money-laundering-field'}>
                        <span className={'money-laundering-label'}>
                            Samlet vurdering relatert til terrorfinansiering
                        </span>
                        <input
                            type={'text'}
                            onChange={(e) =>
                                setAdditionalData({ ...additionalData, terrorismeConclusion: e.currentTarget.value })
                            }
                        />
                    </label>
                </div>
                <h3 className={'moneylaundering-header'}>Konklusjon</h3>
                <div>
                    <select onChange={(e) => setStatus(e.currentTarget.value)} defaultValue={''}>
                        <option></option>
                        <option value={1}>OK - settes til utbetaling</option>
                        <option value={2}>Avvik fra rutinen - nærmere oppfølging kreves</option>
                        <option value={3}>Ikke godkjent - utbetaling stoppet</option>
                    </select>
                    <ConfirmButton
                        title="Lagre hvitvaskingsinfo for prosjektet"
                        message={`Er du sikker på at du vil lagre hvitvaskingsinfo for prosjektet?`}
                        disabled={!status}
                        className={'money-laundering-submit'}
                        onClick={() => storeMoneyLaundering()}
                    />
                </div>
            </VisibleIf>
        </div>
    );
};

const ProjectPayouts = ({ project = {}, toBePaidOut, payouts = [] }) => {
    const [processing, setProcessing] = useState(false);
    const { bankAccount: receiverBankAccount } = useReceiverBankAccount({ receiver: project?.receiver });
    const { bankAccount: projectBankAccount } = useProjectBankAccount({ project });
    const projectAccountIsSameAsReceiverAccount = receiverBankAccount?.id === projectBankAccount?.id;
    const accountChangedAt = project?.payout_bank_account_changed_at ? (
        formatDate(project?.payout_bank_account_changed_at)
    ) : (
        <span className="strong">Ukjent</span>
    );

    const createPayout = (projectId, checkKAR = false) => {
        setProcessing(true);
        api.post(`${spleisUrl}/api/admin/payout/${projectId}/create-and-set-ready`, { checkKAR })
            .then(() => {
                setProcessing(false);
                showInfo('Laget utbetaling for prosjekt');
                window.notify();
            })
            .catch((err) => {
                setProcessing(false);
                showError(`Feil oppsto da vi skulle lage utbetaling: ${err}`);
                window.notify();
            });
    };

    const setPayoutReady = (payoutId) => {
        setProcessing(true);
        api.put(`${spleisUrl}/api/admin/payout/${payoutId}`)
            .then(() => {
                setProcessing(false);
                showInfo('Prosjektet ble lagt opp til betaling!');
                window.notify();
            })
            .catch((err) => {
                setProcessing(false);
                showError(`Feil da vi skulle legge opp prosjektet til utbetaling: ${err}`);
                window.notify();
            });
    };

    const setPayout = (payoutId) => {
        setProcessing(true);
        api.put(`${spleisUrl}/api/admin/payout/${payoutId}/paidOut`)
            .then(() => {
                showInfo('Prosjektet ble satt som utbetalt!');
                setProcessing(false);
                window.notify();
            })
            .catch((err) => {
                setProcessing(false);
                showError(`Feil da vi skulle sette utbetalt: ${err}`);
                window.notify();
            });
    };

    const verifyAccountOwnership = (bankAccount) => {
        setProcessing(true);
        api.post(`${spleisUrl}/api/admin/bank-account/${bankAccount.id}/verify-ownership`)
            .then(() => {
                showInfo('KAR sjekket');
                setProcessing(false);
                window.notify();
            })
            .catch((err) => {
                setProcessing(false);
                showError(`Feil da vi skulle sjekke KAR: ${err}`);
            });
    };

    const toBePaidOutBelongsToProject = !toBePaidOut || toBePaidOut.project_id === project.id;
    const payoutBelongsToProject = !payouts || !(payouts[0] && payouts[0].project_id !== project.id);

    if (!project.id || !toBePaidOutBelongsToProject || !payoutBelongsToProject) {
        return null;
    }

    const existsPayoutThatWillBeOverwritten =
        payouts.filter((payout) => !payout.blocked || !payout.blocked.blocked_types).length > 0;

    const bankAccountVerificationTextAndButton = (bankAccount) => {
        const statusCode = bankAccount?.kar_account_verification?.statusCode;
        if (statusCode === '01' || statusCode === '02') {
            return (
                <>
                    (<span className="green">KAR: OK</span>)
                </>
            );
        }
        const verifyButton = (
            <>
                (<ButtonLink onClick={() => verifyAccountOwnership(bankAccount)}>verifiser</ButtonLink>)
            </>
        );
        if (bankAccount?.kar_account_verification?.statusText) {
            return (
                <>
                    (<span className="red">KAR: {bankAccount?.kar_account_verification?.statusText}</span>){' '}
                    {verifyButton}
                </>
            );
        }
        return (
            <>
                (<span className="gray">KAR: Ikke verifisert</span>) {verifyButton}
            </>
        );
    };

    return (
        <div className="project-table-data">
            <header className="project-table-data-header">
                <h2>Utbetalinger</h2>
                <div>
                    <div className={styles.spacer}>Utbetalingskonto sist endret: {accountChangedAt}</div>
                    {!projectAccountIsSameAsReceiverAccount && projectBankAccount?.account_number && (
                        <p className={'warning'}>Mottaker har et annet kontonummer enn prosjektet.</p>
                    )}
                    {!projectBankAccount?.account_number && (
                        <p className={'warning'}>Prosjektet har ikke noe kontonummer satt.</p>
                    )}
                    {projectBankAccount?.account_number && (
                        <div className={styles.spacer}>
                            Prosjektet vil bli utbetalt til{' '}
                            <span className="strong">{formatBankAccount(projectBankAccount?.account_number)}</span>
                            &nbsp;{bankAccountVerificationTextAndButton(projectBankAccount)}
                        </div>
                    )}
                    {!projectAccountIsSameAsReceiverAccount && receiverBankAccount?.account_number && (
                        <div className={styles.spacer}>
                            Mottaker sitt kontonummer er{' '}
                            <span className="strong">{formatBankAccount(receiverBankAccount.account_number)}</span>
                            &nbsp;{bankAccountVerificationTextAndButton(receiverBankAccount)}
                        </div>
                    )}
                    <div className={styles.spacer}>
                        <UpdateProjectAccountNumber projectBankAccount={projectBankAccount} project={project} />
                    </div>
                </div>
                <div>
                    {payouts.length} {payouts.length === 1 ? 'utbetaling' : 'utbetalinger'}
                </div>
            </header>
            <VisibleIf isVisible={payouts.length > 0 || !!toBePaidOut}>
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Total</th>
                            <th>Gebyr</th>
                            <th>MVA</th>
                            <th>Gebyrgrunnlag</th>
                            <th>Utbetal</th>
                            <th>Status</th>
                            <th>Utbetales til</th>
                            <VisibleIf isVisible={canPayout(project)}>
                                <th />
                            </VisibleIf>
                            <th></th>
                            <th>Gebyrdetaljer</th>
                        </tr>
                    </thead>
                    <tbody>
                        {!!toBePaidOut && (
                            <tr key={'toBePaidOut'}>
                                <td name="#">Rest</td>
                                <td name="total">
                                    {toBePaidOut.settlement ? formatMoney(toBePaidOut.settlement.collectedAmount) : '-'}
                                </td>
                                <td
                                    name="fee"
                                    className={parseInt(toBePaidOut.fee, 0) > 0 ? 'table-positive-number' : ''}
                                >
                                    {formatMoney(toBePaidOut.fee)}
                                </td>
                                <td name="vat">{formatMoney(toBePaidOut.vat)}</td>
                                <td name="basis_for_fee">{formatMoney(toBePaidOut.basis_for_fee)}</td>
                                <td name="payout_amount">{formatMoney(toBePaidOut.amount)}</td>
                                <td name="payout_status">
                                    <span>Ikke lagt opp til utbetaling</span>
                                </td>
                                <td name="payout_to" />
                                <VisibleIf isVisible={canPayout(project)}>
                                    <td name="payout_button">
                                        <UnacknowledgedProjectReportsWithData id={project.id} />
                                        <span>
                                            <ConfirmButton
                                                title="Opprett og legg opp"
                                                message={`Er du sikker på at du vil lage en utbetaling? ${
                                                    existsPayoutThatWillBeOverwritten
                                                        ? 'Det finnes en utbetaling som ikke er lagt opp og blir overskrevet. '
                                                        : ''
                                                }${
                                                    project.matchingGift && !project.matchingGift.transaction_id
                                                        ? 'Matching gift-beløpet er ikke registrert innbetalt. '
                                                        : ''
                                                }`}
                                                disabled={!canPayout(project) || processing}
                                                onClick={() => createPayout(project.id, true)}
                                                onOptionalClick={() => createPayout(project.id, false)}
                                                optionalText={'Uten KAR-sjekk'}
                                            />
                                        </span>
                                    </td>
                                </VisibleIf>
                                <td />
                                <td name="fee_details">
                                    <FeeDetails settlement={toBePaidOut.settlement} />
                                </td>
                            </tr>
                        )}

                        {payouts.map((payout) => {
                            const blockedTypes = (payout.blocked && payout.blocked.blocked_types) || [];
                            return (
                                <tr key={payout.id}>
                                    <td>{payout.id}</td>
                                    <td>{payout.settlement ? formatMoney(payout.settlement.collectedAmount) : '-'}</td>
                                    <td className={parseInt(payout.fee, 0) > 0 ? 'table-positive-number' : ''}>
                                        {formatMoney(payout.fee)}
                                    </td>
                                    <td>{formatMoney(payout.vat)}</td>
                                    <td>{formatMoney(payout.basis_for_fee)}</td>
                                    <td>{formatMoney(payout.amount)}</td>
                                    <td>
                                        <span>{payoutStatus(payout)}</span>
                                        <VisibleIf isVisible={!project.payout_bank_account_id}>
                                            <span>Mangler verifisert kontonummer</span>
                                        </VisibleIf>
                                    </td>
                                    <td>
                                        <VisibleIf isVisible={!!payout.account_data}>
                                            <span>
                                                {payout.account_data &&
                                                    formatBankAccount(payout.account_data.account_number)}
                                            </span>
                                        </VisibleIf>
                                    </td>
                                    <VisibleIf isVisible={canPayout(project)}>
                                        <td>
                                            <span>
                                                <VisibleIf
                                                    isVisible={
                                                        !(payout.blocked?.blocked_types?.length > 0) &&
                                                        !payout.payout_at
                                                    }
                                                >
                                                    <UnacknowledgedProjectReportsWithData id={project.id} />
                                                    <ConfirmButton
                                                        title="Legg utbetaling opp for utbetaling"
                                                        message="Er du sikker på at du vil legge opp denne til utbetaling?"
                                                        disabled={!canPayout(project) || processing}
                                                        onClick={() => setPayoutReady(payout.id)}
                                                    />
                                                </VisibleIf>
                                                {blockedTypesHasStatusUnderPayment(blockedTypes) && (
                                                    <ConfirmButton
                                                        className="dangerous-button"
                                                        title="Sett utbetalt"
                                                        message="Utbetaling gjøres normalt via betalingsfil. Er du helt sikker på at du vil sette denne til utbetalt manuelt?"
                                                        disabled={!canPayout(project) || processing}
                                                        onClick={() => setPayout(payout.id)}
                                                    />
                                                )}
                                            </span>
                                        </td>
                                    </VisibleIf>
                                    <td>
                                        {payout.pdf_url ? (
                                            <a href={payout.pdf_url} target={'_blank'} rel={'noopener noreferrer'}>
                                                {payout.pdf_url}
                                            </a>
                                        ) : null}
                                    </td>
                                    <td name="fee_details">
                                        <FeeDetails settlement={payout.settlement} />
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </VisibleIf>
        </div>
    );
};

const ProjectActions = (props) => {
    const [isProcessing, setIsProcessing] = useState(false);

    const markForRefund = () => {
        setIsProcessing(true);
        api.post(`${spleisUrl}/api/admin/project/${props.project.id}/mark-for-refund`)
            .then(() => showInfo('Prosjektet er lagt opp til refundering, og vil bli refundert neste arbeidsdag'))
            .catch((err) => {
                showError(`Feil når vi skulle legge opp prosjekt for refundering: ${err.text}`);
            })
            .finally(() => {
                setIsProcessing(false);
                window.notify();
            });
    };

    const unmarkForRefund = () => {
        setIsProcessing(true);
        api.remove(`${spleisUrl}/api/admin/project/${props.project.id}/mark-for-refund`)
            .then(() => showInfo('Prosjektet er ikke lenger lagt opp til refundering'))
            .catch((err) => {
                showError(`Feil når vi skulle legge opp prosjekt for refundering: ${err.text}`);
            })
            .finally(() => {
                setIsProcessing(false);
                window.notify();
            });
    };

    const setFollowUp = (followUp) => {
        setIsProcessing(true);
        api.post(`${spleisUrl}/api/admin/project/${props.project.id}/follow-up`, {
            followUp,
        })
            .then(() => {
                if (followUp) {
                    showInfo('Prosjekt ble satt til oppfølging');
                } else {
                    showInfo('Prosjekt er ikke lengre til oppfølging ');
                }
            })
            .catch((err) => {
                showError(`Feil ved endring av oppfølging av prosjekt: ${err.text}`);
            })
            .finally(() => {
                setIsProcessing(false);
                window.notify();
            });
    };

    const addToKimh = () => {
        setIsProcessing(true);
        api.put(`${spleisUrl}/api/admin/project/${props.project.id}/add-to-kimh`)
            .then(() => {
                showInfo('Lagt til i KIMH');
                window.notify();
            })
            .catch((err) => {
                showError(`Klarte ikke legge til i KIMH: ${err.text}`);
            })
            .finally(() => setIsProcessing(false));
    };

    const removeHeaderImage = () => {
        setIsProcessing(true);
        api.remove(`${spleisUrl}/api/admin/project/${props.project.id}/picture`)
            .then(() => {
                setIsProcessing(false);
                window.notify();
                showInfo('Bilde er nå slettet');
            })
            .catch((err) => {
                setIsProcessing(false);
                showError(`Feil ved sletting av bilde: ${err}`);
            })
            .finally(() => setIsProcessing(false));
    };

    const { project, projectIsUnderPayment } = props;
    const followUpButtonText = project.admin_follow_up ? 'Fjern til oppfølging' : 'Sett til oppfølging';

    return (
        <div className="task-actions">
            <ApproveProject project={project} className="task-action-button" />

            <BlockProject
                project={project}
                className="task-action-button"
                projectIsUnderPayment={projectIsUnderPayment}
                updateProject={props.fetchData}
            />

            <Button
                className={`task-action-button`}
                title={followUpButtonText}
                onClick={() => setFollowUp(!project.admin_follow_up)}
            >
                {followUpButtonText}
            </Button>

            {isMarkedForRefund(project) ? (
                <ConfirmButton
                    className="task-action-button dangerous-button"
                    title="Stans lagt opp refusjon"
                    disabled={!canRefund(project) || !isMarkedForRefund(project) || isProcessing}
                    onClick={unmarkForRefund}
                    message="Sikker på at du vil stanse refusjonen?"
                    successText="Stans refusjon"
                />
            ) : (
                <ConfirmButton
                    className="task-action-button dangerous-button"
                    title="Legg opp for refusjon"
                    disabled={!canRefund(project) || isMarkedForRefund(project) || isProcessing}
                    onClick={markForRefund}
                    message="Sikker på at du vil legge spleisen opp for refusjon?<br />Den vil bli refundert neste arbeidsdag."
                    successText="Legg opp for refusjon"
                />
            )}

            {!!project.img_url && (
                <ConfirmButton
                    className="task-action-button dangerous-button"
                    title="Fjern bilde"
                    disabled={!project.img_url || isProcessing}
                    onClick={removeHeaderImage}
                    message="Sikker på at du vil fjerne bilde?"
                    successText="Fjern bilde"
                />
            )}

            {moment().isBefore(KIMH_END_DATE) && !(project.profile && project.profile.kimh2020Project) && (
                <ConfirmButton
                    className="task-action-button"
                    title="Legg til KIMH"
                    disabled={isProcessing}
                    message="Sikker på at du vil legge spleisen til i Klubben i mitt hjerte?"
                    onClick={addToKimh}
                />
            )}

            <ProjectOptions buttonClass="task-action-button" />
        </div>
    );
};

const oneOrMorePayoutsAreInProgress = (payouts) => {
    if (!payouts) {
        return false;
    }

    for (let payout of payouts) {
        const blockedTypes = (payout.blocked && payout.blocked.blocked_types) || [];
        if (blockedTypesHasStatusUnderPayment(blockedTypes)) {
            return true;
        }
    }

    return false;
};

const getFlaggedUserReason = (reason) => {
    if (!reason) {
        return null;
    }
    const {
        numberOfProjectsWithNegativeRating,
        numberOfProjectsDeleted,
        numberOfUserRedmarks,
        numberOfRefundedProjects,
        numberOfReports,
        numberOfOtherUsersWithSameVerification,
    } = reason;
    let text = [];
    if (numberOfProjectsWithNegativeRating > 0) {
        text.push(<p>{`Spleiser med negativ rating: ${numberOfProjectsWithNegativeRating}`}</p>);
    }
    if (numberOfProjectsDeleted > 0) {
        text.push(<p>{`Slettede spleiser: ${numberOfProjectsDeleted}`}</p>);
    }
    if (numberOfUserRedmarks > 0) {
        text.push(<p>{`Markering på bruker: ${numberOfUserRedmarks}`}</p>);
    }
    if (numberOfRefundedProjects > 0) {
        text.push(<p>{`Refunderte spleiser: ${numberOfRefundedProjects}`}</p>);
    }
    if (numberOfReports > 0) {
        text.push(<p>{`Rapporteringer: ${numberOfReports}`}</p>);
    }
    if (numberOfOtherUsersWithSameVerification > 0) {
        text.push(<p>{`Antall andre brukere: ${numberOfOtherUsersWithSameVerification}`}</p>);
    }

    return text;
};

const ShowMoreText = ({ text }) => {
    const [showMore, setShowMore] = useState(false);
    if (text?.length < 1) {
        return null;
    }

    return (
        <>
            {showMore && (
                <>
                    <hr></hr>
                    <div>{text}</div>
                </>
            )}
            {text && (
                <>
                    <hr></hr>
                    <ButtonLink
                        onClick={() => {
                            setShowMore(!showMore);
                        }}
                    >
                        {showMore ? 'Skjul hvorfor' : 'Se hvorfor'}
                    </ButtonLink>
                </>
            )}
        </>
    );
};

const renderFlagInfo = (flag) => {
    if (!flag.value) {
        return null;
    }

    let text = '';
    let reasonText = '';
    if (flag.type === 'keyword') {
        text = `Inneholder ordet "${flag.reason}"`;
    } else if (flag.type === 'ownerScore') {
        reasonText = getFlaggedUserReason(flag.reason);
        text = 'Innsamler er mistenkelig';
    } else if (flag.type === 'receiverScore') {
        reasonText = getFlaggedUserReason(flag.reason);
        text = 'Mottaker er mistenkelig';
    } else {
        return null;
    }
    return (
        <div className="project-flags">
            <p>{text}</p>
            <p>flagget {moment(flag.flagged_at).format(DATE_FORMAT)}</p>
            <ShowMoreText text={reasonText} />
        </div>
    );
};

export const Project = ({ project, projectId, fetchData }) => {
    const [payouts, setPayouts] = useState([]);
    const [payoutAggregate, setPayoutAggregate] = useState(null);
    const [toBePaidOut, setToBePaidOut] = useState(null);
    const [invoices, setInvoices] = useState([]);
    const [creditedInvoices, setCreditedInvoices] = useState([]);

    useEffect(() => {
        const updatePayouts = () => {
            api.get(`${spleisUrl}/api/admin/payout/project/${projectId}`)
                .then((payouts) => setPayouts(payouts))
                .catch(() => {
                    setPayouts([]);
                });
        };
        updatePayouts();
        window.subscribe(updatePayouts);

        return () => window.unsubscribe(updatePayouts);
    }, [projectId]);

    useEffect(() => {
        const updatePayoutAggregate = () => {
            api.get(`${spleisUrl}/api/admin/payout/project/${projectId}/payout-aggregate`)
                .then((payoutAggregate) => setPayoutAggregate(payoutAggregate || {}))
                .catch(() => showError('Feil ved henting av utbetalingssammendrag'));
        };
        updatePayoutAggregate();
        window.subscribe(updatePayoutAggregate);

        return () => window.unsubscribe(updatePayoutAggregate);
    }, [projectId]);

    useEffect(() => {
        const updateInvoices = () => {
            api.get(`${paymentUrl}/api/invoice/project/${projectId}`)
                .then((res) => {
                    setInvoices(res.filter((invoice) => !invoice.creditnote_at));
                    setCreditedInvoices(res.filter((invoice) => invoice.creditnote_at));
                })
                .catch(() => showError('Feil ved henting av fakturaer'));
        };
        updateInvoices();
        window.subscribe(updateInvoices);

        return () => window.unsubscribe(updateInvoices);
    }, [projectId]);

    useEffect(() => {
        const updateToBePaidOut = () => {
            if (project.published_at && project.collected_amount > 0) {
                api.get(`${spleisUrl}/api/admin/payout/project/${projectId}/remaining`)
                    .then((toBePaidOut) => setToBePaidOut(toBePaidOut))
                    .catch(() => {
                        setToBePaidOut(null);
                    });
            }
        };
        updateToBePaidOut();
        window.subscribe(updateToBePaidOut);

        return () => window.unsubscribe(updateToBePaidOut);
    }, [projectId, project]);

    const approveFlags = () => {
        api.post(`${spleisUrl}/api/admin/project/${project.id}/approve-flags`)
            .then(() => {
                showInfo('Flagg godkjent');
                window.notify();
                fetchData();
            })
            .catch(() => {
                showError('Klarte ikke å godkjenne flagg');
            });
    };

    const giveAutoApproveFeedback = (feedback) => {
        api.put(`${spleisUrl}/api/admin/project/${project.id}/auto-approve-feedback`, { feedback })
            .then(() => {
                showInfo('Tilbakemelding lagret');
                window.notify();
            })
            .catch(() => {
                showError('Klarte ikke å lagre tilbakemelding');
            });
    };

    const giveAutoCategorizationFeedback = (feedback) => {
        api.put(`${spleisUrl}/api/admin/project/${project.id}/auto-categorization-feedback`, { feedback })
            .then(() => {
                showInfo('Tilbakemelding lagret');
                window.notify();
            })
            .catch(() => {
                showError('Klarte ikke å lagre tilbakemelding');
            });
    };

    const now = moment();
    const latestPayoutOrPublishedAt = moment(payoutAggregate?.latest_payout_at || project.published_at);
    const shouldBePaidOutOrRefundedByDate = latestPayoutOrPublishedAt.clone().add(9, 'months');
    const shouldShowWarningFromDate = shouldBePaidOutOrRefundedByDate.clone().subtract(1, 'months');
    const bannerWarningPayoutOrRefundContent = now.isBefore(shouldBePaidOutOrRefundedByDate)
        ? `Innsamlingen burde bli utbetalt eller refundert før ${shouldBePaidOutOrRefundedByDate.format(
              'LL'
          )} (${shouldBePaidOutOrRefundedByDate.fromNow()})`
        : 'Innsamlingen burde vært refundert eller betalt ut.';
    const shouldShowBannerWarningPayoutOrRefund =
        payoutAggregate != null &&
        now.isAfter(shouldShowWarningFromDate) &&
        parseInt(project.collected_amount) > parseInt(payoutAggregate?.total_collected_amount_at_latest_payout || 0);

    const blockedTypes = (project.blocked && project.blocked.blocked_types) || [];
    const projectIsUnderPayment = oneOrMorePayoutsAreInProgress(payouts);

    let srcSet = null;
    if (project.images?.img_urls) {
        srcSet = `${project.images.img_urls.cropped500} 500w,
                  ${project.images.img_urls.cropped800} 800w,
                  ${project.images.img_urls.cropped1200} 1200w,
                  ${project.images.img_urls.cropped1800} 1800w
                `;
    }

    return (
        <ProjectContext.Provider value={project}>
            <Helmet>
                <title>{project.title ? `«${project.title}»` : ''}</title>
            </Helmet>
            <div className="project-container">
                <header className="task-header project-header">
                    <VisibleIf isVisible={srcSet || !!project.img_url}>
                        <div className={styles.coverImgContainer}>
                            <img className={styles.coverImg} src={project.img_url} srcSet={srcSet} />
                        </div>
                    </VisibleIf>

                    <ProjectActions
                        project={project}
                        projectIsUnderPayment={projectIsUnderPayment}
                        fetchData={fetchData}
                    />

                    <header>
                        <ProjectTitle project={project} />
                        <h2 className="project-subtitle">
                            {!!project?.profile?.fundAsGiftCode && (
                                <p>
                                    Fond i gave:{' '}
                                    <a
                                        href={`${spleisFrontUrl}/gi-fond/${project.profile.fundAsGiftCode}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {project.profile.fundAsGiftCode}
                                    </a>
                                </p>
                            )}
                            <a
                                className="project-id"
                                href={`${spleisFrontUrl}/${project.id}`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                #{project.id}
                            </a>
                            <FavoriteProject projectId={projectId} />
                            {project.shortLink ? (
                                <div>
                                    <a
                                        className="short-link"
                                        href={`${spleisFrontUrl}/${project.shortLink.short}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        (/{project.shortLink.short})
                                    </a>
                                </div>
                            ) : null}
                            {project.template_id && (
                                <div className="template-link">
                                    Mal: <Link to={`/cms/template/${project.template_id}`}>{project.template_id}</Link>
                                </div>
                            )}
                        </h2>
                    </header>

                    <section className="project-statistics">
                        <VisibleIf isVisible={!!project.published_at}>
                            <ul className="project-statistics-numbers">
                                <li>
                                    Innsamlet:
                                    <div className="project-collected-stats">
                                        {formatMoneyKr(project.collected_amount)}
                                        {project.moneygoal && parseInt(project.moneygoal, 0)
                                            ? ` av ${formatMoneyKr(project.moneygoal)}, ${
                                                  project.goaltype === 'TARGET' ? 'Fast' : 'Fleksibelt'
                                              }`
                                            : ''}
                                    </div>
                                </li>
                                <li>Givere: {project.giver_count}</li>
                            </ul>
                        </VisibleIf>
                    </section>
                </header>

                {project?.id && <ProjectAlerts projectId={project.id} />}

                <VisibleIf isVisible={isMarkedForRefund(project)}>
                    <Banner
                        title={`Prosjektet er lagt opp for refundering og vil bli refundert ${formatDateOnly(
                            project.marked_for_refund_at
                        )}`}
                        themeClass="warning"
                    />
                </VisibleIf>
                <VisibleIf isVisible={!!project.refunded_at}>
                    <Banner
                        title={`Prosjektet ble refundert ${formatDate(project.refunded_at)}`}
                        themeClass="warning"
                    />
                </VisibleIf>
                <VisibleIf isVisible={project.can_self_serve_refund}>
                    <Banner themeClass="error" title={'Selvbetjent refusjon er aktivert!'} />
                </VisibleIf>
                <VisibleIf isVisible={project.autoApproved}>
                    <div className={styles.autoInfo}>
                        <Banner title={`Prosjektet har automatisk godkjenning 🤖`} themeClass="info" />
                        <VisibleIf isVisible={!project.hasAutoApproveFeedback}>
                            <p>Er den automatiske vurderinga riktig?</p>
                            <div className={styles.autoButtons}>
                                <Button onClick={() => giveAutoApproveFeedback(true)}>Ja</Button>
                                <Button onClick={() => giveAutoApproveFeedback(false)}>Nei</Button>
                            </div>
                        </VisibleIf>
                    </div>
                </VisibleIf>
                <VisibleIf isVisible={project.auto_categorized}>
                    <div className={styles.autoInfo}>
                        <Banner title={`Prosjektet har automatisk kategorisering 📊`} themeClass="info" />
                        <VisibleIf isVisible={project.auto_categorization_feedback === null}>
                            <p>Er den automatiske kategoriseringen riktig?</p>
                            <div className={styles.autoButtons}>
                                <Button onClick={() => giveAutoCategorizationFeedback(true)}>Ja</Button>
                                <Button onClick={() => giveAutoCategorizationFeedback(false)}>Nei</Button>
                            </div>
                        </VisibleIf>
                    </div>
                </VisibleIf>
                <VisibleIf isVisible={!!project.deleted}>
                    <Banner title={`Prosjektet har blitt slettet`} themeClass="warning" />
                </VisibleIf>
                <VisibleIf isVisible={shouldShowBannerWarningPayoutOrRefund}>
                    <Banner title={`${bannerWarningPayoutOrRefundContent}`} themeClass="warning" />
                </VisibleIf>

                <VisibleIf isVisible={!!(blockedTypes && blockedTypes.includes('BLOCKED_FOR_TRANSACTIONS'))}>
                    <Banner title="Blokkert for innbetalinger" themeClass="warning" />
                </VisibleIf>
                <VisibleIf isVisible={!!project?.content_hidden}>
                    <Banner title="Spleisen er sensurert" themeClass="warning" />
                </VisibleIf>
                <VisibleIf isVisible={!!project?.will_be_paused_at}>
                    <Banner
                        title={`Vil bli blokkert for innbetalinger ${moment(project.will_be_paused_at).format(
                            DATE_FORMAT
                        )}`}
                        themeClass="warning"
                    />
                </VisibleIf>
                <VisibleIf isVisible={!!(blockedTypes && blockedTypes.includes('BLOCKED_FOR_REFUND'))}>
                    <Banner title="Blokkert for refundering" themeClass="warning" />
                </VisibleIf>
                <VisibleIf isVisible={!!(blockedTypes && blockedTypes.includes('BLOCKED_FOR_PAYOUT'))}>
                    <Banner title="Blokkert for utbetaling" themeClass="warning" />
                </VisibleIf>
                <VisibleIf isVisible={project?.blocked?.flags?.filter((f) => !f.approved_at).length > 0}>
                    <FunctionalBanner
                        title="Flagget"
                        themeClass="warning"
                        buttonCallback={() => approveFlags()}
                        buttonText="Godkjenn flagg"
                    >
                        {project?.blocked?.flags?.filter((f) => !f.approved_at).map((f) => renderFlagInfo(f))}
                    </FunctionalBanner>
                </VisibleIf>

                <div className="task-body project-body">
                    <Timeline data={buildTimeline(project)} />

                    <OwnerAndReceiver project={project} projectId={projectId} />

                    <section className="project-purpose-container">
                        <ProjectPurpose project={project} />
                    </section>

                    <section className="project-description-container">
                        <ProjectDescription project={project} />
                    </section>
                    <p>
                        <a
                            href={`${spleisFrontUrl}/${project.id}`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >{`${spleisFrontUrl}/${project.id}`}</a>
                    </p>

                    <RewardsWithData id={project.id} />
                    <ProjectClaims project={project} />
                    <MatchingGift project={project} />
                    <section className="payouts-container">
                        <ProjectPayouts payouts={payouts} toBePaidOut={toBePaidOut} project={project} />
                        {project.id && <TransactionsWithData id={project.id} showUserId={true} />}
                        <InvoiceTable title="Fakturaer" data={invoices} />
                        <RefundsWithData id={project.id} />
                        <InvoiceTable title="Krediterte fakturaer" data={creditedInvoices} />
                        <ProjectVersionsWithData id={project.id} project={project} />
                        <ProjectUpdatesWithData id={project.id} project={project} />
                        <MoneyLaunderingInfo project={project} projectId={project.id} />
                    </section>
                    {project?.id && <ProjectSummary project={project} />}
                </div>
            </div>
        </ProjectContext.Provider>
    );
};

export const ProjectDetail = (props) => (
    <>
        <ProjectTags id={props.project.id} />
        <ProjectCategories project={props.project} id={props.project.id} withExplanation />
        <Reports lookupKey="project" id={props.project.id} />
        <Ratings id={props.project.id} project={props.project} />
        <Comments lookupKey="project" id={props.project.id} postToSlack={false} canCollapse={true} />
        <CustomMessage project={props.project} />
        <Audits lookupKey="projectId" id={props.project.id} />
    </>
);

class ProjectTask extends Component {
    render() {
        const project = this.props.data || {} || {};

        return (
            <TaskContainer detail={<ProjectDetail project={project} />} detailClass={styles.detailClass}>
                <Project
                    projectId={this.props.match.params.projectId}
                    project={project}
                    fetchData={this.props.fetchData}
                />
            </TaskContainer>
        );
    }
}

export default withData(
    ProjectTask,
    (id) => `${spleisUrl}/api/admin/project/${id}`,
    (props) => props.match.params.projectId
);
