import FormButton from '../FormButton/FormButton';
import { Search } from '../Menu/Menu';
import { spleisUrl } from '../config';
import React, { useState } from 'react';
import api from '../api';
import styles from './MatchingGift.module.scss';
import { showError, showInfo } from '../store';

const CreateMatchingGift = ({ project }) => {
    const [org, setOrg] = useState(null);
    const [maxAmount, setMaxAmount] = useState(null);
    const [email, setEmail] = useState(null);

    const createMatchingGift = async () => {
        try {
            await api.post(`${spleisUrl}/api/admin/project/matching-gift`, {
                projectId: project.id,
                organizationId: org,
                maxAmount,
                email,
            });
            showInfo('Matching gift opprettet');
            window.notify();
        } catch (e) {
            showError('Det oppsto en feil da vi skulle opprette matching gift');
        }
    };

    if (project.matchingGift?.matching) {
        return null;
    }

    return (
        <FormButton title={`Opprett matching gift`} onClick={createMatchingGift}>
            <div className={styles.matchingGiftCreateForm}>
                <Search
                    id="org-search-box"
                    searchUrl={`${spleisUrl}/api/admin/organization/search`}
                    placeholder="Finn org"
                    onClick={(id) => {
                        setOrg(id);
                    }}
                    listItem={(option) => (
                        <div>
                            <div>{option.object.name ? option.object.name : 'Ukjent'}</div>
                            <div style={{ fontSize: '0.8em', color: '#676767' }}>
                                {option.object.organization_number} (#
                                {option.object.id})
                            </div>
                        </div>
                    )}
                    selectedItem={(option) => <div>{option.object.name ? option.object.name : 'Ukjent'}</div>}
                />
                <input
                    placeholder={'Maks beløp'}
                    type={'number'}
                    value={maxAmount}
                    onChange={(e) => setMaxAmount(e.target.value)}
                />
                <input placeholder={'E-post'} type={'email'} value={email} onChange={(e) => setEmail(e.target.value)} />
            </div>
        </FormButton>
    );
};

export default CreateMatchingGift;
