import React, { Component } from 'react';
import moment from 'moment';
import AccountingTable from './AccountingTable';
import { Helmet } from 'react-helmet';

export default class UnmappedData extends Component {
    render() {
        return (
            <>
                <Helmet>
                    <title>Ikke-mappede refusjoner</title>
                </Helmet>
                <AccountingTable
                    url={'unmapped-refunds'}
                    columns={[
                        { Header: 'ID', accessor: 'id', minWidth: 40, minResizeWidth: 30 },
                        { Header: 'Prosjekt', accessor: 'to_project', minWidth: 40, minResizeWidth: 30 },
                        {
                            Header: 'Beløp',
                            accessor: 'amount',
                            minWidth: 40,
                            minResizeWidth: 30,
                            Cell: (row) => <span>{Math.round(row.value).toLocaleString()} kr.</span>,
                        },
                        {
                            Header: 'Dato',
                            accessor: 'refunded_at',
                            minWidth: 200,
                            minResizeWidth: 30,
                            Cell: (row) => <span>{moment(row.value).format('lll')}</span>,
                        },
                        { Header: 'Type', accessor: 'type', minResizeWidth: 30 },
                    ]}
                />
            </>
        );
    }
}
