import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import 'moment/locale/nb';
import { Route } from 'react-router-dom';
import TaskList from '../TaskList/TaskList';

import api from '../api';
import { spleisUrl } from '../config';

import './Payout.scss';
import Project from '../Project/Project';
import TaskListSelect from '../TaskList/TaskListSelect';
import ListOverview from '../ListOverview/ListOverview';
import MainContainer from '../MainContainer/MainContainer';
import HelmetForSubpages from '../HelmetForSubpages/HelmetForSubpages';
import { useLocation, useParams } from 'react-router';
import useQueryString from 'use-query-string';
import { SORT_OPTIONS } from '../Project/ProjectSort/ProjectSort';
import { filterProjectsGlobal } from '../ProjectListFilter/ProjectListFilter';

moment.locale('nb');

export const FILTER_OPTIONS = [
    { value: 'ALL', title: 'Alle', defaultSort: 'collected-amount-desc' },
    { value: 'NOT_ENOUGH_MONEY_TO_PAYOUT', title: 'Har ikke nådd målet', defaultSort: 'collected-amount-desc' },
    { value: 'PAYOUT_IN_TRANSIT_PAYOUT_FILE', title: 'Lagt i utbetalingsfil', defaultSort: 'collected-amount-desc' },
    {
        value: 'READY_FOR_PAYOUT_PRIVATE',
        title: 'Legg opp til utbetaling (privat)',
        defaultSort: 'collected-amount-desc',
    },
    {
        value: 'READY_FOR_PAYOUT_ORGANIZATION',
        title: 'Legg opp til utbetaling (org)',
        defaultSort: 'collected-amount-desc',
    },
    { value: 'MISSING_ACCOUNT_NUMBER', title: 'Mangler kontonummer', defaultSort: 'collected-amount-desc' },
    {
        value: 'ORGANIZATION_WAITING_FOR_CONFIRMATION',
        title: 'Mangler org.-bekreftelse for utbetaling',
        defaultSort: 'collected-amount-desc',
    },
    {
        value: 'MISSING_VERIFIED_ACCOUNT_NUMBER',
        title: 'Mangler verifisert kontonummer',
        defaultSort: 'collected-amount-desc',
    },
    {
        value: 'MISSING_VERIFIED_ACCOUNT_NUMBER_AND_DOES_NOT_HAVE_EVENT_ID',
        status: 'MISSING_VERIFIED_ACCOUNT_NUMBER',
        predicate: (todo) => !todo.event_id,
        title: 'Mangler verifisert kontonummer (ikke eventer)',
        defaultSort: 'collected-amount-desc',
    },
    {
        value: 'MISSING_VERIFIED_ACCOUNT_NUMBER_WHERE_ORG_COULD_BE_USED',
        title: 'Mangler verifisert kontonummer hvor org har verifisert kontonummer',
        defaultSort: 'collected-amount-desc',
    },
    {
        value: 'ORGANIZATION_IS_MISSING_VERIFIED_BANK_ACCOUNT',
        title: 'Org. mangler verifisert konto',
        defaultSort: 'collected-amount-desc',
    },
    { value: 'ORGANIZATION_NOT_VERIFIED', title: 'Organisasjon ikke verifisert', defaultSort: 'collected-amount-desc' },
    {
        value: 'PROJECT_BLOCKED_FOR_PAYOUT',
        title: 'Prosjekt blokkert for utbetaling',
        defaultSort: 'collected-amount-desc',
    },
    {
        value: 'PROJECT_BLOCKED_FOR_PAYOUT_AND_DOES_NOT_HAVE_EVENT_ID',
        status: 'PROJECT_BLOCKED_FOR_PAYOUT',
        predicate: (todo) => !todo.event_id,
        title: 'Prosjekt blokkert for utbetaling (ikke eventer)',
        defaultSort: 'collected-amount-desc',
    },
    { value: 'PAYOUT_WAITING_FOR_BANK_TRANSACTION', title: 'Under utbetaling', defaultSort: 'collected-amount-desc' },
    { value: 'PAYOUT_BLOCKED_FOR_PAYOUT', title: 'Utbetaling blokkert', defaultSort: 'collected-amount-desc' },
];

const Payout = () => {
    const params = useParams();
    const projectId = parseInt(params.projectId, 10);
    const location = useLocation();
    const [query, setQuery] = useQueryString(location, (path) => window.history.pushState(null, null, path));
    const filter = query?.filter ? FILTER_OPTIONS.find((f) => f.value === query.filter) : FILTER_OPTIONS[0];
    const sort = query?.sort
        ? SORT_OPTIONS.find((s) => s.value === query.sort)
        : SORT_OPTIONS.find((s) => s.value === filter.defaultSort) || SORT_OPTIONS[0];
    const [payoutTodos, setPayoutTodos] = useState(null);
    const [filteredAndSorted, setFilteredAndSorted] = useState([]);
    const [refreshPayoutTodos, setRefreshPayoutTodos] = useState({});

    const todoShouldBeIncluded = (todo, filter) => {
        const value = filter.status || filter.value;
        const included = value === 'ALL' || todo.status === value || todo.statuses?.includes(value);
        if (!included) {
            return false;
        }
        if (filter.predicate) {
            return filter.predicate(todo);
        }
        return true;
    };

    useEffect(() => {
        fetchPayoutTodos().then((todos) => {
            const filteredPayoutTodos = todos.filter((todo) => todoShouldBeIncluded(todo, filter));
            const filteredAndSortedPayoutTodos = sort.fn(filteredPayoutTodos);
            setPayoutTodos(todos);
            setFilteredAndSorted(filterProjectsGlobal(filteredAndSortedPayoutTodos));
        });
    }, [filter, sort, refreshPayoutTodos]);

    const onChangeFilter = (filterValue) => {
        const filterOption = FILTER_OPTIONS.find((f) => f.value === filterValue);
        const sortOption = SORT_OPTIONS.find((s) => s.value === filterOption.defaultSort) || SORT_OPTIONS[0];
        setQuery({
            filter: filterOption.value,
            sort: sortOption.value,
        });
    };

    const onChangeSort = (sortValue) => {
        setQuery({
            sort: sortValue,
        });
    };

    const fetchPayoutTodos = async () => {
        try {
            if (payoutTodos) {
                return payoutTodos;
            }
            return await api.get(`${spleisUrl}/api/admin/payout/todos`);
        } catch (e) {
            return [];
        }
    };

    const clearCache = useCallback(() => {
        setPayoutTodos(null);
    }, [setPayoutTodos]);

    const refreshData = useCallback(() => {
        setTimeout(() => setRefreshPayoutTodos({}), 250);
    }, []);

    useEffect(() => {
        window.subscribe(clearCache);
        window.subscribe(refreshData);

        return () => {
            window.unsubscribe(clearCache);
            window.unsubscribe(refreshData);
        };
    }, []);

    return (
        <MainContainer>
            <HelmetForSubpages title="Utbetalinger" />
            <div className={`task-list-container ${!projectId ? 'block' : ''}`}>
                <div className="task-list-wrapper">
                    <header className="tasks-header">
                        <TaskListSelect
                            options={FILTER_OPTIONS}
                            onChange={(e) => onChangeFilter(e.target.value)}
                            selected={filter}
                        />
                        <TaskListSelect
                            options={SORT_OPTIONS}
                            onChange={(e) => onChangeSort(e.target.value)}
                            selected={sort}
                        />
                        <div className="tasks-header-subtitle">{filteredAndSorted.length} innsamlinger</div>
                    </header>
                    <ul className="task-list">
                        {filteredAndSorted.map((p) => (
                            <TaskList
                                project={p}
                                link={`/payouts/${p.id}?filter=${filter.value}&sort=${sort.value}`}
                                key={p.id}
                                selected={projectId === parseInt(p.id, 0)}
                            />
                        ))}
                    </ul>
                </div>
            </div>
            <Route path="/payouts/:projectId" component={Project} />
            {!projectId && (
                <ListOverview
                    type={'utbetalinger'}
                    items={FILTER_OPTIONS.map((filterOption) => {
                        const items = payoutTodos?.filter((todo) => todoShouldBeIncluded(todo, filterOption));
                        return {
                            title: filterOption.title,
                            callback: () => onChangeFilter(filterOption.value),
                            items: filterProjectsGlobal(items || []),
                        };
                    })}
                />
            )}
        </MainContainer>
    );
};

export default Payout;
