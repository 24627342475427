import React, { useEffect, useState } from 'react';
import api from '../api';
import { spleisUrl } from '../config';
import { showError } from '../store';
import Button from '../Button/Button';
import styles from './Templates.module.scss';
import history from '../history';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const Templates = () => {
    const [templates, setTemplates] = useState({});
    const [projectIdToCopy, setprojectIdToCopy] = useState(null);
    useEffect(() => {
        api.get(`${spleisUrl}/api/admin/template/all`)
            .then((res) => setTemplates(res))
            .catch(() => showError('Feil ved henting av maler fra baksystem', 3));
    }, []);

    const createTemplateFromProject = () => {
        api.post(`${spleisUrl}/api/admin/template`, { projectId: projectIdToCopy })
            .then((res) => {
                setTemplates({
                    templates: [res, ...templates.templates],
                    templatesForTemplateCauses: [...templates.templatesForTemplateCauses],
                });
                history.push(`/cms/template/${res.id}`);
            })
            .catch(() => showError('Feil ved henting av maler fra baksystem', 3));
    };

    const activeTemplateCauses = templates.templatesForTemplateCauses?.filter((t) => t?.template_cause?.active);
    const inactiveTemplateCauses = templates.templatesForTemplateCauses?.filter((t) => !t?.template_cause?.active);

    return (
        <div className={styles['templates-container']}>
            <Helmet>
                <title>Prosjektmaler</title>
            </Helmet>
            <div className="task-header">
                <h1 className="task-title">Prosjektmaler</h1>
            </div>
            <div className={styles['templates-create-new']}>
                <label className={styles['template-create-label']}>Lag mal fra prosjekt</label>
                <input
                    className={styles['template-create-input']}
                    onChange={(e) => setprojectIdToCopy(e.target.value)}
                    placeholder="Id til prosjekt"
                />
                <Button onClick={createTemplateFromProject} className={styles['template-save-button']}>
                    Lag ny mal
                </Button>
            </div>

            <div>
                <h2>Prosjektmaler</h2>
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <td>ID</td>
                            <td>Navn</td>
                            <td>Antall spleiser</td>
                        </tr>
                    </thead>
                    <tbody>
                        {templates.templates?.map((t) => {
                            return (
                                <tr key={t.id}>
                                    <td>{t.id}</td>
                                    <td>
                                        <a href={`/cms/template/${t.id}`}>
                                            {t.template_title || t.title || (
                                                <span className={styles.noTemplateTitle}>Ingen tittel</span>
                                            )}
                                        </a>
                                    </td>
                                    <td>{t.project_count}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>

            <div>
                <h2>Kampanjer</h2>
                <h3>Opprettet av organisasjonene selv</h3>
                <h4>Aktive ({activeTemplateCauses?.length})</h4>
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <td>ID</td>
                            <td>Navn</td>
                            <td>Org.</td>
                            <td>Antall spleiser</td>
                        </tr>
                    </thead>
                    <tbody>
                        {activeTemplateCauses?.map((t) => {
                            return (
                                <tr key={t.id}>
                                    <td>{t.id}</td>
                                    <td>
                                        <Link to={`/cms/template/${t.id}`}>
                                            {t.template_cause.title || t.template_title || t.title || (
                                                <span className={styles.noTemplateTitle}>Ingen tittel</span>
                                            )}
                                        </Link>
                                    </td>
                                    <td>
                                        <Link to={`/org/${t.template_cause.organization_id}`}>
                                            {t.template_cause.organization_id}
                                        </Link>
                                    </td>
                                    <td>{t.project_count}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>

                <h4>Ikke-aktive ({inactiveTemplateCauses?.length})</h4>
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <td>ID</td>
                            <td>Navn</td>
                            <td>Org.</td>
                            <td>Antall spleiser</td>
                        </tr>
                    </thead>
                    <tbody>
                        {inactiveTemplateCauses?.map((t) => {
                            return (
                                <tr key={t.id}>
                                    <td>{t.id}</td>
                                    <td>
                                        <Link to={`/cms/template/${t.id}`}>
                                            {t.template_cause.title || t.template_title || t.title || (
                                                <span className={styles.noTemplateTitle}>Ingen tittel</span>
                                            )}
                                        </Link>
                                    </td>
                                    <td>
                                        <Link to={`/org/${t.template_cause.organization_id}`}>
                                            {t.template_cause.organization_id}
                                        </Link>
                                    </td>
                                    <td>{t.project_count}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default Templates;
