import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import moment from 'moment';

const MapInvoiceFilter = ({ fromDate, toDate, amount, handleInputChangeAndSearch, performSearch }) => {
    return (
        <div className="accounting-filter">
            <label>
                Beløp
                <input
                    name={'amount'}
                    value={amount}
                    onChange={handleInputChangeAndSearch}
                    onKeyPress={e => {
                        if (e.key === 'Enter') {
                            performSearch();
                        }
                    }}
                />
            </label>
            <label>
                Fra og med dato
                <DatePicker
                    selected={fromDate}
                    onChange={date => {
                        handleInputChangeAndSearch({
                            target: {
                                name: 'fromDate',
                                value: date,
                            },
                        });
                    }}
                />
            </label>
            <label>
                Til og med dato
                <DatePicker
                    utcOffset={moment().utcOffset()}
                    selected={toDate}
                    onChange={date => {
                        handleInputChangeAndSearch({
                            target: {
                                name: 'toDate',
                                value: date,
                            },
                        });
                    }}
                />
            </label>
            <button onClick={performSearch}>Søk</button>
        </div>
    );
};

MapInvoiceFilter.propTypes = {
    project: PropTypes.string,
    fromDate: PropTypes.object,
    toDate: PropTypes.object,
    handleInputChangeAndSearch: PropTypes.func.isRequired,
    performSearch: PropTypes.func.isRequired,
};

export default MapInvoiceFilter;
