import api from '../api';
import { useEffect, useState } from 'react';
import { spleisUrl } from '../config';

export const useProjectBankAccount = ({ project }) => {
    const [bankAccount, setBankAccount] = useState(null);
    const payoutBankAccount = project?.payout_bank_account_id;

    const fetchBankAccount = () => {
        if (payoutBankAccount) {
            api.get(`${spleisUrl}/api/admin/bank-account/${payoutBankAccount}`).then(bankAccount =>
                setBankAccount(bankAccount)
            );
        }
    }

    useEffect(() => {
        fetchBankAccount()
    }, [payoutBankAccount]);

    useEffect(() => {
        window.subscribe(fetchBankAccount);
        return () => window.unsubscribe(fetchBankAccount);
    })

    return { bankAccount };
};
