import React, { Component } from 'react';
import moment from 'moment';
import 'moment/locale/nb';
import { Link, Route } from 'react-router-dom';
import api from '../api';
import { spleisUrl } from '../config';
import VisibleIf from '../VisibleIf/VisibleIf';
import ButtonLink from '../ButtonLink/ButtonLink';
import { sortObjectArrayByDateFieldOrId } from '../dates';
import './Organizations.scss';
import Organization, { VERIFICATION_STATUSES } from '../Org/Org';
import queryString from 'qs';
import ListOverview from '../ListOverview/ListOverview';
import MainContainer from '../MainContainer/MainContainer';
import Subscription from '../Subscription/Subscription';
import { Helmet } from 'react-helmet';

moment.locale('nb');

const TODO = 'TODO';
const IN_WORK = 'IN_WORK';
const BLOCKED_WAITING_FOR_RESPONSE = 'BLOCKED_WAITING_FOR_RESPONSE';
const BLOCKED_ARCHIVED = 'BLOCKED_ARCHIVED';

const FILTERS = [
    {
        name: VERIFICATION_STATUSES.IN_WORK,
        value: 'IN_WORK',
        func: (org) => org.verification_status === IN_WORK,
    },
    {
        name: VERIFICATION_STATUSES.BLOCKED_WAITING_FOR_RESPONSE,
        value: 'BLOCKED_WAITING_FOR_RESPONSE',
        func: (org) => org.verification_status === BLOCKED_WAITING_FOR_RESPONSE,
    },
    {
        name: 'Klar for verifisering',
        value: 'READY',
        func: (org) => {
            return (
                isNotArchived(org) &&
                hasBankAccount(org) &&
                hasMainContact(org) &&
                (!org.verification_status || org.verification_status === TODO)
            );
        },
    },
    {
        name: 'Mangler kontonummer',
        value: 'MISSING_ACCOUNT_NUMBER',
        func: (org) => {
            return isNotArchived(org) && !hasBankAccount(org);
        },
    },
    {
        name: 'Ikke verifisert kontonummer',
        value: 'NOT_VERIFIED_ACCOUNT_NUMBER',
        func: (org) => {
            if (isNotArchived(org) && hasBankAccount(org)) {
                return !org.main_accounts[0].verified_at;
            }
            return false;
        },
    },
    {
        name: 'Mangler kontaktperson',
        value: 'MISSING_MAIN_CONTACT',
        func: (org) => {
            return isNotArchived(org) && !hasMainContact(org);
        },
    },
    {
        name: 'Ikke verifisert kontaktperson',
        value: 'NOT_VERIFIED_MAIN_CONTACT',
        func: (org) => {
            if (isNotArchived(org) && hasMainContact(org)) {
                return !org.main_contacts[0].verified_at;
            }
            return false;
        },
    },
    {
        name: 'Ikke godkjent kontaktpersonkandidat',
        value: 'NOT_APPROVED_MAIN_CONTACT_CANDIDATE',
        func: (org) => isNotArchived(org) && hasContactPersonCandidates(org),
    },
    {
        name: 'Har innsamling og mangler kontaktperson',
        value: 'HAS_PROJECT_MISSING_MAIN_CONTACT',
        func: (org) => isNotArchived(org) && org.activeWithoutContactPerson,
    },
    {
        name: 'Alle uverifiserte',
        value: 'NOT_VERIFIED',
        func: (org) => {
            return isNotArchived(org);
        },
    },
    { name: 'Arkiverte', value: 'ACTIVATED', func: (org) => org.verification_status === BLOCKED_ARCHIVED },
    { name: 'Verifiserte', value: 'VERIFIED', func: (org) => !!org.verified_at },
    { name: 'Favoritter', value: 'FAVORITES', func: (org) => !!org.favorite },
];

const isNotArchived = (org) => {
    return org.verification_status !== BLOCKED_ARCHIVED;
};

const hasBankAccount = (org) => {
    return (
        org.main_accounts && org.main_accounts.length > 0 && org.main_accounts[0] && org.main_accounts[0].account_number
    );
};

const hasMainContact = (org) => {
    return org.main_contacts && org.main_contacts.length > 0 && org.main_contacts[0];
};

const hasContactPersonCandidates = (org) => org.contact_candidates && org.contact_candidates.length > 0;

const OrgListItem = (props) => {
    const { id, name } = props.organization;

    return (
        <li className={`org-list-item-container${props.selected ? ' org-selected' : ''}`}>
            <Link className="org-list-item" to={props.link}>
                <span className="org-list-item-id">#{id}</span>
                <h4 className="org-list-item-name">{name}</h4>
            </Link>
        </li>
    );
};

class Organizations extends Component {
    constructor(props) {
        super(props);
        this.state = {
            organizations: [],
            filter: FILTERS[0],
        };
    }

    fetchData = () => {
        const fetchNotVerifiedOrganizations = api.get(`${spleisUrl}/api/admin/organization/not-verified`);
        const fetchContactPersonCandidates = api.get(`${spleisUrl}/api/admin/organization/contact-candidates`);

        const activeOrgsWithoutContactPerson = api.get(
            `${spleisUrl}/api/admin/organization/active-without-contact-person`
        );
        const favoriteOrganizations = api.get(`${spleisUrl}/api/admin/organization/favorites`);

        Promise.all([
            fetchNotVerifiedOrganizations,
            fetchContactPersonCandidates,
            activeOrgsWithoutContactPerson,
            favoriteOrganizations,
        ])
            .then(([orgs, contacts, activeOrgs, favorites]) => {
                const organizations = orgs.map((org) => ({
                    ...org,
                    contact_candidates: contacts.filter((c) => c.org_id === org.id),
                    activeWithoutContactPerson: activeOrgs.some((activeOrg) => activeOrg.id === org.id),
                    favorite: favorites.some((favoriteOrg) => favoriteOrg.id === org.id),
                }));
                const favoriteOrganizations = favorites.map((org) => ({ ...org, favorite: true }));
                return [
                    ...organizations,
                    ...favoriteOrganizations.filter((fav) => !organizations.find((o) => o.id === fav.id)),
                ];
            })
            .then((organizations) => {
                this.setState({
                    organizations: sortObjectArrayByDateFieldOrId(organizations, 'created_at'),
                });
            })
            .catch((err) => console.error(err));
    };

    componentDidMount() {
        const query = queryString.parse(this.props.location.search.slice(1));
        if (!!query.filter) {
            this.updateFilterList(query.filter);
        }
        window.subscribe(this.fetchData);
        this.fetchData();
    }

    componentWillUnmount() {
        window.unsubscribe(this.fetchData);
    }

    updateFilterList(filterValue) {
        const filter = FILTERS.find((t) => t.value === filterValue);
        this.setState({
            filter,
        });
        this.props.history.push({
            pathname: this.props.location.pathname,
            search: filter.value && `?filter=${filter.value}`,
        });
    }

    render() {
        if (!this.state) {
            return null;
        }

        const filteredOrganizations = this.state.organizations.filter(this.state.filter.func);
        const selectedOrganizationId = parseInt(this.props.match.params.id, 10);

        return (
            <MainContainer>
                <Helmet>
                    <title>Organisasjoner</title>
                </Helmet>
                <VisibleIf isVisible={!!this.props.match.params.id}>
                    <ButtonLink className="back-link" onClick={this.props.history.goBack}>
                        &larr; Tilbake
                    </ButtonLink>
                </VisibleIf>
                <div className={`org-list-container ${!this.props.match.params.id ? 'block' : ''}`}>
                    <div className="org-list-wrapper">
                        <header className="org-header">
                            <h2 className="org-list-title">Organisasjoner</h2>
                            <select
                                className="org-select"
                                onChange={(e) => this.updateFilterList(e.target.value)}
                                value={this.state.filter.value}
                            >
                                {FILTERS.map((filter) => (
                                    <option key={filter.value} value={filter.value}>
                                        {filter.name}
                                    </option>
                                ))}
                            </select>
                            <div className="org-header-subtitle">{filteredOrganizations.length} organisasjoner</div>
                        </header>
                        <ul className="org-list">
                            {filteredOrganizations.map((o) => (
                                <OrgListItem
                                    organization={o}
                                    link={`/organizations/${o.id}`}
                                    key={o.id}
                                    selected={selectedOrganizationId === parseInt(o.id, 0)}
                                />
                            ))}
                        </ul>
                    </div>
                </div>
                <Route exact path="/organizations/:organizationId/subscription" component={Subscription} />
                <Route exact path="/organizations/:id" component={Organization} />
                {!selectedOrganizationId && (
                    <ListOverview
                        type={'organisasjoner'}
                        items={FILTERS.map((f) => ({
                            title: f.name,
                            callback: () => this.updateFilterList(f.value),
                            items: this.state.organizations.filter(f.func),
                        }))}
                    />
                )}
            </MainContainer>
        );
    }
}

export default Organizations;
