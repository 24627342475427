import { Link, Route, Switch, useParams, withRouter } from 'react-router-dom';

import React from 'react';
import './ContentManagement.scss';
import Shortlinks from '../Shortlinks/Shortlinks';
import PromotedProjects from './PromotedProjects';
import ObservationKeywords from '../ObservationKeywords/ObservationKeywords';
import Templates from '../Template/Templates';
import ShowTemplate from '../Template/ShowTemplate';
import MainContainer from '../MainContainer/MainContainer';
import TaskContainer from '../TaskContainer/TaskContainer';
import Giveaways from '../Giveaways/Giveaways';
import { Helmet } from 'react-helmet';
import HelmetForSubpages from '../HelmetForSubpages/HelmetForSubpages';

const ContentManagement = () => {
    const params = useParams();

    const currentSubpage = params?.slug;
    const isSelected = (page) => {
        return currentSubpage === page;
    };

    return (
        <MainContainer>
            <HelmetForSubpages title="CMS" />
            <div className={`task-list-container ${!currentSubpage ? 'block' : ''}`}>
                <div className="task-list-wrapper">
                    <header className="tasks-header">
                        <h2 className="cms-header">CMS</h2>
                    </header>
                    <ul className="task-list cms-task-list">
                        <li
                            className={`cms-menu-item ${
                                isSelected('/cms/shortlinks/') ? 'cms-menu-item-selected' : ''
                            }`}
                        >
                            <Link className={'cms-menu-item'} to={`/cms/shortlinks/`}>
                                Shortlinks
                            </Link>
                        </li>
                        <li
                            className={`cms-menu-item ${
                                isSelected('/cms/favourites/') ? 'cms-menu-item-selected' : ''
                            }`}
                        >
                            <Link className={'cms-menu-item'} to={`/cms/favourites/`}>
                                Utvalgte innsamlinger
                            </Link>
                        </li>
                        <li
                            className={`cms-menu-item ${
                                isSelected('/cms/observation-keywords/') ? 'cms-menu-item-selected' : ''
                            }`}
                        >
                            <Link className={'cms-menu-item'} to={`/cms/observation-keywords/`}>
                                Ord til observasjon
                            </Link>
                        </li>
                        <li
                            className={`cms-menu-item ${isSelected('/cms/templates/') ? 'cms-menu-item-selected' : ''}`}
                        >
                            <Link className={'cms-menu-item'} to={`/cms/templates/`}>
                                Prosjektmaler
                            </Link>
                        </li>
                        <li
                            className={`cms-menu-item ${isSelected('/cms/giveaways/') ? 'cms-menu-item-selected' : ''}`}
                        >
                            <Link className={'cms-menu-item'} to={`/cms/giveaways/`}>
                                Giveaways
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
            <TaskContainer>
                <Switch>
                    <Route path="/cms/shortlinks" component={Shortlinks} />
                    <Route path="/cms/favourites" component={PromotedProjects} />
                    <Route path="/cms/observation-keywords" component={ObservationKeywords} />
                    <Route path="/cms/template/:id" component={ShowTemplate} />
                    <Route path="/cms/templates" component={Templates} />
                    <Route path="/cms/giveaways" component={Giveaways} />
                </Switch>
            </TaskContainer>
        </MainContainer>
    );
};

export default withRouter(ContentManagement);
